<template>
	<div class="questions_taker_container">
		<div v-if="loading" class="background">
			<div v-if="loading" class="text-center lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>
		<div class="timer_display">
			<md-icon> schedule </md-icon>
			<p>Time Left: {{ formattedTime }}</p>
		</div>
		<div style="width: 100%; display: flex; align-items: center">
			<md-icon style="margin: 0 !important; font-size: 2rem !important; margin-right: 1rem">edit</md-icon>
			<h1>{{ quizTitle }}</h1>
		</div>
		<form class="questions_taker_form" @submit.prevent="submitQuiz(ApplicationStatus.COMPLETED)">
			<div class="question" v-for="(item, index) in quizItems" :key="`item-${item.id}-${index}`">
				<md-card class="md-layout-item md-size-100">
					<md-card-content>
						<h2 :class="{ unanswered: unansweredQuestionsIds.includes(item.questionId) }">{{ item.question }}</h2>

						<!-- Images -->
						<div v-if="item.image.contentUri" class="image_container">
							<img
								:src="item.image.contentUri"
								:alt="item.title"
								style="width: auto; height: auto; object-fit: contain"
								@click="openModal(item.image.contentUri, item.title)"
							/>
						</div>

						<!-- ZoomModal -->
						<ZoomModal v-if="showModal" :imageSrc="currentImageSrc" :imageAlt="currentImageAlt" @close="closeModal" />

						<!-- Conditional rendering for questions -->
						<div v-if="item.question">
							<!-- Text questions -->
							<md-field v-if="item.type === null">
								<md-input type="text" v-model="responses[item.id]" @input="updateResponse(item.id, $event.target.value)" :placeholder="item.description"></md-input>
							</md-field>
							<!-- Choice questions -->
							<template v-if="item.options">
								<div class="answers" v-for="(option, optionIndex) in item.options" :key="optionIndex">
									<input
										:type="item.type"
										:id="`choice-${index}-${optionIndex}`"
										:name="`choice-${index}`"
										:value="option.value"
										@change="updateResponse(index, option.value)"
									/>
									<label :for="`choice-${index}-${optionIndex}`">{{ option.value }}</label>
								</div>
							</template>
						</div>
					</md-card-content>
				</md-card>
			</div>
			<md-button class="md-success" type="submit">Submit</md-button>
		</form>
		<modal v-if="error">
			<template #header>
				<h4 class="modal-title black">Whoa there! 🤚</h4>
			</template>
			<template #body>
				<h4>{{ errorMessage }}</h4>
			</template>
			<template #footer>
				<md-button class="md-success" @click="closeErrorModal"> Ok </md-button>
			</template>
		</modal>
	</div>
</template>

<script>
import { ApplicationStatus } from '../constants/application-status';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import db from '@/firebase/init';
import Modal from '@/components/Modal.vue';
import slugify from 'slugify';
import ZoomModal from '@/components/ZoomModal.vue';

import { logSystemError } from '../helpers/error.helper';
import { errorMessages } from '@/constants/messages.js';
export default {
	name: 'QuizComponent',
	components: {
		Modal,
		ZoomModal,
	},
	data() {
		return {
			showModal: false,
			currentImageSrc: '',
			currentImageAlt: '',
			ApplicationStatus,
			quizTitle: '',
			quizItems: [],
			responses: [],
			timer: null,
			timeLeft: null,
			userAlias: '',
			testStatus: null,
			skillName: '',
			loading: true,
			error: false,
			getVettingTestError: false,
			errorMessage: '',
			slug: '',
			unansweredQuestionsIds: [],
			testTime: 600,
			startTime: null,
			skillItem: null,
			jobName: '',
			userEmail: '',
		};
	},
	async created() {
		await this.initializeQuiz();
	},
	computed: {
		formattedTime() {
			const minutes = Math.floor(this.timeLeft / 60);
			const seconds = Math.floor(this.timeLeft % 60);
			return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
		},
	},
	beforeDestroy() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	},
	methods: {
		openModal(imageSrc, imageAlt) {
			this.currentImageSrc = imageSrc;
			this.currentImageAlt = imageAlt;
			this.showModal = true;
		},
		closeModal() {
			this.showModal = false;
		},
		closeErrorModal() {
			this.error = false;
			const formId = this.$route.params.formId;

			if (this.getVettingTestError) {
				this.stopTimer();
				if (formId) {
					this.$router.go(-1);
				} else {
					this.$router.push({ name: 'vetting-tests', params: { jobId: this.$route.params.jobId, skillName: this.$route.params.skillName } });
				}
			}
		},
		async initializeQuiz() {
			await this.fetchUserAlias();
			await this.getJobName();
			await this.calculateRemainingTime();
			await this.getVettingTest();
			this.initializeTimer();
		},
		async fetchUserAlias() {
			const userSnapshot = await db.collection('users').where('userId', '==', firebase.auth().currentUser.uid).get();
			userSnapshot.forEach(doc => {
				this.userAlias = doc.data().alias;
				this.userEmail = doc.data().email;
				this.slug = slugify(this.userAlias + ' ' + this.$route.params.jobId, {
					replacement: '-',
					remove: /[$*_+~.()'"!\-:@]/g,
					lower: true,
				});
			});
		},
		async getJobName() {
			const jobSnapshot = await db.collection('jobs').where('jobId', '==', this.$route.params.jobId).get();
			jobSnapshot.docs.forEach(doc => {
				this.jobName = doc.data().name;
			});
		},
		async calculateRemainingTime() {
			const docId = `${this.userAlias}-${this.$route.params.jobId}-${this.$route.params.formId}`;
			const skillTestDoc = db.collection('vettingTests').doc(docId);
			try {
				const doc = await skillTestDoc.get();
				if (doc.exists) {
					this.startTime = new Date(doc.data().created.seconds * 1000 + doc.data().created.nanoseconds / 1000000);
					const currentTime = new Date();
					this.testStatus = doc.data().testStatus;

					const timeDifference = (currentTime - this.startTime) / 1000;
					if (timeDifference > this.testTime) {
						this.timeLeft = 0;
						this.submitQuiz(ApplicationStatus.TIMED_OUT, true);
					} else {
						this.timeLeft = this.testTime - timeDifference;
						this.initializeTimer();
					}
				} else {
					this.timeLeft = this.testTime;
					this.initializeTimer();
				}
			} catch (error) {
				this.error = true;
			}
		},
		initializeTimer() {
			if (!this.loading) {
				this.timeLeft = this.timeLeft || this.testTime;
				this.startTimer();
			}
		},
		async startTimer() {
			if (this.timer) {
				clearInterval(this.timer);
			}

			const skillName = this.$route.params.skillName.trim().toLowerCase();
			const jobId = this.$route.params.jobId;
			const formId = this.$route.params.formId;
			const docId = `${this.userAlias}-${jobId}-${formId}`;
			const cachedTestRef = db.collection('cachedTests').doc(docId);
			const cachedTestSnapshot = await cachedTestRef.get();
			const timmerStatus = cachedTestSnapshot.exists ? true : false;

			await cachedTestRef.set(
				{
					skillItem: `${skillName}-${jobId}`,
					skillData: {
						skill: skillName,
						timmer: timmerStatus,
					},
				},
				{ merge: true }
			);
			this.timer = setInterval(() => {
				this.timeLeft--;
				if (this.timeLeft <= 0) {
					clearInterval(this.timer);
					if (this.isStillOnQuizPage()) {
						this.submitQuiz(ApplicationStatus.TIMED_OUT, true);
					}
				}
			}, 1000);
		},
		isStillOnQuizPage() {
			return this.$route.name === 'vetting-test';
		},
		stopTimer() {
			clearInterval(this.timer);
			this.timer = null;
		},
		async submitQuiz(testStatus, autoSubmit = false) {
			if (!autoSubmit) {
				this.unansweredQuestionsIds = this.quizItems.filter(item => !this.responses.some(response => response.questionId === item.questionId)).map(item => item.questionId);

				if (this.unansweredQuestionsIds.length > 0) {
					this.error = true;
					this.errorMessage = `You haven’t answered some questions. Go back to the test to see which questions you have missed`;
					return;
				}
			}
			try {
				this.loading = true;
				this.stopTimer();
				const docId = `${this.userAlias}-${this.$route.params.jobId}-${this.$route.params.formId}`;
				const scoreVettingTest = firebase.functions().httpsCallable('scoreVettingTest');
				await scoreVettingTest({
					userAlias: this.userAlias,
					skill: this.$route.params.skillName,
					jobId: this.$route.params.jobId,
					vettingTestId: docId,
					answers: this.responses,
					vettingTestStatus: testStatus,
				});
				await db.collection('cachedTests').doc(docId).delete();
				this.loading = false;
				this.$emit('bypassRoutingCheck', true);
				if (!this.error) {
					this.$router.push({ name: 'vetting-tests', params: { jobId: this.$route.params.jobId, skillName: this.$route.params.skillName } });
				}
			} catch (error) {
				this.stopTimer();
				this.$emit('bypassRoutingCheck', true);
				this.error = true;
				this.getVettingTestError = true;
				this.errorMessage = 'There was an error submitting the test. Please contact Jobox Support for assistance.';
			}
		},
		updateResponse(index, value) {
			const question = this.quizItems[index];
			if (!question) {
				return;
			}

			const existingResponseIndex = this.responses.findIndex(response => response.questionId === question.questionId);
			const newResponse = { answer: value, questionId: question.questionId };

			if (existingResponseIndex !== -1) {
				this.$set(this.responses, existingResponseIndex, newResponse);
			} else {
				this.responses.push(newResponse);
			}
		},
		async getVettingTest() {
			this.loading = true;
			let errMessage = '';
			try {
				const { formId, jobId } = this.$route.params;
				const docId = `${this.userAlias}-${jobId}-${formId}`;
				const skillTestRef = db.collection('vettingTests').doc(docId);
				const skillTestDoc = await skillTestRef.get();
				const cachedTestRef = db.collection('cachedTests').doc(docId);
				const cachedTestDoc = await cachedTestRef.get();
				if (!skillTestDoc.exists) {
					const getVetting = firebase.functions().httpsCallable('getVettingTest');
					const result = await getVetting({
						formId: formId,
						jobAlias: jobId,
						userAlias: this.userAlias,
						skill: this.$route.params.skillName,
					});
					const vettingTest = result.data;
					await cachedTestRef.set(vettingTest);
					this.quizTitle = vettingTest.title;
					this.quizItems = vettingTest.questions;
				} else {
					if (cachedTestDoc.exists) {
						const cachedVettingTest = cachedTestDoc.data();
						this.quizItems = cachedVettingTest.questions;
						this.quizTitle = cachedVettingTest.title;
					} else {
						const skillTest = skillTestDoc.data();

						if (typeof skillTest.pass === 'boolean') {
							this.$emit('bypassRoutingCheck', true);
							this.$router.push({
								name: 'completed-vetting-test',
								params: { jobName: this.jobName, skillName: this.$route.params.skillName, jobId: this.$route.params.jobId },
							});
						} else {
							this.error = true;
							errMessage = `You have already completed this vetting test (${this.$route.params.skillName}) for this job.`;
						}

						this.getVettingTestError = true;
						this.errorMessage = errMessage;
					}
				}
				this.loading = false;
			} catch (error) {
				this.stopTimer();
				this.$emit('bypassRoutingCheck', true);
				console.log(error.message);
				logSystemError(this.userAlias, this.userEmail, firebase.auth().currentUser.uid, this.$route.fullPath, {
					message: error.message,
					stack: error.stack,
					name: error.name,
					code: error.code || 'N/A',
					additionalData: {
						formId: this.$route.params.formId,
						jobId: this.$route.params.jobId,
						skillName: this.$route.params.skillName,
					},
				});
				this.loading = false;
				this.error = true;
				this.getVettingTestError = true;
				this.errorMessage = errorMessages.TEST_NOT_FOUND;
			}
		},
	},
};
</script>

<style scoped>
.timer_display {
	position: fixed;
	top: 10px;
	right: 100px;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	z-index: 100000;
	display: flex;
	align-items: center;
}

.timer_display > p {
	font-weight: bold;
	margin: 0; /* Remove default margins */
	margin-left: 10px;
}
.questions_taker_container {
	background-color: white;
	padding: 2rem;
	width: 1000px;
	margin: auto;
	border-radius: 10px;
	position: relative;
	overflow: auto;
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 16px;
	font-weight: bold;
}

label {
	font-size: 14px;
	margin-left: 10px;
}
.image_container {
	position: relative;
	width: 100%;
	background-color: rgb(239, 239, 239);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	border-radius: 10px;
	cursor: pointer;
	overflow: hidden;
}

.image_container img {
	transition: transform 0.3s ease;
	width: 100%;
	height: auto;
	object-fit: contain;
}

.image_container::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	transition: background-color 0.3s ease;
	border-radius: 10px;
}

.image_container:hover::before {
	background-color: rgba(0, 0, 0, 0.5);
}

.image_container:hover img {
	transform: scale(1.05);
}

.question {
	margin: 30px 0 30px 0;
}

.answers {
	margin-top: 1rem;
}

.background {
	width: 100vw !important;
	height: 100vh !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.unanswered {
	color: red;
}

@media (max-width: 800px) {
	.questions_taker_container {
		width: 700px;
	}
}
@media (max-width: 700px) {
	.questions_taker_container {
		width: 600px;
	}

	.image_container {
		padding: 0;
	}
}
@media (max-width: 600px) {
	.questions_taker_container {
		width: 500px;
	}
}
@media (max-width: 500px) {
	.questions_taker_container {
		width: 400px;
	}
}
@media (max-width: 400px) {
	.questions_taker_container {
		width: 300px;
	}
}
</style>
