<template>
	<div class="content" v-if="!approved">
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<hr />

		<div v-if="isOfferDeclined">
			<h2 class="centre">Response Sent!</h2>
		</div>
		<div v-else>
			<h2 class="centre">Application Sent!</h2>
		</div>

		<hr />
		<div v-if="isOfferDeclined">
			<p class="centre">We will let you know on the outcome of your response</p>
		</div>
		<div v-else>
			<p class="centre">We will let you know on the outcome of your application</p>
		</div>

		<hr />
		<client-status-profile
			:companyName="this.client.companyName"
			:clientName="this.client.clientName"
			:clientAlias="client.clientAlias"
			:name="this.client.name"
			:picture="this.picture"
			:cardUserImage="cardUserImage"
		/>
		<div class="centre">
			<md-button @click="cancelModal = true" class="md-danger"> Withdraw Application </md-button>
		</div>
		<!-- Modal: Cancel -->
		<modal v-if="cancelModal" @close="cancelModalHide">
			<template slot="header">
				<h4 class="modal-title black">Withdraw Application</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="cancelModalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<p class="black">You are now choosing to withdraw your application for this job. Would you like to proceed?</p>
			</template>

			<template slot="footer">
				<div style="text-align: center">
					<md-button class="md-button md-danger" @click="cancelModalHide">No</md-button>
					&nbsp;&nbsp;&nbsp;
					<md-button class="md-button md-success" @click="studentCancelSelect(applicant)">Yes</md-button>
				</div>
			</template>
		</modal>
	</div>
	<div v-else class="content">
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<hr />
		<h2 class="centre">Application Successful!</h2>
		<hr />
		<p class="centre">Congratulations you have been selected for the job</p>
		<hr />
		<client-status-profile
			:companyName="this.client.companyName"
			:clientName="this.client.clientName"
			:clientAlias="client.clientAlias"
			:name="this.client.name"
			:picture="this.picture"
			:cardUserImage="cardUserImage"
			:applicationStatus="applicationStatus"
		/>
		<div class="centre">
			<p><b>I would like to:</b></p>
			<md-button @click="openReasonsModal" class="md-danger"> Decline Job </md-button>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			<md-button @click="acceptOffer" class="md-success"> Accept Job </md-button>
		</div>

		<!-- Modal: Decline -->
		<!-- Step 3 -->
		<modal v-if="declineModal" @close="declineModalHide">
			<template slot="header">
				<h4 class="modal-title black">Whoa there! ✋</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="declineModalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<p class="black">Are you sure you want to turn down the job offer?</p>
			</template>

			<template slot="footer">
				<div style="text-align: center">
					<md-button class="md-button md-danger" @click="declineModalHide">No</md-button>
					&nbsp;&nbsp;&nbsp;

					<md-button class="md-button md-success" @click="sendStudentResponse">Yes</md-button>
				</div>
			</template>
		</modal>

		<modal v-if="confirmSelection" @close="closeConfirmSelectionModal">
			<template slot="header">
				<h4 class="modal-title black">Whoa there! ✋</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="closeConfirmSelectionModal">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<p class="black">Please make a selection for a reason for declining the offer</p>
			</template>

			<template slot="footer">
				<div style="text-align: center">
					<md-button class="md-button md-success" @click="closeConfirmSelectionModal">OK</md-button>
				</div>
			</template>
		</modal>

		<modal v-if="closeReasonModal">
			<template slot="header">
				<h4 class="modal-title black">Please specify the reasons for rejecting the offer</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideReasonsModal">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<md-table v-model="rejectionReasons" md-card @md-selected="onSelect">
					<md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple">
						<md-table-cell>{{ item.reason }}</md-table-cell>
					</md-table-row>
				</md-table>
			</template>

			<template slot="footer">
				<div class="centre" style="text-align: center">
					<md-button class="md-button md-danger" @click="hideReasonsModal"> Cancel</md-button>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<md-button class="md-button md-success" @click="confirmDeclineModal"> Confirm</md-button>
				</div>
			</template>
		</modal>

		<modal v-if="sendResponseModal">
			<template slot="body">
				<p>Thank you for your reponse. We will be in touch with the employer's response</p>
			</template>

			<template slot="footer">
				<md-button class="md-button md-success" @click="closeResponseModal"> Okay</md-button>
			</template>
		</modal>
	</div>
</template>
<script>
import firebase from 'firebase/compat/app';
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Modal } from '@/components';
import { mapGetters, mapActions } from 'vuex';
import { OfferRejectionReasons } from '@/constants/offerRejectionReasons';
import ClientStatusProfile from '@/components/Profile/ClientStatusProfile.vue';

export default {
	components: {
		Modal,
		ClientStatusProfile,
	},
	computed: {
		isReasonSelected() {
			return this.selectedDeclineReasons.length > 0;
		},
		isOfferDeclined() {
			return !this.approved && this.status == 'decline';
		},
	},
	data() {
		return {
			applicationStatus: null,
			rejectionReasons: OfferRejectionReasons,
			selectedDeclineReasons: [],
			client: {},
			approved: false,
			cancelModal: false,
			declineModal: false,
			loading: true,
			applicant: {},
			picture: null,
			closeReasonModal: false,
			salary: false,
			startDate: false,
			offer: false,
			privacy: false,
			sendResponseModal: false,
			confirmSelection: false,
			status: '',
		};
	},
	props: {
		cardUserImage: {
			type: String,
			default: '/img/dashboard/client/card-1.jpg',
		},
	},
	methods: {
		...mapActions(['studentDeclineSelect', 'studentCancelSelect', 'acceptJob']),
		cancelModalHide() {
			this.cancelModal = false;
		},
		declineModalHide() {
			this.declineModal = false;
			this.selectedDeclineReasons = [];
		},
		hideReasonsModal() {
			this.closeReasonModal = false;
		},
		openReasonsModal() {
			this.closeReasonModal = true;
		},
		sendStudentResponse() {
			this.closeReasonModal = false;
			this.sendResponseModal = true;
			this.declineModal = false;
		},
		confirmDeclineModal() {
			if (!this.isReasonSelected) {
				this.closeReasonModal = false;
				this.confirmSelection = true;
			} else {
				this.closeReasonModal = false;
				this.declineModal = true;
			}
		},
		closeConfirmSelectionModal() {
			this.confirmSelection = false;
		},
		async closeResponseModal() {
			this.sendResponseModal = false;
			this.approved = false;
			this.status == 'decline';
			this.studentDeclineSelect(this.applicant);
			let declineReasonList = this.selectedDeclineReasons.map(x => x.reason);
			await sendReject(declineReasonList, this.client.clientName, this.client.clientEmail, this.applicant.applicant, this.applicant.applicantEmail, this.client.name);
		},
		onSelect(items) {
			this.selectedDeclineReasons = items;
		},
		async acceptOffer() {
			this.acceptJob(this.applicant);
			await sendAcceptOffer(this.client.clientName, this.client.clientEmail, this.applicant.applicant, this.applicant.applicantEmail, this.client.name);
		},
	},
	created() {
		let job = db.collection('micros').doc(this.$route.params.id);
		job.get().then(doc => {
			this.client = doc.data();
			this.client.id = doc.id;
			db.collection('clients')
				.doc(this.client.clientAlias)
				.get()
				.then(doc => {
					this.picture = doc.data().profile;
				});
		});

		this.user = firebase.auth().currentUser;
		let applicants = db.collection('applications');
		applicants
			.where('jobId', '==', this.$route.params.id)
			.where('studentId', '==', this.user.uid)
			.where('approved', '==', true)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.applicant = doc.data();
					this.applicant.id = doc.id;
					this.approved = true;
					this.status = this.applicant.status;
				});
			});

		if (!this.approved) {
			applicants
				.where('jobId', '==', this.$route.params.id)
				.where('studentId', '==', this.user.uid)
				.get()
				.then(snapshot => {
					snapshot.forEach(doc => {
						this.applicant = doc.data();
						this.applicant.id = doc.id;
						this.status = this.applicant.status;
					});
				});
		}
		applicants.onSnapshot(snapshot => {
			snapshot.docChanges().forEach(change => {
				if (change.type == 'modified') {
					this.applicant = change.doc.data();
					this.applicant.id = change.doc.id;
					this.approved = this.applicant.approved;
				}
			});
		});
		this.loading = false;
	},
};
async function sendReject(selectedDeclineReasons, employerName, employerEmail, studentName, studentEmail, jobTitle) {
	const sendRejection = firebase.functions().httpsCallable('sendStudentRejectsJob');
	try {
		const params = {
			selectedDeclineReasons,
			employerName,
			employerEmail,
			studentName,
			studentEmail,
			jobTitle,
		};
		const response = await sendRejection(params);
		return response;
	} catch (e) {
		console.log({ e });
	}
}

async function sendAcceptOffer(employerName, employerEmail, studentName, studentEmail, jobName) {
	const sendEmployeerandStudentAcceptOffer = firebase.functions().httpsCallable('sendStudentAcceptsJob');
	try {
		const params = {
			employerName,
			employerEmail,
			studentName,
			studentEmail,
			jobName,
		};
		const response = await sendEmployeerandStudentAcceptOffer(params);
		return response;
	} catch (e) {
		console.log({ e });
	}
}
</script>

<style scoped>
thead {
	display: none;
}
</style>
