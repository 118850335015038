import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import router from '@/routes/routes';
import api from '@/store/api/api';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import moment from 'moment';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

export default {
	state: {
		message:
			"In the collection 'ContentManagent', the document id 'Login Error Modal' may be missing.\n\nPlease click the link to review the confluence page to see how to resolve the issue. ",
		solution: 'https://jobox.atlassian.net/wiki/spaces/JWA/pages/103677976/Login+Error+Modal',
		otherMessage: "Contact tech support immediately. Issue is related to the collection 'ContentManagent' in the document id 'Login Error Modal'",
		header: null,
		body: null,
		footer: null,
		modal: false,
		loading: false,
		user: null,
		client: null,
		student: null,
		content: null,
		redirectToRegister: false,
		userList: [],
	},
	getters: {
		headerL: state => state.header,
		bodyL: state => state.body,
		footerL: state => state.footer,
		modalL: state => state.modal,
		loadingL: state => state.loading,
	},
	mutations: {
		modalHide: state => {
			state.modal = false;
			if (state.redirectToRegister) {
				router.push({ name: 'Register' }).catch(err => console.error(err));
				state.redirectToRegister = false;
			}
		},
		setLoading(state, value) {
			state.loading = value;
		},
		showModal(state, payload) {
			state.modal = true;
			state.header = payload.header;
			state.body = payload.body;
			state.footer = payload.footer;
			state.error = payload.error;
		},
		hideModal(state) {
			state.modal = false;
		},
		setUser(state, user) {
			state.user = user;
		},
		setClient(state, client) {
			state.client = client;
		},
	},
	actions: {
		modalHide: context => {
			context.commit('modalHide');
		},
		async login(context, payload) {
			if (payload.email && payload.password) {
				context.commit('setLoading', true);

				try {
					const auth = getAuth();
					const userCredential = await signInWithEmailAndPassword(auth, payload.email, payload.password);

					const userSnapshot = await db.collection('users').where('userId', '==', userCredential.user.uid).get();

					if (!userSnapshot.empty) {
						const userPromises = userSnapshot.docs.map(async doc => {
							const userData = doc.data();
							context.commit('setUser', userData);
							if (userData.user === 'client') {
								if (auth.currentUser.emailVerified) {
									if ('accessType' in userData) {
										context.commit('setLoading', false);
										router.push({ name: 'client-profile', params: { id: userData.clientAlias } });
									} else {
										const client = await db.collection('clients').doc(doc.id).get();
										const clientData = client.data();

										if (client.exists && clientData.accountCreated) {
											context.commit('setClient', clientData);
											context.commit('setLoading', false);
											router.push({ name: 'client-profile', params: { id: doc.id } });
										} else {
											context.commit('setLoading', false);
											router.push({ name: 'create-client-account' });
										}
									}
								} else if (!auth.currentUser.emailVerified) {
									context.commit('setLoading', false);
									router.push({ name: 'verify-client-users' });
								}
							} else if (userData.user === 'student') {
								if (auth.currentUser.emailVerified) {
									const student = await db.collection('students').doc(doc.id).get();

									if (student.exists && student.data().accountCreated) {
										context.commit('setLoading', false);
										router.push({ name: 'student-profile', params: { id: doc.id } });
									} else {
										context.commit('setLoading', false);
										router.push({ name: 'create-student-account' });
									}
								} else if (!auth.currentUser.emailVerified) {
									context.commit('setLoading', false);
									router.push({ name: 'verify-student-users' });
								}
							}
						});

						await Promise.all(userPromises);
					} else {
						context.commit('setLoading', false);
						context.commit('showModal', {
							header: 'Whoa there! ✋',
							body: 'Jobox user account not found. Please create a new account on the Jobox platform',
							footer: 'Got it',
							error: true,
						});
					}
				} catch (error) {
					context.commit('setLoading', false);
					context.commit('showModal', {
						header: 'Whoa there! ✋',
						body: 'You have entered the wrong password or email. Please check your login details and try again.',
						footer: 'Got it',
						error: true,
					});
				}
			} else {
				context.commit('setLoading', false);
				handleError(context, new Error('Email and password are required.'));
			}
		},
	},
};

function handleError(commit, error) {
	db.collection('ContentManagement')
		.doc('Login Error Modal')
		.get()
		.then(doc => {
			const content = doc.data();

			commit('showModal', {
				header: content.header,
				body: error.message,
				footer: content.footer,
				error: true,
			});
		})
		.catch(errCMS => {
			const message = error.message;
			if (errCMS.message == "Cannot read property 'header' of undefined") {
				const args = { type: 'errors', subject: errCMS.message, message: message };
				// Send error to tech support
				api.notification(args)
					.then(() => {
						commit('showModal', {
							header: 'Whoa there! ✋',
							body: message,
							footer: 'Got it',
						});
					})
					.catch(errAPI => {
						db.collection(args.type).add({
							jobId: null,
							created: moment(Date.now()).format('L'),
							issue: errAPI.message,
							message: "API post call to 'notification' failed. Contact tech support immediately.",
						});
						db.collection(args.type).add({
							jobId: null,
							created: moment(Date.now()).format('L'),
							issue: errCMS.message,
						});
					});
			} else {
				const args = { type: 'errors', subject: errCMS.message, message: commit.otherMessage };
				// Send error to tech support
				api.notification(args)
					.then(() => {
						commit('showModal', {
							header: 'Whoa there! ✋',
							body: message,
							footer: 'Got it',
						});
					})
					.catch(errAPI => {
						db.collection(args.type).add({
							jobId: null,
							created: moment(Date.now()).format('L'),
							issue: errAPI.message,
							message: "API post call to 'notification' failed. Contact tech support immediately.",
						});
						db.collection(args.type).add({
							jobId: null,
							created: moment(Date.now()).format('L'),
							issue: errCMS.message,
						});
					});
			}
		});
}
