<template>
	<div class="md-layout">
		<div class="md-layout-item">
			<md-card>
				<div class="mobile-filter-container">
					<h1 class="data-table-heading">Bursaries</h1>
					<i class="fa-solid fa-user-doctor"></i>

					<md-card-content>
						<md-card v-for="(bursary, index) in bursaryData" :key="index">
							<md-card-header>
								<div class="md-title">{{ bursary.title }}</div>
							</md-card-header>

							<md-card-content>
								<div class="row">
									<md-icon class="md-size-1x" style="color: darkorange; margin-right: 10px">business</md-icon>
									{{ bursary.companyName }}
								</div>
							</md-card-content>

							<md-card-content>
								<div class="row">
									<md-icon class="md-size-1x" style="color: green; margin-right: 10px">event</md-icon>
									{{ bursary.applicationDeadline }}
								</div>
							</md-card-content>

							<md-card-actions>
								<router-link :to="{ name: 'view-bursary', params: { id: bursary.bursaryId } }">
									<md-button class="jb-standard">View</md-button>
								</router-link>
							</md-card-actions>
						</md-card>
					</md-card-content>
				</div>
			</md-card>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		bursaryData: {},
	},
};
</script>

<style scoped>
.md-table + .md-table {
	margin-top: 16px;
}

.md-table-cell-container {
	display: flex;
	justify-content: flex-start;
}

.md-table-cell-container .md-button {
	width: auto !important;
}

.md-card .md-card-content {
	padding: 5px 20px;
}

.data-table-heading {
	display: flex;
	align-items: center;
	justify-content: center;
}

.filter-row {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.mobile-filter-container {
	position: relative;
	padding: 1rem;
}
@media (max-width: 680px) {
	.data-table-heading {
		font-size: 30px;
	}

	.md-layout-item {
		padding: 0;
	}

	.md-card .md-title {
		font-size: 16px;
	}
}
</style>
