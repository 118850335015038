<template>
	<div>
		<h5 class="info-text">Have one last final look at the job you are about to post</h5>
		<div class="md-layout">
			<md-card>
				<md-card-content>
					<collapse
						:collapse="
							jobType === 'Once-off Project/Task'
								? ['Job Description', 'Job Requirements', 'Job Information', 'Cost Breakdown']
								: ['Job Description', 'Job Requirements', 'Job Information']
						"
						icon="keyboard_arrow_down"
						color-collapse="success"
					>
						<template slot="md-collapse-pane-1">
							<md-card class="bg-success">
								<md-card-content>
									<h3 class="card-category card-category-social" style="text-align: center"><i class="fas fa-list-ul"></i> Job Description</h3>
									<h4 class="card-title">Job Name</h4>
									<p class="card-description">{{ name }}</p>

									<h4 class="card-title">Job Description</h4>
									<p class="card-description" v-html="description"></p>

									<h4 class="card-title">Job Title</h4>
									<p class="card-description">{{ jobCategory }}</p>

									<h4 class="card-title">Skills Required</h4>
									<ul v-if="selectedSkills">
										<li v-for="(skill, index) in selectedSkills" :key="index" class="card-description">{{ skill.skillName }}</li>
									</ul>
									<h4 class="card-title">Salary Range</h4>
									<p class="card-description">{{ budgetFormat }}</p>
								</md-card-content>
							</md-card>
						</template>
						<template slot="md-collapse-pane-2">
							<md-card class="bg-success">
								<md-card-content>
									<h3 class="card-category card-category-social" style="text-align: center"><i class="fas fa-dungeon"></i> Job Requirements</h3>
									<h4 class="card-title">Minimum Level of Education</h4>
									<p class="card-description">{{ education }}</p>

									<h4 class="card-title">Minimum Work Experience</h4>
									<p class="card-description">{{ experience }}</p>
								</md-card-content>
							</md-card>
						</template>
						<template slot="md-collapse-pane-3">
							<md-card class="bg-success">
								<md-card-content>
									<h3 class="card-category card-category-social" style="text-align: center"><i class="fas fa-clipboard-list"></i> Job Information</h3>
									<h4 class="card-title">Location</h4>
									<p class="card-description">{{ location }}</p>

									<h4 class="card-title">Application Deadline</h4>
									<p class="card-description">{{ formattedDate }}</p>

									<h4 v-if="jobType === 'Once-off Project/Task'" class="card-title">Estimated Duration</h4>
									<p v-if="jobType === 'Once-off Project/Task'" class="card-description">{{ deadlineData }}</p>

									<h4 v-if="jobType !== 'Once-off Project/Task'" class="card-title">Working Days</h4>
									<ul v-if="jobType !== 'Once-off Project/Task'">
										<li v-for="(day, index) in selectedDaysOfTheWeek" :key="index" class="card-description">{{ day }}</li>
									</ul>

									<h4 v-if="jobType === 'Internship' || jobType === 'Part-time'" class="card-title">Working Hours Per Week</h4>
									<p v-if="jobType === 'Internship' || jobType === 'Part-time'" class="card-description">{{ selectedHours }}</p>
								</md-card-content>
							</md-card>
						</template>
						<template slot="md-collapse-pane-4">
							<md-card v-show="jobType === 'Once-off Project/Task'" class="bg-success">
								<md-card-content>
									<h3 class="card-category card-category-social" style="text-align: center"><i class="fas fa-wallet"></i> Breakdown</h3>
									<h4 class="card-title">Total Cost</h4>
									<p class="card-description">
										<b>R{{ total }}</b> = <b>R{{ rate }}</b> + <b>R{{ fee }}</b> + <b>R{{ price.facilitationFee }}.00</b>
									</p>
									<hr />
									<h4 class="card-title">Cost Breakdown</h4>
									<p class="card-description" v-if="jobType === 'Once-off Project/Task'">
										Fixed-Term Rate: <b>R{{ rate }}</b>
									</p>
									<p class="card-description" v-if="jobType !== 'Once-off Project/Task'">
										Salary: <b>R{{ rate }}</b>
									</p>
									<p class="card-description">
										Jobox Service Fee ({{ percentage }}%): <b>R{{ fee }}</b>
									</p>
									<p class="card-description">
										Jobox Facilitation Cost: <b>R{{ price.facilitationFee }}.00</b>
									</p>
								</md-card-content>
							</md-card>
						</template>
					</collapse>
				</md-card-content>
			</md-card>
		</div>
	</div>
</template>

<script>
import { Collapse } from '@/components';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default {
	components: {
		Collapse,
	},
	props: {
		name: {},
		description: {},
		jobType: {},
		education: {},
		experience: {},
		industryCategory: {},
		jobCategory: {},
		skills: {},
		location: {},
		deadline: {},
		daysOfTheWeek: {},
		hours: {},
		budget: {},
		payment: {},
		applicationDeadline: {},
	},
	data() {
		return {
			deadlineData: this.deadline,
			price: {},
			selectedDaysOfTheWeek: this.daysOfTheWeek,
			selectedHours: this.hours,
			selectedBudget: this.budget,
			selectedApplicationDeadline: this.applicationDeadline,
			selectedSkills: this.skills,
			computedLocation: this.location,
		};
	},
	computed: {
		total() {
			let total = (this.selectedBudget * (1 + this.price.serviceFee) + this.price.facilitationFee).toFixed(2);
			return total;
		},
		rate() {
			let rate = (this.selectedBudget * 1).toFixed(2);
			return rate;
		},
		fee() {
			let fee = (this.selectedBudget * this.price.serviceFee).toFixed(2);
			return fee;
		},
		percentage() {
			return this.price.serviceFee * 100;
		},
		budgetFormat() {
			if (this.selectedBudget?.min || this.selectedBudget?.max) {
				let { min, max } = this.selectedBudget;
				return 'R' + min + ' ' + '-' + ' ' + 'R' + max;
			} else {
				return 'R' + this.budget;
			}
		},
		formattedDate() {
			const formattedDate = moment(this.selectedApplicationDeadline).format('LL');
			return formattedDate !== 'Invalid date' ? formattedDate : 'No deadline specified';
		},
	},
	mounted() {
		let businessModel = db.collection('Settings').doc('Business Model');
		businessModel.get().then(doc => {
			this.price = doc.data();
		});

		this.user = firebase.auth().currentUser;
		let ref = db.collection('jobs');
		ref.where('clientId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					if (doc.exists) {
						this.microsDoc = db.collection('micros').doc(this.$route.params.id);
						this.skillsDoc = db.collection('skills').doc(this.$route.params.id);
						this.jobsDoc = db.collection('jobs').doc(this.$route.params.id);
						this.microsDoc.get().then(doc => {
							if (doc.exists) {
								this.deadlineData = doc.data().duration;
								this.selectedDaysOfTheWeek = doc.data().daysOfTheWeek;
								this.selectedHours = doc.data().workingHours;
								this.selectedBudget = doc.data().budget;
								let payment = db.collection('payments').doc(this.$route.params.id);
								payment.get().then(doc => {
									this.paid = doc.data().inboundPayment;
								});
								this.skillsDoc.get().then(doc => {
									if (doc.exists) {
										this.selectedSkills = doc.data().skills; // skills
										this.industryCategory = doc.data().industry; // skills
										this.jobCategory = doc.data().category; // skills
										this.jobsDoc.get().then(doc => {
											if (doc.exists) {
												this.jobType = doc.data().jobType; // jobs
												this.education = doc.data().education; // jobs
												this.experience = doc.data().experience; //jobs
												this.selectedApplicationDeadline = new Date(doc.data().applicationDeadline);
											}
										});
									}
								});
							}
						});
					}
				});
			});
	},
};
</script>
<style></style>
