import mixpanel from 'mixpanel-browser';
const { generalValues } = require('../constants/index');

let token = '';
if (generalValues.ENVIRONMENT === 'prod') {
	token = '270caae65968406f49500ba26766bbad';
} else {
	token = '0e03903489631a4be30adf316c7d7968';
}

mixpanel.init(token);
const MixpanelFunctions = {
	register(details) {
		mixpanel.track('Register', {
			'User Type': details.userType,
		});
	},

	addPersonalDetails(details) {
		mixpanel.track('Adding Personal Details', {
			'Email Address': details.emailAddress,
		});
	},

	addEducation(details) {
		mixpanel.track('Adding Education', {
			'University ': details.university,
			'Email Address': details.emailAddress,
		});
	},

	addExperience(details) {
		mixpanel.track('Adding Experience', {
			'University ': details.university,
			'Email Address': details.emailAddress,
		});
	},

	addPreferredCompanies(details) {
		mixpanel.track('Adding Preferred Companies', {
			'University ': details.university,
			'Email Address': details.emailAddress,
		});
	},

	addPortfolio(details) {
		mixpanel.track('Adding Portfolio', {
			'University ': details.university,
			'Email Address': details.emailAddress,
		});
	},

	addBankingDetails(details) {
		mixpanel.track('Adding Banking Details', {
			'University ': details.university,
			'Email Address': details.emailAddress,
		});
	},

	signUpStudent(details) {
		mixpanel.track('Sign Up Student', {
			'University ': details.university,
			'Faculty ': details.faculty,
			'Year Of Study': details.year,
			'Gender ': details.gender,
			'Ethnicity ': details.ethnicity,
			'Email Address': details.emailAddress,
			'Citizenship ': details.citizen,
		});
	},

	signUpEmployer(details) {
		mixpanel.track('Sign Up Employer', {
			'Company Industry': details.companyIndustry,
			'Company Name': details.companyName,
			'Company Size': details.companySize,
			'Email Address': details.emailAddress,
		});
	},

	proceedingWithVetting(details) {
		mixpanel.track('Proceeding With Vetting', {
			'University ': details.university,
			'Faculty ': details.faculty,
			'Year Of Study': details.yearOfStudy,
			'Gender ': details.gender,
			'Ethnicity ': details.ethnicity,
			'Email Address': details.emailAddress,
			'Citizenship ': details.citizen,
			'Job Id': details.jobId,
			'Job Type': details.jobType,
			'Job Name': details.jobName,
			'Company Industry': details.companyIndustry,
			'Company Name ': details.companyName,
			'Company Size': details.companySize,
		});
	},

	notProceedingWithVetting(details) {
		mixpanel.track('Not Proceeding With Vetting', {
			'University ': details.university,
			'Faculty ': details.faculty,
			'Year Of Study': details.yearOfStudy,
			'Gender ': details.gender,
			'Ethnicity ': details.ethnicity,
			'Email Address': details.emailAddress,
			'Citizenship ': details.citizen,
			'Job Id': details.jobId,
			'Job Type': details.jobType,
			'Job Name': details.jobName,
			'Company Industry': details.companyIndustry,
			'Company Name ': details.companyName,
			'Company Size': details.companySize,
		});
	},

	postAJob(details) {
		mixpanel.track('Post A Job', {
			'Job Id': details.jobId,
			'Job Type': details.jobType,
			'Job Name': details.jobName,
			'Job Location Type': details.jobLocationType,
			'Salary ': details.salary,
			'Company Industry': details.companyIndustry,
			'Company Name ': details.companyName,
			'Company Size': details.companySize,
		});
	},

	inviteForInterviewLink(details) {
		mixpanel.track('Invite For Interview Link', {
			'University ': details.university,
			'Faculty ': details.faculty,
			'Year Of Study': details.yearOfStudy,
			'Gender ': details.gender,
			'Ethnicity ': details.ethnicity,
			'Email Address': details.emailAddress,
			'Citizenship ': details.citizen,
			'Job Id': details.jobId,
			'Job Type': details.jobType,
			'Job Name': details.jobName,
			'Company Industry': details.companyIndustry,
			'Company Name ': details.companyName,
			'Company Size': details.companySize,
		});
	},

	inviteForInterviewWithJobox(details) {
		mixpanel.track('Invite For Interview With Jobox', {
			'University ': details.university,
			'Faculty ': details.faculty,
			'Year Of Study': details.yearOfStudy,
			'Gender ': details.gender,
			'Ethnicity ': details.ethnicity,
			'Email Address': details.emailAddress,
			'Citizenship ': details.citizen,
			'Job Id': details.jobId,
			'Job Type': details.jobType,
			'Job Name': details.jobName,
			'Company Industry': details.companyIndustry,
			'Company Name ': details.companyName,
			'Company Size': details.companySize,
		});
	},

	noInterviewScheduled(details) {
		mixpanel.track('No Interview Scheduled', {
			'Company Industry': details.companyIndustry,
			'Company Name': details.companyName,
			'Company Size': details.companySize,
		});
	},

	sendOffer(details) {
		mixpanel.track('Send Offer', {
			'University ': details.university,
			'Faculty ': details.faculty,
			'Year Of Study': details.yearOfStudy,
			'Gender ': details.gender,
			'Ethnicity ': details.ethnicity,
			'Email Address': details.emailAddress,
			'Citizenship ': details.citizen,
			'Job Id': details.jobId,
			'Job Type': details.jobType,
			'Job Name': details.jobName,
			'Company Industry': details.companyIndustry,
			'Company Name ': details.companyName,
			'Company Size': details.companySize,
		});
	},
};
export default MixpanelFunctions;
