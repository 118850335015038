import moment from 'moment';

export const generateTimes = () => {
	const startTime = moment().startOf('day');
	const endTime = moment().endOf('day').subtract(15, 'minutes');
	const interval = 15;

	const times = [];
	while (startTime.isSameOrBefore(endTime)) {
		times.push(startTime.format('HH:mm'));
		startTime.add(interval, 'minutes');
	}

	return times;
};

export const formatPayload = selection => {
	const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	const schedule = days.map(day => ({
		on: false,
		times: [],
	}));

	for (const { day, startTime, endTime } of selection) {
		const dayIndex = days.indexOf(day);
		schedule[dayIndex].on = true;
		schedule[dayIndex].times.push({
			startMinutes: convertTimeToMinutes(startTime),
			endMinutes: convertTimeToMinutes(endTime),
		});
	}

	return schedule;
};

const convertTimeToMinutes = timeStr => {
	const [hour, minute] = timeStr.split(':').map(Number);
	return hour * 60 + minute;
};
