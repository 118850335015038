<template>
	<div class="error_container">
		<md-field :class="fieldClass" style="padding-bottom: 10px">
			<md-icon v-if="iconName">{{ this.iconName }}</md-icon>
			<label class="label">{{ this.label }}</label>
			<md-input :required="required" :name="name" :type="type" :value="value" @input="$emit('input', $event)" />
		</md-field>
		<span v-if="error" class="error_message">{{ label }} {{ error }}</span>
	</div>
</template>

<script>
export default {
	name: 'TextField',
	props: {
		label: String,
		value: [String, Number],
		name: String,
		type: String,
		iconName: String,
		error: [String, Boolean],
		required: Boolean,
	},
	computed: {
		fieldClass() {
			return {
				'md-error': !!this.error,
			};
		},
	},
};
</script>
<style scoped>
.error_message {
	color: red;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 10px;
	padding-top: 0%;
}

@media (max-width: 400px) {
	.label {
		font-size: 10px;
	}
}
</style>
