<template>
	<div class="error_container md-layout-item ml-auto mt-4 md-small-size-100">
		<md-icon v-if="iconName">{{ this.iconName }}</md-icon>
		<md-autocomplete
			@input="$emit('input', $event)"
			@change="$emit('change', $event)"
			:class="fieldClass"
			:value="value"
			type="text"
			:name="name"
			required
			:md-options="options"
			style="padding-top: 2rem"
		>
			<label class="label">{{ label }}</label>
		</md-autocomplete>
		<p v-if="error" class="error_message">{{ label }} {{ error }}</p>
	</div>
</template>

<script>
export default {
	name: 'AutoSelectField',
	props: {
		label: String,
		options: [Array, String],
		error: [String, Boolean],
		required: Boolean,
		value: [String, Number, Array],
		name: String,
		iconName: String,
	},
	computed: {
		fieldClass() {
			return {
				'md-invalid': !!this.error,
			};
		},
	},
};
</script>

<style>
.md-menu-content-bottom-start,
.md-menu-content-small,
.md-menu-content {
	width: 300px !important;
}
.error_message {
	color: red;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 10px;
}

@media (max-width: 420px) {
	.label {
		font-size: 12px !important;
		margin-bottom: 1rem;
	}
}
</style>
