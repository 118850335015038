export const helperArticles = {
	VIDEO_SUBMISSION_HELP: 'https://intercom.help/jobox-64fe20407ade/en/articles/8159741-how-to-create-a-shareable-link-for-uploading-video-submissions-on-jobox',
	WRITTEN_SUBMISSION_HELP:
		'https://intercom.help/jobox-64fe20407ade/en/articles/8159385-how-to-upload-written-and-video-submissions-after-successfully-passing-the-vetting-test-on-jobox',
	OPEN_JOBS_HELP: 'https://intercom.help/jobox-64fe20407ade/en/articles/7018891-how-to-apply-for-a-job-on-the-jobox-platform',
	POST_JOB_HELP: 'https://intercom.help/jobox-64fe20407ade/en/articles/8069400-how-to-post-a-job-on-jobox',
	COMMENTS_HELP: '',
	USER_MANAGEMENT_HELP: 'https://intercom.help/jobox-64fe20407ade/en/articles/8880655-how-to-use-the-multiple-user-feature-on-jobox',
	INTERVIEW_HELP: 'https://intercom.help/jobox-64fe20407ade/en/articles/8232490-how-to-invite-your-favourite-candidates-for-an-interview-on-jobox',
	APPLICANT_SHORTLIST_HELP: 'https://intercom.help/jobox-64fe20407ade/en/articles/8159949-how-to-review-shortlisted-applicants-on-jobox',
	VETTING_HELP: 'https://intercom.help/jobox-64fe20407ade/en/articles/9064938-introducing-jobox-s-new-vetting-process',
};
