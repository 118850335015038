<template>
	<modal class="modal-frame">
		<template slot="header">
			<div class="top-left-text">
				<p>{{ currentProfile.profile.name }} {{ currentProfile.profile.surname }}</p>
			</div>
			<md-button class="md-simple md-just-icon md-round modal-default-button" @click="closeViewTranscriptModal">
				<md-icon class="md-size-3x">clear</md-icon>
			</md-button>
		</template>
		<template slot="body">
			<div>
				<iframe :src="this.academicTranscript.url"> </iframe>
				<div class="bottom-right-button">
					<md-button @click="viewProfile" class="md-success"> View Profile </md-button>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
import { Modal } from '@/components';
export default {
	components: { Modal },
	data() {
		return {
			academicTranscriptObject: null,
		};
	},
	methods: {
		closeViewTranscriptModal() {
			this.$emit('closeViewTranscriptModal');
		},
		viewProfile() {
			this.$emit('closeViewTranscriptModal');
		},
	},
	props: {
		academicTranscript: {
			type: Object,
			required: true,
		},
		currentProfile: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style>
.modal-frame .modal-container {
	max-width: 1200px !important;
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
}
iframe {
	width: 1100px !important;
	height: 600px !important;
}
.top-left-text {
	position: absolute;
	left: 10px;
	font-weight: bold;
	margin-left: 35px;
	font-size: 1.5rem;
	padding: 10px;
}
.bottom-right-button {
	position: absolute;
	right: 10px;
	margin-right: 20px;
	padding: 15px;
}
</style>
