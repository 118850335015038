<template>
	<div class="profile-container">
		<div class="profile-banner">
			<div class="profile-banner-content">
				<div style="width: 100%; display: flex; align-items: center; justify-content: center; margin-bottom: 2rem">
					<md-button v-if="edit" class="jb-standard" @click="editProfile">Edit Profile</md-button>
				</div>

				<div class="profile-avatar">
					<img v-if="profile.profile" class="img" :src="profile.profile" />
					<img v-if="!profile.profile" class="img" :src="cardUserImage" />
				</div>

				<div style="width: 100%">
					<span class="profile-full-name-header">
						<h1 class="profile-full-name">{{ profile.companyName }}</h1>
					</span>
					<p class="profile-bio">{{ profile.bio }}</p>
					<div style="width: 100%">
						<div class="profile-social-media-icons">
							<p>
								<i v-if="profile.website" class="fa fa-globe" style="color: #1da1f2; cursor: pointer" @click="openLink(profile.website)"></i>
								{{ profile.website }}
							</p>

							<p>
								<i v-if="profile.industry" class="fa fa-industry" style="color: #ff4500; cursor: pointer"></i>
								{{ profile.industry }}
							</p>
							<p>
								<i v-if="profile.companySize" class="fa fa-users" style="color: #5dade2; cursor: pointer"></i>
								{{ profile.companySize }} employees
							</p>

							<p>
								<i v-if="profile.addressLine1" class="fa fa-map-marker" style="color: #e74c3c; cursor: pointer"></i>
								{{ `${profile.addressLine1 === ' null' ? '' : `${profile.addressLine1},`}  ${profile.city}, ${profile.country}` }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import firebase from 'firebase/compat/app';
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
export default {
	components: {},
	data() {
		return {
			profile: [],
			user: [],
			rating: 4.84,
			edit: null,
			loading: true,
		};
	},
	props: {
		cardUserImage: {
			type: String,
			default: '/img/default-avatar.png',
		},
	},
	methods: {
		editProfile() {
			this.loading = true;
			this.$router.push({ name: 'edit-client-profile', params: { id: this.$route.params.id } });
			this.loading = false;
		},
		openLink(link) {
			window.open(link, '_blank');
		},
	},
	computed: {
		hasSocialMedia() {
			const profile = this.profile;
			return profile.website !== '';
		},
	},
	async created() {
		try {
			const user = await db.collection('users').where('userId', '==', firebase.auth().currentUser.uid).get();
			user.docs.forEach(async doc => {
				if (doc.data().accessType) {
					const client = await db.collection('clients').doc(doc.data().clientAlias).get();
					this.profile = client.data();
					this.loading = false;
				} else {
					if (firebase.auth().currentUser.uid == doc.data().userId) {
						this.edit = true;
						const client = await db.collection('clients').doc(doc.id).get();
						this.profile = client.data();
						this.loading = false;
					}
				}
			});
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style scoped>
img {
	width: 100px;
	height: 100px !important;
	object-fit: cover;
	border-radius: 50vw;
	margin-top: 18px;
	margin-right: 2rem;
}

.profile-container {
	width: 1200px;
	margin: auto;
	height: fit-content;
	padding: 2rem;
}

.profile-banner {
	width: 100%;
	height: auto;
	background-color: white;
	padding: 2rem;
	border-radius: 10px;
}

.profile-banner-row {
	width: 100%;
	display: flex;
}

.profile-bio {
	color: grey !important;
	font-style: italic;
	font-size: 12px;
	margin-top: -10px;
	text-align: left;
}

.profile-social-media-icons {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.profile-full-name {
	font-size: 2rem;
}
@media (max-width: 1270px) {
	.profile-container {
		width: 98%;
	}
}
@media (max-width: 570px) {
	.profile-social-media-icons {
		width: 100%;
		flex-direction: column;
	}
	.profile-banner-row {
		flex-direction: column;
	}

	.profile-full-name {
		font-size: 2rem;
	}
	.fab,
	.fa {
		font-size: 1.4rem !important;
		margin: 10px;
	}
	.profile-container {
		padding: 0;
	}
	.profile-heading {
		margin-top: 1rem;
	}
	.profile-avatar {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.profile-full-name-header {
		text-align: center;
	}
}
@media (max-width: 410px) {
	.profile-top {
		font-size: 12px;
	}

	.profile-full-name {
		font-size: 22px;
	}
}
</style>
