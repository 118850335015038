<template>
	<div>
		<modal v-if="modal">
			<template slot="header">
				<h4 class="modal-title black">Experience</h4>
			</template>
			<template slot="body">
				<!-- Job Title -->
				<div class="md-layout-item ml-auto mt-4 md-small-size-100">
					<md-field :class="[{ 'md-valid': !errors.has('jobTitle') && touched.jobTitle }, { 'md-form-group': true }, { 'md-error': errors.has('jobTitle') }]">
						<md-icon><i class="fas fa-briefcase"></i></md-icon>
						<label>Job Title</label>
						<md-input v-model="jobTitle" data-vv-name="jobTitle" type="text" name="jobTitle" v-validate="modelValidations.jobTitle"> </md-input>
						<slide-y-down-transition>
							<md-icon class="error" v-show="errors.has('jobTitle')">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!errors.has('jobTitle') && touched.email">done</md-icon>
						</slide-y-down-transition>
					</md-field>
				</div>
				<!-- Employer -->
				<div class="md-layout-item ml-auto mt-4 md-small-size-100">
					<md-field :class="[{ 'md-valid': !errors.has('employer') && touched.employer }, { 'md-form-group': true }, { 'md-error': errors.has('employer') }]">
						<md-icon><i class="fas fa-user-tie"></i></md-icon>
						<label>Employer</label>
						<md-input v-model="employer" data-vv-name="employer" type="text" name="employer" v-validate="modelValidations.employer"> </md-input>
						<slide-y-down-transition>
							<md-icon class="error" v-show="errors.has('employer')">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!errors.has('employer') && touched.employer">done</md-icon>
						</slide-y-down-transition>
					</md-field>
				</div>
				<!-- Start Date -->
				<div class="md-layout-item ml-auto mt-4 md-small-size-100">
					<md-datepicker
						v-model="startDate"
						data-vv-name="startDate"
						v-validate="modelValidations.startDate"
						md-immediately
						:class="[{ 'md-valid': !errors.has('startDate') && touched.startDate }, { 'md-form-group': true }, { 'md-error': errors.has('startDate') }]"
					>
						<label>Start Date</label>
						<slide-y-down-transition>
							<md-icon class="error" v-show="errors.has('startDate')">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!errors.has('startDate') && touched.startDate">done</md-icon>
						</slide-y-down-transition>
					</md-datepicker>
				</div>
				<!-- End Date -->
				<div class="md-layout-item ml-auto mt-4 md-small-size-100">
					<md-datepicker
						v-if="work === false"
						v-model="endDate"
						data-vv-name="endDate"
						v-validate="modelValidations.endDate"
						md-immediately
						:class="[{ 'md-valid': !errors.has('endDate') && touched.endDate }, { 'md-form-group': true }, { 'md-error': errors.has('endDate') }]"
					>
						<label>End Date</label>
						<slide-y-down-transition>
							<md-icon class="error" v-show="errors.has('endDate')">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!errors.has('endDate') && touched.endDate">done</md-icon>
						</slide-y-down-transition>
					</md-datepicker>
					<md-checkbox v-model="work" @change="setEndDate">I currently work here</md-checkbox>
				</div>
				<!-- Description of experience -->
				<md-field :class="[{ 'md-valid': !errors.has('description') && touched.description }, { 'md-form-group': true }, { 'md-error': errors.has('description') }]">
					<label>Description of experience</label>
					<md-textarea class="pad" v-model="description" data-vv-name="description" type="description" name="description" v-validate="modelValidations.description">
					</md-textarea>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('description')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('description') && touched.description">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</template>
			<!-- footer -->
			<template slot="footer">
				<div style="text-align: right">
					<md-button
						class="md-button md-danger"
						@click="
							modalHide();
							clearModal();
						"
						>Cancel</md-button
					>
					&nbsp;
					<md-button class="md-button md-success" @click="confirm">Confirm</md-button>
				</div>
			</template>
		</modal>

		<modal v-if="emptyFieldError">
			<template slot="header">
				<h4 class="modal-title black">Woah there ✋</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="emptyFieldErrorHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<h4>Please fill in all fields.</h4>
			</template>
		</modal>
	</div>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions';
import { Modal } from '@/components';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		SlideYDownTransition,
		Modal,
	},
	props: {
		selectedExp: Number,
		student: Object,
		status: String,
	},
	data() {
		return {
			emptyFieldError: false,
			user: null,
			experience: null,
			jobTitle: null,
			employer: null,
			startDate: null,
			endDate: null,
			work: false,
			description: null,
			touched: {
				jobTitle: false,
				employer: false,
				startDate: false,
				endDate: false,
				work: false,
				description: false,
			},
			modelValidations: {
				jobTitle: {
					min: 2,
					required: true,
				},
				employer: {
					min: 2,
					required: true,
				},
				startDate: {
					required: true,
				},
				endDate: {
					required: true,
				},
				description: {
					min: 10,
					max: 280,
					required: true,
				},
			},
		};
	},
	methods: {
		validate() {
			return this.$validator.validateAll().then(res => {
				this.$emit('on-validated', res);
				return res;
			});
		},
		confirm: async function () {
			if (!this.jobTitle || !this.employer || !this.startDate || !this.endDate || !this.description) {
				this.emptyFieldError = true;
				return;
			} else if (this.status === 'add') {
				this.emptyFieldError = false;
				let expID = Date.now();
				this.experience = this.student.collection('experiences').doc('experience' + expID);
				await this.experience
					.set({
						experienceNum: expID,
						jobTitle: this.jobTitle,
						employer: this.employer,
						startDate: moment(this.startDate).format('L'),
						endDate: moment(this.endDate).format('L'),
						work: this.work,
						lastModified: moment(Date.now()).format('L'),
						description: this.description,
					})
					.then(() => {
						this.$notify({
							message: 'Your data has been saved!',
							icon: 'add_alert',
							horizontalAlign: 'center',
							verticalAlign: 'top',
							type: 'success',
						});

						this.clearModal();
						this.modalHide();
					})
					.catch(err => {
						// An error occured!
					});
			} else if (this.status === 'update') {
				this.experience = this.student.collection('experiences').doc('experience' + this.selectedExp);
				await this.experience
					.get()
					.then(doc => {
						if (doc.exists) {
							if (this.jobTitle) {
								this.experience.update({
									jobTitle: this.jobTitle,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (this.employer) {
								this.experience.update({
									employer: this.employer,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (this.startDate) {
								this.experience.update({
									startDate: moment(this.startDate).format('L'),
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (this.endDate) {
								this.experience.update({
									endDate: this.work ? null : moment(this.endDate).format('L'),
									lastModified: moment(Date.now()).format('L'),
								});
							}
							this.experience.update({
								work: this.work,
								lastModified: moment(Date.now()).format('L'),
							});
							if (this.description) {
								this.experience.update({
									description: this.description,
									lastModified: moment(Date.now()).format('L'),
								});
							}
						}

						this.$notify({
							message: 'Your data has been saved!',
							icon: 'add_alert',
							horizontalAlign: 'center',
							verticalAlign: 'top',
							type: 'success',
						});

						this.clearModal();
						this.modalHide();
					})
					.catch(err => {
						// An error occured
					});
				this.emptyFieldError = false;
			}
		},
		deleteExperience: async function () {
			this.experience = await this.student.collection('experiences').doc('experience' + this.selectedExp);
			await this.experience
				.delete()
				.then(() => {
					this.decrementExpNum();
					this.clearModal();
					this.modalHide();
				})
				.catch(err => {
					// An error occured
				});
		},
		clearModal: function () {
			this.jobTitle = null;
			this.employer = null;
			this.startDate = null;
			this.endDate = null;
			this.work = false;
			this.description = null;
		},
		...mapActions(['modalHide', 'incrementExpNum', 'decrementExpNum']),
		emptyFieldErrorHide() {
			this.emptyFieldError = false;
		},
		setEndDate() {
			this.endDate = this.work;
		},
	},
	computed: {
		...mapGetters({
			modal: 'getModal',
		}),
	},
	watch: {
		modal() {
			if (this.modal) {
				if (this.status === 'update') {
					this.experience = this.student.collection('experiences').doc('experience' + this.selectedExp);
					this.experience
						.get()
						.then(doc => {
							if (doc.exists) {
								this.jobTitle = doc.data().jobTitle;
								this.employer = doc.data().employer;
								if (doc.data().startDate) {
									this.startDate = new Date(doc.data().startDate);
								} else {
									this.startDate = null;
								}

								this.work = doc.data().work;

								if (doc.data().work === false) {
									this.endDate = new Date(doc.data().endDate);
								} else {
									this.endDate = null;
								}
								this.description = doc.data().description;
							}
						})
						.catch(err => {});
				}
			}
		},
		jobTitle() {
			this.touched.jobTitle = true;
		},
		employer() {
			this.touched.employer = true;
		},
		startDate() {
			this.touched.startDate = true;
		},
		endDate() {
			this.touched.endDate = true;
		},
		work() {
			this.touched.work = true;
		},
	},
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
	.md-field label {
		font-size: 11px;
	}
}

.md-card {
	background-color: red !important;
}
</style>
