import { required, helpers, email, numeric, between } from '@vuelidate/validators';
import { REG_EXP } from '@/constants/reg-expression.const';

const pdfFileTypeValidator = value => {
	if (!value) {
		return false;
	}
	return value.type === 'application/pdf';
};

export const requiredValidator = helpers.withMessage('is required.', required);
export const passwordValidator = value => {
	if (!value) return;
	return REG_EXP.PASSWORD.test(value);
};
export const emailValidator = helpers.withMessage('is not a valid email address.', email);
export const numericValidator = helpers.withMessage('must be numeric.', numeric);
export const pdfFileValidator = helpers.withMessage('must be a pdf file.', pdfFileTypeValidator);
