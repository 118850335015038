<template>
	<div>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<div class="md-layout" v-if="activeJobs">
			<client-active-jobs-data-table :data="jobs" class="desktop-table"> </client-active-jobs-data-table>

			<client-active-jobs-mobile-data-table :data="jobs" class="mobile-table" />
		</div>
		<div v-else-if="activeJobs === false">
			<h1 class="black" style="text-align: center">You have no active jobs</h1>
		</div>
	</div>
</template>

<script>
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import ClientActiveJobsDataTable from '@/components/Tables/ClientActiveJobsDataTable.vue';
import ClientActiveJobsMobileDataTable from '@/components/Tables/ClientActiveJobsMobileDataTable.vue';

export default {
	components: {
		ClientActiveJobsDataTable,
		ClientActiveJobsMobileDataTable,
	},
	data() {
		return {
			product1: '/img/dashboard/client/card-1.jpg',
			jobs: [],
			activeJobs: null,
			loading: true,
		};
	},
	methods: {
		async fetchData(userId) {
			try {
				const jobsCollection = db.collection('jobs');
				const microCollection = db.collection('micros');
				const applicationsCollection = db.collection('applications');
				const skillsCollection = db.collection('skills');

				const jobSnapshot = await jobsCollection.where('clientId', '==', userId).get();

				const jobPromises = jobSnapshot.docs.map(async doc => {
					const jobData = doc.data();
					const jobId = doc.data().jobId;

					// Fetch applications, micros (active, select, rate), and skills in one go
					const [appSnapshot, microSnapshot, skillsSnapshot] = await Promise.all([
						applicationsCollection.where('jobId', '==', jobId).get(),
						microCollection.where('jobId', '==', jobId).where('status', 'in', ['active', 'select', 'rate']).get(),
						skillsCollection.doc(jobId).get(),
					]);

					const applications = appSnapshot.docs.map(doc => doc.data());
					const microSnapshots = microSnapshot.docs.map(doc => doc.data());
					const skillsData = skillsSnapshot.exists ? skillsSnapshot.data().category : null;

					return microSnapshots.map(doc => ({
						...doc,
						id: doc.id,
						category: skillsData,
						profilePicture: jobData.clientProfile || null,
						applicationDeadline: jobData.applicationDeadline,
						type: jobData.jobType,
						applications: applications,
						verified: jobData.verified,
					}));
				});
				const allJobs = await Promise.all(jobPromises);
				this.jobs = allJobs.flat();
				this.activeJobs = this.jobs.length > 0;
				this.loading = false;
			} catch (error) {
				console.error('Error fetching data:', error);
				this.loading = false;
			}
		},
	},
	async created() {
		this.loading = true;
		const user = firebase.auth().currentUser;
		let users = await db.collection('users').where('userId', '==', user.uid).get();
		users.docs.forEach(async doc => {
			let userData = doc.data();
			if (userData.accessType) {
				db.collection('clients')
					.doc(userData.clientAlias)
					.get()
					.then(doc => {
						this.fetchData(doc.data().userId);
					});
			} else {
				this.fetchData(userData.userId);
			}
		});
	},
};
</script>
<style scoped>
.mobile-table {
	display: none;
}

@media (max-width: 680px) {
	.desktop-table {
		display: none;
	}
	.mobile-table {
		display: block;
	}
}
</style>
