<template>
	<modal>
		<template slot="header">
			<div class="black">
				<h2>You are almost there</h2>
			</div>
		</template>

		<template slot="body">
			<div class="black">
				<p>
					Thank you for signing up. You should see a verification link in your email (it might be in your spam folder). Verify your email to complete your registration.
				</p>
			</div>
		</template>

		<template slot="footer">
			<div>
				<md-button class="md-button jb-standard" @click="resendVerification"> Resend Verification </md-button>
			</div>

			<modal v-if="modal" @close="modalHide">
				<template slot="header">
					<h4 class="modal-title black">{{ feedbackHeader }}</h4>
					<md-button class="md-simple md-just-icon md-round modal-default-button" @click="modalHide">
						<md-icon>clear</md-icon>
					</md-button>
				</template>

				<template slot="body">
					<p class="black">{{ feedback }}</p>
				</template>

				<template slot="footer">
					<div style="text-align: center">
						<md-button class="md-button md-success" @click="modalHide">Got it</md-button>
					</div>
				</template>
			</modal>
		</template>
	</modal>
</template>

<script>
import { Modal } from '@/components';
import firebase from 'firebase/compat/app';
import router from '@/routes/routes';
import db from '@/firebase/init';

export default {
	components: {
		Modal,
	},
	data() {
		return {
			user: null,
			auth: null,
			email: null,
			feedback: null,
			feedbackHeader: null,
			modal: false,
		};
	},
	methods: {
		resendVerification() {
			this.modal = true;
			this.user.sendEmailVerification().then(() => {
				this.modal = true;
				this.feedbackHeader = 'Check your inbox!';
				this.feedback = 'Email Sent. Check your inbox and verify!';
			});
		},
		modalHide() {
			router.push({ name: 'Login' });
		},
	},
	created() {
		this.auth = firebase.auth();
		this.user = this.auth.currentUser;
		let ref = db.collection('users');
		ref.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.email = doc.data().email;
				});
			});
	},
};
</script>
