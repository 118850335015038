export const OfferRejectionReasons = [
	{
		reason: 'Salary is not my expected salary',
	},
	{
		reason: 'I have received another offer',
	},
	{
		reason: 'Can not start on specified date',
	},
	{
		reason: 'Prefer not to say',
	},
	{
		reason: 'Other',
	},
];
