<template>
	<div>
		<p class="section-heading">What days of the week would you like to host your interviews on?</p>
		<p v-if="error !== ''" style="color: red; font-weight: bold">{{ error }}</p>
		<div>
			<div class="day-of-the-week-selector" v-for="(day, index) in daysOfWeek" :key="day">
				<div class="selector-row">
					<div class="selector-row checkbox">
						<input type="checkbox" :name="day" :id="day" v-model="selectedDaysOfTheWeek" :value="index" @change="toggleTimeSlots(index)" />
						<p class="selector-label">{{ day }}</p>
					</div>
					<div class="selector-row slots" v-if="selectedDaysOfTheWeek.includes(index)">
						<md-field>
							<md-select v-model="selectedTimeSlots[index].startTime" :placeholder="selectedTimeSlots[index].startTime" @input="confirmSelectedTimeSlots">
								<md-option v-for="time in times" :key="time" :value="time">{{ time }}</md-option>
							</md-select>
						</md-field>
						<p class="time-separator">to</p>
						<md-field>
							<md-select v-model="selectedTimeSlots[index].endTime" :placeholder="selectedTimeSlots[index].endTime" @input="confirmSelectedTimeSlots">
								<md-option v-for="time in times" :key="time" :value="time">{{ time }}</md-option>
							</md-select>
						</md-field>
					</div>
					<div class="selector-row slots" v-else>
						<p class="unavailable">Unavailable</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { generateTimes } from '../../utils/generateTimes';

export default {
	props: {
		allDaysOfWeek: {
			type: Array,
			default: () => [],
		},
		apiData: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		const selectedTimeSlots = [];
		for (let i = 0; i < this.allDaysOfWeek.length; i++) {
			selectedTimeSlots.push({
				startTime: null,
				endTime: null,
			});
		}
		return {
			daysOfWeek: this.allDaysOfWeek,
			selectedTimeSlots,
			selectedDaysOfTheWeek: [],
			times: [],
			error: '',
			success: '',
		};
	},
	mounted() {
		this.times = generateTimes();
		this.retrieveState();
		if (this.apiData) {
			this.initializeSelectedTimeSlots();
		}
	},
	watch: {
		selectedTimeSlots: {
			handler() {
				this.confirmSelectedTimeSlots();
			},
			deep: true,
		},
	},
	methods: {
		initializeSelectedTimeSlots() {
			if (this.apiData && this.apiData.schedule && this.apiData.schedule.length > 0) {
				for (let i = 0; i < this.apiData.schedule.length; i++) {
					const scheduleItem = this.apiData.schedule[i];
					if (scheduleItem.on && scheduleItem.times && scheduleItem.times.length > 0) {
						this.selectedDaysOfTheWeek.push(i);
						this.selectedTimeSlots[i].startTime = this.convertMinutesToTime(scheduleItem.times[0].startMinutes);
						this.selectedTimeSlots[i].endTime = this.convertMinutesToTime(scheduleItem.times[0].endMinutes);
					}
				}
			}
		},
		convertMinutesToTime(minutes) {
			const hours = Math.floor(minutes / 60);
			const mins = minutes % 60;
			const timeString = `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
			return timeString;
		},
		toggleTimeSlots(index) {
			const selectedDay = this.selectedDaysOfTheWeek.includes(index);
			if (!selectedDay) {
				this.selectedTimeSlots[index].startTime = null;
				this.selectedTimeSlots[index].endTime = null;
			}
		},
		confirmSelectedTimeSlots() {
			const selectedTimeSlots = [];
			for (const index of this.selectedDaysOfTheWeek) {
				const timeSlot = this.selectedTimeSlots[index];
				if (timeSlot.startTime && timeSlot.endTime) {
					if (timeSlot.startTime >= timeSlot.endTime) {
						this.error = 'Start time should be less than end time';
						this.success = false;
						return;
					}
					selectedTimeSlots.push({
						day: this.daysOfWeek[index],
						startTime: timeSlot.startTime,
						endTime: timeSlot.endTime,
					});
				} else {
					this.error = 'Please select start and end times for all selected days';
					this.success = false;
					return;
				}
			}
			if (!selectedTimeSlots.length) {
				this.error = 'Please select at least one time slot';
				this.success = false;
				this.$emit('selectedDayOfTheWeek', []);
				return;
			}
			this.error = '';
			this.success = true;
			this.$emit('selectedDayOfTheWeek', selectedTimeSlots);
			this.saveState();
		},
		saveState() {
			const state = {
				selectedDaysOfTheWeek: this.selectedDaysOfTheWeek,
				selectedTimeSlots: this.selectedTimeSlots,
			};
			localStorage.setItem('appointmentSlots', JSON.stringify(state));
		},
		retrieveState() {
			const appointmentSlots = JSON.parse(localStorage.getItem('appointmentSlots'));
			if (appointmentSlots) {
				this.selectedDaysOfTheWeek = appointmentSlots.selectedDaysOfTheWeek;
				this.selectedTimeSlots = appointmentSlots.selectedTimeSlots;
			}
		},
	},
};
</script>

<style scoped>
.section-heading {
	text-align: left;
	font-weight: bold;
}

.selector-row {
	display: flex;
	align-items: center;
}

.selector-row.slots {
	margin-left: 2rem;
}

.selector-row.checkbox {
	width: 100px;
}

.selector-label {
	margin: 0;
	font-weight: bold;
	margin-left: 10px;
	font-size: 16px;
}

.time-separator {
	margin: 0 1rem;
}
</style>
