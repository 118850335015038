<template>
	<div class="container">
		<h2 class="test-information-heading">{{ this.$route.params.skillName }} Test Information</h2>
		<p>
			Here you'll be given a vetting test to complete, to see if you are the right fit for the role. <br />This assessment measures the ability to problem solve, use new
			information, and think critically. <br />
			<br />
			It tells employers how easily a new hire will internalize training for a new role. Research also shows that this is one of the best predictors of job performance.
			<br />
			<br />
			The topics tested in this vetting test are as follows:
			<br />
		</p>
		<ul>
			<li>{{ this.$route.params.skillName }}</li>
		</ul>
		<p>Test Duration: 10 minutes</p>
		<p>
			Want to be better prepared for Jobox’s vetting test? Follow this link: <br />
			<a href="https://intercom.help/jobox-64fe20407ade/en/articles/7018895-prepare-for-success-jobox-s-vetting-tests-and-screening-assessments">
				Prepare for Success: Jobox's Vetting Tests and Screening Assessments 📝 | Jobox Help Center
			</a>
			<br />
			<br />
		</p>
		<p class="sub-heading">Take note of the following:</p>
		<ol>
			<li>Remember to thoroughly read the questions.</li>
			<li>No take backs!</li>
			<li>If you have any questions, please send an email to contact@joinjobox.com</li>
			<li>The Start Test button should open and start the test</li>
			<li class="warning-text">Once you start the test, the timer will continue even when you close or leave the screen.</li>
		</ol>
		<div class="start-test-button">
			<md-button v-if="!error" class="md-success" style="width: fit-content" @click="navigateToResults">Start Test</md-button>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			error: false,
		};
	},
	methods: {
		navigateToResults() {
			this.$router.push({
				name: 'vetting-test',
				params: { jobId: this.$route.params.jobId, formId: this.$route.params.formId },
			});
		},
	},
};
</script>

<style scoped>
.container {
	padding: 2rem 1em;
	width: 100%;
	max-width: 800px;
	margin: auto;
	height: fit-content;
	background-color: white;
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.sub-heading {
	font-weight: bold;
}
.test-information-heading {
	text-align: center;
}
ol,
ul {
	margin-top: -10px;
}

.start-test-button {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 2rem;
}
.warning-text {
	font-size: 17px;
	color: red;
	font-weight: bold;
}
</style>
