<template>
	<div>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div>
				<img src="@/assets/img/logo.png" />
				<div class="loading"></div>
			</div>
		</div>
		<h5 class="info-text">Let's start with the basic information</h5>
		<div class="md-layout">
			<notifications></notifications>
			<div class="md-layout-item md-size-40 md-small-size-100">
				<div class="picture-container">
					<div class="picture">
						<div v-if="!image">
							<img :src="avatar" class="picture-src" title="" />
						</div>
						<div v-else>
							<img :src="image" />
						</div>
						<input type="file" @change="previewImage" title="Profile Picture" />
					</div>
					<h6 class="description">Profile Picture</h6>
				</div>
			</div>
			<div class="md-layout-item md-size-60 mt-4 md-small-size-100">
				<div :class="[{ 'error field': errors.has('firstName') }]">
					<md-field :class="[{ 'md-valid': !errors.has('firstName') && touched.firstName }, { 'md-form-group': true }, { 'md-error field': errors.has('firstName') }]">
						<md-icon>face</md-icon>
						<label>First Name</label>
						<md-input
							@change="addFirstName"
							v-model="firstName"
							data-vv-name="firstName"
							type="text"
							name="firstName"
							required
							v-validate="modelValidations.firstName"
						></md-input>
						<slide-y-down-transition>
							<md-icon class="error" v-show="errors.has('firstName')">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!errors.has('firstName') && touched.firstName">done</md-icon>
						</slide-y-down-transition>
					</md-field>
					<span v-if="errors.has('firstName')" class="error-message">Please enter a First Name</span>
				</div>

				<div :class="[{ 'error field': errors.has('lastName') }]">
					<md-field :class="[{ 'md-valid': !errors.has('lastName') && touched.lastName }, { 'md-form-group': true }, { 'md-error field': errors.has('lastName') }]">
						<md-icon>record_voice_over</md-icon>
						<label>Last Name</label>
						<md-input @change="addLastName" v-model="lastName" data-vv-name="lastName" type="text" name="lastName" required v-validate="modelValidations.lastName">
						</md-input>
						<slide-y-down-transition>
							<md-icon class="error" v-show="errors.has('lastName')">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!errors.has('lastName') && touched.lastName">done</md-icon>
						</slide-y-down-transition>
					</md-field>
					<span v-if="errors.has('lastName')" class="error-message">Please enter a Last Name</span>
				</div>

				<div :class="[{ 'error field': errors.has('phoneNumber') }]">
					<md-field
						:class="[{ 'md-valid': !errors.has('phoneNumber') && touched.phoneNumber }, { 'md-form-group': true }, { 'md-error field': errors.has('phoneNumber') }]"
					>
						<md-icon>phone</md-icon>
						<label>Phone Number</label>
						<md-input
							@change="addPhoneNumber"
							v-model="phoneNumber"
							data-vv-name="phoneNumber"
							type="text"
							name="phoneNumber"
							required
							v-validate="modelValidations.phoneNumber"
						>
						</md-input>
						<slide-y-down-transition>
							<md-icon class="error" v-show="errors.has('phoneNumber')">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!errors.has('phoneNumber') && touched.phoneNumber">done</md-icon>
						</slide-y-down-transition>
					</md-field>
					<span v-if="!phoneNumber" class="error-message">Please enter a Phone Number</span>
					<span v-if="phoneNumber && phoneNumber.length < 10" class="error-message">Please ensure your phone number is 10 digits </span>
				</div>
			</div>
			<div class="md-layout-item md-size-60 mt-4 md-small-size-100">
				<div :class="[{ 'error field': errors.has('companyName') }]">
					<md-field
						:class="[{ 'md-valid': !errors.has('companyName') && touched.companyName }, { 'md-form-group': true }, { 'md-error field': errors.has('companyName') }]"
					>
						<md-icon><i class="fas fa-building"></i></md-icon>
						<label>Company Name</label>
						<md-input
							@change="addCompanyName"
							v-model="companyName"
							data-vv-name="companyName"
							type="text"
							name="companyName"
							required
							v-validate="modelValidations.companyName"
						>
						</md-input>
						<slide-y-down-transition>
							<md-icon class="error" v-show="errors.has('companyName')">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!errors.has('companyName') && touched.companyName">done</md-icon>
						</slide-y-down-transition>
					</md-field>
					<span v-if="errors.has('companyName')" class="error-message">Please enter a Company Name</span>
				</div>

				<div :class="[{ 'error field': errors.has('companyWebsite') }]">
					<md-field
						:class="[
							{ 'md-valid': !errors.has('companyWebsite') && touched.companyWebsite },
							{ 'md-form-group': true },
							{ 'md-error field': errors.has('companyWebsite') },
						]"
					>
						<md-icon><i class="fas fa-globe"></i></md-icon>
						<label>Company Website</label>
						<md-input
							@change="addCompanyWebsite"
							v-model="companyWebsite"
							data-vv-name="companyWebsite"
							type="text"
							name="companyWebsite"
							v-validate="modelValidations.companyWebsite"
						>
						</md-input>
						<slide-y-down-transition>
							<md-icon class="error" v-show="errors.has('companyWebsite')">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!errors.has('companyWebsite') && touched.companyWebsite">done</md-icon>
						</slide-y-down-transition>
					</md-field>
					<span v-if="errors.has('companyWebsite')" class="error-message">Please enter a Company Website</span>
				</div>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<div :class="[{ 'error field': errors.has('companySize') }]">
					<md-field
						:class="[{ 'md-valid': !errors.has('companySize') && touched.companySize }, { 'md-form-group': true }, { 'md-error field': errors.has('companySize') }]"
					>
						<md-icon><i class="fas fa-sitemap"></i></md-icon>
						<label>Company Size</label>
						<md-select
							class="pad"
							@input="addCompanySize"
							v-model="companySize"
							data-vv-name="companySize"
							name="select"
							required
							v-validate="modelValidations.companySize"
						>
							<md-option v-for="(sizeType, index) in sizeTypes" :key="index" :value="sizeType">{{ sizeType }}</md-option>
						</md-select>
						<slide-y-down-transition>
							<md-icon class="error" v-show="errors.has('companySize')">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!errors.has('companySize') && touched.companySize">done</md-icon>
						</slide-y-down-transition>
					</md-field>
					<span v-if="errors.has('companySize')" class="error-message">Please enter a Company Size</span>
				</div>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<div :class="[{ 'error field': errors.has('industry') }]">
					<md-autocomplete
						class="industry"
						@input="addIndustry"
						v-model="industry"
						:md-options="industries"
						data-vv-name="industry"
						name="industry"
						required
						v-validate="modelValidations.industry"
						:class="[{ 'md-valid': !errors.has('industry') && touched.industry }, { 'md-form-group': true }, { 'md-error field': errors.has('industry') }]"
					>
						<label style="margin-left: 35px">Industry</label>
						<slide-y-down-transition>
							<md-icon class="error" v-show="errors.has('industry')">close</md-icon>
						</slide-y-down-transition>
						<slide-y-down-transition>
							<md-icon class="success" v-show="!errors.has('industry') && touched.industry">done</md-icon>
						</slide-y-down-transition>
					</md-autocomplete>
					<span v-if="errors.has('industry')" class="error-message">Please enter an Industry</span>
				</div>
			</div>
		</div>
		<div :class="[{ 'error field': errors.has('aboutMe') }]">
			<md-field :class="[{ 'md-valid': !errors.has('aboutMe') && touched.aboutMe }, { 'md-error field': errors.has('aboutMe') }]">
				<label>About Us</label>
				<md-textarea @change="addAboutMe" v-model="aboutMe" data-vv-name="aboutMe" type="text" name="aboutMe" required v-validate="modelValidations.aboutMe"></md-textarea>
				<slide-y-down-transition>
					<md-icon class="error" v-show="errors.has('aboutMe')">close</md-icon>
				</slide-y-down-transition>
				<slide-y-down-transition>
					<md-icon class="success" v-show="!errors.has('aboutMe') && touched.aboutMe">done</md-icon>
				</slide-y-down-transition>
			</md-field>
			<span v-if="errors.has('aboutMe')" class="error-message">Please enter a Company Description</span>
		</div>
		<modal v-if="modal" @close="modalHide">
			<template slot="header">
				<h4 class="modal-title black">{{ header }}</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="modalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<p class="black">{{ body }}</p>
			</template>
			<template slot="footer">
				<div class="centre">
					<md-button class="md-button md-success" @click="modalHide">Got it</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions';
import { Modal } from '@/components';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { mapActions } from 'vuex';

export default {
	components: {
		SlideYDownTransition,
		Modal,
	},
	props: {
		avatar: {
			type: String,
			default: './img/default-avatar.png',
		},
	},
	data() {
		return {
			alias: null,
			file: null,
			image: '',
			loading: null,
			modal: false,
			header: '',
			body: '',
			user: null,
			client: null,
			users: null,
			firstName: null,
			lastName: null,
			phoneNumber: null,
			companyName: null,
			companyWebsite: 'http://www.',
			vat: null,
			companySize: null,
			aboutMe: null,
			sizeTypes: [],
			industry: null,
			industries: [],
			touched: {
				companyName: false,
				companyWebsite: false,
				vat: false,
				companySize: false,
				industry: false,
				aboutMe: false,
				image: false,
			},
			modelValidations: {
				firstName: {
					required: true,
				},
				lastName: {
					required: true,
				},
				phoneNumber: {
					required: true,
					min: 10,
					max: 10,
				},
				companyName: {
					required: true,
				},
				companyWebsite: {
					required: true,
				},
				vat: {
					required: true,
					min: 10,
					max: 10,
				},
				companySize: {
					required: true,
				},
				industry: {
					required: true,
				},
				aboutMe: {
					required: true,
				},
			},
		};
	},
	methods: {
		previewImage(event) {
			var file = event.target.files[0];
			if (!file) {
				// empty
			} else if (file.size < 2 * 1024 * 1024) {
				// less than 2MB
				this.fileUpload(file);
			} else {
				this.modal = true;
				this.header = 'Whoa there! ✋';
				this.body = 'You cannot exceed the file limit of 2MB';
			}
		},
		fileUpload(data) {
			this.loading = true;
			const storageRef = firebase
				.storage()
				.ref()
				.child('users/clients/' + this.alias + '/profile/' + data.name)
				.put(data);
			storageRef.on(
				`state_changed`,
				snapshot => {},
				error => {
					console.log(error.message);
				},
				() => {
					storageRef.snapshot.ref.getDownloadURL().then(url => {
						this.image = url;
						this.$emit('image', this.image);
						this.loading = false;
					});
				}
			);
		},
		modalHide() {
			this.modal = false;
		},
		validate() {
			return this.$validator.validateAll().then(res => {
				this.$emit('on-validated', res);
				return res;
			});
		},
		addFirstName: function () {
			this.$emit('firstName', this.firstName);
		},
		addLastName: function () {
			this.$emit('lastName', this.lastName);
		},
		addPhoneNumber: function () {
			this.$emit('phoneNumber', this.phoneNumber);
		},
		addCompanyName: function () {
			this.$emit('companyName', this.companyName);
		},
		addCompanyWebsite: function () {
			this.$emit('companyWebsite', this.companyWebsite);
		},
		addVat: function () {
			this.$emit('vat', this.vat);
		},
		addCompanySize: function () {
			this.$emit('companySize', this.companySize);
		},
		addIndustry: function () {
			this.$emit('industry', this.industry);
		},
		addAboutMe: function () {
			this.$emit('aboutMe', this.aboutMe);
		},
		...mapActions(['addClientDetails']),
	},
	watch: {
		firstName() {
			this.touched.firstName = true;
		},
		lastName() {
			this.touched.lastName = true;
		},
		phoneNumber() {
			this.touched.phoneNumber = true;
		},
		companyName() {
			this.touched.companyName = true;
		},
		companyWebsite() {
			this.touched.companyWebsite = true;
		},
		vat() {
			this.touched.vat = true;
		},
		companySize() {
			this.touched.companySize = true;
		},
		industry() {
			this.touched.industry = true;
		},
		aboutMe() {
			this.touched.aboutMe = true;
		},
		image() {
			this.touched.image = true;
		},
	},
	created() {
		let settings = db.collection('Settings').doc('Drop-down Lists');
		settings.get().then(doc => {
			this.industries = doc.data().Industries;
			this.sizeTypes = doc.data().CompanySizes;
		});

		this.user = firebase.auth().currentUser;
		this.addClientDetails({ name: 'user', value: this.user });
		let ref = db.collection('users');
		ref.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.alias = doc.id;
					this.client = db.collection('clients').doc(doc.id);
					this.addClientDetails({ name: 'client', value: this.client });
					this.addClientDetails({ name: 'userDoc', value: db.collection('users').doc(this.alias) });
					this.client
						.get()
						.then(doc => {
							if (doc.exists) {
								this.companyName = doc.data().companyName;
								this.companyWebsite = doc.data().website;
								this.vat = doc.data().vat;
								this.companySize = doc.data().companySize;
								this.industry = doc.data().industry;
								this.aboutMe = doc.data().bio;
								this.image = doc.data().profile;
							}
							this.users = db.collection('users').doc(this.$route.params.id);
						})
						.catch(err => {
							console.log(err.message);
						});
					let clientUser = db.collection('users');
					clientUser
						.where('userId', '==', this.user.uid)
						.get()
						.then(snapshot => {
							snapshot.forEach(doc => {
								this.firstName = doc.data().name;
								this.lastName = doc.data().surname;
								this.phoneNumber = doc.data().phone;
								this.$emit('firstName', this.firstName);
								this.$emit('lastName', this.lastName);
								this.$emit('phoneNumber', this.phoneNumber);
							});
						});
				});
			});
	},
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
	.md-field label {
		font-size: 11px;
	}
}
.industry {
	padding-left: 35px;
}

.error-message {
	font-size: 10px;
	color: red;
	margin-left: 2rem;
}

.error.field {
	margin-bottom: 4rem !important;
}
</style>
