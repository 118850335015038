import AuthLayout from '@/views/AuthLayout.vue';
import MicroApplication from '@/views/dashboard/student/jobs/application/micro/Application.vue';

export default {
	path: '/',
	component: AuthLayout,
	name: 'student-jobs',
	children: [
		{
			path: 'jobs/micro/:id',
			name: 'student-micro',
			components: { default: MicroApplication },
			meta: {
				applyForJob: true,
				userRole: 'student',
				jobType: 'micro',
			},
		},
	],
};
