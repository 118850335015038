<template>
	<div class="wizard-container">
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<!--        You can switch " data-color="primary" "  with one of the next bright colors: "green", "orange", "red", "blue"       -->
		<md-card class="md-card-wizard active" data-color="green">
			<md-card-header>
				<slot name="header">
					<h3 class="card-title">{{ title }}</h3>
					<h3 class="description">{{ subTitle }}</h3>
				</slot>
			</md-card-header>
			<div class="wizard-navigation">
				<ul class="nav nav-pills" role="tablist">
					<li
						v-for="(tab, index) in tabs"
						:key="tab.title"
						role="tab"
						:tabindex="tab.checked ? 0 : ''"
						:id="`step-${tab.tabId}`"
						:aria-controls="tab.tabId"
						:aria-disabled="tab.active"
						:aria-selected="tab.active"
						:ref="`tab-${index}`"
						class="nav-item wizard-tab-link"
						:style="linkWidth"
					>
						<a
							class="nav-link"
							@click="navigateToTab(index)"
							:class="[{ 'disabled-wizard-link': !tab.checked }, { active: tab.active }, { checked: tab.checked }]"
							data-toggle="tab"
						>
							<tab-item-content :tab="tab"></tab-item-content>
						</a>
					</li>
				</ul>
				<div
					class="moving-tab"
					:class="{ 'error-link': activeTab.hasError }"
					v-if="activeTab"
					style="transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1); width: 100%"
					:style="movingTabStyles"
				>
					<tab-item-content :tab="activeTab" :moving-tab="true"></tab-item-content>
				</div>
			</div>

			<md-card-content>
				<div class="tab-content">
					<slot :activeIndex="activeTabIndex" :activeTab="activeTab"> </slot>
				</div>
			</md-card-content>

			<md-card-actions md-alignment="space-between">
				<slot name="footer" :next-tab="nextTab" :prev-tab="prevTab">
					<div>
						<md-button v-if="activeTabIndex > 0" @click.native="prevTab" class="btn-previous">
							<div class="pc-view">{{ prevButtonText }}</div>
							<div class="mobi-view"><i class="fas fa-arrow-left"></i></div>
						</md-button>
					</div>
					<div>
						<md-button v-if="activeTabIndex < tabCount - 1" @click.native="nextTab" class="btn-next jb-standard">
							<div class="pc-view">{{ nextButtonText }}</div>
							<div class="mobi-view"><i class="fas fa-arrow-right"></i></div>
						</md-button>
						<md-button @click="createAccount" v-else class="md-button jb-standard md-theme-default" slot="footer">
							<div class="pc-view">{{ finishButtonText }}</div>
							<div class="mobi-view"><i class="fa fa-check"></i></div>
						</md-button>
					</div>
				</slot>
			</md-card-actions>
		</md-card>
	</div>
</template>
<script>
import { throttle } from './throttle';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { getFormattedDate } from '../../../../utils/time';
import MixpanelFunctions from '@/mixpanel/mixpanel';

export default {
	name: 'simple-wizard',
	props: {
		startIndex: {
			type: Number,
			default: 0,
		},
		title: {
			type: String,
			default: 'Title',
		},
		subTitle: {
			type: String,
			default: 'Subtitle',
		},
		prevButtonText: {
			type: String,
			default: 'Previous',
		},
		nextButtonText: {
			type: String,
			default: 'Next',
		},
		finishButtonText: {
			type: String,
			default: 'Finish',
		},
		vertical: {
			type: Boolean,
		},
		dob: {
			required: true,
		},
		gender: {
			required: true,
		},
		race: {
			required: true,
		},
		location: {
			required: true,
		},
		relocation: {
			required: true,
		},
		citizenship: {
			required: false,
		},
		disability: {
			required: true,
		},
		disabilityDescription: {
			required: true,
		},
		license: {
			required: true,
		},
		vehicle: {
			required: true,
		},
		programme: {
			required: true,
		},
		image: {
			required: false,
		},
		jobTitle1: {
			required: false,
		},
		employer1: {
			required: false,
		},
		startDate1: {
			required: false,
		},
		endDate1: {
			required: false,
		},
		description1: {
			required: false,
		},
		identification: {
			required: false,
		},
		passport: {
			required: false,
		},
		bio: {
			required: true,
		},
		studying: {
			required: true,
		},
		institution: {
			required: true,
		},
		campus: {
			required: true,
		},
		studentNo: {
			required: true,
		},
		faculty: {
			required: true,
		},
		degree: {
			required: true,
		},
		year: {
			required: true,
		},
		graduateStatus: {
			required: true,
		},
		linkedIn: {
			required: false,
		},
		facebook: {
			required: false,
		},
		twitter: {
			required: false,
		},
		instagram: {
			required: false,
		},
		github: {
			required: false,
		},
		certificate1: {
			required: false,
		},
		certificate2: {
			required: false,
		},
		certificate3: {
			required: false,
		},
		personalWebsite: {
			required: false,
		},
		portfolio: {
			required: false,
		},
		cv: {
			required: false,
		},
		industryCategory: {
			required: true,
		},
		extraCirricularActivities: {
			required: false,
		},
		accountName: {
			required: true,
		},
		accountNumber: {
			required: true,
		},
		accountType: {
			required: true,
		},
		bankName: {
			required: true,
		},
		branchCode: {
			required: true,
		},
		email: {},
		qualificationsValid: {},
		choices: {},
		department: {},
	},
	components: {
		TabItemContent: {
			props: ['tab', 'movingTab'],
			render(h) {
				return h('span', [this.tab.$slots.label || this.tab.label]);
			},
		},
	},
	provide() {
		return {
			addTab: this.addTab,
			removeTab: this.removeTab,
		};
	},
	data() {
		return {
			tabs: [],
			activeTabIndex: 0,
			tabLinkWidth: 0,
			tabLinkHeight: 50,
			user: null,
			emailVerified: null,
			feedback: null,
			feedbackHeader: null,
			alias: null,
			loading: true,
			modal: false,
		};
	},
	computed: {
		tabCount() {
			return this.tabs.length;
		},
		linkWidth() {
			let width = 100;
			if (this.tabCount > 0) {
				width = 100 / this.tabCount;
			}
			if (this.vertical) {
				width = 100;
			}
			return { width: `${width}%` };
		},
		activeTab() {
			return this.tabs[this.activeTabIndex];
		},
		movingTabStyles() {
			let translateXValue =
				this.activeTabIndex == 0
					? this.tabLinkWidth * this.activeTabIndex - 8
					: this.activeTabIndex == this.tabCount - 1
					? this.tabLinkWidth * this.activeTabIndex + 8
					: this.tabLinkWidth * this.activeTabIndex;

			let translateYValue = 0;
			if (this.vertical) {
				translateYValue = this.tabLinkHeight * this.activeTabIndex;
				translateXValue = 0;
			}
			let styles = {
				transform: `translate3d(${translateXValue}px, ${translateYValue}px, 0px)`,
			};
			if (this.tabLinkWidth !== 0) {
				styles.width = `${this.tabLinkWidth}px`;
			}
			return styles;
		},
	},
	methods: {
		...mapActions(['modalShow', 'updateStudentAccount']),
		addFeedback: function () {
			this.$emit('feedback', this.feedback, this.feedbackHeader);
		},
		addEmailVerified: function () {
			this.$emit('emailVerified', this.emailVerified);
		},
		async createAccount() {
			this.loading = true;
			this.user.reload().then(() => {
				this.emailVerified = this.user.emailVerified;
				this.addEmailVerified();
			});
			let isValid = await this.validate();

			if (this.user.emailVerified && isValid) {
				await this.updateStudentAccount(this.activeTabIndex);
				let students = db.collection('students').doc(this.alias);
				students.get().then(doc => {
					if (doc.exists) {
						if (this.dateOfBirth) {
							students.update({
								dateOfBirth: moment(this.dob).format('L'),
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.gender) {
							students.update({
								gender: this.gender,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.race) {
							students.update({
								race: this.race,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.location) {
							students.update({
								location: this.location,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.relocation) {
							students.update({
								relocation: this.relocation,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.citizenship) {
							students.update({
								citizenship: this.citizenship,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.identification) {
							students.update({
								identification: this.identification,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.passport) {
							students.update({
								passport: this.passport,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.image) {
							students.update({
								image: this.image,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.jobTitle1) {
							students.update({
								jobTitle1: this.jobTitle1,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.employer1) {
							students.update({
								employer1: this.employer1,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.startDate1) {
							students.update({
								startDate1: this.startDate1,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.endDate1) {
							students.update({
								endDate1: this.endDate1,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.description1) {
							students.update({
								description1: this.description1,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.disability) {
							students.update({
								disability: this.disability,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.disabilityDescription) {
							students.update({
								disabilityDescription: this.disabilityDescription,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.license) {
							students.update({
								license: this.license,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.vehicle) {
							students.update({
								vehicle: this.vehicle,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.programme) {
							students.update({
								programme: this.programme,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.bio) {
							students.update({
								bio: this.bio,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.institution) {
							students.update({
								institution: this.institution,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.institution) {
							students.update({
								institution: this.institution,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.campus) {
							students.update({
								campus: this.campus,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.studentNo) {
							students.update({
								studentNo: this.studentNo,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.faculty) {
							students.update({
								faculty: this.faculty,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.degree) {
							students.update({
								degree: this.degree,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.year) {
							students.update({
								year: this.year,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.graduateStatus) {
							students.update({
								graduateStatus: this.graduateStatus,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.linkedIn) {
							students.update({
								linkedIn: this.linkedIn,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.facebook) {
							students.update({
								facebook: this.facebook,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.twitter) {
							students.update({
								twitter: this.twitter,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.instagram) {
							students.update({
								instagram: this.instagram,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.github) {
							students.update({
								github: this.github,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.certificate1) {
							students.update({
								certificate1: this.certificate1,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.certificate2) {
							students.update({
								certificate2: this.certificate2,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.certificate3) {
							students.update({
								certificate3: this.certificate3,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.personalWebsite) {
							students.update({
								personalWebsite: this.personalWebsite,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.portfolio) {
							students.update({
								portfolio: this.portfolio,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.cv) {
							students.update({
								cv: this.cv,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.industryCategory) {
							students.update({
								industryCategory: this.industryCategory,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (this.extraCirricularActivities) {
							students.update({
								extraCirricularActivities: this.extraCirricularActivities,
								lastModified: moment(Date.now()).format('L'),
							});
						}

						if (this.choices) {
							students.update({
								'preferredCompanies.choices': this.choices,
								lastModified: getFormattedDate(),
							});
						}
						if (this.department) {
							students.update({
								'preferredCompanies.department': this.department,
								lastModified: getFormattedDate(),
							});
						}

						students.update({
							accountCreated: true,
							lastModified: moment(Date.now()).format('L'),
						});
					} else {
						students.set({
							userId: this.user.uid,
							created: moment(Date.now()).format('L'),
							lastModified: moment(Date.now()).format('L'),
							dateOfBirth: moment(this.dob).format('L'),
							gender: this.gender,
							race: this.race,
							location: this.location,
							relocation: this.relocation,
							citizenship: this.citizenship,
							identification: this.identification,
							passport: this.passport,
							image: this.image,
							disability: this.disability,
							disabilityDescription: this.disabilityDescription,
							license: this.license,
							vehicle: this.vehicle,
							programme: this.programme,
							bio: this.bio,
							institution: this.institution,
							institutionType: 'University',
							campus: this.campus,
							studentNo: this.studentNo,
							faculty: this.faculty,
							degree: this.degree,
							year: this.year,
							graduateStatus: this.graduateStatus,
							accountCreated: true,
							portfolio: null,
							linkedIn: null,
							facebook: null,
							twitter: null,
							instagram: null,
							preferredCompanies: {
								choices: this.choices,
								department: this.department,
							},
							extraCirricularActivities: this.extraCirricularActivities,
						});
					}
					db.collection('vetted')
						.doc(this.alias)
						.set({
							userId: this.user.uid,
							created: moment(Date.now()).format('L'),
							lastModified: moment(Date.now()).format('L'),
							accountsAdministrator: false,
							customerServiceAgent: false,
							dataCapturer: false,
							personalAssistant: false,
							receptionist: false,
							intern: false,
							operations: false,
							copywriter: false,
							digitalMarketingSpecialist: false,
							graphicDesigner: false,
							marketingAssistant: false,
							photographer: false,
							seoSpecialist: false,
							videoEditor: false,
							bookkeeper: false,
							juniorAccountant: false,
							tutor: false,
							barista: false,
							bartender: false,
							chef: false,
							waiterWaitress: false,
							recruiter: false,
							databaseAdministrator: false,
							helpdeskAndITSupportTechnician: false,
							networkAdministrator: false,
							softwareDeveloper: false,
							systemAdministrator: false,
							tester: false,
							webDesigner: false,
							iTTechnician: false,
							clerk: false,
							legalSecretary: false,
							paralegal: false,
							shopAssistant: false,
							visualMerchandiser: false,
							promoter: false,
							salesSupportAgent: false,
							salesperson: false,
							artisan: false,
							automotiveTransportAndLogistics: false,
							contactCentre: false,
							healthcareBeauty: false,
							hospitalityTourism: false,
							iTTechTelecoms: false,
							manufacturingWarehouse: false,
							marketingAndAdvertising: false,
							officeAdmin: false,
							restaurantsBarCafe: false,
							security: false,
							education: false,
							other: false,
						});
					const educationMixpanelList = JSON.parse(localStorage.getItem('educationMixpanelList'));

					let userDetails = {
						university: educationMixpanelList[0].institution,
						emailAddress: this.email,
					};
					MixpanelFunctions.addBankingDetails(userDetails);

					var studentDetails = {
						university: educationMixpanelList[0].institution,
						faculty: educationMixpanelList[0].faculty,
						yearOfStudy: educationMixpanelList[0].year,
						gender: this.gender,
						ethnicity: this.race,
						emailAddress: this.email,
						citizen: this.citizenship,
					};
					MixpanelFunctions.signUpStudent(studentDetails);
					localStorage.removeItem('educationMixpanelList');

					this.$notify({
						message: 'Account successfully created!',
						icon: 'add_alert',
						horizontalAlign: 'center',
						verticalAlign: 'top',
						type: 'success',
					});
					this.$router.push({ name: 'apply' });
					this.loading = false;
				});
				this.user = firebase.auth().currentUser;
				let ref = db.collection('users');
				// let data = {
				// 	accountName: this.accountName,
				// 	accountNumber: this.accountNumber,
				// 	accountType: this.accountType,
				// 	bankName: this.bankName,
				// 	branchCode: this.branchCode,
				// };
				// ref.where('userId', '==', this.user.uid)
				// 	.get()
				// 	.then(snapshot => {
				// 		snapshot.forEach(async doc => {
				// 			await db
				// 				.collection(`students/${doc.id}/bankingDetails`)
				// 				.doc('bankingDetails' + doc.id)
				// 				.set(data);
				// 		});
				// 	});
			} else if (!this.user.emailVerified) {
				this.feedbackHeader = 'Check your inbox!';
				this.feedback = 'You have not verified that ' + this.email + ' is your email address.';
				this.loading = false;
				this.addFeedback();
			} else if (!isValid) {
				this.feedbackHeader = '';
				this.feedback = 'Please fill in the required information';
				this.loading = false;
				this.addFeedback();
			}
		},
		addTab(tab) {
			const index = this.$slots.default.indexOf(tab.$vnode);
			let tabTitle = tab.title || '';
			tab.tabId = `${tabTitle.replace(/ /g, '')}${index}`;
			if (!this.activeTab && index === 0) {
				tab.active = true;
				tab.checked = true;
			}
			if (this.activeTab === tab.name) {
				tab.active = true;
				tab.checked = true;
			}
			this.tabs.splice(index, 0, tab);
		},
		removeTab(tab) {
			const tabs = this.tabs;
			const index = tabs.indexOf(tab);
			if (index > -1) {
				tabs.splice(index, 1);
			}
		},
		validate(tab) {
			let tabToValidate = tab || this.activeTab;
			let beforeChange = tabToValidate.beforeChange;
			if (beforeChange) {
				return Promise.resolve(beforeChange())
					.then(res => {
						this.activeTab.hasError = res ? false : true;
						return res;
					})
					.catch(() => {
						this.activeTab.hasError = true;
					});
			} else {
				return Promise.resolve(true);
			}
		},
		async nextTab() {
			let isValid = await this.validate();
			const educationMixpanelList = JSON.parse(localStorage.getItem('educationMixpanelList'));

			if (!this.user.emailVerified) {
				this.user.reload();
				this.emailVerified = this.user.emailVerified;
				this.addEmailVerified();
			}

			if (this.activeTabIndex === 0) {
				let userDetails = {
					emailAddress: this.email,
				};
				MixpanelFunctions.addPersonalDetails(userDetails);
			}

			if (this.activeTabIndex === 1 || this.activeTabIndex === 2 || this.activeTabIndex === 3 || this.activeTabIndex === 4) {
				if (educationMixpanelList.length === 0) {
					isValid = false;
					this.feedback = 'Please add your most recent studies!';
					this.feedbackHeader = 'Recent studies missing!';
					this.addFeedback();
					return;
				}

				let userDetails = {
					university: educationMixpanelList[0].institution,
					emailAddress: this.email,
				};

				if (this.activeTabIndex === 1) {
					MixpanelFunctions.addEducation(userDetails);
				}

				if (this.activeTabIndex === 2) {
					MixpanelFunctions.addExperience(userDetails);
				}

				if (this.activeTabIndex === 3) {
					MixpanelFunctions.addPreferredCompanies(userDetails);
				}

				if (this.activeTabIndex === 4) {
					MixpanelFunctions.addPortfolio(userDetails);
				}
			}

			if (isValid && this.activeTabIndex < this.tabCount - 1) {
				this.updateStudentAccount({
					step: this.activeTabIndex,
					dob: this.dob,
					gender: this.gender,
					race: this.race,
					location: this.location,
					relocation: this.relocation,
					citizenship: this.citizenship,
					identification: this.identification,
					passport: this.passport,
					disability: this.disability,
					disabilityDescription: this.disabilityDescription,
					bio: this.bio,
					license: this.license,
					vehicle: this.vehicle,
					programme: this.programme,
					image: this.image,
					jobTitle1: this.jobTitle1,
					employer1: this.employer1,
					startDate1: this.startDate1,
					endDate1: this.endDate1,
					description1: this.description1,
					linkedIn: this.linkedIn,
					facebook: this.facebook,
					twitter: this.twitter,
					instagram: this.instagram,
					github: this.github,
					certificate1: this.certificate1,
					certificate2: this.certificate2,
					certificate3: this.certificate3,
					personalWebsite: this.personalWebsite,
					portfolio: this.portfolio,
					id: this.id,
					cv: this.cv,
					industryCategory: this.industryCategory,
					extraCirricularActivities: this.extraCirricularActivities,
					accountName: this.accountName,
					accountNumber: this.accountNumber,
					accountType: this.accountType,
					bankName: this.bankName,
					branchCode: this.branchCode,
					preferredCompanies: {
						choices: this.choices,
						department: this.department,
					},
				})
					.then(() => {
						this.$notify({
							message: 'Your changes have been saved!',
							icon: 'add_alert',
							horizontalAlign: 'center',
							verticalAlign: 'top',
							type: 'success',
						});
					})
					.catch(err => {
						console.log('error', err);
					});

				this.activeTabIndex++;
			}
			return isValid;
		},
		prevTab() {
			if (!this.user.emailVerified) {
				this.user.reload();
				this.emailVerified = this.user.emailVerified;
				this.addEmailVerified();
			}
			this.activeTabIndex--;
		},
		async navigateToTab(index) {
			if (this.tabs[index].checked) {
				// recursively validate each tab
				if (index > this.activeTabIndex) {
					let valid = await this.nextTab();
					if (valid) {
						this.navigateToTab(index);
					}
				} else {
					this.activeTabIndex = index;
				}
			}
		},
		onResize() {
			let tabLinks = document.getElementsByClassName('wizard-tab-link');
			if (tabLinks.length > 0 && this.tabCount > 0) {
				let { clientWidth, clientHeight } = tabLinks[0];
				this.tabLinkWidth = clientWidth;
				this.tabLinkHeight = clientHeight;
			}
		},
	},
	mounted() {
		this.activeTabIndex = this.startIndex;
		this.$nextTick(() => {
			this.tabs[this.activeTabIndex].active = true;
			this.tabs[this.activeTabIndex].checked = true;
			this.onResize();
		});
		window.addEventListener(
			'resize',
			() => {
				throttle(this.onResize, 40);
			},
			false
		);
	},
	watch: {
		activeTabIndex(newValue, oldValue) {
			if (newValue !== oldValue) {
				let oldTab = this.tabs[oldValue];
				let newTab = this.tabs[newValue];
				oldTab.active = false;
				newTab.active = true;

				if (!newTab.checked) {
					newTab.checked = true;
				}
				this.$emit('tab-change', oldTab, newTab);
				this.$emit('update:startIndex', newValue);
			}
		},
	},
	created() {
		this.user = firebase.auth().currentUser;
		let ref = db.collection('users');
		ref.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.alias = doc.id;
					this.email = doc.data().email;
				});
			});
		this.loading = false;
	},
};
</script>
<style lang="scss">
/* Tab content animation */
.tab-content {
	display: flex; // to avoid horizontal scroll when animating
	.tab-pane {
		display: block;
		animation: fadeIn 0.5s;
		width: 100%;
	}
}

/**
    Extra niceties. Display error tabs and disable navigation unvisited tabs
   */
.wizard-navigation .nav-link {
	&.active,
	&.checked {
		cursor: pointer;
	}
}

.disabled-wizard-link {
	cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
	.pc-view {
		display: none;
	}
}

@media only screen and (min-width: 768px) {
	.mobi-view {
		display: none;
	}
}
</style>
