<template>
	<div class="container">
		<img src="@/assets/img/logo.png" height="125px" width="125px" style="cursor: pointer" />

		<h1>Woah There!</h1>
		<h3>Your account is still unverified. Please close this tab and try again.</h3>
	</div>
</template>
<script>
export default {
	components: {},
	data() {
		return {};
	},
	methods: {},
};
</script>
<style scoped>
.container {
	width: 100%;
	height: calc(100vh - 200px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.container > h1 {
	text-align: center;
}
</style>
