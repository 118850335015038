<template>
	<div class="profile-container">
		<div class="profile-banner">
			<div class="profile-banner-content">
				<div style="width: 100%; display: flex; align-items: center; justify-content: center; margin-bottom: 2rem">
					<md-button v-if="edit" class="jb-standard" @click="editProfile">Edit Profile</md-button>
				</div>

				<div class="profile-avatar">
					<img v-if="profile.profile" class="img" :src="profile.profile" />
					<img v-if="!profile.profile" class="img" :src="cardUserImage" />
				</div>
				<div class="profile-banner-row">
					<div>
						<span class="profile-full-name-header">
							<h3 class="profile-top">Hi there 👋, welcome</h3>
							<h1 style="margin-top: -4px" class="profile-full-name">{{ user.name }} {{ user.surname }}</h1>
						</span>
						<p class="profile-bio">{{ profile.bio }}</p>
						<div v-if="hasSocialMedia">
							<div class="profile-social-media-icons">
								<i v-if="profile.linkedIn" class="fab fa-linkedin" style="color: #0e76a8; cursor: pointer" @click="openLink(profile.linkedIn)"></i>
								<i v-if="profile.github" class="fab fa-github" style="color: #000; cursor: pointer" @click="openLink(profile.github)"></i>
								<i v-if="profile.facebook" class="fab fa-facebook" style="color: #3b5998; cursor: pointer" @click="openLink(profile.facebook)"></i>
								<i v-if="profile.twitter" class="fab fa-twitter" style="color: #00acee; cursor: pointer" @click="openLink(profile.twitter)"></i>
								<i v-if="profile.instagram" class="fab fa-instagram" style="color: #d6249f; cursor: pointer" @click="openLink(profile.instagram)"></i>
								<i v-if="profile.personalWebsite" class="fa fa-globe" style="color: black; cursor: pointer" @click="openLink(profile.personalWebsite)"></i>
							</div>
						</div>
					</div>
				</div>
				<div v-if="profile && profile.qualifications && profile.qualifications.length">
					<div class="profile-heading">
						<div class="row">
							<md-icon class="profile"> school </md-icon>
							<h4 style="margin-left: 1rem">Education</h4>
						</div>
					</div>

					<div v-for="degree in profile.qualifications" :key="degree.qualificationNum" class="profile-education-card">
						<div class="profile-row">
							<div>
								<h4>{{ degree.degree }}</h4>
								<p style="font-size: 10px; font-style: italic; color: grey !important; margin-top: -16px">{{ degree.institution }}</p>
							</div>
							<h4 style="font-size: 10px">{{ degree.startDate }} - {{ degree.endDate === 'Invalid date' ? 'Present' : degree.endDate }}</h4>
						</div>
					</div>
				</div>

				<div v-if="experience && experience.length">
					<div class="profile-heading">
						<div class="row">
							<md-icon class="profile"> work </md-icon>
							<h4 style="margin-left: 1rem">Experience</h4>
						</div>
					</div>

					<div v-for="(experience, index) in experience" :key="index" class="profile-education-card">
						<div class="profile-row">
							<div>
								<h4>{{ experience.jobTitle }}</h4>
								<p style="font-size: 10px; font-style: italic; color: lightgrey !important; margin-top: -16px">{{ experience.institution }}</p>
							</div>
							<h4 style="font-size: 10px">{{ experience.startDate }} - {{ experience.endDate === 'Invalid date' ? 'Present' : experience.endDate }}</h4>
						</div>
					</div>
				</div>

				<div v-if="profile && profile.extraCirricularActivities && profile.extraCirricularActivities.length">
					<div class="profile-heading">
						<div class="row">
							<md-icon class="profile"> sports </md-icon>
							<h4 style="margin-left: 1rem">Extracurricular Activities</h4>
						</div>
					</div>

					<div class="profile-row">
						<div v-for="(activity, index) in profile.extraCirricularActivities" :key="index" class="extra-cirricular-card">
							<ul>
								<li>{{ activity }}</li>
							</ul>
						</div>
					</div>
				</div>

				<div v-if="profile && profile.preferredCompanies && profile.preferredCompanies.choices && profile.preferredCompanies.choices.length">
					<div class="profile-heading">
						<div class="row">
							<md-icon class="profile"> business </md-icon>
							<h4 style="margin-left: 1rem">Preferred Companies</h4>
						</div>
					</div>

					<div class="profile-row">
						<div v-for="(activity, index) in profile.preferredCompanies.choices" :key="index" class="extra-cirricular-card">
							<ul>
								<li>{{ activity }}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
export default {
	name: 'client-profile',
	components: {},
	data() {
		return {
			profile: [],
			user: [],
			experience: null,
			rating: 4.84,
			edit: null,
			loading: true,
			sectionTestimonials2: {
				testimonials1: require('@/assets/img/faces/kendall.jpg'),
				testimonials2: require('@/assets/img/faces/christian.jpg'),
			},
		};
	},
	props: {
		cardUserImage: {
			type: String,
			default: '/img/default-avatar.png',
		},
	},
	methods: {
		editProfile() {
			this.loading = true;
			this.$router.push({ name: 'edit-student-profile', params: { id: this.$route.params.id } });
		},
		openLink(link) {
			window.open(link, '_blank');
		},
	},
	async created() {
		try {
			const userRef = db.collection('students').doc(this.$route.params.id);

			const userDoc = await userRef.get();
			this.user = userDoc.data();

			const auth = firebase.auth().currentUser.uid === this.user.userId;

			const studentRef = db.collection('students').doc(this.$route.params.id);

			const studentDoc = await studentRef.get();
			this.profile = studentDoc.data();

			const qualificationsRef = await studentRef.collection('qualifications').get();
			this.profile.qualifications = qualificationsRef.docs.map(doc => doc.data());

			const experienceRef = await studentRef.collection('experiences').get();
			this.experience = experienceRef.docs.map(doc => doc.data());

			this.loading = false;

			if (auth) {
				this.edit = true;
			}
		} catch (error) {
			console.error(error);
		}
	},
	computed: {
		hasSocialMedia() {
			const profile = this.profile;
			return profile.github !== '' || profile.linkedIn !== '' || profile.facebook !== '' || profile.twitter !== '' || profile.instagram !== '';
		},
	},
};
</script>
<style scoped>
img {
	width: 100px;
	height: 100px !important;
	object-fit: cover;
	border-radius: 50vw;
	margin-top: 18px;
	margin-right: 2rem;
}
.mt-0 {
	margin-top: 0 !important;
}

.profile-container {
	width: 1200px;
	margin: auto;
	height: fit-content;

	padding: 2rem;
}

.profile-banner {
	width: 100%;
	height: auto;
	background-color: white;
	padding: 2rem;
	border-radius: 10px;
}

.profile-banner-row {
	width: 100%;
	display: flex;
}

.row {
	display: flex;
	align-items: center;
}

.md-icon.profile {
	margin: 0 !important;
	font-size: 50px !important;
	margin-right: 10px !important;
	color: rgb(255, 139, 1) !important;
}

.profile-bio {
	color: grey !important;
	font-style: italic;
	font-size: 12px;
	margin-top: -10px;
	text-align: left;
}

.profile-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.profile-heading {
	margin-top: 3rem;
}

.profile-education-card {
	width: 90%;
	margin-left: 4rem;
	height: auto;
	border: 1px solid lightgrey;
	position: relative;
	padding: 1rem;
	margin-top: 1rem;
	border-radius: 5px;
}

.extra-cirricular-card {
	border-radius: 10px;
	background: #ffffff;
	padding: 10px;
	width: 100%;
}

.extra-cirricular-card > ul {
	margin-left: 2rem;
}
.extra-cirricular-card > ul > li {
	margin-top: -2rem;
	font-size: 14px;
}

.fab,
.fa {
	font-size: 1.7rem !important;
	margin: 10px;
}

@media (max-width: 1270px) {
	.profile-container {
		width: 98%;
	}
}
@media (max-width: 570px) {
	.profile-social-media-icons {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.profile-banner-row {
		flex-direction: column;
	}

	.profile-education-card {
		margin-left: 0;
		width: 100%;
	}
	.profile-full-name {
		font-size: 2rem;
	}
	.extra-cirricular-card > ul {
		margin-left: -2rem;
	}
	.fab,
	.fa {
		font-size: 1.4rem !important;
		margin: 10px;
	}
	.profile-container {
		padding: 0;
	}
	.md-icon.profile {
		font-size: 30px !important;
		margin-right: 0 !important;
	}
	.profile-heading {
		margin-top: 1rem;
	}
	.profile-avatar {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.profile-full-name-header {
		text-align: center;
	}
}
@media (max-width: 410px) {
	.profile-top {
		font-size: 12px;
	}

	.profile-full-name {
		font-size: 22px;
	}
}
</style>
