<template>
	<div class="md-layout">
		<div class="md-layout-item">
			<md-card>
				<div class="mobile-filter-container">
					<job-filter-modal
						:filterClicked="filterClicked"
						:data="data"
						@closeFilterModal="closeFilterModal"
						@selectedCategory="handleCategoryChange"
						@selectedEmploymentType="handleEmploymentTypeChange"
						@selectedLocations="handleLocationChange"
					>
					</job-filter-modal>

					<h1 class="data-table-heading">Job Posts</h1>
					<i class="fa-solid fa-user-doctor"></i>
					<md-card-header class="md-card-header-icon md-card-header-green">
						<div class="card-icon">
							<md-icon>assignment</md-icon>
						</div>
						<div class="filter-row">
							<md-button style="background-color: #448aff !important" @click="openFilter">Filter</md-button>
						</div>
					</md-card-header>

					<md-card-content>
						<md-table-toolbar>
							<md-field>
								<md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Search records" v-model="searchQuery"> </md-input>
							</md-field>
						</md-table-toolbar>
						<md-card v-for="(job, index) in queriedData" :key="index">
							<md-card-header>
								<div class="md-title">{{ job.name }}</div>
							</md-card-header>
							<md-card-content>
								<div class="row" style="margin-top: 10px">
									<md-icon class="md-size-1x" style="color: darkorange">work</md-icon>
									{{ job.category }}
								</div>
							</md-card-content>
							<md-card-content>
								<div class="row" style="margin-top: 10px">
									<md-icon class="md-size-1x" style="color: #2196f3; margin-bottom: 5px">calendar_today</md-icon>
									{{ job.applicationDeadline }}
								</div>
							</md-card-content>
							<md-card-content>
								<div class="row" style="margin-top: 5px" v-if="checkStatus(job) === 'Awaiting Verification'">
									<md-icon class="md-size-1x" style="color: red">hourglass_bottom</md-icon>
									{{ checkStatus(job) }}
								</div>
								<div class="row" style="margin-top: 5px" v-else>
									<md-icon class="md-size-1x" style="color: green">done</md-icon>
									{{ checkStatus(job) }}
								</div>
							</md-card-content>
							<md-card-actions>
								<router-link :to="{ name: 'client-micro-status', params: { id: job.jobId } }">
									<md-button class="md-success">View</md-button>
								</router-link>
							</md-card-actions>
						</md-card>
					</md-card-content>
				</div>
			</md-card>
		</div>
	</div>
</template>

<script>
import JobFilterModal from '@/components/Modals/JobFilterModal.vue';
import { ApplicationStatus } from './../../constants/application-status.js';
export default {
	components: { JobFilterModal },
	props: {
		data: {},
		columns: { type: Array },
	},
	data() {
		return {
			footerTable: this.columns,
			searchQuery: '',
			searchedData: this.data,
			filterClicked: false,
			jobCategories: [],
			employmentTypes: [],
			locations: [],
		};
	},
	computed: {
		queriedData() {
			let result = this.data;

			if (this.searchQuery.length > 0) {
				result = result.filter(job => {
					const name = job.name.toLowerCase();
					const keyword = this.searchQuery.toLowerCase();
					const companyName = job.companyName.toLowerCase();
					return name.includes(keyword) || companyName.includes(keyword);
				});
			}

			if (this.jobCategories.length > 0 || this.employmentTypes.length > 0 || this.locations.length > 0) {
				result = result.filter(job => {
					const categoryMatch = this.jobCategories.length === 0 || this.jobCategories.includes(job.category);
					const typeMatch = this.employmentTypes.length === 0 || this.employmentTypes.includes(job.type);
					const locationMatch = this.locations.length === 0 || this.locations.includes(job.location);
					return categoryMatch && typeMatch && locationMatch;
				});
			}

			return result;
		},
	},

	methods: {
		checkStatus(item) {
			if (item.verified) {
				const shortlistCandidates = item.applications.filter(x => x.status === ApplicationStatus.SHORTLIST);
				return shortlistCandidates.length > 0 ? `${shortlistCandidates.length} Application(s) Submitted` : 'Verified';
			}
			return 'Awaiting Verification';
		},

		openFilter() {
			this.filterClicked = true;
		},
		handleCategoryChange(data) {
			this.jobCategories = data;
		},
		handleEmploymentTypeChange(data) {
			this.employmentTypes = data;
		},
		handleLocationChange(data) {
			this.locations = data;
		},
		closeFilterModal(data) {
			this.filterClicked = data;
		},
	},
};
</script>

<style scoped>
.md-table + .md-table {
	margin-top: 16px;
}

.md-table-cell-container {
	display: flex;
	justify-content: flex-start;
}

.md-table-cell-container .md-button {
	width: auto !important;
}

.md-card .md-card-content {
	padding: 5px 20px;
}

.data-table-heading {
	display: flex;
	align-items: center;
	justify-content: center;
}

.filter-row {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.mobile-filter-container {
	position: relative;
	padding: 1rem;
}
@media (max-width: 680px) {
	.data-table-heading {
		font-size: 30px;
	}

	.md-layout-item {
		padding: 0;
	}

	.md-card .md-title {
		font-size: 16px;
	}
}
</style>
