<template>
	<div class="wizard-container">
		<div v-if="loading" class="background">
			<div v-if="loading" class="text-center lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>
		<h1 style="text-align: center">Post a Bursary</h1>
		<md-steppers :md-active-step.sync="active" md-linear style="padding: 2rem" ref="stepper">
			<!-- Description Step -->
			<md-step id="description" md-label="Description" md-description="Bursary details" :md-done.sync="steps.description">
				<first-step
					@next="nextStep('description', 'requirements')"
					@title="addTitle"
					@description="addDescription"
					@workPlacement="addWorkPlacement"
					@workPlacementInfo="addWorkPlacementInfo"
					@deadline="addDeadline"
				/>
			</md-step>

			<!-- Requirements Step -->
			<md-step id="requirements" md-label="Requirements" md-description="Bursary requirements" :md-done.sync="steps.requirements">
				<second-step
					@next="nextStep('requirements', 'funding')"
					@previous="previousStep('requirements', 'description')"
					@requirements="addRequirements"
					@cv="addCv"
					@id="addId"
					@proofOfIncome="addProofOfIncome"
					@matricResults="addMatricResults"
					@academicTranscript="addAcademicTranscript"
					@tuitionQuotation="addTuitionQuotation"
					@other="addOther"
					@newDocument="addNewDocument"
					@addAdditionalDocuments="addAdditionalDocuments"
					@documentInstructions="addDocumentInstructions"
				/>
			</md-step>

			<!-- Funding Details Step -->
			<md-step id="funding" md-label="Funding Details" md-description="Bursary funding details" :md-done.sync="steps.funding">
				<third-step
					@next="nextStep('funding', 'additional')"
					@previous="previousStep('funding', 'requirements')"
					@tuition="addTuition"
					@accommodation="addAccommodation"
					@meals="addMeals"
					@books="addBooks"
					@stipend="addStipend"
					@additionalCoverDetails="addAdditionalCoverDetails"
				/>
			</md-step>

			<!-- Additional Submissions Step -->
			<md-step id="additional" md-label="Additional Submissions" md-description="Optional submissions" :md-done.sync="steps.additional">
				<fourth-bursary-step
					@next="nextStep('additional', 'review')"
					@previous="previousStep('additional', 'funding')"
					@videoSubmission="addVideoSubmission"
					@videoSubmissionDetails="addVideoSubmissionDetails"
					@writtenSubmission="addWrittenSubmission"
					@writtenSubmissionDetails="addWrittenSubmissionDetails"
				/>
			</md-step>

			<!-- Review Step -->
			<md-step id="review" md-label="Review" md-description="Review your information" :md-done.sync="steps.review">
				<fifth-step
					@postBursary="postBursary"
					@previous="previousStep('review', 'additional')"
					:title="title"
					:description="description"
					:workPlacement="workPlacement"
					:workPlacementInfo="workPlacementInfo"
					:applicationDeadline="applicationDeadline"
					:deadline="deadline"
					:requirements="requirements"
					:cv="cv"
					:id="id"
					:proofOfIncome="proofOfIncome"
					:matricResults="matricResults"
					:academicTranscript="academicTranscript"
					:tuitionQuotation="tuitionQuotation"
					:other="other"
					:newDocument="newDocument"
					:additionalDocuments="additionalDocuments"
					:documentInstructions="documentInstructions"
					:tuition="tuition"
					:accommodation="accommodation"
					:meals="meals"
					:books="books"
					:stipend="stipend"
					:additionalCoverDetails="additionalCoverDetails"
					:videoSubmission="videoSubmission"
					:videoSubmissionDetails="videoSubmissionDetails"
					:writtenSubmission="writtenSubmission"
					:writtenSubmissionDetails="writtenSubmissionDetails"
					:resetSteps="true"
				/>
			</md-step>
		</md-steppers>

		<modal v-if="success">
			<template slot="header">
				<div style="font-size: 2rem">✅</div>
			</template>
			<template slot="body">
				<p>Thank you for the bursary post. Our Customer Success Team will be in touch once your post has been verified</p>
			</template>

			<template slot="footer">
				<md-button class="md-button md-success" @click="closeModal"> Okay</md-button>
			</template>
		</modal>
	</div>
</template>

<script>
import FirstStep from './steps/FirstStep.vue';
import SecondStep from './steps/SecondStep.vue';
import ThirdStep from './steps/ThirdStep.vue';
import FourthBursaryStep from './steps/FourthBursaryStep.vue';
import FifthStep from './steps/FifthStep.vue';
import db from '@/firebase/init';
import slugify from 'slugify';
import Modal from '@/components/Modal';
import firebase from 'firebase/compat/app';
import { serverTimestamp } from 'firebase/firestore';

export default {
	components: {
		FirstStep,
		SecondStep,
		ThirdStep,
		FourthBursaryStep,
		FifthStep,
		Modal,
	},
	data() {
		return {
			active: 'description',
			steps: {
				description: false,
				requirements: false,
				funding: false,
				additional: false,
				review: false,
			},
			slug: null,
			loading: false,
			success: false,
			auth: firebase.auth().currentUser,
			alias: '',
			title: '',
			description: '',
			workPlacement: '',
			workPlacementInfo: '',
			applicationDeadline: '',
			deadline: '',
			requirements: '',
			cv: false,
			id: false,
			proofOfIncome: false,
			matricResults: false,
			academicTranscript: false,
			tuitionQuotation: false,
			other: false,
			newDocument: '',
			additionalDocuments: [],
			documentInstructions: '',
			tuition: false,
			accommodation: false,
			meals: false,
			books: false,
			stipend: false,
			additionalCoverDetails: '',
			videoSubmission: '',
			videoSubmissionDetails: '',
			writtenSubmission: '',
			writtenSubmissionDetails: '',
		};
	},
	methods: {
		closeModal() {
			this.success = false;
			this.$router.push({ name: 'client-profile', params: { id: this.alias } });
		},
		nextStep(currentStep, nextStep) {
			this.steps[currentStep] = true;
			this.active = nextStep;
			this.updateStepNumbers();
		},
		previousStep(currentStep, previousStep) {
			this.active = previousStep;
			this.updateStepNumbers();
		},
		addTitle(title) {
			this.title = title;
		},
		addDescription(description) {
			this.description = description;
		},
		addWorkPlacement(workPlacement) {
			this.workPlacement = workPlacement;
		},
		addWorkPlacementInfo(workPlacementInfo) {
			this.workPlacementInfo = workPlacementInfo;
		},
		addDeadline(deadline) {
			this.deadline = deadline;
		},
		addCv(cv) {
			this.cv = cv;
		},
		addId(id) {
			this.id = id;
		},
		addRequirements(requirements) {
			this.requirements = requirements;
		},
		addProofOfIncome(proofOfIncome) {
			this.proofOfIncome = proofOfIncome;
		},
		addMatricResults(matricResults) {
			this.matricResults = matricResults;
		},
		addAcademicTranscript(academicTranscript) {
			this.academicTranscript = academicTranscript;
		},
		addTuitionQuotation(tuitionQuotation) {
			this.tuitionQuotation = tuitionQuotation;
		},
		addOther(other) {
			this.other = other;
		},
		addNewDocument(newDocument) {
			this.newDocument = newDocument;
		},
		addAdditionalDocuments(additionalDocuments) {
			this.additionalDocuments = additionalDocuments;
		},
		addDocumentInstructions(documentInstructions) {
			this.documentInstructions = documentInstructions;
		},
		addTuition(tuition) {
			this.tuition = tuition;
		},
		addAccommodation(accommodation) {
			this.accommodation = accommodation;
		},
		addMeals(meals) {
			this.meals = meals;
		},
		addBooks(books) {
			this.books = books;
		},
		addStipend(stipend) {
			this.stipend = stipend;
		},
		addAdditionalCoverDetails(additionalCoverDetails) {
			this.additionalCoverDetails = additionalCoverDetails;
		},
		addVideoSubmission(videoSubmission) {
			this.videoSubmission = videoSubmission;
		},
		addVideoSubmissionDetails(videoSubmissionDetails) {
			this.videoSubmissionDetails = videoSubmissionDetails;
		},
		addWrittenSubmission(writtenSubmission) {
			this.writtenSubmission = writtenSubmission;
		},
		addWrittenSubmissionDetails(writtenSubmissionDetails) {
			this.writtenSubmissionDetails = writtenSubmissionDetails;
		},
		async postBursary() {
			this.slug = slugify(this.title + ' ' + Date.now(), {
				replacement: '-',
				remove: /[$*_+~.()'"!\-:@]/g,
				lower: true,
			});
			const bursaryData = {
				title: this.title,
				description: this.description,
				workPlacement: this.workPlacement,
				workPlacementInformation: this.workPlacementInfo,
				applicationDeadline: this.deadline,
				employerId: this.auth.uid,
				created: serverTimestamp(),
				bursaryRequirements: this.requirements,
				documentationRequirements: {
					cv: this.cv,
					id: this.id,
					proofOfIncome: this.proofOfIncome,
					matricResults: this.matricResults,
					academicTranscript: this.academicTranscript,
					tuitionQuotation: this.tuitionQuotation,
					other: this.other,
				},
				additionalDocuments: this.additionalDocuments,
				documentSubmissionInstructions: this.documentInstructions,
				fundingCover: {
					tuition: this.tuition,
					accommodation: this.accommodation,
					meals: this.meals,
					books: this.books,
					stipend: this.stipend,
				},
				additionalCoverDetails: this.additionalCoverDetails,
				additionalSubmissions: {
					videoSubmission: this.videoSubmission,
					videoSubmissionDetails: this.videoSubmissionDetails,
					writtenSubmission: this.writtenSubmission,
					writtenSubmissionDetails: this.writtenSubmissionDetails,
				},
				verified: false,
				bursaryClosed: false,
			};

			try {
				this.loading = true;
				await db.collection('bursaries').doc(this.slug).set(bursaryData);
				this.loading = false;
				this.success = true;
			} catch (error) {
				this.loading = false;
				this.success = false;
			}
		},
		updateStepNumbers(reset = false) {
			this.$nextTick(() => {
				const stepHeaders = this.$refs.stepper.$el.querySelectorAll('.md-stepper-header');
				let i = reset ? 1 : 0;
				stepHeaders.forEach(header => {
					const stepNumber = i + 1;
					const stepIcon = header.querySelector('.md-stepper-number');
					stepIcon.textContent = stepNumber;
					i++;
				});
			});
		},
	},
	async mounted() {
		this.updateStepNumbers();
		const users = await db.collection('users').where('userId', '==', this.auth.uid).get();
		users.forEach(doc => {
			this.alias = doc.data().alias;
		});
	},
};
</script>

<style scoped>
.md-field {
	margin-bottom: 16px;
}

.error-text {
	font-size: 10px;
	color: red;
}

.bursary-field {
	margin: 1.5rem 0 1.5rem 0;
}
.wizard-container {
	max-width: 1600px;
	margin: auto;
	background-color: white;
	padding: 2rem;
}

.background {
	width: 100vw !important;
	height: 100vh !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.lds-circle > div {
	position: relative;
}
</style>
