<template>
	<div class="appointment-container">
		<div v-if="loading" class="background" style="display: flex; align-items: center; justify-content: center"></div>
		<div v-if="loading" class="text-center lds-circle" style="position: absolute; top: 50%; left: 50%">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<div class="appointment-content">
			<md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideAppointmentsModal">
				<md-icon>clear</md-icon>
			</md-button>
			<div style="margin-top: 4rem">
				<h3>{{ appointment.name }}</h3>
				<weekday-selector :allDaysOfWeek="['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']" :apiData="appointment" @selectedDayOfTheWeek="selectedDayOfTheWeek" />
				<md-button class="md-success" @click="checkData"> Update Appointment </md-button>
			</div>
		</div>
		<modal v-if="success" @close="success = false">
			<template slot="header">
				<h2 class="modal-title" style="color: green">Success!</h2>
			</template>

			<template slot="body">
				<p class="black">Your appointment slot has been successfully updated.</p>
			</template>

			<template slot="footer">
				<div class="centre">
					<md-button class="md-button md-success" @click="hideModal">Got it</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
import WeekdaySelector from '@/components/Inputs/WeekdaySelector.vue';
import firebase from 'firebase/compat/app';
import { formatPayload } from '@/utils/generateTimes.js';
import { Modal } from '@/components';

export default {
	components: {
		WeekdaySelector,
		Modal,
	},
	props: {
		appointment: {
			type: Object,
		},
	},
	data() {
		return {
			schedule: null,
			name: null,
			duration: null,
			loading: false,
			success: false,
		};
	},
	methods: {
		hideModal() {
			this.success = false;
			this.hideAppointmentsModal();
		},
		hideAppointmentsModal() {
			localStorage.removeItem('appointmentSlots');
			this.$emit('hideAppointmentsModal', false);
		},
		async checkData() {
			this.loading = true;
			this.success = false;
			const bookingUrl = this.appointment.bookingUrl;

			const lastSlashIndex = bookingUrl.lastIndexOf('/');
			const extractedString = bookingUrl.substring(lastSlashIndex + 1);
			const payload = {
				appointmentId: this.appointment.id,
				externalUserId: firebase.auth().currentUser.uid,
				link: extractedString,
				name: this.appointment.name,
				duration: this.appointment.duration,
				schedule: this.schedule ? this.schedule : this.appointment.schedule,
			};
			try {
				const updateAppointment = firebase.functions().httpsCallable('updateAppointment');
				const response = await updateAppointment(payload);
				this.loading = false;
				this.success = true;
			} catch (e) {
				this.loading = false;
				this.success = false;
			}
		},
		selectedDayOfTheWeek(updatedSchedule) {
			this.schedule = formatPayload(updatedSchedule);
		},
	},
};
</script>

<style scoped>
.appointment-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;
}

.appointment-content {
	height: 800px;
	width: 800px;
	background-color: white;
	overflow-y: scroll;
	padding: 2rem;
	position: fixed;
	border-radius: 10px;
}
</style>
