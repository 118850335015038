<template>
	<div>
		<h5 class="info-text">Let us know where your workplace is located?</h5>
		<div class="md-layout">
			<notifications></notifications>

			<input id="input" name="input" type="text" placeholder="Enter the location" />
			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('addressLine1') && touched.addressLine1 }, { 'md-form-group': true }, { 'md-error': errors.has('addressLine1') }]">
					<md-icon><i class="fas fa-map-pin"></i></md-icon>
					<label>Address</label>
					<md-input
						@change="addAddressLine1"
						placeholder=""
						v-model="addressLine1"
						data-vv-name="addressLine1"
						type="text"
						name="addressLine1"
						required
						v-validate="modelValidations.addressLine1"
					>
					</md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('addAddressLine1')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('addAddressLine1') && touched.addAddressLine1">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('city') && touched.city }, { 'md-form-group': true }, { 'md-error': errors.has('city') }]">
					<md-icon><i class="fas fa-map-marker-alt"></i></md-icon>
					<label>City</label>
					<md-input @change="addCity" v-model="city" data-vv-name="city" type="text" name="city" required v-validate="modelValidations.city"> </md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('city')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('city') && touched.city">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('province') && touched.province }, { 'md-form-group': true }, { 'md-error': errors.has('province') }]">
					<md-icon><i class="fas fa-map-pin"></i></md-icon>
					<label>Province</label>
					<md-input @change="addProvince" v-model="province" data-vv-name="province" type="text" name="province" required v-validate="modelValidations.province">
					</md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('province')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('province') && touched.province">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>
			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('country') && touched.country }, { 'md-form-group': true }, { 'md-error': errors.has('country') }]">
					<md-icon><i class="fas fa-map-pin"></i></md-icon>
					<label>Country</label>
					<md-input @change="addCountry" v-model="country" data-vv-name="country" type="text" name="country" required v-validate="modelValidations.province"> </md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('country')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('country') && touched.country">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('postalCode') && touched.postalCode }, { 'md-form-group': true }, { 'md-error': errors.has('postalCode') }]">
					<md-icon><i class="fas fa-map-marker-alt"></i></md-icon>
					<label>Postal Code</label>
					<md-input
						@change="addPostalCode"
						v-model="postalCode"
						data-vv-name="postalCode"
						type="text"
						name="postalCode"
						required
						v-validate="modelValidations.postalCode"
					>
					</md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('postalCode')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('postalCode') && touched.postalCode">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>
		</div>
	</div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default {
	components: {
		SlideYDownTransition,
	},
	props: {
		avatar: {
			type: String,
			default: './img/default-avatar.png',
		},
	},
	data() {
		return {
			streetNumber: null,
			streetName: null,
			user: null,
			client: null,
			addressLine1: null,
			addressLine2: null,
			city: null,
			province: null,
			country: null,
			postalCode: null,
			provinces: [],
			touched: {
				addressLine1: false,
				addressLine2: false,
				city: false,
				province: false,
				postalCode: false,
				country: false,
			},
			modelValidations: {
				addressLine1: {
					required: true,
				},
				city: {
					required: true,
				},
				province: {
					required: true,
				},
				postalCode: {
					required: true,
					min: 4,
				},
				country: {
					required: true,
				},
			},
		};
	},
	methods: {
		validate() {
			return this.$validator.validateAll().then(res => {
				this.$emit('on-validated', res);
				return res;
			});
		},
		addAddressLine1: function () {
			this.$emit('addressLine1', this.addressLine1);
		},
		addCity: function () {
			this.$emit('city', this.city);
		},
		addProvince: function () {
			this.$emit('province', this.province);
		},
		addPostalCode: function () {
			this.$emit('postalCode', this.postalCode);
		},
		addCountry: function () {
			this.$emit('country', this.country);
		},
	},
	watch: {
		addressLine1() {
			this.touched.addressLine1 = true;
		},
		addressLine2() {
			this.touched.addressLine2 = true;
		},
		city() {
			this.touched.city = true;
		},
		province() {
			this.touched.province = true;
		},
		postalCode() {
			this.touched.postalCode = true;
		},
		country() {
			this.touched.country = true;
		},
	},
	updated() {
		let google = window.google;
		const autocomplete = new google.maps.places.Autocomplete(document.getElementById('input'));
		autocomplete.addListener('place_changed', () => {
			const place = autocomplete.getPlace();
			for (let i = 0; i < place.address_components.length; i++) {
				for (let j = 0; j < place.address_components[i].types.length; j++) {
					if (place.address_components[i].types[j] === 'administrative_area_level_1') {
						this.province = place.address_components[i].long_name;
						this.$emit('province', this.province);
					}
					if (place.address_components[i].types[j] === 'postal_code') {
						this.postalCode = place.address_components[i].long_name;
						this.$emit('postalCode', this.postalCode);
					}
					if (place.address_components[i].types[j] === 'locality') {
						this.city = place.address_components[i].long_name;
						this.$emit('city', this.city);
					}
					if (place.address_components[i].types[j] === 'country') {
						this.country = place.address_components[i].long_name;
						this.$emit('country', this.country);
					}

					if (place.address_components[i].types[j] === 'street_number') {
						this.streetNumber = place.address_components[i].long_name;
					}
					if (place.address_components[i].types[j] === 'route') {
						this.streetName = place.address_components[i].long_name;
					}
				}
			}

			if (this.streetNumber === null) {
				this.streetNumber = '';
			} else if (this.streetName === null) {
				this.streetName = '';
			}

			this.addressLine1 = this.streetNumber + ' ' + this.streetName;
			this.$emit('addressLine1', this.addressLine1);
		});
	},
	created() {
		let settings = db.collection('Settings').doc('Drop-down Lists');
		settings.get().then(doc => {
			this.provinces = doc.data().Provinces;
		});

		this.user = firebase.auth().currentUser;
		let ref = db.collection('users');
		ref.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.client = db.collection('clients').doc(doc.id);
					this.client
						.get()
						.then(doc => {
							if (doc.exists) {
								this.addressLine1 = doc.data().addressLine1;
								this.city = doc.data().city;
								this.province = doc.data().province;
								this.postalCode = doc.data().postalCode_zipCode;
								this.country = doc.data().country;
							}
						})
						.catch(err => {
							console.log(err.message);
						});
				});
			});
	},
};
</script>
<style scoped>
input {
	width: 100%;
	padding: 1rem;
	border: none;
	border-bottom: 1px solid black;
}
@media only screen and (max-width: 768px) {
	.md-field label {
		font-size: 11px;
	}
}
</style>
