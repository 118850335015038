import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getCurrentDateTime } from '@utils';

const storage = getStorage();

export function createStorageRef(alias, location, fileName) {
	return ref(storage, `users/students/${alias}/${location}/${fileName}`);
}

export function uploadFile(storageRef, file, onProgress, onError, onSuccess) {
	const uploadTask = uploadBytesResumable(storageRef, file);
	uploadTask.on(
		'state_changed',
		snapshot => {
			onProgress && onProgress(snapshot);
		},
		error => {
			onError && onError(error);
		},
		async () => {
			try {
				const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
				onSuccess && onSuccess(downloadURL);
			} catch (error) {
				onError && onError(error);
			}
		}
	);
}

export async function updateQualificationDocument(qualificationRef, academicTranscript, firebaseUrl) {
	const doc = await qualificationRef.get();
	if (doc.exists) {
		await qualificationRef.update({
			academicTranscript: { fileName: academicTranscript, url: firebaseUrl },
			lastModified: getCurrentDateTime(),
		});
	}
}
