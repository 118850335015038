<template>
	<div>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div>
				<img src="@/assets/img/logo.png" />
				<div class="loading"></div>
			</div>
		</div>
		<h5 class="info-text">Social Media Accounts and Additional Documents</h5>
		<p class="info-text">Please do not include inappropriate handles 🤪</p>
		<div class="md-layout">
			<notifications></notifications>
			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('linkedIn') && touched.linkedIn }, { 'md-form-group': true }, { 'md-error': errors.has('linkedIn') }]">
					<md-icon><i class="fab fa-linkedin"></i></md-icon>
					<label>LinkedIn</label>
					<md-input
						@change="addLinkedIn"
						v-model="linkedIn"
						data-vv-name="linkedIn"
						@blur="setTouched('linkedIn')"
						type="url"
						name="linkedIn"
						required
						v-validate="modelValidations.linkedIn"
					></md-input>
					<md-tooltip md-direction="top" v-bind:style="{ maxWidth: '800px' }">Please add https:// to the beginning of the URL</md-tooltip>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('linkedIn')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('linkedIn') && touched.linkedIn">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('facebook') && touched.facebook }, { 'md-form-group': true }, { 'md-error': errors.has('facebook') }]">
					<md-icon><i class="fab fa-facebook"></i></md-icon>
					<label>Facebook</label>
					<md-input
						@blur="setTouched('facebook')"
						@change="addFacebook"
						v-model="facebook"
						data-vv-name="facebook"
						type="url"
						name="facebook"
						v-validate="modelValidations.facebook"
					></md-input>
					<md-tooltip md-direction="top" v-bind:style="{ maxWidth: '800px' }">Please add https:// to the beginning of the URL</md-tooltip>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('facebook')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('facebook') && touched.facebook">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('twitter') && touched.twitter }, { 'md-form-group': true }, { 'md-error': errors.has('twitter') }]">
					<md-icon><i class="fab fa-twitter"></i></md-icon>
					<label>Twitter</label>
					<md-input
						@blur="setTouched('twitter')"
						@change="addTwitter"
						v-model="twitter"
						data-vv-name="twitter"
						type="url"
						name="twitter"
						v-validate="modelValidations.twitter"
					></md-input>
					<md-tooltip md-direction="top" v-bind:style="{ maxWidth: '800px' }">Please add https:// to the beginning of the URL</md-tooltip>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('twitter')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('twitter') && touched.twitter">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('instagram') && touched.instagram }, { 'md-form-group': true }, { 'md-error': errors.has('instagram') }]">
					<md-icon><i class="fab fa-instagram"></i></md-icon>
					<label>Instagram</label>
					<md-input
						@blur="setTouched('instagram')"
						@change="addInstagram"
						v-model="instagram"
						data-vv-name="instagram"
						type="url"
						name="instagram"
						v-validate="modelValidations.instagram"
					></md-input>
					<md-tooltip md-direction="top" v-bind:style="{ maxWidth: '800px' }">Please add https:// to the beginning of the URL</md-tooltip>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('instagram')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('instagram') && touched.instagram">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('github') && touched.github }, { 'md-form-group': true }, { 'md-error': errors.has('github') }]">
					<md-icon><i class="fab fa-github"></i></md-icon>
					<label>Github</label>
					<md-input
						@blur="setTouched('github')"
						@change="addGithub"
						v-model="github"
						data-vv-name="github"
						type="url"
						name="github"
						v-validate="modelValidations.github"
					></md-input>
					<md-tooltip md-direction="top" v-bind:style="{ maxWidth: '800px' }">Please add https:// to the beginning of the URL</md-tooltip>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('github')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('github') && touched.github">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field
					:class="[{ 'md-valid': !errors.has('personalWebsite') && touched.personalWebsite }, { 'md-form-group': true }, { 'md-error': errors.has('personalWebsite') }]"
				>
					<md-icon><i class="fas fa-globe"></i></md-icon>
					<label>Personal Website</label>
					<md-input
						@blur="setTouched('personalWebsite')"
						@change="addPersonalWebsite"
						v-model="personalWebsite"
						data-vv-name="personalWebsite"
						type="url"
						name="personalWebsite"
						v-validate="modelValidations.personalWebsite"
					></md-input>
					<md-tooltip md-direction="top" v-bind:style="{ maxWidth: '800px' }">Please add https:// to the beginning of the URL</md-tooltip>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('personalWebsite')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('personalWebsite') && touched.personalWebsite">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('id') && touched.id }, { 'md-form-group': true }, { 'md-error': errors.has('id') }]">
					<label>ID/Passport</label>
					<md-file @change="previewID" v-model="id" data-vv-name="id" name="id" v-validate="modelValidations.id" />
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('id')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('id') && touched.id">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('cv') && touched.cv }, { 'md-form-group': true }, { 'md-error': errors.has('cv') }]">
					<label>CV</label>
					<md-file @change="previewCV" v-model="cv" data-vv-name="cv" name="cv" v-validate="modelValidations.cv" />
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('cv')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('cv') && touched.cv">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('portfolio') && touched.portfolio }, { 'md-form-group': true }, { 'md-error': errors.has('portfolio') }]">
					<label>Portfolio</label>
					<md-file @change="previewPortfolio" v-model="portfolio" data-vv-name="portfolio" name="portfolio" v-validate="modelValidations.portfolio" />
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('portfolio')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('portfolio') && touched.portfolio">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('certificate1') && touched.certificate1 }, { 'md-form-group': true }, { 'md-error': errors.has('certificate1') }]">
					<label>Certificate #1</label>
					<md-file @change="previewCertificate1" v-model="certificate1" data-vv-name="certificate1" name="certificate1" v-validate="modelValidations.certificate1" />
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('certificate1')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('certificate1') && touched.certificate1">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div v-if="certificate1" class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('certificate2') && touched.certificate2 }, { 'md-form-group': true }, { 'md-error': errors.has('certificate2') }]">
					<label>Certificate #2</label>
					<md-file @change="previewCertificate2" v-model="certificate2" data-vv-name="certificate2" name="certificate2" v-validate="modelValidations.certificate2" />
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('certificate2')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('certificate2') && touched.certificate2">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>

			<div v-if="certificate2" class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('certificate3') && touched.certificate3 }, { 'md-form-group': true }, { 'md-error': errors.has('certificate3') }]">
					<label>Certificate #3</label>
					<md-file @change="previewCertificate3" v-model="certificate3" data-vv-name="certificate3" name="certificate3" v-validate="modelValidations.certificate3" />
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('certificate3')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('certificate3') && touched.certificate3">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>
		</div>
		<div>
			<h4>Extracurricular Activities</h4>
			<md-button class="md-plain jb-standard md-fab-bottom-right" @click="addActivity"> <md-icon>add</md-icon>Add Extracurricular Activities</md-button>

			<multi-select
				:openModal="openActivityModal"
				@cancel="closeModal => (openActivityModal = closeModal)"
				:items="activities"
				@confirmSelection="selectedValue => confirmSelection(selectedValue)"
				:heading="this.heading"
			>
			</multi-select>

			<!-- show the selectd items -->
			<div>
				<md-table v-model="selectedActivities" md-card>
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell>{{ item.value }}</md-table-cell>
					</md-table-row>
				</md-table>
			</div>
		</div>
		<modal v-if="modal" @close="modalHide">
			<template slot="header">
				<h4 class="modal-title black">Whoa there! ✋</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="modalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<p class="black">You cannot exceed the file limit of 2MB</p>
			</template>
			<template slot="footer">
				<div class="centre">
					<!-- Modal: Verify Email and continue creating account -->
					<md-button class="md-button md-success" @click="modalHide">Got it</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions';
import { Modal } from '@/components';
import db from '@/firebase/init';
import firebase, { storage } from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import moment from 'moment';
import { Activities } from '@/constants/extra-cirricular-activities';
import MultiSelect from '@/components/Inputs/MultiSelect.vue';
export default {
	components: {
		SlideYDownTransition,
		Modal,
		MultiSelect,
	},
	data() {
		return {
			selectedActivities: [],
			activities: Activities,
			openActivityModal: false,
			firebaseUrl: null,
			modal: null,
			alias: null,
			loading: false,
			linkedIn: null,
			facebook: null,
			twitter: null,
			instagram: null,
			github: null,
			portfolio: null,
			certificate1: null,
			certificate2: null,
			certificate3: null,
			personalWebsite: null,
			id: null,
			cv: null,
			uploadCV: 0,
			transcript: null,
			touched: {
				linkedIn: false,
				facebook: false,
				twitter: false,
				instagram: false,
				github: false,
				portfolio: false,
				cv: false,
				certificate1: false,
				certificate2: false,
				certificate3: false,
				personalWebsite: false,
			},
			modelValidations: {
				linkedIn: {
					min: 6,
				},
				facebook: {
					min: 6,
				},
				twitter: {
					min: 6,
				},
				instagram: {
					min: 6,
				},
				github: {
					min: 6,
				},
				personalWebsite: {
					min: 6,
				},
				portfolio: {},
				certificate1: {},
				certificate2: {},
				certificate3: {},
				id: {},
				cv: {
					required: true,
				},
				transcript: {},
			},
			heading: 'Please select your Extracurricular Activities',
			extraCirricularActivities: [],
		};
	},
	methods: {
		setTouched(field) {
			this.touched[field] = true;
		},
		addActivity() {
			this.openActivityModal = true;
		},
		confirmSelection(value) {
			this.selectedActivities = value;
			this.extraCirricularActivities = value.map(x => x.value);
			this.openActivityModal = false;
			this.addExtraCirricularActivities();
		},
		previewID(event) {
			this.uploadValue = 0;
			var file = event.target.files[0];
			if (file.size < 2 * 1024 * 1024) {
				this.fileUpload(file, 'ID');
			} else {
				this.modal = true;
			}
		},
		previewCV(event) {
			this.uploadValue = 0;
			var file = event.target.files[0];
			if (file.size < 2 * 1024 * 1024) {
				this.fileUpload(file, 'CV');
			} else {
				this.modal = true;
			}
		},
		previewPortfolio(event) {
			this.uploadValue = 0;
			var file = event.target.files[0];
			if (file.size < 2 * 1024 * 1024) {
				this.fileUpload(file, 'Portfolio');
			} else {
				this.modal = true;
			}
		},
		previewCertificate1(event) {
			this.uploadValue = 0;
			var file = event.target.files[0];
			if (file.size < 2 * 1024 * 1024) {
				this.fileUpload(file, 'certificate-1');
			} else {
				this.modal = true;
			}
		},
		previewCertificate2(event) {
			this.uploadValue = 0;
			var file = event.target.files[0];
			if (file.size < 2 * 1024 * 1024) {
				this.fileUpload(file, 'certificate-2');
			} else {
				this.modal = true;
			}
		},
		previewCertificate3(event) {
			this.uploadValue = 0;
			var file = event.target.files[0];
			if (file.size < 2 * 1024 * 1024) {
				this.fileUpload(file, 'certificate-3');
			} else {
				this.modal = true;
			}
		},
		fileUpload(data, location) {
			this.loading = true;
			const storageRef = firebase
				.storage()
				.ref()
				.child('users/students/' + this.alias + '/' + location + '/' + data.name)
				.put(data);
			storageRef.on(
				`state_changed`,
				snapshot => {
					this.uploadCV = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				},
				error => {
					console.log(error.message);
				},
				async () => {
					this.uploadCV = 100;
					storageRef.snapshot.ref.getDownloadURL().then(url => {
						this.firebaseUrl = url;
						if (location === 'ID') {
							this.id = data.name;
							this.student.update({
								id: { fileName: this.id, url: this.firebaseUrl },
								lastModified: moment(Date.now()).format('L'),
							});
							this.loading = false;
						}
						if (location === 'CV') {
							this.cv = data.name;
							this.student.update({
								cv: { fileName: this.cv, url: this.firebaseUrl },
								lastModified: moment(Date.now()).format('L'),
							});
							this.loading = false;
						}
						if (location === 'Portfolio') {
							this.portfolio = data.name;
							this.student.update({
								portfolio: { fileName: this.portfolio, url: this.firebaseUrl },
								lastModified: moment(Date.now()).format('L'),
							});
							this.loading = false;
						}
						if (location === 'certificate-1') {
							this.certificate1 = data.name;
							this.student.update({
								certificate1: { fileName: this.certificate1, url: this.firebaseUrl },
								lastModified: moment(Date.now()).format('L'),
							});
							this.loading = false;
						}
						if (location === 'certificate-2') {
							this.certificate2 = data.name;
							this.student.update({
								certificate2: { fileName: this.certificate2, url: this.firebaseUrl },
								lastModified: moment(Date.now()).format('L'),
							});
							this.loading = false;
						}
						if (location === 'certificate-3') {
							this.certificate3 = data.name;
							this.student.update({
								certificate3: { fileName: this.certificate3, url: this.firebaseUrl },
								lastModified: moment(Date.now()).format('L'),
							});
							this.loading = false;
						}
					});
				}
			);
		},
		modalHide() {
			this.modal = false;
		},
		validate() {
			return this.$validator.validateAll().then(res => {
				this.$emit('on-validated', res);
				return res;
			});
		},
		addLinkedIn: function () {
			this.$emit('linkedIn', this.linkedIn);
		},
		addFacebook: function () {
			this.$emit('facebook', this.facebook);
		},
		addTwitter: function () {
			this.$emit('twitter', this.twitter);
		},
		addInstagram: function () {
			this.$emit('instagram', this.instagram);
		},
		addGithub: function () {
			this.$emit('github', this.github);
		},
		addCertificate1: function () {
			this.$emit('certificate1', this.certificate1);
		},
		addCertificate2: function () {
			this.$emit('certificate2', this.certificate2);
		},
		addCertificate3: function () {
			this.$emit('certificate3', this.certificate3);
		},
		addPersonalWebsite: function () {
			this.$emit('personalWebsite', this.personalWebsite);
		},
		addPortfolio: function () {
			this.$emit('portfolio', this.portfolio);
		},
		addCV: function () {
			this.$emit('cv', this.cv);
		},
		addExtraCirricularActivities: function () {
			this.$emit('extraCirricularActivities', this.extraCirricularActivities);
		},
		selectedExtraCiricularActivities() {
			for (const activity in this.extraCirricularActivities) {
				this.selectedActivities.push({ id: activity, value: this.extraCirricularActivities[activity] });
			}
		},
	},
	watch: {
		portfolio() {
			this.touched.portfolio = true;
		},
		certificate1() {
			this.touched.certificate1 = true;
		},
		certificate2() {
			this.touched.certificate2 = true;
		},
		certificate3() {
			this.touched.certificate3 = true;
		},
		id() {
			this.touched.id = true;
		},
		cv() {
			this.touched.cv = true;
		},
	},
	created() {
		this.user = firebase.auth().currentUser;
		let ref = db.collection('users');
		ref.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.alias = doc.id;
					this.student = db.collection('students').doc(doc.id);
					this.student
						.get()
						.then(doc => {
							if (doc.exists) {
								let data = doc.data();
								this.linkedIn = data.linkedIn;
								this.facebook = data.facebook;
								this.twitter = data.twitter;
								this.instagram = data.instagram;
								this.github = data.github;
								this.personalWebsite = data.personalWebsite;
								this.portfolio = data.portfolio ? data.portfolio.fileName : null;
								this.id = data.id ? data.id.fileName : null;
								this.cv = data.cv ? data.cv.fileName : null;
								this.certificate1 = data.certificate1 ? data.certificate1.fileName : null;
								this.certificate2 = data.certificate2 ? data.certificate2.fileName : null;
								this.certificate3 = data.certificate3 ? data.certificate3.fileName : null;
								this.extraCirricularActivities = data.extraCirricularActivities;
								this.selectedExtraCiricularActivities();
							}
						})
						.catch(err => {
							console.log(err.message);
						});
				});
			});
	},
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
	.md-field label {
		font-size: 11px;
	}
}
</style>
