<template>
	<div class="full-page" :class="{ 'nav-open': $sidebar.showSidebar }">
		<md-toolbar md-elevation="0" class="md-transparent md-toolbar-absolute">
			<div class="md-toolbar-row md-offset">
				<div class="md-toolbar-section-start" @click="navigateHome">
					<!-- <h3 class="md-title">{{ $route.name }}</h3> -->
					<img src="@/assets/img/Jobox-Logo.png" height="125px" width="125px" style="cursor: pointer" />
				</div>
				<div class="md-toolbar-section-end">
					<md-button class="md-just-icon md-simple md-round md-toolbar-toggle" :class="{ toggled: $sidebar.showSidebar }" @click="toggleSidebar">
						<span class="icon-bar"></span>
						<span class="icon-bar"></span>
						<span class="icon-bar"></span>
					</md-button>

					<div class="md-collapse" :class="{ 'off-canvas-sidebar': responsive }">
						<md-list>
							<div @click="navigateHome">
								<md-list-item @click="linkClick"> <md-icon>home</md-icon> Home </md-list-item>
							</div>

							<router-link :to="{ path: '/login' }">
								<md-list-item @click="linkClick"> <md-icon>fingerprint</md-icon> Login </md-list-item>
							</router-link>
							<router-link :to="{ path: '/register' }">
								<md-list-item @click="linkClick"> <md-icon>person_add</md-icon> Register </md-list-item>
							</router-link>
						</md-list>
					</div>
				</div>
			</div>
		</md-toolbar>
		<div class="wrapper wrapper-full-page" @click="toggleSidebarPage">
			<div class="page-header header-filter" :class="setPageClass" filter-color="black" :style="setBgImage">
				<div class="container md-offset">
					<zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
						<router-view></router-view>
					</zoom-center-transition>
				</div>
				<footer class="footer">
					<div class="container md-offset">
						<nav>
							<ul>
								<li><a href="https://joinjobox.com/terms-and-conditions/" target="_blank">Terms and Conditions</a></li>
								<li><a href="https://joinjobox.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
								<li><a href="https://intercom.help/jobox-64fe20407ade/en" target="_blank">FAQ</a></li>
							</ul>
						</nav>
						<div class="copyright text-center">&copy; Jobox {{ new Date().getFullYear() }}</div>
					</div>
				</footer>
			</div>
		</div>
	</div>
</template>
<script>
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
	components: {
		ZoomCenterTransition,
	},
	props: {
		backgroundColor: {
			type: String,
			default: 'black',
		},
	},
	inject: {
		autoClose: {
			default: true,
		},
	},
	data() {
		return {
			responsive: false,
			showMenu: false,
			menuTransitionDuration: 250,
			pageTransitionDuration: 300,
			year: new Date().getFullYear(),
		};
	},
	computed: {
		setBgImage() {
			let images = {
				Login: './img/login/Login.jpeg',
				ForgotPassword: './img/forgotPassword/Forgot_Password.jpeg',
				Configuration: './img/forgotPassword/Forgot_Password.jpeg',
				Register: './img/register/Sign_Up.jpeg',
				Contact: './img/contact/Contact.jpeg',
				Faq: './img/contact/Contact.jpeg',
				PrivacyPolicy: './img/privacyPolicy/Privacy_Policy.jpeg',
				TermsAndConditions: './img/termsAndConditions/Terms_And_Conditions.jpeg',
				Lock: './img/lock.jpg',
				StudentAccount: './img/register/Sign_Up.jpeg',
				ClientAccount: './img/register/Sign_Up.jpeg',
				NotFound: './img/404.jpeg',
			};
			return {
				backgroundImage: `url(${images[this.$route.name]})`,
			};
		},
		setPageClass() {
			return `${this.$route.name}-page`.toLowerCase();
		},
	},
	methods: {
		toggleSidebarPage() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		},
		linkClick() {
			if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
				this.$sidebar.displaySidebar(false);
			}
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		toggleNavbar() {
			document.body.classList.toggle('nav-open');
			this.showMenu = !this.showMenu;
		},
		closeMenu() {
			document.body.classList.remove('nav-open');
			this.showMenu = false;
		},
		onResponsiveInverted() {
			if (window.innerWidth < 991) {
				this.responsive = true;
			} else {
				this.responsive = false;
			}
		},
		navigateHome() {
			window.open('https://www.joinjobox.com', '_blank');
		},
	},
	mounted() {
		this.onResponsiveInverted();
		window.addEventListener('resize', this.onResponsiveInverted);
	},
	beforeDestroy() {
		this.closeMenu();
		window.removeEventListener('resize', this.onResponsiveInverted);
	},
	beforeRouteUpdate(to, from, next) {
		// Close the mobile menu first then transition to next page
		if (this.showMenu) {
			this.closeMenu();
			setTimeout(() => {
				next();
			}, this.menuTransitionDuration);
		} else {
			next();
		}
	},
};
</script>
<style lang="scss" scoped>
$scaleSize: 0.1;
$zoomOutStart: 0.7;
$zoomOutEnd: 0.46;
@keyframes zoomIn8 {
	from {
		opacity: 0;
		transform: scale3d($scaleSize, $scaleSize, $scaleSize);
	}
	100% {
		opacity: 1;
	}
}
.wrapper-full-page .zoomIn {
	animation-name: zoomIn8;
}
@keyframes zoomOut8 {
	from {
		opacity: 1;
		transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
	}
	to {
		opacity: 0;
		transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
	}
}
.wrapper-full-page .zoomOut {
	animation-name: zoomOut8;
}

@media (max-width: 450px) {
	.container {
		padding: 0;
	}
	footer {
		margin-top: 3rem;
		nav {
			ul {
				display: flex;
				flex-direction: column;
			}
		}
	}
}
</style>
