<template>
	<div>
		<br />
		<br />
		<md-field :class="v$.title.$error && 'md-error'">
			<md-icon> book </md-icon>
			<label>Name of Bursary</label>
			<md-input @input="addTitle" required v-model="title"></md-input>
		</md-field>
		<p v-if="v$.title.$error" class="error_message">Please enter the name of the bursary</p>

		<div style="margin: 3rem 0 0 0 !important">
			<div class="row">
				<md-icon :class="v$.description.$error && 'error'">description</md-icon>
				<label style="margin-left: 0.5rem" :class="v$.description.$error && 'error_message'"> Bursary Description </label>
			</div>
			<p class="error_message" v-if="v$.description.$error">Please enter a bursary description</p>
			<vue-editor style="margin: 1rem 0 3rem 0 !important; width: 100% !important" v-model="description" @text-change="addDescription" />
		</div>

		<md-field :class="v$.workPlacement.$error && 'md-error'">
			<md-icon style="margin-right: 1rem" :class="v$.workPlacement.$error && 'error'">business</md-icon>
			<label for="workPlacement">Does this bursary involve work placement?</label>
			<md-select required v-model="workPlacement" @input="addWorkPlacement" name="workPlacement" id="workPlacement">
				<md-option value="Yes">Yes</md-option>
				<md-option value="No">No</md-option>
			</md-select>
		</md-field>
		<p v-if="v$.workPlacement.$error" class="error_message">Please select an option whether this bursary involves work placement</p>
		<br />
		<br />
		<div v-if="workPlacement === 'Yes'">
			<div class="row">
				<md-icon style="margin-right: 1rem" :class="v$.workPlacementInfo.$error && 'error'">business</md-icon>
				<label :style="v$.workPlacementInfo.$error && 'color:red'">Work Placement Information</label>
			</div>
			<p class="error_message" v-if="v$.workPlacementInfo.$error">Please add work place information</p>
			<vue-editor style="margin: 1rem 0 3rem 0 !important" v-model="workPlacementInfo" @text-change="addWorkPlacementInfo" />
		</div>
		<br />
		<br />

		<label :style="v$.deadline.$error && 'color:red'">Application Deadline</label>
		<md-datepicker required :class="v$.deadline.$error && 'md-invalid'" v-model="deadline" @input="addDeadline"></md-datepicker>

		<p class="error_message" v-if="v$.deadline.$error">Please add a deadline</p>

		<div class="justify-end">
			<md-button class="md-raised md-primary" @click="nextStep">Next</md-button>
		</div>

		<!-- <button @click="test">test</button> -->
	</div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
	components: {
		VueEditor,
	},
	data() {
		return {
			title: '',
			description: '',
			workPlacement: '',
			workPlacementInfo: '',
			deadline: '',
		};
	},
	setup() {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			title: { required },
			description: { required },
			workPlacement: { required },
			workPlacementInfo: {
				required: this.workPlacement === 'Yes' ? required : false,
			},
			deadline: { required },
		};
	},
	methods: {
		nextStep() {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				this.$emit('next');
			}
		},
		addTitle() {
			this.$emit('title', this.title);
		},
		addDescription() {
			this.$emit('description', this.description);
		},
		addWorkPlacement() {
			this.$emit('workPlacement', this.workPlacement);
		},
		addWorkPlacementInfo() {
			this.$emit('workPlacementInfo', this.workPlacementInfo);
		},
		addDeadline() {
			this.$emit('deadline', this.deadline);
		},
	},
};
</script>
