<template>
	<modal>
		<template slot="header">
			<div :class="!this.filterSelected ? 'alertMessage' : ''">Please select at least one filter type</div>
		</template>
		<template slot="body">
			<select-field v-model="gender" :options="genderType" name:="genderTypeList" iconName="account_circle" label="Gender" @input="filterGender"/> <select-field
			v-model="favourite" :options="favouriteTypeList" name:="favouriteTypelist" iconName="star" label="Favourite Applicant" @input="filterFavourites"/> <select-field
			v-model="qualification" :options="qualificationType" name:="qualificationType" iconName="workspace_premium" label="Qualification Type" @input="filterQualification"/>
			<select-field v-model="ageRange" :options="ageRangeType" name:="ageRangeType" iconName="directions_run" label="Age Range" @input="filterAge"/> <select-field
			v-model="ethnicity" :options="ethinicityType" name:="ethnicityTypeList" iconName="diversity_3" label="Ethnicity" @input="filterEthnicity" :multiple="true"/>
			<select-field v-model="university" :options="this.institution" name:="institution" iconName="school" label="University" @input="filterUniversities" :multiple="true"/>
			<select-field v-model="applicationStatus" :options="this.status" name:="applicationStatus" iconName="fact_check" label="Application Status"
			@input="filterApplicationStatus" :multiple="true"/> <select-field v-model="extracirricularActivities" :options="this.activities" name:="ethnicityTypeList"
			iconName="groups" label="Extracurricular Activities" @input="filterExtracirricularActivities" :multiple="true"/>
			<select-field v-model="town" :options="townsList" name="locationTypeList" iconName="place" label="Location" @input="filterLocation" />
			<select-field
				v-model="studentRelocation"
				:options="studentRelocationType"
				name="studentRelocationTypeList"
				iconName="commute"
				label="Willing to relocate?"
				@input="filterStudentRelocation"
			/>
		</template>
		<template slot="footer">
			<div>
				<md-button class="md-success" @click="resetFilters()">Clear Filters</md-button>
				&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
				<md-button class="md-danger" @click="closeModal()">Close</md-button>
			</div>
		</template>
	</modal>
</template>
<script>
import { Modal } from '@/components';
import SelectField from '@/components/Inputs/SelectField.vue';
import { QualificationType } from '@/constants/qualification-type.js';
import { GenderType } from '@/constants/gender-type.js';
import { AgeRangeType } from '@/constants/age-range-type.js';
import { FavouriteTypeList } from '@/constants/favourite-type-list.js';
import { EthnicityType } from '@/constants/ethnicity-type.js';
import { ApplicationStatus } from '@/constants/application-status.js';
import { Activities } from '@/constants/extra-cirricular-activities.js';
export default {
	components: {
		Modal,
		SelectField,
	},
	props: {
		userGender: String,
		userFavourite: String,
		userQualification: String,
		userAgeRange: String,
		userEthnicity: Array,
		universities: Array,
		applicant: Array,
		selectedTown: String,
		selectedStudentRelocation: String,
	},
	computed: {
		townsList() {
			if (this.applicant && Array.isArray(this.applicant)) {
				return this.applicant.map(a => a.location && a.location.town).filter(Boolean);
			}
			return [];
		},
	},
	mounted() {
		if (this.selectedTown && this.townsList.includes(this.selectedTown)) {
			this.town = this.selectedTown;
		}
	},
	data() {
		return {
			qualificationType: Object.values(QualificationType),
			favouriteTypeList: Object.values(FavouriteTypeList),
			genderType: Object.values(GenderType),
			ageRange: this.userAgeRange,
			ethnicity: this.userEthnicity,
			ethinicityType: Object.values(EthnicityType),
			gender: this.userGender,
			favourite: this.userFavourite,
			qualification: this.userQualification,
			filterSelected: null,
			ageRangeType: Object.values(AgeRangeType),
			institution: this.universities,
			status: Object.values(ApplicationStatus),
			activities: Activities.map(x => x.value),
			university: [],
			extracirricularActivities: [],
			applicationStatus: [],
			town: null,
			studentRelocation: this.selectedStudentRelocation,
			studentRelocationType: ['Yes', 'No'],
		};
	},
	methods: {
		filterAge() {
			if (this.ageRange) this.$emit('setAge', this.ageRange);
		},
		filterGender() {
			this.$emit('setGender', this.gender);
		},
		filterFavourites() {
			if (this.favourite != null) {
				const favouriteSelection = this.favourite === 'yes' ? true : false;
				this.$emit('setFilterFavourite', favouriteSelection);
			}
		},
		filterQualification() {
			if (this.qualification) this.$emit('setQualification', this.qualification);
		},
		filterUniversities() {
			this.$emit('setUniversities', this.university);
		},
		filterExtracirricularActivities() {
			this.$emit('setExtracirricularActivities', this.extracirricularActivities);
		},
		filterApplicationStatus() {
			this.$emit('setApplicationStatus', this.applicationStatus);
		},
		filterEthnicity() {
			this.$emit('setEthnicity', this.ethnicity);
		},
		filterLocation() {
			this.$emit('setLocation', this.town);
		},
		filterStudentRelocation() {
			if (this.studentRelocation) this.$emit('setStudentRelocation', this.studentRelocation);
		},
		resetFilters() {
			this.$emit('resetFilters', true);
			this.favourite = null;
			this.gender = null;
			this.ageRange = null;
			this.qualification = null;
			this.applicationStatus = [];
			this.extracirricularActivities = [];
			this.university = [];
			this.ethnicity = [];
			this.town = null;
			this.studentRelocation = null;
		},
		closeModal() {
			this.$emit('closeModal', false);
		},
	},
};
</script>

<style scoped>
.alertMessage {
	color: red;
	font-weight: bold;
}
.section {
	display: flex;
	justify-content: space-between;
}
.modal-footer {
	display: flex;
	justify-content: space-between;
}
</style>
