<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
			<simple-wizard
				v-bind:image="image"
				v-bind:firstName="firstName"
				v-bind:lastName="lastName"
				v-bind:dob="dob"
				v-bind:gender="gender"
				v-bind:race="race"
				v-bind:location="location"
				v-bind:relocation="relocation"
				v-bind:citizenship="citizenship"
				v-bind:id="id"
				v-bind:passport="passport"
				v-bind:identification="identification"
				v-bind:disability="disability"
				v-bind:disabilityDescription="disabilityDescription"
				v-bind:license="license"
				v-bind:vehicle="vehicle"
				v-bind:bio="bio"
				v-bind:programme="programme"
				v-bind:studying="studying"
				v-bind:institution="institution"
				v-bind:campus="campus"
				v-bind:studentNo="studentNo"
				v-bind:faculty="faculty"
				v-bind:degree="degree"
				v-bind:major="major"
				v-bind:year="year"
				v-bind:graduateStatus="graduateStatus"
				v-bind:accountName="accountName"
				v-bind:accountNumber="accountNumber"
				v-bind:accountType="accountType"
				v-bind:bankName="bankName"
				v-bind:branchCode="branchCode"
				v-bind:email="email"
				v-bind:linkedIn="linkedIn"
				v-bind:facebook="facebook"
				v-bind:twitter="twitter"
				v-bind:instagram="instagram"
				v-bind:github="github"
				v-bind:certificate1="certificate1"
				v-bind:certificate2="certificate2"
				v-bind:certificate3="certificate3"
				v-bind:portfolio="portfolio"
				v-bind:personalWebsite="personalWebsite"
				v-bind:cv="cv"
				v-bind:industryCategory="industryCategory"
				v-bind:qualificationsValid="qualificationsValid"
				v-bind:choices="choices"
				v-bind:department="department"
				v-bind:extraCirricularActivities="extraCirricularActivities"
				@emailVerified="addEmailVerified"
				@feedback="addFeedback"
			>
				<template slot="header">
					<md-button class="btn-next md-success" @click="back">Save Changes</md-button>
					<p></p>
					<h3 class="title">Edit Your Profile</h3>
					<h5 class="category">This information will let us know more about you.</h5>
				</template>

				<wizard-tab :before-change="() => validateStep('step1')">
					<template slot="label">
						<div class="pc-view">About</div>
						<div class="mobi-view"><i class="fas fa-user"></i></div>
					</template>
					<first-step
						ref="step1"
						@on-validated="onStepValidated"
						@image="addImage"
						@firstName="addFirstName"
						@lastName="addLastName"
						@dob="addDob"
						@gender="addGender"
						@race="addRace"
						@location="addLocation"
						@relocation="addRelocation"
						@license="addLicense"
						@vehicle="addVehicle"
						@id="addId"
						@bio="addBio"
						@citizenship="addCitizenship"
						@passport="addPassport"
						@programme="addProgramme"
						@identification="addIdentification"
					>
					</first-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step2')">
					<template slot="label">
						<div class="pc-view">Studies</div>
						<div class="mobi-view"><i class="fas fa-university"></i></div>
					</template>
					<second-step
						ref="step2"
						@on-validated="onStepValidated"
						@institution="addInstitution"
						@studentNo="addStudentNo"
						@qualificationsValid="addQualificationsValid"
						@faculty="addFaculty"
						@degree="addDegree"
						@year="addYear"
						@graduateStatus="addGraduateStatus"
						@campus="addCampus"
					>
					</second-step>
				</wizard-tab>

				<wizard-tab>
					<template slot="label">
						<div class="pc-view">Experience</div>
						<div class="mobi-view"><i class="fas fa-briefcase"></i></div>
					</template>
					<third-step ref="step3"> </third-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step4')">
					<template slot="label">
						<div class="pc-view">Prefered Employer</div>
						<div class="mobi-view"><i class="fas fa-globe-africa"></i></div>
					</template>
					<fourth-step ref="step4" @on-validated="onStepValidated" @choices="addChoices" @department="addDepartment"> </fourth-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step5')">
					<template slot="label">
						<div class="pc-view">Portfolio</div>
						<div class="mobi-view"><i class="fas fa-piggy-bank"></i></div>
					</template>
					<fifth-step
						ref="step5"
						@on-validated="onStepValidated"
						@linkedIn="addLinkedIn"
						@facebook="addFacebook"
						@twitter="addTwitter"
						@instagram="addInstagram"
						@github="addGithub"
						@certificate1="addCertificate1"
						@certificate2="addCertificate2"
						@certificate3="addCertificate3"
						@portfolio="addPortfolio"
						@personalWebsite="addPersonalWebsite"
						@id="addId"
						@cv="addCv"
						@industryCategory="addIndustryCategory"
						@extraCirricularActivities="addExtraCirricularActivity"
					>
					</fifth-step>
				</wizard-tab>

				<!-- <wizard-tab :before-change="() => validateStep('step6')">
					<template slot="label">
						<div class="pc-view">
							<h1 style="font-size: 10px">Banking</h1>
						</div>
						<div class="mobi-view"><i class="fas fa-briefcase"></i></div>
					</template>
					<sixth-step
						ref="step6"
						@on-validated="onStepValidated"
						@accountName="addAccountName"
						@accountNumber="addAccountNumber"
						@accountType="addAccountType"
						@bankName="addBankName"
						@branchCode="addBranchCode"
					></sixth-step>
				</wizard-tab> -->
			</simple-wizard>
		</div>
		<!-- Modal: Error handling -->
		<modal v-if="modal" @close="modalHide">
			<template slot="header">
				<h4 class="modal-title black">{{ feedbackHeader }}</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="modalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<p class="black">{{ feedback }}</p>
			</template>

			<template slot="footer">
				<div style="text-align: center">
					<md-button class="md-button md-success" @click="modalHide">Got it</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>
<script>
import { WizardTab, Modal } from '@/components';
import SimpleWizard from './wizard/Wizard.vue';
import FirstStep from './wizard/formSteps/FirstStep.vue';
import SecondStep from './wizard/formSteps/SecondStep.vue';
import ThirdStep from './wizard/formSteps/ThirdStep.vue';
import FourthStep from './wizard/formSteps/FourthStep.vue';
import FifthStep from './wizard/formSteps/FifthStep';
//import SixthStep from './wizard/formSteps/SixthStep.vue';
import firebase from 'firebase/compat/app';
import db from '@/firebase/init';
import { mapActions } from 'vuex';
import moment from 'moment';
import { getFormattedDate } from '../../../../utils/time';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
export default {
	data() {
		return {
			qualificationsValid: false,
			wizardModel: {},
			auth: null,
			user: null,
			feedback: null,
			feedbackHeader: null,
			image: null,
			firstName: null,
			lastName: null,
			dob: null,
			gender: null,
			race: null,
			location: null,
			relocation: null,
			citizenship: null,
			identification: null,
			id: null,
			passport: null,
			disability: null,
			disabilityDescription: null,
			license: null,
			vehicle: null,
			programme: null,
			bio: null,
			institution: null,
			studying: null,
			startDate: null,
			endDate: null,
			campus: null,
			studentNo: null,
			faculty: null,
			degree: null,
			major: null,
			year: null,
			graduateStatus: null,
			accountName: null,
			accountNumber: null,
			accountType: null,
			bankName: null,
			branchCode: null,
			linkedIn: null,
			facebook: null,
			twitter: null,
			instagram: null,
			github: null,
			portfolio: null,
			personalWebsite: null,
			certificate1: null,
			certificate2: null,
			certificate3: null,
			cv: null,
			industryCategory: null,
			email: null,
			emailVerified: null,
			modal: false,
			alias: null,
			qualificationAlias: null,
			qualifications: null,
			department: null,
			choices: null,
			extraCirricularActivities: null,
		};
	},
	components: {
		FirstStep,
		SecondStep,
		ThirdStep,
		FourthStep,
		FifthStep,
		SimpleWizard,
		WizardTab,
		Modal,
	},
	methods: {
		...mapActions(['updateStudentAccount']),
		back() {
			this.validateStep('step4');
			this.updateStudentDetails();
			this.$router.go(-1);
		},
		async updateStudentDetails() {
			let userDoc = db.collection('users').doc(this.alias);
			userDoc.get().then(doc => {
				if (doc.exists) {
					if (this.firstName) {
						userDoc.update({
							name: this.firstName,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.lastName) {
						userDoc.update({
							surname: this.lastName,
							lastModified: moment(Date.now()).format('L'),
						});
					}
				}
			});
			let students = db.collection('students').doc(this.alias);
			students.get().then(doc => {
				if (doc.exists) {
					if (this.firstName) {
						students.update({
							name: this.firstName,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.lastName) {
						students.update({
							surname: this.lastName,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.dob) {
						students.update({
							dateOfBirth: moment(this.dob).format('L'),
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.gender) {
						students.update({
							gender: this.gender,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.race) {
						students.update({
							race: this.race,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.location) {
						students.update({
							location: this.location,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.relocation) {
						students.update({
							relocation: this.relocation,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.citizenship) {
						students.update({
							citizenship: this.citizenship,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.identification) {
						students.update({
							identification: this.identification,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.passport) {
						students.update({
							passport: this.passport,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.image) {
						students.update({
							image: this.image,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.jobTitle1) {
						students.update({
							jobTitle1: this.jobTitle1,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.employer1) {
						students.update({
							employer1: this.employer1,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.startDate1) {
						students.update({
							startDate1: this.startDate1,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.endDate1) {
						students.update({
							endDate1: this.endDate1,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.description1) {
						students.update({
							description1: this.description1,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.disability) {
						students.update({
							disability: this.disability,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.disabilityDescription) {
						students.update({
							disabilityDescription: this.disabilityDescription,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.license) {
						students.update({
							license: this.license,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.vehicle) {
						students.update({
							vehicle: this.vehicle,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.programme) {
						students.update({
							programme: this.programme,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.bio) {
						students.update({
							bio: this.bio,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.institution) {
						students.update({
							institution: this.institution,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.institution) {
						students.update({
							institution: this.institution,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.campus) {
						students.update({
							campus: this.campus,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.studentNo) {
						students.update({
							studentNo: this.studentNo,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.faculty) {
						students.update({
							faculty: this.faculty,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.degree) {
						students.update({
							degree: this.degree,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.year) {
						students.update({
							year: this.year,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.graduateStatus) {
						students.update({
							graduateStatus: this.graduateStatus,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.linkedIn) {
						students.update({
							linkedIn: this.linkedIn,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.facebook) {
						students.update({
							facebook: this.facebook,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.twitter) {
						students.update({
							twitter: this.twitter,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.instagram) {
						students.update({
							instagram: this.instagram,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.github) {
						students.update({
							github: this.github,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.certificate1) {
						students.update({
							certificate1: this.certificate1,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.certificate2) {
						students.update({
							certificate2: this.certificate2,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.certificate3) {
						students.update({
							certificate3: this.certificate3,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.personalWebsite) {
						students.update({
							personalWebsite: this.personalWebsite,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.portfolio) {
						students.update({
							portfolio: this.portfolio,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.cv) {
						students.update({
							cv: this.cv,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.industryCategory) {
						students.update({
							industryCategory: this.industryCategory,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (this.extraCirricularActivities) {
						students.update({
							extraCirricularActivities: this.extraCirricularActivities,
							lastModified: moment(Date.now()).format('L'),
						});
					}

					if (this.choices) {
						students.update({
							'preferredCompanies.choices': this.choices,
							lastModified: getFormattedDate(),
						});
					}

					if (this.department) {
						students.update({
							'preferredCompanies.department': this.department,
							lastModified: getFormattedDate(),
						});
					}
				}
			});

			this.$notify({
				message: 'Your changes have been saved!',
				icon: 'add_alert',
				horizontalAlign: 'center',
				verticalAlign: 'top',
				type: 'success',
			});
		},
		modalHide() {
			this.modal = false;
		},
		validateStep(ref) {
			return this.$refs[ref].validate();
		},
		onStepValidated(validated, model) {
			this.wizardModel = { ...this.wizardModel, ...model };
		},
		refresh() {
			if (!this.emailVerified) {
				this.user.reload();
				this.emailVerified = this.user.emailVerified;
			}
		},
		addImage: function (image) {
			this.image = image;
			this.refresh();
		},
		addFirstName: function (firstName) {
			this.firstName = firstName;
			this.refresh();
		},
		addLastName: function (lastName) {
			this.lastName = lastName;
			this.refresh();
		},
		addDob: function (dob) {
			this.dob = dob;
			this.refresh();
		},
		addGender: function (gender) {
			this.gender = gender;
			this.refresh();
		},
		addRace: function (race) {
			this.race = race;
			this.refresh();
		},
		addLocation: function (location) {
			this.location = location;
			this.refresh();
		},
		addRelocation: function (relocation) {
			this.relocation = relocation;
			this.refresh();
		},
		addCitizenship: function (citizenship) {
			this.citizenship = citizenship;
			this.refresh();
		},
		addPassport: function (passport) {
			this.passport = passport;
			this.refresh();
		},
		addIdentification: function (identification) {
			this.identification = identification;
			this.refresh();
		},
		addDisability: function (disability) {
			this.disability = disability;
			this.refresh();
		},
		addDisabilityDescription: function (disabilityDescription) {
			this.disabilityDescription = disabilityDescription;
			this.refresh();
		},
		addLicense: function (license) {
			this.license = license;
			this.refresh();
		},
		addVehicle: function (vehicle) {
			this.vehicle = vehicle;
			this.refresh();
		},
		addProgramme: function (programme) {
			this.programme = programme;
			this.refresh();
		},
		addBio: function (bio) {
			this.bio = bio;
			this.refresh();
		},
		addStudying: function (studying) {
			this.studying = studying;
			this.refresh();
		},
		addInstitution: function (institution) {
			this.institution = institution;
			this.refresh();
		},
		addStartDate: function (startDate) {
			this.startDate = startDate;
			this.refresh();
		},
		addEndDate: function (endDate) {
			this.endDate = endDate;
			this.refresh();
		},
		addCampus: function (campus) {
			this.campus = campus;
			this.refresh();
		},
		addStudentNo: function (studentNo) {
			this.studentNo = studentNo;
			this.refresh();
		},
		addQualificationsValid: function (qualificationsValid) {
			this.qualificationsValid = qualificationsValid;
			this.refresh();
		},
		addFaculty: function (faculty) {
			this.faculty = faculty;
			this.refresh();
		},
		addDegree: function (degree) {
			this.degree = degree;
			this.refresh();
		},
		addMajor: function (major) {
			this.major = major;
			this.refresh();
		},
		addYear: function (year) {
			this.year = year;
			this.refresh();
		},
		addGraduateStatus: function (graduateStatus) {
			this.graduateStatus = graduateStatus;
			this.refresh();
		},
		addAccountName: function (accountName) {
			this.accountName = accountName;
			this.refresh();
		},
		addAccountNumber: function (accountNumber) {
			this.accountNumber = accountNumber;
			this.refresh();
		},
		addAccountType: function (accountType) {
			this.accountType = accountType;
			this.refresh();
		},
		addBankName: function (bankName) {
			this.bankName = bankName;
			this.refresh();
		},
		addBranchCode: function (branchCode) {
			this.branchCode = branchCode;
			this.refresh();
		},
		addLinkedIn: function (linkedIn) {
			this.linkedIn = linkedIn;
			this.refresh();
		},
		addFacebook: function (facebook) {
			this.facebook = facebook;
			this.refresh();
		},
		addTwitter: function (twitter) {
			this.twitter = twitter;
			this.refresh();
		},
		addInstagram: function (instagram) {
			this.instagram = instagram;
			this.refresh();
		},
		addGithub: function (github) {
			this.github = github;
			this.refresh();
		},
		addPortfolio: function (portfolio) {
			this.portfolio = portfolio;
			this.refresh();
		},
		addPersonalWebsite: function (personalWebsite) {
			this.personalWebsite = personalWebsite;
			this.refresh();
		},
		addCertificate1: function (certificate1) {
			this.certificate1 = certificate1;
			this.refresh();
		},
		addCertificate2: function (certificate2) {
			this.certificate2 = certificate2;
			this.refresh();
		},
		addCertificate3: function (certificate3) {
			this.certificate3 = certificate3;
			this.refresh();
		},
		addId: function (id) {
			this.id = id;
			this.refresh();
		},
		addCv: function (cv) {
			this.cv = cv;
			this.refresh();
		},
		addFeedback: function (feedback, feedbackHeader) {
			this.modal = true;
			this.feedbackHeader = feedbackHeader;
			this.feedback = feedback;
		},
		addEmailVerified: function (emailVerified) {
			this.emailVerified = emailVerified;
			this.refresh();
		},
		addIndustryCategory: function (industryCategory) {
			this.industryCategory = industryCategory;
		},
		addExtraCirricularActivity: function (extraCirricularActivities) {
			this.extraCirricularActivities = extraCirricularActivities;
		},
		addDepartment: function (department) {
			this.department = department;
		},
		addChoices: function (choices) {
			this.choices = choices;
		},
	},
	created() {
		this.auth = firebase.auth();

		this.user = this.auth.currentUser;
		this.emailVerified = this.user.emailVerified;
		let ref = db.collection('users');
		ref.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.alias = doc.data().alias;
					this.email = doc.data().email;
				});
			});
	},
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
	.pc-view {
		display: none;
	}
	.md-layout-item.md-xsmall-size-80 {
		min-width: 100%;
		overflow-x: hidden;
	}
	.md-card-wizard .nav-pills > li i {
		font-size: 0px;
	}
}

@media only screen and (min-width: 768px) {
	.mobi-view {
		display: none;
	}
}
</style>
