<template>
	<div class="content">
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div>
				<img src="@/assets/img/logo.png" />
				<div class="loading"></div>
			</div>
		</div>
		<md-card class="padding">
			<!-- <div class="status-bar-container">
				<div class="step-container">
					<div :class="checkStep() === 2 || checkStep() === 3 ? 'step active' : 'step'">
						<md-icon :class="checkStep() === 2 || checkStep() === 3 ? 'md-size-2x active' : 'md-size-2x'">verified_user</md-icon>
						<h4>Step 1</h4>
						<h4>Job Verification</h4>
					</div>
				</div>
				 <div :class="checkStep() === 2 || checkStep() === 3 ? 'line active' : 'line'"></div>
				<div class="step-container">
					<div :class="checkStep() === 3 ? 'step active' : 'step'">
						<md-icon :class="checkStep() === 3 ? 'md-size-2x active' : 'md-size-2x'">school</md-icon>
						<h4>Step 2</h4>
						<h4>Student Applications</h4>
					</div>
				</div> 
				<div :class="checkStep() === 3 ? 'line active' : 'line'"></div>
				<div class="step-container">
					<div :class="checkStep() === 3 ? 'step active' : 'step'">
						<md-icon :class="checkStep() === 3 ? 'md-size-2x active' : 'md-size-2x'">people</md-icon>
						<h4>Step 2</h4>
						<h4>Select Applicants</h4>
					</div>
				</div>
				<div :class="checkStep() === 3 ? 'line active' : 'line'"></div>
				<div class="step-container">
					<div :class="checkStep() === 3 ? 'step active' : 'step'">
						<md-icon :class="checkStep() === 3 ? 'md-size-2x active' : 'md-size-2x'">people</md-icon>
						<h4>Step 3</h4>
						<h4>Request Video Submissions</h4>
					</div>
				</div>

				<div :class="checkStep() === 3 ? 'line active' : 'line'"></div>
				<div class="step-container">
					<div :class="checkStep() === 3 ? 'step active' : 'step'">
						<md-icon :class="checkStep() === 3 ? 'md-size-2x active' : 'md-size-2x'">people</md-icon>
						<h4>Step 4</h4>
						<h4>Invite for Interviews</h4>
					</div>
				</div>

				<div :class="checkStep() === 3 ? 'line active' : 'line'"></div>
				<div class="step-container">
					<div :class="checkStep() === 3 ? 'step active' : 'step'">
						<md-icon :class="checkStep() === 3 ? 'md-size-2x active' : 'md-size-2x'">people</md-icon>
						<h4>Step 5</h4>
						<h4>Feedback</h4>
					</div>
				</div>

				<div :class="checkStep() === 3 ? 'line active' : 'line'"></div>
				<div class="step-container">
					<div :class="checkStep() === 3 ? 'step active' : 'step'">
						<md-icon :class="checkStep() === 3 ? 'md-size-2x active' : 'md-size-2x'">people</md-icon>
						<h4>Step 8</h4>
						<h4>Finalise Offers</h4>
					</div>
				</div>
			</div>
			<h2 v-if="checkStep() === 1" class="status-message">Please be patient, we'll get back to you in 24 hours</h2>
			<h2 v-if="checkStep() === 2" class="status-message">Please be patient, students will start applying soon</h2> -->
			<div :class="checkStep() === 3 ? 'show' : 'hide'">
				<!-- <TableSelect v-if="select || active" :registerOnInvite="this.registerOnInvite" @selected="addSelected" @applicants="addApplicants" /> -->
				<applicant-table-select></applicant-table-select>
			</div>
			<md-card-actions class="button-container">
				<div :class="checkStep() === 3 ? 'show' : 'hide'">
					<md-button v-if="inviteCallback" class="btn-next md-success button" @click="onInvite" style="max-width: 155px">Invite for interview</md-button>
					&nbsp;&nbsp;&nbsp;
				</div>
				<md-button v-if="select || active" class="btn-next md-info button" @click="edit" style="max-width: 110px">Edit Job</md-button>
				&nbsp;&nbsp;&nbsp;
				<md-button v-if="select && !paid" class="btn-next md-danger button" @click="cancelJob" style="max-width: 110px">Cancel Job</md-button>
			</md-card-actions>
		</md-card>
		<!-- Modal: Error handling -->
		<modal v-if="modal" @close="modalHide">
			<template slot="header">
				<!--h4 v-if="!invoice" class="modal-title black">Make Payment</h4>
        <h4 v-if="invoice" class="modal-title black">Invoice</h4-->
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="modalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<notifications></notifications>
				<p v-if="!invoice" class="black">Your payment is outstanding</p>
				<p v-if="!invoice" class="black">Your payment could take up to 5 minutes to reflect depending on your internet speed</p>
				<iframe v-if="invoice" id="invoice"></iframe>
			</template>

			<template slot="footer">
				<div v-if="!invoice" class="centre">
					<md-button class="md-button md-success" @click="makePayment">Pay Now</md-button>
				</div>
				<div v-if="invoice" class="centre">
					<md-button class="md-button md-success" @click="sendEmail">Send</md-button>
					&nbsp;&nbsp;
					<md-button class="md-button md-info" @click="downloadFile">Download</md-button>
				</div>
			</template>
		</modal>

		<modal v-if="interviewModal">
			<template slot="header">
				<h4 class="modal-title black">Woah there ✋</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="interviewModalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<h4>Please select an applicant using the checkboxes.</h4>
			</template>
		</modal>
	</div>
</template>
<script>
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

//import TableSelect from './flow/select/TableSelect.vue';
// import Active from './flow/active/Active.vue';
// import Complete from './flow/complete/Complete.vue';
// import Incomplete from './flow/incomplete/Incomplete.vue';
// import Dissatisfied from './flow/dissatisfied/Dissatisfied.vue';
// import Rate from './flow/rate/Rate.vue';
// import Summary from './flow/summary/Summary.vue';
import { Modal } from '@/components';
import moment from 'moment';
import ApplicantTableSelect from '@/views/dashboard/client/jobs/applications/applicants/ApplicantTableSelect.vue';

export default {
	components: {
		Modal,
		ApplicantTableSelect,
	},
	data() {
		return {
			statusIndex: 1,
			appliedStudents: null,
			interviewModal: false,
			selected: null,
			job: {},
			paid: true,
			select: false,
			active: false,
			complete: false,
			incomplete: false,
			dissatisfied: false,
			rate: false,
			summary: false,
			verified: false,
			modal: false,
			loading: false,
			invoice: false,
			enableInvoice: false,
			inviteCallback: null,
		};
	},
	methods: {
		checkStep() {
			return (this.statusIndex = 3);
			// console.log(this.statusIndex);
			// if (this.verified && this.appliedStudents?.length > 0) {
			// 	return (this.statusIndex = 3);
			// }
			// if (this.verified) {
			// 	return (this.statusIndex = 2);
			// }
			// if (!this.verified) {
			// 	return (this.statusIndex = 1);
			// }
		},
		addApplicants(applicants) {
			this.appliedStudents = applicants;
		},
		interviewModalHide() {
			this.interviewModal = false;
		},
		addSelected: function (selected) {
			this.selected = selected;
		},
		edit() {
			this.$router.push({ name: 'edit-micro-job', params: { id: this.$route.params.id } });
		},
		modalHide() {
			this.invoice = false;
			this.modal = false;
		},
		payment() {
			this.modal = true;
		},
		registerOnInvite(callback) {
			console.log('callbck', callback);
			this.inviteCallback = callback;
		},
		onInvite() {
			if (!this.selected || this.selected.length < 1) {
				this.interviewModal = true;
			} else {
				this.inviteCallback();
			}
		},
		status() {
			if (this.job.status == 'select') this.select = true;
			else this.select = false;

			if (this.job.status == 'active') this.active = true;
			else this.active = false;

			if (this.job.status == 'complete') this.complete = true;
			else this.complete = false;

			if (this.job.status == 'incomplete') this.incomplete = true;
			else this.incomplete = false;

			if (this.job.status == 'dissatisfied') this.dissatisfied = true;
			else this.dissatisfied = false;

			if (this.job.status == 'rate') this.rate = true;
			else this.rate = false;
			if (this.job.status == 'summary') this.summary = true;
			else this.summary = false;
		},
		makePayment: function () {
			this.loading = true;
			this.$store.dispatch('makePayment', this.job);
		},
		cancelJob() {
			this.loading = true;
			db.collection('micros')
				.doc(this.$route.params.id)
				.update({
					status: 'removed',
					studentId: null,
					lastModified: moment(Date.now()).format('L'),
				})
				.then(() => {
					this.$router.push({ name: 'post-a-job' });
				});
		},
		async viewInvoice() {
			this.invoice = true;
			let doc = await db.collection('invoices').doc(this.job.id).get();
			let invoice = doc.data();

			const storage = firebase.app().storage(invoice.bucket);
			const storageRef = storage.ref(invoice.filePath);

			storageRef
				.getDownloadURL()
				.then(fileUrl => {
					var pdf = document.getElementById('invoice');
					pdf.setAttribute('src', fileUrl);
				})
				.catch(err => {
					this.$notify({
						message: 'File failed to load',
						icon: 'add_alert',
						horizontalAlign: 'center',
						verticalAlign: 'top',
						type: 'danger',
					});
				});
			this.modal = true;
		},
		sendEmail() {
			this.$store.dispatch('sendPdf', this.job);
			this.$notify({
				message: 'Invoice sent',
				icon: 'add_alert',
				horizontalAlign: 'center',
				verticalAlign: 'top',
				type: 'success',
			});
		},
		async downloadFile() {
			let doc = await db.collection('invoices').doc(this.job.id).get();
			let invoice = doc.data();

			const storage = firebase.app().storage(invoice.bucket);
			const storageRef = storage.ref(invoice.filePath);

			storageRef.getDownloadURL().then(url => {
				this.$notify({
					message: 'Download started...',
					icon: 'add_alert',
					horizontalAlign: 'center',
					verticalAlign: 'top',
					type: 'success',
				});

				// This can be downloaded directly:
				var xhr = new XMLHttpRequest();
				xhr.responseType = 'blob';
				xhr.onload = event => {
					var blob = xhr.response;
				};
				xhr.open('GET', url);
				xhr.send();
			});
		},
	},
	async created() {
		let routeId = '001-dreamworks-animator-1674645744855';
		this.loading = true;
		let task_project = db.collection('micros');
		task_project
			.where('jobId', '==', this.$route.params.id)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.job = doc.data();
					this.job.id = doc.id;
					this.status();
				});
			});

		task_project.onSnapshot(snapshot => {
			snapshot.docChanges().forEach(change => {
				if (change.type == 'modified') {
					this.job = change.doc.data();
					this.status();
				}
			});
		});

		let job = db.collection('jobs');
		job.where('jobId', '==', this.$route.params.id)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.verified = doc.data().verified;
				});
			});

		job.onSnapshot(snapshot => {
			snapshot.docChanges().forEach(change => {
				if (change.type == 'modified') {
					this.verified = change.doc.data().verified;
				}
			});
		});

		let payment = db.collection('payments');
		payment
			.where('jobId', '==', this.$route.params.id)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.paid = doc.data().inboundPayment;
				});
			});

		payment.onSnapshot(snapshot => {
			snapshot.docChanges().forEach(change => {
				if (change.type == 'modified') {
					this.paid = change.doc.data().inboundPayment;
				}
			});
		});
		this.loading = false;

		//this.enableInvoice = await db.collection('invoices').doc(this.$route.params.id).get()
		//                       .then(doc => {
		//                          if (doc.exists)
		//                            return doc.data().generated;
		//                          else
		//                            return false;
		//                       });
		//
		//let docExists = setInterval(async () => {
		//  this.enableInvoice = await db.collection('invoices').doc(this.$route.params.id).get()
		//                       .then(doc => {
		//                         if (doc.exists)
		//                            return doc.data().generated;
		//                          else
		//                            return false;
		//                       });

		//  if (this.enableInvoice) clearInterval(docExists);
		//}, 1500);
	},
};
</script>
<style scoped>
.padding {
	padding: 20px;
}
.centre {
	text-align: center;
}
.bold {
	font-weight: bold;
}
.button {
	max-width: 88px;
}
.margin {
	margin: auto;
}
.orange {
	color: orange;
	font-weight: bold;
}
.status-bar-container {
	/* width: 60%; */
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* margin: 1rem auto; */
}
.status-bar-container .step-container {
	display: flex;
	align-items: center;
}
.status-bar-container .line {
	width: 100%;
	height: 3px;
	background-color: lightgrey;
}
.status-bar-container .line.active {
	background-color: green;
}
.status-bar-container .step-container .step {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border: 3px solid lightgray;
	border-radius: 100vw;
}
.status-bar-container .step-container .step.active {
	border: 5px solid green;
}
.status-bar-container .step-container .step > h4 {
	font-size: 10px;
	margin-top: -5px;
}

.show {
	display: block;
}

.hide {
	display: none;
}
.md-icon {
	margin: 0;
	margin-bottom: 1rem;
}
.md-icon.active {
	color: green !important;
}

.button-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.md-card-actions.md-alignment-right {
	justify-content: center !important;
}

.status-message {
	text-align: center;
}
</style>
