<template>
	<div class="results-container">
		<div v-if="loading" class="background">
			<div v-if="loading" class="text-center lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>
		<div v-if="showFail">
			<h2 class="copy-heading">Bad news 😞</h2>
			<p class="copy-text">Unfortunately, you did not pass enough of the required tests to apply for the {{ job.name }} role</p>
			<br />
			<p class="copy-text">Here are your results:</p>
			<br />
		</div>
		<template v-if="acquiredSkillsCount >= 3">
			<h2 class="copy-heading">Congratulations 🎉</h2>
			<p class="copy-text">You can apply for the {{ job.name }} role</p>
			<br />
			<p class="copy-text">Here are your results:</p>
			<br />
		</template>
		<md-table class="results-table">
			<md-table-row>
				<md-table-head>Skill</md-table-head>
				<md-table-head>Result</md-table-head>
			</md-table-row>

			<md-table-row v-for="(skill, index) in writtenSkillTests" :key="index">
				<md-table-cell>{{ skill.id }}</md-table-cell>
				<md-table-cell :class="resultClass(skill.testResult.pass)">
					{{ formatResult(skill.testResult.pass) }}
				</md-table-cell>
			</md-table-row>
		</md-table>
		<md-button v-if="acquiredSkillsCount >= 3 && !showApplyButton" class="md-success apply-button" @click="navigateToJob"> Proceed to Application </md-button>
		<md-button v-if="acquiredSkillsCount >= 3 && showApplyButton" class="md-success apply-button" @click="apply"> Submit Application </md-button>
		<modal v-if="error">
			<template slot="header">
				<h3 style="color: red !important; font-weight: bold !important">Oops, we encountered an issue</h3>
			</template>
			<template slot="body">
				<p style="color: red">There has been an error in updating your application status</p>
			</template>
			<template slot="footer">
				<md-button class="md-success" @click="closeErrorModal"> Ok </md-button>
			</template>
		</modal>
		<modal v-if="successModal" @close="successModal = false">
			<template slot="header">
				<h4 class="modal-title black">Congratulations!</h4>
			</template>

			<template slot="body">
				<p class="black">Your application has been sent. We will let you know the outcome of your application.</p>
			</template>

			<template slot="footer">
				<div class="centre">
					<md-button class="md-button md-success" @click="closeSuccessModal">Got it</md-button>
				</div>
			</template>
		</modal>
		<modal v-if="successModal" @close="successModal = false">
			<template slot="header">
				<h4 class="modal-title black">Congratulations!</h4>
			</template>

			<template slot="body">
				<p class="black">Your application has been sent. We will let you know the outcome of your application.</p>
			</template>

			<template slot="footer">
				<div class="centre">
					<md-button class="md-button md-success" @click="closeSuccessModal">Got it</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
import firebase from 'firebase/compat/app';
import db from '@/firebase/init';
import slugify from 'slugify';
import { ApplicationStatus } from '../../../../../constants/application-status';
import { Modal } from '@/components';
import moment from 'moment';
export default {
	data() {
		return {
			jobSkills: null,
			studentAcquiredSkills: null,
			currentJobId: this.$route.params.jobId,
			writtenSkillTests: [],
			passedTestsCount: 0,
			acquiredSkills: [],
			acquiredSkillsCount: 0,
			jobApplication: null,
			slug: null,
			showFail: false,
			error: false,
			job: null,
			loading: false,
			additionalSubmissions: null,
			auth: firebase.auth().currentUser,
			qualifications: null,
			user: null,
			student: null,
			successModal: false,
		};
	},
	components: {
		Modal,
	},
	computed: {
		totalSkills() {
			return this.jobSkills && this.jobSkills.skills.length;
		},
		showApplyButton() {
			if (typeof this.additionalSubmissions === 'object') {
				const { videoSubmission, writtenSubmission } = this.additionalSubmissions;
				return videoSubmission === 'No' && writtenSubmission === 'No';
			} else if (typeof this.additionalSubmissions === 'boolean') {
				return !this.additionalSubmissions;
			}
			return false;
		},
	},
	async created() {
		try {
			this.loading = true;
			const currentUserId = firebase.auth().currentUser.uid;

			const [alias, jobSkills, job, additionalSubmissions, { student, qualifications }, user] = await Promise.all([
				this.fetchUserAlias(currentUserId),
				this.fetchSkills(this.currentJobId),
				this.fetchJob(this.currentJobId),
				this.fetchAdditionalSubmissions(this.currentJobId),
				this.fetchStudent(this.currentJobId),
				this.fetchUser(currentUserId),
			]);
			this.jobSkills = jobSkills;
			this.job = job;
			this.additionalSubmissions = additionalSubmissions;
			this.student = student;
			this.qualifications = qualifications;
			this.user = user;
			this.job = job;
			this.additionalSubmissions = additionalSubmissions;
			this.student = student;
			this.qualifications = qualifications;
			this.user = user;

			this.studentAcquiredSkills = await this.fetchAcquiredSkills(alias);

			this.checkIsVetted();

			this.slug = slugify(alias + ' ' + encodeURIComponent(this.$route.params.jobId), {
				replacement: '-',
				remove: /[$*_+~.()'"!\-:@]/g,
				lower: true,
			});
			if (this.acquiredSkillsCount < 3) {
				this.disqualifyStudent(this.slug);
			}
			this.loading = false;
		} catch (error) {
			this.loading = false;
			this.error = true;
			this.error = true;
		}
	},

	methods: {
		closeSuccessModal() {
			this.successModal = false;
			this.$router.push({
				name: 'apply',
			});
		},
		async fetchJob(jobId) {
			const jobSkillsDoc = await db.collection('jobs').doc(jobId).get();
			return jobSkillsDoc.exists ? jobSkillsDoc.data() : null;
		},
		async fetchAdditionalSubmissions(jobId) {
			const microsDoc = await db.collection('micros').doc(jobId).get();
			return microsDoc.exists ? microsDoc.data().additionalSubmissions : null;
		},
		async fetchStudent() {
			const studentQuery = await db.collection('students').where('userId', '==', this.auth.uid).get();
			if (studentQuery.empty) {
				return null;
			}
			const studentDoc = studentQuery.docs[0];
			const qualificationsSnapshot = await studentDoc.ref.collection('qualifications').get();
			let qualifications = [];
			qualificationsSnapshot.forEach(doc => {
				qualifications.push(doc.data());
			});
			return {
				student: studentDoc.data(),
				qualifications: qualifications,
			};
		},
		async fetchUser(userId) {
			const userQuery = await db.collection('users').where('userId', '==', userId).get();
			const userDoc = userQuery.docs[0];
			return userDoc.exists ? userDoc.data() : null;
		},
		async apply() {
			try {
				this.loading = true;
				const application = db.collection('applications').doc(this.slug);
				await application.set({
					jobId: this.job.jobId,
					jobType: 'micro',
					job: this.job.name,
					studentId: this.auth.uid,
					created: moment(Date.now()).format('L'),
					lastModified: moment(Date.now()).format('L'),
					applicant: this.auth.displayName,
					clientName: this.job.clientName,
					degree: this.qualifications,
					bio: this.student.bio,
					alias: this.user.alias,
					applicantEmail: this.user.email,
					clientEmail: this.job.email,
					approved: false,
					status: ApplicationStatus.APPLIED,
					applicationStatus: ApplicationStatus.APPLIED,
					interviewRequestSent: false,
					favourite: false,
					writtenSubmissionUrl: null,
					videoSubmissionUrl: null,
				});
				this.loading = false;
				this.successModal = true;
			} catch (error) {
				this.loading = false;
				this.error = true;
				this.successModal = false;
			}
		},
		closeErrorModal() {
			this.error = false;
		},
		async disqualifyStudent(alias) {
			let application = db.collection('applications').doc(alias);
			await application.update({
				status: ApplicationStatus.DISQUALIFIED,
				applicationStatus: ApplicationStatus.DISQUALIFIED,
			});
		},

		navigateToJob() {
			this.$router.push({
				name: 'micro-application',
				params: { id: this.$route.params.jobId },
			});
		},
		checkIsVetted() {
			const currentJobId = this.$route.params.jobId;
			this.acquiredSkillsCount = 0;
			let failedTest = false;
			this.jobSkills.skills.forEach(skill => {
				const standardizedSkillName = skill.skillName.trim().toLowerCase();
				const studentSkill = this.studentAcquiredSkills.find(s => s.id.trim().toLowerCase() === standardizedSkillName);
				if (studentSkill) {
					const testResultForCurrentJob = studentSkill.testResults.find(testResult => testResult.jobId === currentJobId);
					const acquiredJobSkills = studentSkill.testResults.some(testResult => testResult.pass === true);
					if (acquiredJobSkills) {
						this.acquiredSkillsCount++;
						this.acquiredSkills.push(skill);
					}
					if (testResultForCurrentJob) {
						this.writtenSkillTests.push({
							id: studentSkill.id,
							testResult: testResultForCurrentJob,
						});
						if (!testResultForCurrentJob.pass) {
							failedTest = true;
						}
					}
				}
			});

			this.showFail = this.acquiredSkillsCount < 3 && failedTest;
		},
		async disqualifyJobApplication(jobId) {
			try {
				await firebase.firestore().collection('micros').doc(jobId).update({
					status: ApplicationStatus.DISQUALIFIED,
				});
			} catch (error) {
				this.error = true;
			}
		},
		async fetchSkills(jobId) {
			const jobSkillsDoc = await db.collection('skills').doc(jobId).get();
			return jobSkillsDoc.exists ? jobSkillsDoc.data() : null;
		},
		async fetchAcquiredSkills(alias) {
			const skillsCollection = await db.collection('students').doc(alias).collection('skills').get();
			return (this.studentAcquiredSkills = skillsCollection.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		},
		async fetchUserAlias(userId) {
			const userQuerySnapshot = await db.collection('users').where('userId', '==', userId).get();
			const userDoc = userQuerySnapshot.docs[0];
			return userDoc.data().alias;
		},
		formatResult(result) {
			return result === false ? 'Failed ❌' : 'Passed ✅';
		},

		resultClass(passed) {
			return {
				'result-failed': !passed,
				'result-passed': passed,
			};
		},
	},
};
</script>

<style scoped>
.results-container {
	width: 100%;
	max-width: 800px;
	margin: 40px auto;
	padding: 40px;
	background-color: #f9f9f9;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.results-table {
	width: 100%;
	margin-bottom: 40px;
}

.md-table-head {
	background-color: #e8e8e8;
	font-weight: bold;
}

.apply-button {
	margin-top: 20px;
}

.result-failed {
	color: #d32f2f;
}

.result-passed {
	color: #388e3c;
}

.copy-text {
	margin-top: -10px;
	text-align: center;
}

.copy-heading {
	font-weight: bold;
	text-align: center;
}
.background {
	width: 100vw !important;
	height: 100vh !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}
</style>
