export default {
	computed: {
		formatBudget: function () {
			if (this.budget?.min || this.budget?.max) {
				let { min, max } = this.budget;
				return `R ${min} - R ${max}`;
			} else {
				return 'R' + this.budget;
			}
		},
	},
};
