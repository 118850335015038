import db from '@/firebase/init';
import moment from 'moment';

export default {
	state: {
		user: null,
		userDoc: null,
		student: null,
		modal: false,
		experiencesNum: 1,
		qualificationsNum: 1,
		campus: null,
		year: null,
		institution: null,
		faculty: null,
		universities: [],
	},
	mutations: {
		addStudentDetails: (state, payload) => {
			if (payload.name === 'user') {
				state.user = payload.value;
			} else if (payload.name === 'userDoc') {
				state.userDoc = payload.value;
			} else if (payload.name === 'student') {
				state.student = payload.value;
			}
		},
		updateStudentAccount: (state, payload) => {
			if (state.userDoc) {
				state.userDoc.get().then(doc => {
					if (doc.exists) {
						if (payload.step === 0) {
							if (payload.firstName) {
								state.userDoc.update({
									name: payload.firstName,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.lastName) {
								state.userDoc.update({
									surname: payload.lastName,
									lastModified: moment(Date.now()).format('L'),
								});
							}
						}
					}
				});
			}

			state.student.get().then(async doc => {
				try {
					if (doc.exists) {
						if (payload.step === 0) {
							if (payload.dob) {
								state.student.update({
									dateOfBirth: moment(payload.dob).format('L'),
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.image) {
								state.student.update({
									profile: payload.image,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.gender) {
								state.student.update({
									gender: payload.gender,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.race) {
								state.student.update({
									race: payload.race,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.location) {
								state.student.update({
									location: payload.location,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.relocation) {
								state.student.update({
									relocation: payload.relocation,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.citizenship) {
								state.student.update({
									citizenship: payload.citizenship,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.identification && payload.citizenship === 'South African Citizen') {
								state.student.update({
									identification: payload.identification,
									passport: null,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (
								(payload.passport && payload.citizenship === 'Not a South African Citizen with No Work Permit') ||
								payload.citizenship === 'Not a South African Citizen with Work Permit'
							) {
								state.student.update({
									passport: payload.passport,
									identification: null,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.disability) {
								state.student.update({
									disability: payload.disability,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.disabilityDescription) {
								state.student.update({
									disabilityDescription: payload.disabilityDescription,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.license === 'No') {
								payload.vehicle = 'No';
								state.student.update({
									vehicle: payload.vehicle,
									license: payload.license,
									lastModified: moment(Date.now()).format('L'),
								});
							} else if (payload.license) {
								state.student.update({
									license: payload.license,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.vehicle) {
								state.student.update({
									vehicle: payload.vehicle,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.programme) {
								state.student.update({
									programme: payload.programme,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.bio) {
								state.student.update({
									bio: payload.bio,
									lastModified: moment(Date.now()).format('L'),
								});
							}
						}

						if (payload.preferredCompanies.choices) {
							state.student.update({
								'preferredCompanies.choices': payload.preferredCompanies.choices,
								lastModified: moment(Date.now()).format('L'),
							});
						}
						if (payload.preferredCompanies.department) {
							state.student.update({
								'preferredCompanies.department': payload.preferredCompanies.department,
								lastModified: moment(Date.now()).format('L'),
							});
						}

						if (payload.step === 4) {
							if (payload.linkedIn) {
								state.student.update({
									linkedIn: payload.linkedIn,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.facebook) {
								state.student.update({
									facebook: payload.facebook,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.twitter) {
								state.student.update({
									twitter: payload.twitter,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.instagram) {
								state.student.update({
									instagram: payload.instagram,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.github) {
								state.student.update({
									github: payload.github,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.portfolio) {
								state.student.update({
									portfolio: payload.portfolio,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.personalWebsite) {
								state.student.update({
									personalWebsite: payload.personalWebsite,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.id) {
								state.student.update({
									id: payload.id,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.cv) {
								state.student.update({
									cv: payload.cv,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.certificate1) {
								state.student.update({
									certificate1: payload.certificate1,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.certificate2) {
								state.student.update({
									certificate2: payload.certificate2,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.certificate3) {
								state.student.update({
									certificate3: payload.certificate3,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.industryCategory) {
								state.student.update({
									interestedIndustries: payload.industryCategory,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.extraCirricularActivities) {
								state.student.update({
									extraCirricularActivities: payload.extraCirricularActivities,
									lastModified: moment(Date.now()).format('L'),
								});
							}
						}

						if (payload.step === 5) {
							if (payload.accountName) {
								state.student.update({
									accountName: payload.accountName,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.accountNumber) {
								state.student.update({
									accountNumber: payload.accountNumber,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.accountType) {
								state.student.update({
									accountType: payload.accountType,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.bankName) {
								let banks = db.collection('Settings').doc('Banks');
								let branches = banks.collection('Branches').doc(payload.bankName);

								await branches.get().then(doc => {
									if (doc.exists) payload.branchCode = doc.data().BranchCode;
									else payload.branchCode = null;
								});

								state.student.update({
									bankName: state.bankName,
									branchCode: payload.branchCode,
									lastModified: moment(Date.now()).format('L'),
								});
							}
							if (payload.branchCode) {
								state.student.update({
									branchCode: payload.branchCode,
									lastModified: moment(Date.now()).format('L'),
								});
							}
						}
					} else {
						state.student.set({
							accountCreated: false,
							userId: state.user.uid,
							created: moment(Date.now()).format('L'),
							lastModified: moment(Date.now()).format('L'),
							dateOfBirth: moment(payload.dob).format('L'),
							profile: payload.image,
							gender: payload.gender,
							race: payload.race,
							location: payload.location,
							relocation: payload.relocation,
							citizenship: payload.citizenship,
							license: payload.license,
							vehicle: payload.vehicle,
							programme: payload.programme,
							disability: payload.disability,
							disabilityDescription: payload.disabilityDescription,
							bio: payload.bio,
							institutionType: 'University',
							preferredCompanies: {
								choices: payload.preferredCompanies.choices,
								department: payload.preferredCompanies.department,
							},
							extraCirricularActivities: payload.extraCirricularActivities,
						});
					}
				} catch (error) {
					// An error occured
				}
			});
		},
		modalHide: state => {
			state.modal = false;
		},
		modalShow: state => {
			state.modal = true;
		},
		incrementExpNum: state => {
			state.experiencesNum++;
		},
		decrementExpNum: state => {
			state.experiencesNum--;
		},
		incrementQualificationNum: state => {
			state.qualificationsNum++;
		},
		decrementQualificationNum: state => {
			state.qualificationsNum--;
		},
	},
	getters: {
		getModal(state) {
			return state.modal;
		},
		getExpNum(state) {
			return state.experiencesNum;
		},
		getQualificationNum(state) {
			return state.qualificationsNum;
		},
	},
	actions: {
		updateStudentAccount: (context, payload) => {
			context.commit('updateStudentAccount', payload);
		},
		addStudentDetails: (context, payload) => {
			context.commit('addStudentDetails', payload);
		},
		modalHide: (context, payload) => {
			context.commit('modalHide', payload);
		},
		modalShow: (context, payload) => {
			context.commit('modalShow', payload);
		},
		incrementExpNum: (context, payload) => {
			context.commit('incrementExpNum', payload);
		},
		decrementExpNum: (context, payload) => {
			context.commit('decrementExpNum', payload);
		},
		incrementQualificationNum: (context, payload) => {
			context.commit('incrementQualificationNum', payload);
		},
		decrementQualificationNum: (context, payload) => {
			context.commit('decrementQualificationNum', payload);
		},
	},
};
