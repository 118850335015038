<template>
	<div>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div>
				<img src="@/assets/img/logo.png" />
				<div class="loading"></div>
			</div>
		</div>
		<h5 class="info-text">Tell us a little about yourself</h5>
		<div class="md-layout">
			<notifications></notifications>
			<div class="md-layout-item md-size-40 md-small-size-100">
				<div class="picture-container">
					<div class="picture">
						<div v-if="!image">
							<img :src="avatar" class="picture-src" title="" />
						</div>
						<div v-else>
							<img :src="image" />
						</div>
						<input type="file" @change="previewImage" title="Profile Picture" accept="image/*" />
					</div>
					<h6 class="description">Profile Picture</h6>
					<!-- <h6 class="description">Currently disabled</h6> -->
				</div>
			</div>
			<div class="md-layout-item md-size-60 mt-4 md-small-size-100">
				<md-field
					slot="inputs"
					:class="[{ 'md-valid': !errors.has('firstName') && touched.firstName }, { 'md-form-group': true }, { 'md-error': errors.has('firstName') }]"
				>
					<md-icon>face</md-icon>
					<label>First Name</label>
					<md-input
						@input="addFirstName"
						v-model="firstName"
						type="text"
						data-vv-name="firstName"
						name="firstName"
						required
						v-validate="modelValidations.firstName"
					></md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('firstName')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('firstName') && touched.firstName">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<md-field slot="inputs" :class="[{ 'md-valid': !errors.has('lastName') && touched.lastName }, { 'md-form-group': true }, { 'md-error': errors.has('lastName') }]">
					<md-icon>person</md-icon>
					<label>Last Name</label>
					<md-input
						@input="addLastName"
						v-model="lastName"
						type="text"
						data-vv-name="lastName"
						name="lastName"
						required
						v-validate="modelValidations.lastName"
					></md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('lastName')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('lastName') && touched.lastName">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<md-datepicker
					@input="addDob"
					v-model="dob"
					data-vv-name="dob"
					required
					v-validate="modelValidations.dob"
					md-immediately
					:class="[{ 'md-valid': !errors.has('dob') && touched.gender }, { 'md-form-group': true }, { 'md-error': errors.has('dob') }]"
				>
					<label>Date of birth</label>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('dob')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('dob') && touched.dob">done</md-icon>
					</slide-y-down-transition>
				</md-datepicker>
			</div>

			<div class="md-layout-item ml-auto mt-4 md-small-size-100">
				<md-field :class="[{ 'md-valid': !errors.has('gender') && touched.gender }, { 'md-form-group': true }, { 'md-error': errors.has('gender') }]">
					<md-icon>face</md-icon>
					<label for="gender">Gender</label>
					<md-select class="pad" @input="addGender" v-model="gender" data-vv-name="gender" name="gender" required v-validate="modelValidations.gender">
						<md-option v-for="(gender, index) in genders" :key="index" :value="gender">{{ gender }}</md-option>
					</md-select>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('gender')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('gender') && touched.gender">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<md-field :class="[{ 'md-valid': !errors.has('race') && touched.race }, { 'md-form-group': true }, { 'md-error': errors.has('race') }]">
					<md-icon>face</md-icon>
					<label for="race">Race</label>
					<md-select class="pad" @input="addRace" v-model="race" data-vv-name="race" name="race" required v-validate="modelValidations.race">
						<md-option v-for="(race, index) in races" :key="index" :value="race">{{ race }}</md-option>
					</md-select>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('race')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('race') && touched.race">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<!-- Location Field -->
				<md-field :class="[{ 'md-valid': !errors.has('location') && touched.location }, { 'md-form-group': true }, { 'md-error': errors.has('location') }]">
					<md-icon><i class="fas fa-map-marker-alt"></i></md-icon>
					<label for="location">Where do you stay (i.e Town or Suburb?)</label>
					<md-input
						id="autocomplete-location"
						type="text"
						v-model="formattedLocationValue"
						placeholder="Enter your location"
						data-vv-name="location"
						name="location"
						v-validate="'required'"
						@input="addLocation"
						@blur="handleLocationBlur"
					></md-input>

					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('location')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('location') && touched.location">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<!-- Relocation Field -->
				<md-field :class="[{ 'md-valid': !errors.has('relocation') && touched.relocation }, { 'md-form-group': true }, { 'md-error': errors.has('relocation') }]">
					<md-icon>commute</md-icon>

					<label for="relocation">Are you willing to relocate?</label>
					<md-select
						class="pad"
						v-model="relocation"
						data-vv-name="relocation"
						name="relocation"
						@input="addRelocation"
						required
						v-validate="modelValidations.relocation"
					>
						<md-option v-for="(answer, index) in yes_no" :key="index" :value="answer">{{ answer }}</md-option>
					</md-select>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('relocation')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('relocation') && touched.relocation">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<md-field :class="[{ 'md-valid': !errors.has('citizenship') && touched.citizenship }, { 'md-form-group': true }, { 'md-error': errors.has('citizenship') }]">
					<md-icon><i class="far fa-user-circle"></i></md-icon>
					<label for="citizenship">Citizenship</label>
					<md-select
						class="pad"
						@input="addCitizenship"
						v-model="citizenship"
						data-vv-name="citizenship"
						name="citizenship"
						required
						v-validate="modelValidations.citizenship"
					>
						<md-option v-for="(citizenship, index) in citizenships" :key="index" :value="citizenship">{{ citizenship }}</md-option>
					</md-select>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('citizenship')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('citizenship') && touched.citizenship">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<md-field
					v-show="citizenship === 'South African Citizen'"
					:class="[{ 'md-valid': !errors.has('identification') && touched.identification }, { 'md-form-group': true }, { 'md-error': errors.has('identification') }]"
				>
					<md-icon><i class="far fa-id-card"></i></md-icon>
					<label>ID Number</label>
					<md-input
						@change="addIdentification"
						v-model="identification"
						data-vv-name="identification"
						type="text"
						name="identification"
						v-validate="modelValidations.identification"
					></md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('identification')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('identification') && touched.identification">done</md-icon>
					</slide-y-down-transition>
				</md-field>
				<p
					class="error_message"
					style="margin-left: 2rem; margin-top: -0.5rem; margin-bottom: 1rem"
					v-show="citizenship === 'South African Citizen' && identification && identification.length < 13 && touched.identification"
				>
					Please ensure your ID number is 13 digits
					<br />
				</p>

				<md-field
					v-show="citizenship !== 'South African Citizen'"
					:class="[{ 'md-valid': !errors.has('passport') && touched.passport }, { 'md-form-group': true }, { 'md-error': errors.has('passport') }]"
				>
					<md-icon><i class="fas fa-passport"></i></md-icon>
					<label>Passport Number</label>
					<md-input @change="addPassport" v-model="passport" data-vv-name="passport" type="text" name="passport" v-validate="modelValidations.passport"></md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('passport')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('passport') && touched.passport">done</md-icon>
					</slide-y-down-transition>
				</md-field>
				<p
					class="error_message"
					style="margin-left: 2rem; margin-top: -0.5rem; margin-bottom: 1rem"
					v-show="citizenship !== 'South African Citizen' && passport && passport.length < 6 && touched.passport"
				>
					Please ensure your Passport number is 6 digits
					<br />
				</p>

				<md-field :class="[{ 'md-valid': !errors.has('license') && touched.license }, { 'md-form-group': true }, { 'md-error': errors.has('license') }]">
					<md-icon><i class="far fa-id-badge"></i></md-icon>
					<label for="license">Do you have a Driver's license?</label>
					<md-select class="pad" @input="addlicense" v-model="license" data-vv-name="license" name="license" required v-validate="modelValidations.license">
						<md-option v-for="(yes_no, index) in yes_no" :key="index" :value="yes_no">{{ yes_no }}</md-option>
					</md-select>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('license')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('license') && touched.license">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<md-field
					v-if="license == 'Yes'"
					:class="[{ 'md-valid': !errors.has('vehicle') && touched.vehicle }, { 'md-form-group': true }, { 'md-error': errors.has('vehicle') }]"
				>
					<md-icon><i class="fas fa-car"></i></md-icon>
					<label for="vehicle">Do you have your own vehicle?</label>
					<md-select class="pad" @input="addVehicle" v-model="vehicle" data-vv-name="vehicle" name="vehicle" required v-validate="modelValidations.vehicle">
						<md-option v-for="(yes_no, index) in yes_no" :key="index" :value="yes_no">{{ yes_no }}</md-option>
					</md-select>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('vehicle')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('vehicle') && touched.vehicle">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<md-field :class="[{ 'md-valid': !errors.has('programme') && touched.programme }, { 'md-form-group': true }, { 'md-error': errors.has('programme') }]">
					<md-icon><i class="fas fa-briefcase"></i></md-icon>
					<label for="programme">Which programme are you a part of?</label>
					<md-select class="pad" @input="addProgramme" v-model="programme" data-vv-name="programme" name="programme" required v-validate="modelValidations.programme">
						<md-option v-for="(programme, index) in programmes" :key="index" :value="programme">{{ programme }}</md-option>
					</md-select>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('programme')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('programme') && touched.programme">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<md-field :class="[{ 'md-valid': !errors.has('bio') && touched.bio }, { 'md-error': errors.has('bio') }]">
					<label>Professional Summary</label>
					<md-textarea
						@change="addBio"
						v-model="bio"
						data-vv-name="bio"
						type="text"
						name="bio"
						aria-placeholder="2 to 3 Sentences about your overall experience"
						required
						v-validate="modelValidations.bio"
					></md-textarea>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('bio')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('bio') && touched.bio">done</md-icon>
					</slide-y-down-transition>
				</md-field>
			</div>
		</div>
		<modal v-if="modal" @close="modalHide">
			<template slot="header">
				<h4 class="modal-title black">{{ header }}</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="modalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<p class="black">{{ body }}</p>
			</template>
			<template slot="footer">
				<div class="centre">
					<!-- Modal: Verify Email and continue creating account -->
					<md-button class="md-button md-success" @click="modalHide">Got it</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>
<script>
/* global google */
import { SlideYDownTransition } from 'vue2-transitions';
import { Modal } from '@/components';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { mapActions } from 'vuex';

export default {
	components: {
		SlideYDownTransition,
		Modal,
	},
	props: {
		avatar: {
			type: String,
			default: './img/default-avatar.png',
		},
	},
	data() {
		return {
			location: { streetNumber: '', streetAddress: '', town: '', province: '', country: '', formattedAddress: '' },
			relocation: '',
			identificationExists: null,
			passportExists: null,
			citizenship: null,
			identification: null,
			passport: null,
			firstName: null,
			lastName: null,
			alias: null,
			image: '',
			loading: null,
			modal: false,
			header: '',
			body: '',
			user: null,
			student: null,
			dob: null,
			gender: null,
			race: null,
			license: null,
			vehicle: null,
			programme: null,
			bio: null,
			programmes: [],
			genders: [],
			races: [],
			citizenships: [],
			yes_no: [],
			touched: {
				location: false,
				relocation: false,
				citizenship: false,
				identification: false,
				passport: false,
				firstName: false,
				lastName: false,
				dob: false,
				gender: false,
				race: false,
				bio: false,
				image: false,
				license: false,
				programme: false,
				vehicle: false,
			},
			modelValidations: {
				location: {
					required: true,
				},
				relocation: {
					required: true,
				},
				firstName: {
					required: true,
				},
				lastName: {
					required: true,
				},
				dob: {
					required: true,
				},
				gender: {
					required: true,
				},
				citizenship: {
					required: true,
				},
				identification: {
					required: true,
					min: 13,
					max: 13,
				},
				passport: {
					required: true,
					min: 6,
				},
				race: {
					required: true,
				},
				image: {
					required: false,
				},
				license: {
					required: false,
				},
				programme: {
					required: true,
				},
				vehicle: {
					required: false,
				},
				bio: {
					required: false,
					// min: 10,
					// max: 100
				},
			},
		};
	},
	mounted() {
		this.initAutocomplete();
	},
	computed: {
		identificationOrPassportExists() {
			return this.identificationExists || this.passportExists;
		},
		formattedLocationValue: {
			get() {
				return this.location ? this.location.formattedAddress : '';
			},
			set(value) {
				if (this.location) {
					this.location.formattedAddress = value;
				}
			},
		},
	},
	methods: {
		handleLocationBlur() {
			this.touched.location = true;
		},
		initAutocomplete() {
			const input = document.getElementById('autocomplete-location');
			const autocomplete = new google.maps.places.Autocomplete(input);

			autocomplete.addListener('place_changed', () => {
				const place = autocomplete.getPlace();
				if (!place.geometry) {
					console.error("No details available for input: '" + place.name + "'");
					return;
				}

				let address = {
					streetNumber: '',
					streetAddress: '',
					town: '',
					province: '',
					country: '',
					formattedAddress: '',
				};

				for (let component of place.address_components) {
					if (component.types.includes('street_number')) {
						address.streetNumber = component.long_name;
					} else if (component.types.includes('route')) {
						address.streetAddress = component.long_name;
					} else if (component.types.includes('locality')) {
						address.town = component.long_name;
					} else if (component.types.includes('administrative_area_level_1')) {
						address.province = component.long_name;
					} else if (component.types.includes('country')) {
						address.country = component.long_name;
					}
				}

				address.formattedAddress = place.formatted_address;

				this.location = address;
			});
		},
		previewImage(event) {
			var file = event.target.files[0];
			if (file.size < 2 * 1024 * 1024) {
				// less than 2MB
				this.fileUpload(file);
			} else {
				this.modal = true;
				this.header = 'Whoa there! ✋';
				this.body = 'You cannot exceed the file limit of 2MB';
			}
		},
		fileUpload(data) {
			this.loading = true;
			const storageRef = firebase
				.storage()
				.ref()
				.child('users/students/' + this.alias + '/profile/' + data.name)
				.put(data);
			storageRef.on(
				`state_changed`,
				() => {},
				error => {
					console.log(error.message);
				},
				() => {
					storageRef.snapshot.ref.getDownloadURL().then(url => {
						this.image = url;
						this.$emit('image', this.image);
						this.loading = false;
					});
				}
			);
		},
		calculateAge(birthday) {
			var today = new Date();
			var birthDate = new Date(birthday);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
		modalHide() {
			this.modal = false;
		},
		getError(fieldName) {
			return this.errors.first(fieldName);
		},
		validate() {
			return this.$validator.validateAll().then(res => {
				this.$emit('on-validated', res);
				return res;
			});
		},
		addFirstName: function () {
			this.$emit('firstName', this.firstName);
		},
		addLastName: function () {
			this.$emit('lastName', this.lastName);
		},
		addDob: function () {
			if (this.calculateAge(this.dob) > 17) {
				this.$emit('dob', this.dob);
			} else {
				this.dob = null;
				this.modal = true;
				this.header = 'Under The Age Limit! ✋';
				this.body = 'Unfortunately, you are too young to sign up to the platform.';
			}
		},
		addGender: function () {
			this.$emit('gender', this.gender);
		},
		addRace: function () {
			this.$emit('race', this.race);
		},
		addLocation: function () {
			this.$emit('location', this.location);
		},
		addRelocation: function () {
			this.$emit('relocation', this.relocation);
		},
		addlicense: function () {
			this.$emit('license', this.license);
		},
		addVehicle: function () {
			this.$emit('vehicle', this.vehicle);
		},
		addProgramme: function () {
			this.$emit('programme', this.programme);
		},
		addBio: function () {
			this.$emit('bio', this.bio);
		},
		addCitizenship: function () {
			this.$emit('citizenship', this.citizenship);
		},
		addIdentification: function () {
			this.$emit('identification', this.identification);
		},
		addPassport: function () {
			this.$emit('passport', this.passport);
		},
		...mapActions(['addStudentDetails']),
	},
	watch: {
		location() {
			this.touched.location = true;
		},
		relocation() {
			this.touched.relocation = true;
		},
		firstName() {
			this.touched.firstName = true;
		},
		lastName() {
			this.touched.lastName = true;
		},
		dob() {
			this.touched.dob = true;
		},
		gender() {
			this.touched.gender = true;
		},
		citizenship() {
			this.touched.citizenship = true;
			if (this.citizenship !== 'South African Citizen') {
				this.modelValidations.identification = {
					required: false,
				};
				this.modelValidations.passport = {
					required: true,
					min: 6,
				};
			}
			if (this.citizenship === 'South African Citizen') {
				this.modelValidations.identification = {
					required: true,
					min: 13,
					max: 13,
				};
				this.modelValidations.passport = {
					required: false,
					min: 6,
				};
			}
		},
		identification() {
			this.touched.identification = true;
		},
		passport() {
			this.touched.passport = true;
		},
		race() {
			this.touched.race = true;
		},
		bio() {
			this.touched.bio = true;
		},
		programme() {
			this.touched.programme = true;
		},
		license() {
			this.touched.license = true;
		},
		vehicle() {
			this.touched.vehicle = true;
		},
	},
	created() {
		let settings = db.collection('Settings').doc('Drop-down Lists');
		settings.get().then(doc => {
			this.genders = doc.data().Genders;
			this.races = doc.data().Races;
			this.yes_no = doc.data().yes_no;
			this.citizenships = doc.data().Citizenship;
			this.programmes = doc.data().SpecialProgrammes;
		});

		this.user = firebase.auth().currentUser;
		this.addStudentDetails({ name: 'user', value: this.user });
		let ref = db.collection('users');
		ref.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.alias = doc.id;
					let userDoc = db.collection('users').doc(doc.id);
					this.addStudentDetails({ name: 'userDoc', value: userDoc });
					userDoc.get().then(doc => {
						this.firstName = doc.data().name;
						this.lastName = doc.data().surname;
					});

					this.student = db.collection('students').doc(doc.id);
					this.addStudentDetails({ name: 'student', value: this.student });
					this.student
						.get()
						.then(doc => {
							if (doc.exists) {
								this.dob = new Date(doc.data().dateOfBirth);
								this.gender = doc.data().gender;
								this.race = doc.data().race;
								this.location = doc.data().location;
								this.relocation = doc.data().relocation;
								this.bio = doc.data().bio;
								this.license = doc.data().license;
								this.vehicle = doc.data().vehicle;
								this.programme = doc.data().programme;
								this.image = doc.data().profile;
								this.citizenship = doc.data().citizenship;
								this.identification = doc.data().identification;
								this.passport = doc.data().passport;
								this.identificationExists = doc.data().identification;
								this.passportExists = doc.data().passport;
							}
						})
						.catch(err => {
							console.log(err.message);
						});
				});
			});
	},
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
	.md-field label {
		font-size: 11px;
	}
}
</style>
