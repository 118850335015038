import { db } from '../firebase/init';
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';

/**
 * @description Logs system errors experienced to Firestore DB
 * @function
 * @param {string} userAlias The userAlias of the user experiencing the error.
 * @param {string} userEmail The userEmail of the user experiencing the error.
 * @param {string} userId  The userId of the user experiencing the error.
 * @param {string} appPage The application page where the error occurred.
 * @param {Object} error The error object of the error experienced.
 * @returns {Promise} A Promise resolved once the data has been successfully written to the backend.
 * **/
export async function logSystemError(userAlias = undefined, userEmail, userId = undefined, appPage = '', error) {
	const systemErrorLogsRef = collection(db, 'systemErrorLogs');

	const logDocRef = doc(systemErrorLogsRef, `${userAlias || 'unknown user'}-${Date.now()}`);
	await setDoc(logDocRef, {
		userEmail,
		userId: userId || 'unknown user',
		appPage,
		error,
		created: serverTimestamp(),
	});
}
