<template>
	<div>
		<md-datepicker
			:value="value"
			@input="$emit('input', $event)"
			data-vv-name="name"
			v-validate="modelValidation"
			md-immediately
			:class="[{ 'md-valid': !error && enableSuccess }, { 'md-form-group': true }, { 'md-error': error }]"
		>
			<label>{{ this.label }}</label>
			<slide-y-down-transition>
				<md-icon class="error" v-show="error">close</md-icon>
			</slide-y-down-transition>
			<slide-y-down-transition v-if="enableSuccess">
				<md-icon class="success" v-show="!error">done</md-icon>
			</slide-y-down-transition>
		</md-datepicker>
		<p v-if="error" class="error_message">{{ error }}</p>
	</div>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions';
export default {
	components: { SlideYDownTransition },
	props: {
		label: { type: String, default: 'Start Date' },
		name: String,
		modelValidation: Object,
		error: [String, Boolean],
		value: Date,
		enableSuccess: Boolean,
	},
};
</script>
