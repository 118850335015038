import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import axios from 'axios';

let api = null;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS';

db.collection('Settings')
	.doc('Backend')
	.get()
	.then(doc => {
		api = axios.create({
			//baseURL: doc.data().devApi
			baseURL: doc.data().api,
		});
	});

export default {
	// Send email
	notification(args) {
		return api.post('notification', args);
	},
	// Outbound Payment
	pay(args) {
		return api.post('pay', args);
	},
	// Get Embed Token
	async getEmbedToken() {
		return await api.get('powerbi/getReportEmbedToken');
	},
	// Send Pdf
	sendPdf(args) {
		return api.post('sendPdf', args);
	},
};
