<template>
	<div class="md-layout" id="top">
		<div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
			<simple-wizard
				v-bind:name="name"
				v-bind:description="description"
				v-bind:jobType="jobType"
				v-bind:education="education"
				v-bind:experience="experience"
				v-bind:programme="programme"
				v-bind:industryCategory="industryCategory"
				v-bind:jobCategory="jobCategory"
				v-bind:skills="skills"
				v-bind:location="location"
				v-bind:deadline="deadline"
				v-bind:daysOfTheWeek="daysOfTheWeek"
				v-bind:hours="hours"
				v-bind:applicationDeadline="applicationDeadline"
				v-bind:budget="budget"
				v-bind:benefit="benefit"
				v-bind:address="address || ''"
				v-bind:writtenSubmission="writtenSubmission"
				v-bind:writtenSubmissionDescription="writtenSubmissionDescription"
				v-bind:videoSubmission="videoSubmission"
				v-bind:videoSubmissionDescription="videoSubmissionDescription"
			>
				<template slot="header">
					<h3 class="title">Post a Job</h3>
					<h5 class="category">This information you fill out will allow students to apply for the job</h5>
					<md-button
						style="position: absolute; top: 3px; left: 10px; font-size: 30px !important"
						class="md-simple md-just-icon md-round modal-default-button"
						@click="openPostJobHelp"
					>
						<md-icon>help</md-icon>
					</md-button>
				</template>

				<wizard-tab :before-change="() => validateStep('step1')">
					<template slot="label">
						<div class="pc-view">Description</div>
						<div class="mobi-view"><i class="fas fa-list-ul"></i></div>
					</template>
					<first-step
						ref="step1"
						@on-validated="onStepValidated"
						@name="addName"
						@description="addDescription"
						@education="addEducation"
						@experience="addExperience"
						@jobType="addJobType"
						@industryCategory="addIndustryCategory"
						@jobCategory="addJobCategory"
						@skills="addSkills"
						@programme="addProgramme"
					>
					</first-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step2')">
					<template slot="label">
						<div class="pc-view">Location</div>
						<div class="mobi-view"><i class="fas fa-location-arrow"></i></div>
					</template>
					<second-step
						ref="step2"
						@on-validated="onStepValidated"
						@location="addLocation"
						@deadline="addDeadline"
						@daysOfTheWeek="addDaysOfTheWeek"
						@hours="addHours"
						@applicationDeadline="addApplicationDeadline"
						@address="addAddress"
						v-bind:jobType="jobType"
					>
					</second-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step3')">
					<template slot="label">
						<div class="pc-view">Salary</div>
						<div class="mobi-view"><i class="fas fa-wallet"></i></div>
					</template>
					<third-step ref="step3" @on-validated="onStepValidated" @budget="addBudget" @benefit="addBenefit" v-bind:jobType="jobType"> </third-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step4')">
					<template slot="label">
						<div class="pc-view">Additional Submissions</div>
						<div class="mobi-view"><i class="fas fa-tasks"></i></div>
					</template>
					<fourth-step
						ref="step4"
						@writtenSubmission="addWrittenSubmission"
						@writtenSubmissionDescription="addWrittenSubmissionDescription"
						@videoSubmission="addVideoSubmission"
						@videoQuestion="addVideoQuestion"
						@on-validated="onStepValidated"
					>
					</fourth-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step4')">
					<template slot="label">
						<div class="pc-view">Review</div>
						<div class="mobi-view"><i class="fas fa-tasks"></i></div>
					</template>
					<fifth-step
						ref="step5"
						v-bind:name="name"
						v-bind:description="description"
						v-bind:jobType="jobType"
						v-bind:education="education"
						v-bind:experience="experience"
						v-bind:industryCategory="industryCategory"
						v-bind:jobCategory="jobCategory"
						v-bind:skills="skills"
						v-bind:location="location"
						v-bind:deadline="deadline"
						v-bind:daysOfTheWeek="daysOfTheWeek"
						v-bind:hours="hours"
						v-bind:applicationDeadline="applicationDeadline"
						v-bind:budget="budget"
						v-bind:benefit="benefit"
					>
					</fifth-step>
				</wizard-tab>
			</simple-wizard>
		</div>
	</div>
</template>
<script>
import FirstStep from './wizard/formSteps/FirstStep.vue';
import SecondStep from './wizard/formSteps/SecondStep.vue';
import ThirdStep from './wizard/formSteps/ThirdStep.vue';
import FourthStep from './wizard/formSteps/FourthStep.vue';
import FifthStep from './wizard/formSteps/FifthStep.vue';
import { helperArticles } from '@/constants/helper-articles.const.js';
import swal from 'sweetalert2';
import { WizardTab } from '@/components';
import SimpleWizard from './wizard/Wizard.vue';

export default {
	components: {
		FirstStep,
		SecondStep,
		ThirdStep,
		FourthStep,
		FifthStep,
		SimpleWizard,
		WizardTab,
	},
	data() {
		return {
			address: null,
			wizardModel: {},
			name: null,
			description: null,
			industryCategory: null,
			jobType: null,
			education: null,
			experience: null,
			jobCategory: null,
			programme: null,
			skills: [],
			location: null,
			deadline: null,
			daysOfTheWeek: null,
			hours: null,
			applicationDeadline: null,
			budget: null,
			benefit: null,
			writtenSubmission: null,
			writtenSubmissionDescription: null,
			videoSubmission: null,
			videoSubmissionDescription: null,
		};
	},
	methods: {
		openPostJobHelp() {
			window.open(helperArticles.POST_JOB_HELP, '_blank');
		},
		validateStep(ref) {
			return this.$refs[ref].validate();
		},
		onStepValidated(validated, model) {
			this.wizardModel = { ...this.wizardModel, ...model };
		},
		addName: function (name) {
			this.name = name;
		},
		addDescription: function (description) {
			this.description = description;
		},
		addJobType: function (jobType) {
			this.jobType = jobType;
		},
		addProgramme: function (programme) {
			this.programme = programme;
		},
		addEducation: function (education) {
			this.education = education;
		},
		addExperience: function (experience) {
			this.experience = experience;
		},
		addJobCategory: function (jobCategory) {
			this.jobCategory = jobCategory;
		},
		addIndustryCategory: function (industryCategory) {
			this.industryCategory = industryCategory;
		},
		addSkills: function (skills) {
			this.skills = skills;
		},
		addLocation: function (location) {
			this.location = location;
		},
		addDeadline: function (deadline) {
			this.deadline = deadline;
		},
		addDaysOfTheWeek: function (daysOfTheWeek) {
			this.daysOfTheWeek = daysOfTheWeek;
		},
		addHours: function (hours) {
			this.hours = hours;
		},
		addApplicationDeadline: function (applicationDeadline) {
			this.applicationDeadline = applicationDeadline;
		},
		addBudget: function (budget) {
			this.budget = budget;
		},
		addBenefit: function (benefit) {
			this.benefit = benefit;
		},
		addAddress: function (address) {
			this.address = address;
		},
		addWrittenSubmission: function (writtenSubmission) {
			this.writtenSubmission = writtenSubmission;
		},
		addWrittenSubmissionDescription: function (writtenSubmissionDescription) {
			this.writtenSubmissionDescription = writtenSubmissionDescription;
		},
		addVideoSubmission: function (videoSubmission) {
			this.videoSubmission = videoSubmission;
		},
		addVideoQuestion: function (videoQuestion) {
			this.videoSubmissionDescription = videoQuestion;
		},
	},
	watch: {
		industryCategory: function () {
			if (this.industryCategory === 'Other') this.$refs.step1.jobCategory = this.name;
		},
	},
};
</script>
<style scoped>
.md-button .md-button-content i {
	font-size: 30px !important;
}
@media only screen and (max-width: 768px) {
	.pc-view {
		display: none;
	}
	.md-layout-item.md-xsmall-size-80 {
		min-width: 100%;
		overflow-x: hidden;
	}
	.md-card-wizard .nav-pills > li i {
		font-size: 0px;
	}
}

@media only screen and (min-width: 768px) {
	.mobi-view {
		display: none;
	}
}
</style>
