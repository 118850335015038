import { render, staticRenderFns } from "./FourthStep.vue?vue&type=template&id=f8a27d8e&scoped=true&"
import script from "./FourthStep.vue?vue&type=script&lang=js&"
export * from "./FourthStep.vue?vue&type=script&lang=js&"
import style0 from "./FourthStep.vue?vue&type=style&index=0&id=f8a27d8e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8a27d8e",
  null
  
)

export default component.exports