<template>
	<div v-if="loading">
		<md-card>
			<md-button
				style="position: absolute; top: 0px; left: 3px; font-size: 30px !imporant"
				class="md-simple md-just-icon md-round modal-default-button"
				@click="openUsersHelp"
			>
				<md-icon>help</md-icon>
			</md-button>

			<div class="container">
				<md-table v-model="users" md-card>
					<md-table-toolbar>
						<h1 class="md-title">Users</h1>
						<div v-if="mainClient"><md-button class="md-success" @click="openAddUsersModal"> Add Users</md-button></div>
					</md-table-toolbar>

					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
						<md-table-cell md-label="Surname" md-sort-by="surname">{{ item.surname }}</md-table-cell>
						<md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
						<md-table-cell md-label="Status" md-sort-by="status">{{ item.status }}</md-table-cell>
						<md-table-cell v-if="mainClient">
							<div @click="selectUserForDelete(item)">
								<md-icon>delete</md-icon>
							</div>
						</md-table-cell>
						<md-table-cell v-if="mainClient">
							<div @click="selectUserForEdit(item)">
								<md-icon>edit</md-icon>
							</div>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</div>
			<div v-if="this.openUserModal">
				<modal>
					<template slot="header">
						<div v-if="this.isEditUser">
							<h2>Edit user</h2>
						</div>
						<div v-else>
							<h2>Invite users to workspace</h2>
						</div>
					</template>
					<template slot="body">
						<form class="labels">
							<div>
								<div v-for="error of v$.firstName.$errors" :key="error.$uid">
									<div class="labels-error">{{ error.$message }}</div>
								</div>
								<label>First Name</label>
								<md-field>
									<md-input v-model="firstName" type="text" name="firstName"></md-input>
								</md-field>
							</div>

							<div>
								<div v-for="error of v$.surname.$errors" :key="error.$uid">
									<div class="labels-error">{{ error.$message }}</div>
								</div>
								<label>Surname</label>
								<md-field>
									<md-input v-model="surname" type="text" name="surname"></md-input>
								</md-field>
							</div>
							<div v-if="!this.isEditUser">
								<div v-for="error of v$.email.$errors" :key="error.$uid">
									<div class="labels-error">{{ error.$message }}</div>
								</div>
								<label>Email</label>
								<md-field>
									<md-input v-model="email" type="text" name="email"></md-input>
								</md-field>
							</div>
						</form>
					</template>
					<template slot="footer">
						<div class="modal-footer">
							<md-button v-if="this.isEditUser" class="md-success" @click="editUsers"> Edit User</md-button>
							<md-button v-else class="md-success" @click="addUsers"> Add User</md-button>
							<md-button class="md-danger" @click="cancel"> Cancel</md-button>
						</div>
					</template>
				</modal>
			</div>

			<div v-if="isConfirmation">
				<modal>
					<template slot="header">
						<h3>{{ this.header }}</h3>
					</template>
					<template slot="body">
						<p>{{ this.message }}</p>
					</template>
					<template slot="footer">
						<md-button
							class="md-success"
							@click="
								() => {
									this.isConfirmation = false;
								}
							"
							>OK</md-button
						>
					</template>
				</modal>
			</div>

			<div v-if="isDeleteUser">
				<modal>
					<template slot="header">
						<h3>Delete User!</h3>
					</template>
					<template slot="body">
						<p>This user will no longer have access to this workspace.</p>
						<p>Are you sure you want to delete {{ this.deleteUser.name }} {{ this.deleteUser.surname }}?</p>
					</template>
					<template slot="footer">
						<div class="modal-footer">
							<md-button class="md-success" @click="deleteSelectedUser"> Yes</md-button>
							<md-button
								class="md-danger"
								@click="
									() => {
										this.isDeleteUser = false;
									}
								"
							>
								No</md-button
							>
						</div>
					</template>
				</modal>
			</div>
		</md-card>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { Modal } from '@/components';
import { helperArticles } from '@/constants/helper-articles.const.js';

//import Modal from 'components/Modal.vue';
export default {
	name: 'TableTemplate',
	components: {
		Modal,
	},
	setup() {
		return { v$: useVuelidate() };
	},
	data: () => ({
		isDeleteUser: false,
		isEditUser: false,
		clientId: null,
		isConfirmation: false,
		openUserModal: false,
		firstName: null,
		surname: null,
		email: null,
		client: null,
		invitedUserList: [],
		users: [],
		mainClient: false,
		invitedClient: false,
		loading: false,
		message: null,
		header: null,
		deleteUser: null,
		editUser: null,
		isUserAdded: null,
	}),
	methods: {
		openUsersHelp() {
			window.open(helperArticles.USER_MANAGEMENT_HELP, '_blank');
		},
		selectUserForDelete(item) {
			this.isDeleteUser = true;
			this.deleteUser = item;
		},
		selectUserForEdit(item) {
			this.isEditUser = true;
			this.editUser = item;
			this.openUserModal = true;
			this.firstName = item.name;
			this.surname = item.surname;
		},
		async deleteSelectedUser() {
			this.isDeleteUser = false;
			const docRef = await db.collection('clients').doc(this.clientId).collection('invitedClients').where('email', '==', this.deleteUser.email).get();
			docRef.docs.forEach(doc => {
				doc.ref
					.delete()
					.then(() => {
						this.deleteFromUsersColletion();
						this.users = this.users.filter(user => user.email != this.deleteUser.email);
						this.isConfirmation = true;
						this.header = 'Success!';
						this.message = 'The user has been successfully removed from the workspace';
					})
					.catch(error => {
						this.isConfirmation = true;
						this.header = 'Whoops!';
						this.message = error;
					});
			});
		},
		async deleteFromUsersColletion() {
			try {
				const usersDB = await db.collection('users').where('email', '==', this.deleteUser.email).get();
				usersDB.docs.forEach(doc => {
					doc.ref.delete();
				});
			} catch (error) {
				console.error(error);
			}
		},
		async editFromUsersCollection() {
			try {
				const usersDB = await db.collection('users').where('email', '==', this.editUser.email).get();
				usersDB.docs.forEach(doc => {
					doc.ref.update({
						name: this.firstName,
						surname: this.surname,
					});
				});
			} catch (error) {
				console.error(error);
			}
		},
		random() {
			let rand1 = Math.floor(Math.random() * 10).toString();
			let rand2 = Math.floor(Math.random() * 10).toString();
			let rand3 = Math.floor(Math.random() * 10).toString();
			let rand4 = Math.floor(Math.random() * 10).toString();
			return rand1 + rand2 + rand3 + rand4;
		},
		async openAddUsersModal() {
			this.openUserModal = true;
		},
		cancel() {
			this.openUserModal = false;
			this.email = null;
			this.surname = null;
			this.firstName = null;
			this.isEditUser = false;
			this.v$.$reset();
		},
		closeConfirmationModal() {
			this.isConfirmation = false;
		},
		async editUsers() {
			this.editFromUsersCollection();
			const docRef = await db.collection('clients').doc(this.clientId).collection('invitedClients').where('email', '==', this.editUser.email).get();
			docRef.docs.forEach(async doc => {
				doc.ref
					.update({
						name: this.firstName,
						surname: this.surname,
					})
					.then(() => {
						const editUserIndex = this.users.findIndex(user => user.email === this.editUser.email);
						this.users[editUserIndex].name = this.firstName;
						this.users[editUserIndex].surname = this.surname;
						this.openUserModal = false;
						this.header = 'Success! 🥳';
						this.message = 'User Details have been succefully changed';
						this.isConfirmation = true;
						this.isEditUser = false;
					});
			});
		},
		async addUsers() {
			let isValid = await this.v$.$validate();
			if (isValid) {
				let userDetails = firebase.auth().currentUser.uid;
				const user = await db.collection('clients').where('userId', '==', userDetails).get();
				user.docs.forEach(async doc => {
					this.client = doc.data();
					this.clientId = doc.id;
				});

				let result = await this.createNewClient(this.clientId);

				if (result) {
					this.sendUserEmail(
						this.email,
						this.capitalizeFirstLetter(this.firstName),
						this.capitalizeFirstLetter(this.surname),
						this.client.companyName,
						this.capitalizeFirstLetter(this.client.name),
						this.capitalizeFirstLetter(this.client.surname),
						this.clientId
					);
					this.openUserModal = false;
					this.header = 'Email Invitation Sent! 🥳';
					this.message = 'The person you invited to the platform will receive an email with the necessary details to join the platform';
					this.isConfirmation = true;
					this.resetValues();
				}
			}
		},
		async createNewClient(clientId) {
			if (this.users.some(obj => obj.email === this.email)) {
				this.openUserModal = false;
				this.header = 'Whoops!';
				this.message = 'This person has already been invited.';
				this.isConfirmation = true;
				this.isUserAdded = false;
				this.resetValues();

				return false;
			} else {
				try {
					await db
						.collection(`clients/${clientId}/invitedClients`)
						.doc('client' + this.random())
						.set({
							name: this.firstName,
							surname: this.surname,
							status: 'Invitation Sent!',
							email: this.email,
						});
					this.isUserAdded = true;
					this.users.push({ name: this.firstName, surname: this.surname, status: 'Invitation Sent!', email: this.email });
					return true;
				} catch (error) {
					console.log('error', error);
				}
			}
		},
		async sendUserEmail(email, firstName, surname, companyName, clientName, clientSurname, clientAlias) {
			const sendUserInvite = firebase.functions().httpsCallable('sendUserInvitation');
			try {
				const params = {
					email,
					firstName,
					surname,
					companyName,
					clientName,
					clientSurname,
					clientAlias,
				};
				const response = await sendUserInvite(params);
				return response;
			} catch (e) {
				console.log({ e });
			}
		},
		resetValues() {
			this.email = null;
			this.surname = null;
			this.firstName = null;
			this.v$.$reset();
		},
		capitalizeFirstLetter(str) {
			return str.charAt(0).toUpperCase() + str.slice(1);
		},
		async getInvitedClientData(id) {
			const docRef = await db.collection('clients').doc(id).collection('invitedClients').get();
			docRef.docs.forEach(doc => {
				this.users.push(doc.data());
			});
		},
	},
	validations() {
		return {
			firstName: { required },
			surname: { required },
			email: { required, email },
		};
	},
	async created() {
		let user = firebase.auth().currentUser;
		const users = await db.collection('users').where('userId', '==', user.uid).get();
		users.docs.forEach(doc => {
			if (doc.exists) {
				const clientData = doc.data();
				if (clientData.accessType) {
					this.getInvitedClientData(doc.data().clientAlias);
					this.clientId = doc.data().clientAlias;
					this.invitedClient = true;
					this.loading = true;
				} else {
					this.getInvitedClientData(doc.data().alias);
					this.clientId = doc.data().alias;
					this.mainClient = true;
					this.loading = true;
				}
			}
		});
	},
};
</script>

<style scoped>
.md-button .md-button-content i {
	font-size: 30px !important;
}
.modal-footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}
.radio-selection {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.md-radio .md-radio-label {
	color: #3c4858 !important;
}
label.md-radio-label {
	color: #3c4858 !important;
}
.labels {
	text-align: left;
}
.labels-error {
	text-align: left;
	font-size: 12px;
	color: red;
}
.md-table >>> .md-table-head {
	/* background-color: #64a151 !important; */
	background-color: #ff5722 !important;
}
.md-table >>> .md-table-head-label {
	color: rgb(255, 255, 255) !important;
}
.container {
	margin: 0px 20px;
}
.modal-header h3 {
	color: black !important;
}
</style>
