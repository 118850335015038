<template>
	<div class="content">
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<hr />
		<h2 class="centre">Please Confirm That the Job Was Completed To Your Satisfaction</h2>
		<hr />
		<h6 class="centre">Student Information</h6>
		<br />
		<div class="md-layout">
			<div class="md-layout-item md-small-size-100">
				<md-card class="md-card-profile">
					<div class="md-card-avatar">
						<img v-if="profile" class="img" :src="profile" />
						<img v-else class="img" :src="cardUserImage" />
					</div>
					<br />
					<md-card-content>
						<h6 class="category text-gray">{{ applicant.degree[0].degree }}</h6>
						<md-button class="md-round md-info md-sm" @click="viewProfile(applicant.alias)">{{ applicant.applicant }}</md-button>
						<p class="card-description">{{ applicant.bio }}</p>
					</md-card-content>
				</md-card>
			</div>
		</div>
		<div class="centre">
			<p>The student has completed the job, satisfied and ready to pay?</p>
			<!-- TO DO: Consider payment methods and restructure DB -->
			<!-- md-button v-if="!paid && job.jobType === 'Once-off Project/Task'" class="btn-next md-info button" @click="payment" style="max-width:110px;">
        Make payment
      </md-button -->
			&nbsp;&nbsp;&nbsp;
			<p>Are you satisfied with the student's completion of the job?</p>
			<md-button @click="dissatisfied(client)" class="md-danger"> I am not satisfied </md-button>
			&nbsp;&nbsp;&nbsp;
			<md-button @click="complete({ id: client.id, paid: paid })" class="md-success"> I am satisfied </md-button>
		</div>
		<!-- Modal: Cancel -->
		<modal v-if="cancelModal" @close="cancelModalHide">
			<template slot="header">
				<h4 class="modal-title black">Whoa there! ✋</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="cancelModalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<p class="black">Are you sure that you want to report that you are dissatisfied with the student’s completion of the job?</p>
			</template>

			<template slot="footer">
				<div style="text-align: center">
					<md-button class="md-button md-danger" @click="cancelModalHide">No</md-button>
					&nbsp;&nbsp;&nbsp;
					<md-button class="md-button md-success" @click="dissatisfied(client)">Yes</md-button>
				</div>
			</template>
		</modal>
		<!-- TO DO: restructure payment methods -->
		<!-- Modal: No Payment  -->
		<!-- modal v-if="modal" @close="noPaymentModalHide">
      <template slot="header">
        <h4 class="modal-title black">Whoa there! ✋</h4>
      </template>

      <template slot="body">
        <p class="black">Please make sure that the payment has been made before you confirm the completion of the job</p>
      </template>

      <template slot="footer">
        <div class="centre">
          <md-button class="md-button md-success" @click="noPaymentModalHide">Got it</md-button>
        </div>
      </template>
    </modal -->
		<!-- TO DO: restructure payment methods -->
		<!--  Make payment -->
		<!-- modal v-if="paymentModal" @close="paymentModalHide">
      <template slot="header">
        <h4 class="modal-title black">Make Payment</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="paymentModalHide">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <notifications></notifications>
        <p class="black">Your payment is outstanding</p>
        <p class="black">Your payment could take up to 5 minutes to reflect depending on your internet speed</p>
      </template>

      <template slot="footer">
        <div class="centre">
          <md-button class="md-button md-success" @click="makePayment">Pay Now</md-button>
        </div>
      </template>
    </modal -->
		<!-- View Profile:  -->
		<modal v-if="profileModal" @close="profileModalHide">
			<template slot="header">
				<div class="md-card-avatar avatar">
					<img class="img radius" :src="cardUserImage" />
				</div>

				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="profileModalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<h2 class="modal-title black">
					<b>{{ student.name + ' ' + student.surname }}</b>
				</h2>
				<p class="black left">
					{{ student.bio }}
				</p>
				<b class="large-font">Qualifications</b> <br />
				<p class="black left">
					<span v-if="student.studying === 'Yes'">The candidate is currently studying the following:</span>
					<span v-if="student.studying === 'No'">The candidate most recently completed the following:</span>
					<br /><br />
					<b>Institution:</b> {{ student.degree[0].institution }} <br />
					<b>Degree:</b> {{ student.degree[0].degree }} <br />
					<b>Graduate Status:</b> {{ student.degree[0].graduateStatus }} <br />
					<b>Year of Study:</b> {{ student.degree[0].year }} <br />
				</p>

				<b class="large-font">Contact Information</b> <br />
				<p class="black left">
					<b>Email Address:</b> {{ student.email || '**********' }} <br />
					<b>Phone Number:</b> {{ student.phone || '**********' }}
					<span v-if="student.personalWebsite"
						><br />
						<b>Website: </b> <a :href="student.personalWebsite" target="_blank">{{ student.personalWebsite }}</a></span
					>
				</p>
				<b class="large-font">Certificates</b> <br />
				<p class="black" v-if="student.certificate1 || student.certificate2 || student.certificate3">
					<md-button v-if="student.certificate1" class="md-round md-info md-sm" @click="certificate1"># 1</md-button> &nbsp;&nbsp;&nbsp;
					<md-button v-if="student.certificate2" class="md-round md-info md-sm" @click="certificate2"># 2</md-button> &nbsp;&nbsp;&nbsp;
					<md-button v-if="student.certificate3" class="md-round md-info md-sm" @click="certificate3"># 3</md-button>
				</p>
				<p class="red left" v-if="!student.certificate1 && !student.certificate2 && !student.certificate3">The candidate has not uploaded any certificates</p>
				<b class="large-font">Resume</b> <br />
				<p class="black">
					<md-button v-if="student.cv && student.cv !== ''" @click="cv" class="md-round md-info md-sm">CV</md-button> &nbsp;&nbsp;&nbsp;
					<md-button v-if="student.portfolio && student.portfolio !== ''" @click="portfolio" class="md-round md-info md-sm">Portfolio</md-button> &nbsp;&nbsp;&nbsp;
				</p>
				<b class="large-font">Social Media Handles</b> <br />
				<p class="black">
					<md-icon v-if="student.linkedIn && student.linkedIn !== ''"><i class="fab fa-linkedin" style="color: #0e76a8; cursor: pointer" @click="linkedin"></i></md-icon>
					<span v-if="student.linkedIn && student.linkedIn !== ''">&nbsp;</span><span v-if="student.linkedIn && student.linkedIn !== ''">&nbsp;</span
					><span v-if="student.linkedIn && student.linkedIn !== ''">&nbsp;</span><span v-if="student.linkedIn && student.linkedIn !== ''">&nbsp;</span>
					<md-icon v-if="student.github && student.github !== ''"><i class="fab fa-github" style="color: #000; cursor: pointer" @click="github"></i></md-icon>
					<span v-if="student.github && student.github !== ''">&nbsp;</span><span v-if="student.github && student.github !== ''">&nbsp;</span
					><span v-if="student.github && student.github !== ''">&nbsp;</span><span v-if="student.github && student.github !== ''">&nbsp;</span>
					<md-icon v-if="student.facebook && student.facebook !== ''"><i class="fab fa-facebook" style="color: #3b5998; cursor: pointer" @click="facebook"></i></md-icon>
					<span v-if="student.facebook && student.facebook !== ''">&nbsp;</span><span v-if="student.facebook && student.facebook !== ''">&nbsp;</span
					><span v-if="student.facebook && student.facebook !== ''">&nbsp;</span><span v-if="student.facebook && student.facebook !== ''">&nbsp;</span>
					<md-icon v-if="student.twitter && student.twitter !== ''"><i class="fab fa-twitter" style="color: #00acee; cursor: pointer" @click="twitter"></i></md-icon>
					<span v-if="student.twitter && student.twitter !== ''">&nbsp;</span><span v-if="student.twitter && student.twitter !== ''">&nbsp;</span
					><span v-if="student.twitter && student.twitter !== ''">&nbsp;</span><span v-if="student.twitter && student.twitter !== ''">&nbsp;</span>
					<md-icon v-if="student.instagram && student.instagram !== ''"
						><i class="fab fa-instagram" style="color: #d6249f; cursor: pointer" @click="instagram"></i
					></md-icon>
					<span class="red left" v-if="!socialMedia">The candidate has no social media handles present</span>
				</p>
			</template>

			<template slot="footer">
				<div class="centre">
					<md-button class="md-button md-success" @click="profileModalHide">Done</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>
<script>
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import moment from 'moment';
import { Modal } from '@/components';
import { mapGetters, mapActions } from 'vuex';
export default {
	components: {
		Modal,
	},
	data() {
		return {
			cancelModal: false,
			client: {},
			applicant: {},
			paid: true,
			//paid: false, remove paywall
			profileModal: false,
			profile: null,
			student: {},
			socialMedia: false,
		};
	},
	props: {
		cardUserImage: {
			type: String,
			default: '/img/dashboard/client/card-1.jpg',
		},
	},
	computed: {
		...mapGetters({
			loading: 'loadingCC',
			modal: 'modalP',
		}),
	},
	methods: {
		...mapActions(['complete', 'dissatisfied', 'noPaymentModalHide']),
		cancelModalHide() {
			this.cancelModal = false;
		},
		cancel() {
			let job = db.collection('micros').doc(this.client.id);
			job.update({
				complete: false,
				lastModified: moment(Date.now()).format('L'),
			});
			this.$router.push({ name: 'client-cancel', params: { id: job.id } });
		},
		viewProfile(alias) {
			this.loading = true;
			db.collection('students')
				.doc(alias)
				.get()
				.then(doc => {
					this.student = doc.data();
					if (
						this.student.linkedIn &&
						this.student.linkedIn !== '' &&
						this.student.github &&
						this.student.github !== '' &&
						this.student.facebook &&
						this.student.facebook !== '' &&
						this.student.twitter &&
						this.student.twitter !== '' &&
						this.student.instagram &&
						this.student.instagram !== ''
					)
						this.socialMedia = true;
					else this.socialMedia = false;
					this.cardUserImage = this.student.profile;
					db.collection('users')
						.doc(alias)
						.get()
						.then(doc => {
							this.student.name = doc.data().name;
							this.student.surname = doc.data().surname;
							this.student.email = doc.data().email;
							this.student.phone = doc.data().phone;
							this.profileModal = true;
							this.pop = true;
							this.loading = false;
						});
				});
		},
		modalHide() {
			this.paymentModal = false;
		},
		// TODO: Restructure payment methods
		//payment() {
		//  this.paymentModal = true;
		//},
		//makePayment: function() {
		//  this.loading = true;
		//  this.$store.dispatch('makePayment', this.job);
		//},
		profileModalHide() {
			this.pop = false;
			this.profileModal = false;
		},
		linkedin() {
			window.open(this.student.linkedIn, '_blank');
		},
		github() {
			window.open(this.student.github, '_blank');
		},
		facebook() {
			window.open(this.student.facebook, '_blank');
		},
		twitter() {
			window.open(this.student.twitter, '_blank');
		},
		instagram() {
			window.open(this.student.instagram, '_blank');
		},
		certificate1() {
			window.open(this.student.certificate1, '_blank');
		},
		certificate2() {
			window.open(this.student.certificate2, '_blank');
		},
		certificate3() {
			window.open(this.student.certificate3, '_blank');
		},
		cv() {
			window.open(this.student.cv, '_blank');
		},
		portfolio() {
			window.open(this.student.portfolio, '_blank');
		},
	},
	created() {
		db.collection('micros')
			.doc(this.$route.params.id)
			.get()
			.then(doc => {
				this.client = doc.data();
				this.client.id = doc.id;
			});

		db.collection('applications')
			.where('jobId', '==', this.$route.params.id)
			.where('status', '==', 'applied')
			.where('approved', '==', true)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.available = true;
					this.applicant = doc.data();
					this.applicant.id = doc.id;
					db.collection('students')
						.doc(this.applicant.alias)
						.get()
						.then(doc => {
							this.profile = doc.data().profile;
						});
				});
			});

		/*
    let payment = db.collection('payments');
    payment.where('jobId', '==', this.$route.params.id).get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        this.paid = doc.data().inboundPayment;
      });
    });
    payment.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if(change.type == 'modified') {
          this.paid = change.doc.data().inboundPayment;
        }
      });
    });
    */
	},
};
</script>
<style scoped>
.centre {
	text-align: center;
	font-weight: bold;
}
.md-layout,
.md-layout-item {
	width: 40%;
	margin: auto;
}
/* Mobile View */
@media only screen and (max-width: 768px) {
	.md-layout,
	.md-layout-item {
		width: 100%;
		margin: auto;
	}
}
/* Pop up modal */
.modal-container {
	margin: -100px auto;
}

.small-font {
	font-size: small;
}

.large-font {
	font-size: large;
}

.left {
	text-align: left;
}

.avatar {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}

.radius {
	border-radius: 100px;
}

.modal-mask {
	position: absolute;
	background-color: rgba(0, 0, 0, 0);
	top: -100px;
}

.profile {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-transition: opacity 0.15s ease;
	transition: opacity 0.15s ease;
}

.red {
	color: red;
	font-weight: bold;
}
</style>
