<template>
	<div :class="filterClicked ? 'filter-modal-container open' : 'filter-modal-container'">
		<div class="filter-modal-row">
			<h2>Filter Job Openings</h2>
		</div>
		<div class="filter-field-container">
			<md-field>
				<label for="job-categories">Job Categories</label>
				<md-select v-model="selectedJobCategories" name="job-categories" id="job-categories" multiple @input="handleCategoryChange">
					<md-option v-for="(value, index) in showUniqueJobCategories" :key="index" :value="value">{{ value }}</md-option>
				</md-select>
			</md-field>
			<md-field>
				<label for="employment-types">Employment Types</label>
				<md-select v-model="selectedEmploymentTypes" name="employment-types" id="employment-types" multiple @input="handleEmploymentTypeChange">
					<md-option v-for="(value, index) in showUniqueEmploymentTypes" :key="index" :value="value">{{ value }}</md-option>
				</md-select>
			</md-field>
			<md-field>
				<label for="locations">Job Locations</label>
				<md-select v-model="selectedLocations" name="locations" id="locations" multiple @input="handleLocationChange">
					<md-option value="Remote">Remote</md-option>
					<md-option value="On-Site">On-Site</md-option>
					<md-option value="Hybrid">Hybrid</md-option>
				</md-select>
			</md-field>
		</div>
		<div class="filter-button-row">
			<md-button class="md-success" @click="handleClearFilters">Clear Filters</md-button>
			<md-button class="md-danger" @click="handleClose">Close</md-button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		filterClicked: {},
		data: {},
	},
	data() {
		return {
			closeFilter: this.filterClicked,
			selectedJobCategories: [],
			selectedEmploymentTypes: [],
			selectedLocations: [],
		};
	},

	methods: {
		handleClearFilters() {
			this.selectedEmploymentTypes = [];
			this.selectedJobCategories = [];
			this.selectedLocations = [];
		},
		handleCategoryChange() {
			this.$emit('selectedCategory', this.selectedJobCategories);
		},
		handleEmploymentTypeChange() {
			this.$emit('selectedEmploymentType', this.selectedEmploymentTypes);
		},
		handleLocationChange() {
			this.$emit('selectedLocations', this.selectedLocations);
		},
		handleClose() {
			this.closeFilter = false;
			this.$emit('closeFilterModal', this.closeFilter);
		},
		getUniqueValues(propertyName) {
			return [...new Set(this.data.map(item => item[propertyName]))];
		},
	},
	computed: {
		showUniqueJobCategories() {
			return this.getUniqueValues('category');
		},
		showUniqueEmploymentTypes() {
			return this.getUniqueValues('type');
		},
		showUniqueLocations() {
			return this.getUniqueValues('location');
		},
	},
};
</script>

<style scoped>
.filter-modal-container {
	width: 800px;
	height: 800px;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
	background-color: white;
	box-shadow: 0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%);
	border-radius: 6px;
	border: none;
	left: 200%; /* start off-screen to the right */
	transition: left 0.3s ease-in-out; /* animate the left property */
	padding: 3rem;
}

.filter-modal-container.open {
	left: 50%; /* bring on-screen */
	transform: translate(-50%, -50%);
	transition: left 0.3s ease-in-out; /* animate the left property */
}
.md-field {
	max-width: 100%;
	margin: 2rem;
}

.filter-field-container {
	width: 100%;
	margin-top: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.filter-modal-row {
	width: 100%;
	display: flex;
	align-items: center;
	text-align: center;
}

.filter-button-row {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 4rem;
}

@media (max-width: 830px) {
	.filter-modal-container {
		width: 90% !important;
		height: fit-content !important;
	}
}
@media (max-width: 680px) {
	.filter-modal-container {
		width: 90% !important;
		height: fit-content !important;
	}

	.filter-modal-row > h2 {
		display: none;
	}

	.filter-field-container {
		margin-top: 0;
	}
	.filter-button-row {
		margin-top: 0;
	}
}
</style>
