<template>
	<div>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<hr />
		<div class="md-layout" v-if="available">
			<md-card class="padding">
				<md-table v-model="queriedData" md-card @md-selected="onSelect">
					<md-table-toolbar>
						<h1 class="md-title">Applicants</h1>
						<div>
							<md-field>
								<md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Search" v-model="searchData"> </md-input>
							</md-field>
						</div>

						<div>
							<md-button class="md-info button" @click="filterDetails()">Filter</md-button>
						</div>
					</md-table-toolbar>

					<md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple">
						<md-table-cell md-label="First Name" md-sort-by="profile.name">{{ item.profile.name }}</md-table-cell>
						<md-table-cell md-label="Surname" md-sort-by="profile.surname">{{ item.profile.surname }}</md-table-cell>
						<md-table-cell md-label="Year of Study">{{ item.selectDegree.year }}</md-table-cell>
						<md-table-cell md-label="Degree"> {{ item.selectDegree.degree }}</md-table-cell>
						<md-table-cell md-label="Status">
							<div :class="getChipClass(item.applicationStatus)">
								{{ item.applicationStatus }}
							</div>
						</md-table-cell>

						<md-table-cell>
							<md-button @click="onViewProfile(item)" class="view_profile"> View Profile </md-button>
							<md-button class="btn-next md-info button" style="margin-left: 1rem" @click="onSendOffer(item)">Send Offer</md-button>
						</md-table-cell>

						<md-table-cell>
							<div @click="setFavourite(item)">
								<div v-if="item.favourite">
									<md-icon style="color: #e2c800">star</md-icon>
								</div>
								<div v-if="!item.favourite">
									<md-icon>star_border</md-icon>
								</div>
							</div>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</md-card>
		</div>

		<modal v-if="profileModalVisible" @close="profileModalHide">
			<template slot="header">
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="profileModalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<CandidateProfile :alias="currentProfile.alias" />
			</template>

			<template slot="footer">
				<!-- action button here -->
			</template>
		</modal>
		<send-offer-modal
			:visible="showSendOfferModal"
			:employerCompany="employerCompany"
			:industry="industry"
			:companySize="companySize"
			:jobName="jobName"
			:jobId="jobId"
			:jobType="jobType"
			:profile="currentProfile"
			@close-send-offer="hideSendOfferModal"
			@onOfferSent="afterOfferSent"
		>
		</send-offer-modal>
		<invite-modal
			:getShowModalAction="this.getShowModalAction"
			:selected="selected"
			:employerEmail="employerEmail"
			:employerName="employerName"
			:employerCompany="employerCompany"
			:jobName="jobName"
			:jobId="jobId"
			:jobType="jobType"
			:industry="industry"
			:companySize="companySize"
			@refresh="this.refresh"
		></invite-modal>
		<div v-if="!available && !approved">
			<br /><br />
			<h2 class="black centre">{{ feedback }}</h2>
		</div>
		<div v-if="showFilter">
			<application-filter-selection
				@closeModal="x => (showFilter = x)"
				@setFilterFavourite="setFilterFavourite"
				@setQualification="setQualification"
				@setGender="setGender"
				@setUniversities="setUniversities"
				@setExtracirricularActivities="setExtracirricularActivities"
				@setApplicationStatus="setApplicationStatus"
				@setEthnicity="setEthnicity"
				@setAge="setAge"
				@resetFilters="resetFilters"
				:userGender="this.gender"
				:userFavourite="this.favourite === true ? 'yes' : this.favourite === false ? 'no' : null"
				:userQualification="this.qualification"
				:userAgeRange="this.ageRange"
				:userEthnicity="this.ethnicity"
				:universities="this.institutions"
			></application-filter-selection>
		</div>
	</div>
</template>

<script>
import db from '@/firebase/init';

import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';

import 'firebase/compat/firestore';

import 'firebase/compat/storage';

import { Modal } from '@/components';
import SendOfferModal from './SendOfferModal.vue';
import InviteModal from './InviteModal.vue';
import CandidateProfile from '@/components/Profile/CandidateProfile.vue';
import ApplicationFilterSelection from '@/components/Modals/ApplicationFilterSelection.vue';

export default {
	components: { Modal, CandidateProfile, InviteModal, SendOfferModal, ApplicationFilterSelection },
	data() {
		return {
			searchData: '',
			resetData: false,
			qualification: null,
			favourite: null,
			ageRange: null,
			gender: null,
			showFilter: false,
			applicants: [],
			currentProfile: null,
			available: false,
			approved: false,
			selected: [],
			profileModalVisible: false,
			index: null,
			loading: true,
			feedback: 'Please be patient, students will start applying soon',
			cardUserImage: '/img/dashboard/client/card-1.jpg',
			showInviteModal: () => {},
			jobName: null,
			jobId: null,
			jobType: null,
			employerCompany: null,
			employerName: null,
			employerEmail: null,
			showSendOfferModal: false,
			industry: null,
			companySize: null,
			currentYear: new Date().getFullYear(),
			institutions: [],
			university: [],
			activities: [],
			ethnicity: [],
			applicationStatus: [],
		};
	},
	computed: {
		queriedData() {
			let result = this.applicants;
			if (this.searchData.length > 0) {
				result = result.filter(applicant => {
					const name = applicant.profile.name.toLowerCase();
					const surname = applicant.profile.surname.toLowerCase();
					const keyword = this.searchData.toLowerCase();
					const degreeSearch = this.searchDegrees(applicant, keyword);
					const yearOfStudy = this.searchYearOfStudy(applicant, keyword);
					return yearOfStudy.length || name.includes(keyword) || surname.includes(keyword) || degreeSearch.length;
				});
			}

			if (
				this.getFavouriteData != null ||
				this.getGenderData != null ||
				this.getQualificationData != null ||
				this.getAgeData != null ||
				this.getUniversity.length != 0 ||
				this.getExtracirricularActivities.length != 0 ||
				this.getEthnicity.length != 0 ||
				this.getApplicationStatus.length != 0
			) {
				result = result.filter(applicant => {
					const qualificationCategory = !this.getQualificationData || this.getQualificationCategory(applicant).includes(this.getQualificationData);
					const genderCategory = !this.getGenderData || this.getGenderData === applicant.profile.gender;
					const favouriteCategory = this.getFavouriteData === null || this.getFavouriteData === applicant.favourite;
					const ethnicityCategory = !this.getEthnicity.length || this.getEthnicity.includes(applicant.profile.race.trim());
					const ageCategory = !this.getAgeData || this.ageRangeCalculation(this.getAgeData, applicant);
					const universityCategory =
						!this.getUniversity.length ||
						this.getUniversity.filter(item => {
							return this.getUniversityList(applicant).includes(item);
						});
					const applicationStatus = !this.getApplicationStatus.length || this.getApplicationStatus.includes(applicant.applicationStatus);
					const activitiesCategory =
						this.getExtracirricularActivities.length === 0 ||
						this.getExtracirricularActivities.filter(item => {
							if (Object.hasOwn(applicant.profile, 'extraCirricularActivities')) {
								return applicant.profile.extraCirricularActivities.includes(item);
							}
						});
					return (
						activitiesCategory.length != 0 &&
						ethnicityCategory &&
						universityCategory.length != 0 &&
						favouriteCategory &&
						genderCategory &&
						qualificationCategory &&
						ageCategory &&
						applicationStatus
					);
				});
			}
			return result;
		},
		getGenderData() {
			return this.gender;
		},
		getFavouriteData() {
			return this.favourite;
		},
		getQualificationData() {
			return this.qualification;
		},
		getAgeData() {
			return this.ageRange;
		},
		getUniversity() {
			return this.university;
		},
		getExtracirricularActivities() {
			return this.activities;
		},
		getEthnicity() {
			return this.ethnicity;
		},
		getApplicationStatus() {
			return this.applicationStatus;
		},
	},
	methods: {
		reset() {
			this.resetData = !this.resetData;
		},
		resetFilters(data) {
			this.resetData = data;
			this.gender = null;
			this.favourite = null;
			this.ageRange = null;
			this.qualification = null;
			this.applicationStatus = [];
			this.ethnicity = [];
			this.activities = [];
			this.university = [];
		},
		searchDegrees(applicant, keyword) {
			const degrees = this.getDegrees(applicant);
			let degreeList = degrees.filter(degree => {
				if (degree.includes(keyword)) return degree;
			});
			return degreeList;
		},
		searchYearOfStudy(applicant, keyword) {
			const yearOfStudy = this.getYearOfStudy(applicant);
			let yearOfStudyList = yearOfStudy.filter(year => {
				if (year.includes(keyword)) return year;
			});
			return yearOfStudyList;
		},
		ageRangeCalculation(ageRange, applicant) {
			if (ageRange) {
				let ageRangeList = ageRange.trim().split('-');
				if (ageRange) {
					let profileYear = new Date(applicant.profile.dateOfBirth).getFullYear();
					let currentAge = this.currentYear - profileYear;
					if (currentAge >= ageRangeList[0] && currentAge <= ageRangeList[1]) {
						return applicant;
					}
				}
			}
		},
		setAge(ageRange) {
			this.ageRange = ageRange;
		},
		setGender(gender) {
			this.gender = gender;
		},
		setFilterFavourite(favourite) {
			this.favourite = favourite;
		},
		setQualification(qualification) {
			this.qualification = qualification;
		},
		setUniversities(university) {
			this.university = university;
		},
		setExtracirricularActivities(activities) {
			this.activities = activities;
		},
		setEthnicity(ethnicity) {
			this.ethnicity = ethnicity;
		},
		setApplicationStatus(applicationStatus) {
			this.applicationStatus = applicationStatus;
		},
		filterDetails() {
			this.showFilter = true;
		},
		async setFavourite(item) {
			await db.collection('applications').doc(item.id).update({
				favourite: !item.favourite,
			});
			this.applicants = [];
			this.getApplicants();
		},
		getChipClass(item) {
			if (item === 'Applied') {
				return { applied: true };
			}

			if (item === 'Offer Accepted') {
				return { offerAccepted: true };
			}

			if (item === 'Offer Rejected') {
				return { offerRejected: true };
			}

			if (item === 'Withdrawn') {
				return { withdrawn: true };
			}

			if (item === 'Offer Sent') {
				return { offerSent: true };
			}

			if (item === 'Interview Request Sent') {
				return { interviewRequest: true };
			}
		},
		async reload() {
			location.reload();
		},
		async profile(alias) {
			let student = {};
			const studentDoc = await db.collection('students').doc(alias).get();
			student = studentDoc.data();
			const usersDoc = await db.collection('users').doc(alias).get();
			student.name = usersDoc.data().name;
			student.surname = usersDoc.data().surname;
			student.email = usersDoc.data().email;
			student.phone = usersDoc.data().phone;
			return student;
		},
		onSelect(items) {
			this.selected = items;
			this.$emit('selected', this.selected);
		},
		onInvite() {
			this.showInviteModal(true);
		},
		onViewProfile(profile) {
			this.currentProfile = profile;
			this.profileModalVisible = true;
		},
		onSendOffer(profile) {
			this.currentProfile = profile;
			this.showSendOfferModal = true;
		},
		hideSendOfferModal() {
			this.showSendOfferModal = false;
		},
		profileModalHide() {
			this.profileModalVisible = false;
			this.currentProfile = null;
		},
		getShowModalAction(cb) {
			this.showInviteModal = (visible = true) => {
				cb(visible);
			};
		},
		afterOfferSent() {
			this.showSendOfferModal = false;
			this.refresh();
		},
		refresh() {
			this.applicants = [];
			this.fetchData();
		},
		getDegrees(applicant) {
			let degreelist = [];
			for (const degree of applicant.universities) {
				if (degree.degree && degree.degree != undefined) {
					degreelist.push(degree.degree.toLowerCase());
				}
			}
			return degreelist;
		},
		getYearOfStudy(applicant) {
			let yearOfStudyList = [];
			for (const degree of applicant.universities) {
				if (degree.year && degree.year != undefined) {
					yearOfStudyList.push(degree.year.toLowerCase());
				}
			}
			return yearOfStudyList;
		},
		getUniversityList(applicant) {
			let universityList = [];
			for (const qualification of applicant.universities) {
				if (qualification.institution != null && qualification.institution != undefined) {
					universityList.push(qualification.institution);
				}
			}
			return universityList;
		},
		getQualificationCategory(applicant) {
			let qualificationCategoryList = [];
			for (const qualification of applicant.universities) {
				if (qualification.graduateStatus != null && qualification.graduateStatus != undefined) {
					qualificationCategoryList.push(qualification.graduateStatus);
				}
			}
			return qualificationCategoryList;
		},
		async getApplicants() {
			const snapshot = await db.collection('applications').where('jobId', '==', this.$route.params.id).get();

			for (const doc of snapshot.docs) {
				let applicant;
				this.available = true;
				applicant = doc.data();
				applicant.id = doc.id;
				applicant.universities = await this.getStudentUniversityData(applicant);
				applicant.selectDegree = this.getDataUniversityList(applicant.universities);
				applicant.profile = await this.profile(applicant.alias);
				if (!applicant?.favourite) applicant.favourite = false;
				this.applicants.push(applicant);
			}

			this.applicants.sort((a, b) => b.favourite - a.favourite);
		},
		async getUniversityData() {
			const university = await db.collection('Settings/Institutions/Names').get();
			university.forEach(doc => {
				this.institutions.push(doc.data().name);
			});
		},
		async getStudentUniversityData(profile) {
			const studentQualifications = await db.collection('students').doc(profile.alias).collection('qualifications').get();
			let studentUniversityList = studentQualifications.docs.map(doc => doc.data());
			return studentUniversityList;
		},
		getDataUniversityList(studentUniversityList) {
			let postGradDegrees = studentUniversityList.filter(x => x.graduateStatus === 'Postgraduate');
			let underGradDegrees = studentUniversityList.filter(x => x.graduateStatus === 'Undergraduate');

			switch (true) {
				case postGradDegrees.length === 1 && !underGradDegrees.length:
					return postGradDegrees[0];
				case underGradDegrees.length === 1 && !postGradDegrees.length:
					return underGradDegrees[0];
				case postGradDegrees.length > 1 && underGradDegrees.length > 1:
					return postGradDegrees.reduce((a, b) => (a.endDate < b.endDate ? a : b));
				case !postGradDegrees.length && underGradDegrees.length > 1:
					return underGradDegrees.reduce((a, b) => (a.endDate < b.endDate ? a : b));
				default:
					return { degree: '', year: '' };
			}
		},
		async fetchData() {
			this.getUniversityData();
			let task_project = db.collection('micros');
			this.registerOnInvite(() => {
				this.onInvite();
			});
			const jobs = await task_project.where('jobId', '==', this.$route.params.id).get();
			jobs.forEach(async doc => {
				const job = doc.data();
				this.jobId = doc.id;
				this.jobName = job.name;
				this.jobType = job.jobType;
				const users = await db.collection('users').where('userId', '==', firebase.auth().currentUser.uid).get();

				users.forEach(doc => {
					const userData = doc.data();
					let d = db.collection('clients').doc(doc.id);
					d.get().then(doc => {
						this.companySize = doc.data().companySize;
						this.industry = doc.data().industry;
					});

					if (job.clientAlias !== userData.alias) {
						// To do: create a 404 page or component
						this.$router.go(-1);
					} else {
						this.loading = false;
						this.employerCompany = job.companyName;
						this.employerEmail = job.clientEmail;
						this.employerName = userData.name + ' ' + userData.surname;
					}
				});
			});

			this.getApplicants();
			this.loading = false;
		},
	},
	async mounted() {
		await this.fetchData();
		this.$emit('applicants', this.applicants);
	},
};
</script>

<style scoped>
.centre {
	text-align: center;
	margin: auto;
}

/* Pop up modal */

.modal-container {
	margin: -100px auto;
}

.small-font {
	font-size: small;
}

.large-font {
	font-size: large;
}

.left {
	text-align: left;
}

.avatar {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}

.radius {
	border-radius: 100px;
}

.red {
	color: red;
	font-weight: bold;
}

.md-button {
	margin: 0;
	padding: 5px;
	height: 30px !important;
	min-width: 27px;
	width: 140px !important;
	line-height: 27px;
}

.md-table >>> .md-table-head {
	background-color: #64a151 !important;
}

.md-table >>> .md-table-head-label {
	color: rgb(255, 255, 255) !important;
}

.md-table >>> .md-checkbox-container {
	background-color: white !important;
}

.view_profile {
	background-color: #ff5722 !important;
}
.statusColor {
	background-color: green;
}
.offerAccepted {
	background-color: #ccffcc;
	border: 2px #99ff99 solid;
	border-radius: 25px;
	display: inline;
	line-height: 50px;
	padding: 10px 15px;
}
.offerRejected {
	background-color: #ffcccc;
	border: 2px #ffb3b3 solid;
	border-radius: 25px;
	display: inline;
	line-height: 50px;
	padding: 10px 15px;
}
.withdrawn {
	background-color: #ffffcc;
	border: 2px #ffff99 solid;
	border-radius: 25px;
	display: inline;
	line-height: 50px;
	padding: 10px 15px;
}
.offerSent {
	background-color: #ccffff;
	border: 2px #99ffff solid;
	border-radius: 25px;
	display: inline;
	line-height: 50px;
	padding: 10px 15px;
}
.interviewRequest {
	background-color: #ccccff;
	border: 2px #b3b3ff solid;
	border-radius: 25px;
	display: inline;
	line-height: 50px;
	padding: 10px 15px;
}
.applied {
	display: inline;
	line-height: 50px;
	border-radius: 25px;
	padding: 10px 15px;
	background-color: #ffe6ff;
	/* background-color: var(--bg-color); */
	border: 2px #ffccff solid;
}
</style>
