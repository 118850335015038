import { render, staticRenderFns } from "./Feedback.vue?vue&type=template&id=272a4cba&scoped=true&"
import script from "./Feedback.vue?vue&type=script&lang=js&"
export * from "./Feedback.vue?vue&type=script&lang=js&"
import style0 from "./Feedback.vue?vue&type=style&index=0&id=272a4cba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272a4cba",
  null
  
)

export default component.exports