export const jobTypes = {
	'Once-off Project/Task': ['deadline', 'applicationDeadline'],
	Internship: ['daysOfTheWeek', 'hours', 'applicationDeadline'],
	'Part-time': ['daysOfTheWeek', 'hours', 'applicationDeadline'],
	Learnership: ['daysOfTheWeek', 'applicationDeadline'],
	Graduate: ['daysOfTheWeek', 'applicationDeadline'],
	'Work Integrated Learning (WIL)': ['daysOfTheWeek', 'applicationDeadline'],
};

export const employmentTypes = {
	ONCE_OFF_PROJECT: 'Once-off Project/Task',
	INTERNSHIP: 'Internship',
	PART_TIME: 'Part-time',
	LEARNERSHIP: 'Learnership',
	GRADUATE: 'Graduate',
	WIL: 'Work Integrated Learning (WIL)',
};
