export const Activities = [
	{
		id: 'GOLDEN_KEY',
		value: 'Golden Key Society',
	},
	{
		id: 'LEADERSHIP_SOCIETY',
		value: 'Leadership in a Society (ie SRC, Faculty House, Executive or General Committee)',
	},
	{
		id: 'UNIVERSITY_SPORTS',
		value: 'University Sports Team',
	},
	{
		id: 'PARTTIME_WORK',
		value: 'Part-Time Work (i.e Tutoring, Bartending, Freelancing, Teaching Assistant)',
	},
	{
		id: 'COMMUNITY_ENGAGEMENT',
		value: 'Community Engagement & Volunteering',
	},
	{
		id: 'MENTORING',
		value: 'Mentoring',
	},
	{
		id: 'OTHER_ACTIVITIES',
		value: 'Other University Activities (i.e Campus Radio, University Choir)',
	},
];
