<template>
	<div class="content">
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<h2 class="centre">Congratulations on being hired</h2>
		<p class="centre">The employer will be in touch with you with the next steps</p>
		<client-status-profile
			:companyName="this.client.companyName"
			:clientName="this.client.clientName"
			:clientAlias="client.clientAlias"
			:name="this.client.name"
			:picture="this.picture"
			:cardUserImage="cardUserImage"
			:applicationStatus="status"
		/>
	</div>
</template>

<script>
import firebase from 'firebase/compat/app';
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import ClientStatusProfile from '@/components/Profile/ClientStatusProfile.vue';

export default {
	data() {
		return {
			client: {},
			loading: true,
			picture: null,
		};
	},
	components: {
		ClientStatusProfile,
	},
	props: {
		cardUserImage: {
			type: String,
			default: '/img/dashboard/client/card-1.jpg',
		},
		status: { type: String },
	},
	created() {
		let job = db.collection('micros').doc(this.$route.params.id);
		job.get().then(doc => {
			this.client = doc.data();
			this.client.id = doc.id;
			db.collection('clients')
				.doc(this.client.clientAlias)
				.get()
				.then(doc => {
					this.picture = doc.data().profile;
				});
		});
		this.loading = false;
	},
};
</script>
<style scoped>
.centre {
	text-align: center;
}
.md-layout,
.md-layout-item {
	width: 40%;
	margin: auto;
}
/* Mobile View */
@media only screen and (max-width: 768px) {
	.md-layout,
	.md-layout-item {
		width: 100%;
		margin: auto;
	}
}
</style>
