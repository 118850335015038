<template>
	<div class="container">
		<div v-if="loading" class="background">
			<div v-if="loading" class="text-center lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>
		<h2 style="text-align: center">📝 Vetting Tests</h2>
		<a style="text-align: center" href="https://intercom.help/jobox-64fe20407ade/en/articles/9064938-introducing-jobox-s-new-vetting-process" target="_blank"
			>Click here for a full overview of our vetting process</a
		>
		<md-table class="tests-table">
			<md-table-row>
				<md-table-head class="test">🎯 Skills</md-table-head>
				<md-table-head style="text-align: right"></md-table-head>
			</md-table-row>

			<md-table-row v-for="[key, value] in writtenSkillTests" :key="`written-${key}`">
				<template v-if="value.testStatus !== ApplicationStatus.IN_PROGRESS">
					<md-table-cell>{{ value.skill }}</md-table-cell>
					<md-table-cell class="tests-table-cell">
						<div class="status-container">
							<div class="status-box">
								<p class="status-text md-success">{{ value.testStatus }}</p>
							</div>
						</div>
					</md-table-cell>
				</template>
			</md-table-row>
			<md-table-row v-for="(skill, index) in skillTestsToWrite" :key="`toWrite-${skill.id || index}`">
				<md-table-cell>{{ skill.skillName }}</md-table-cell>
				<md-table-cell class="tests-table-cell">
					<template v-if="testStatuses[skill.skillName] !== 'Disqualified'">
						<md-button @click="viewTest(skill.formId, skill.skillName)" :class="[buttonClass(skill)]" style="width: fit-content">
							<span v-if="testStatuses[skill.skillName]">
								{{ testStatuses[skill.skillName] }}
							</span>
							<span v-else>Loading...</span>
						</md-button>
					</template>
					<template v-else>
						<span class="status-text disqualified">Disqualified</span>
					</template>
				</md-table-cell>
			</md-table-row>
		</md-table>
		<modal v-if="error">
			<template #header>
				<h4 class="modal-title black">Whoa there! 🤚</h4>
			</template>
			<template #body>
				<h4>{{ errorMessage }}</h4>
			</template>
			<template #footer>
				<md-button class="md-success" @click="closeErrorModal"> Ok </md-button>
			</template>
		</modal>
	</div>
</template>
<script>
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { ApplicationStatus } from '../../../../../constants/application-status';
import { logSystemError } from '@/helpers/error.helper';
import Modal from '@/components/Modal.vue';
import { errorMessages } from '@/constants/messages.js';
export default {
	components: {
		Modal,
	},
	data() {
		return {
			ApplicationStatus,
			jobSkills: [],
			studentAcquiredSkills: new Map(),
			jobId: this.$route.params.jobId,
			skillTestsToWrite: [],
			passedTestsCount: 0,
			writtenSkillTests: new Map(),
			acquiredSkills: [],
			acquiredSkillsCount: 0,
			alias: null,
			loading: false,
			testTime: 600,
			errorMessage: '',
			error: false,
			startTime: null,
			showErrorModal: false,
			timedOutSkill: [],
			testStatuses: {},
		};
	},
	async created() {
		await this.getData();
		await this.loadTestStatuses();
		this.filterSkillTestsToWrite();

		if (this.skillTestsToWrite.length === 0) {
			this.navigateToResults();
		}
	},

	computed: {
		buttonClass() {
			return skill => {
				const status = this.testStatuses[skill.skillName];
				return status === 'Continue Test' ? 'continue-test-button' : status === 'Timed Out' ? 'timeout-test-button' : 'md-success';
			};
		},
	},
	methods: {
		filterSkillTestsToWrite() {
			this.skillTestsToWrite = this.skillTestsToWrite.filter(skill => {
				const status = this.testStatuses[skill.skillName];
				return status !== 'Disqualified';
			});
		},
		async getData() {
			this.loading = true;
			const currentUserId = firebase.auth().currentUser.uid;
			try {
				const userQuerySnapshot = await db.collection('users').where('userId', '==', currentUserId).get();
				const userDoc = userQuerySnapshot.docs[0];
				this.alias = userDoc.data().alias;

				const studentRef = db.collection('students').doc(this.alias);
				const studentSkills = await studentRef.collection('skills').get();
				studentSkills.docs.map(doc => this.studentAcquiredSkills.set(doc.id.trim().toLowerCase(), doc.data()));

				const vettingTests = await db.collection('vettingTests').where('jobAlias', '==', this.jobId).where('userId', '==', currentUserId).get();
				vettingTests.docs.map(doc => this.writtenSkillTests.set(doc.id.trim().toLowerCase(), doc.data()));

				const skills = await db.collection('skills').doc(this.jobId).get();
				if (skills.exists) {
					this.jobSkills = skills.data();
				}

				this.checkRemainingTests();

				if (this.skillTestsToWrite.length === 0) {
					this.navigateToResults();
				}
			} catch (e) {
				await logSystemError(this.alias, null, firebase.auth().currentUser.uid, 'VettingTests.vue', e);
				console.error('Error fetching data.');
			} finally {
				this.loading = false;
			}
		},
		getEntriesBySkill(set, skill) {
			for (const item of set.values()) {
				if (item.skill === skill) {
					return new Date(item.created.seconds * 1000 + item.created.nanoseconds / 1000000);
				}
			}
		},
		closeErrorModal() {
			this.error = false;
			this.getData();
			window.location.reload();
		},
		async getTestStatuses(skill, formId) {
			const testId = `${this.alias}-${this.$route.params.jobId}-${formId}`;
			const vettingTestSnapshot = await db.collection('vettingTests').doc(testId).get();
			const cachedTestSnapshot = await db.collection('cachedTests').doc(testId).get();

			if (!vettingTestSnapshot.exists) {
				return 'Take Test';
			}

			if (!cachedTestSnapshot.exists) {
				return 'Disqualified';
			}

			const cachedTestData = cachedTestSnapshot.data();
			const skillName = skill.skillName.trim().toLowerCase();
			const storedData = cachedTestData.skillData;

			if (storedData && skillName === storedData.skill && storedData.timmer) {
				return 'Continue Test';
			}
			if (this.timedOutSkill.includes(skillName)) {
				return 'Timed Out';
			}

			return 'Take Test';
		},
		async loadTestStatuses() {
			const statusPromises = this.skillTestsToWrite.map(skill =>
				this.getTestStatuses(skill, skill.formId).then(status => ({
					skillName: skill.skillName,
					status: status,
				}))
			);

			const statuses = await Promise.all(statusPromises);

			statuses.forEach(({ skillName, status }) => {
				this.$set(this.testStatuses, skillName, status);
			});
		},
		checkRemainingTests() {
			const currentJobId = this.$route.params.jobId;
			this.acquiredSkillsCount = 0;
			this.skillTestsToWrite = [];
			this.jobSkills.skills.forEach(skill => {
				const studentSkill = this.studentAcquiredSkills.get(skill.skillName.trim().toLowerCase());
				if (studentSkill) {
					const acquiredJobSkills = studentSkill.testResults.some(testResult => testResult.pass);
					if (acquiredJobSkills) {
						this.acquiredSkillsCount++;
					}

					const completedOrPassedTest = studentSkill.testResults.some(
						testResult => testResult.jobId === currentJobId || (testResult.jobId !== currentJobId && testResult.pass)
					);
					if (!completedOrPassedTest) {
						this.skillTestsToWrite.push(skill);
					}
				} else {
					this.skillTestsToWrite.push(skill);
				}
			});
		},
		async viewTest(formId, skill) {
			if (!formId) {
				this.errorMessage = errorMessages.TEST_NOT_FOUND;
				this.showErrorModal = true;
				return;
			}
			const testId = `${this.alias}-${this.$route.params.jobId}-${formId}`;
			const skillName = skill.trim().toLowerCase();
			const skillItem = `${skillName}-${this.jobId}`;

			try {
				const cachedTestSnapshot = await db.collection('cachedTests').doc(testId).get();
				let shouldNavigate = true;
				if (cachedTestSnapshot.exists) {
					const cachedTestData = cachedTestSnapshot.data();
					const storedData = cachedTestData.skillData;

					if (storedData) {
						if (skillName === storedData.skill) {
							this.startTime = this.getEntriesBySkill(this.writtenSkillTests, skill);
							const timeDifference = (new Date() - this.startTime) / 1000;

							if (timeDifference > this.testTime) {
								this.error = true;
								this.errorMessage = 'Oops! Unfortunately, the test timed out.';
								localStorage.removeItem(skillItem);
								this.timedOutSkill.push(skillName);
								await this.submitQuiz(ApplicationStatus.TIMED_OUT, skill, formId);
								shouldNavigate = false;
							}
						}
					}
				}
				if (shouldNavigate) {
					const test = await db.collection('vettingTests').doc(testId).get();
					const testParams = { jobId: this.jobId, formId: formId, skillName: skill };

					this.$router.push({
						name: test.exists ? 'vetting-test' : 'vetting-test-information',
						params: testParams,
					});
				}
			} catch (e) {
				await logSystemError(this.alias, null, firebase.auth().currentUser.uid, 'VettingTests.vue', e);
				console.error('Error accessing document.', e);
			}
		},
		async submitQuiz(testStatus, skill, formId) {
			try {
				const docId = `${this.alias}-${this.jobId}-${formId}`;
				const scoreVettingTest = firebase.functions().httpsCallable('scoreVettingTest');
				await scoreVettingTest({
					userAlias: this.alias,
					skill: skill,
					jobId: this.jobId,
					vettingTestId: docId,
					answers: [],
					vettingTestStatus: testStatus,
				});
				await db.collection('cachedTests').doc(docId).delete();
			} catch (error) {
				this.error = true;
				this.errorMessage = 'There was an error submitting the test. Please contact Jobox Support for assistance.';
			}
		},
		navigateToResults() {
			this.$router.push({
				name: 'vetting-test-results',
				params: { jobId: this.jobId },
			});
		},
	},
};
</script>

<style scoped>
.md-table-head-label {
	font-size: 5rem !important;
}
.container {
	width: 100%;
	max-width: 1200px;
	margin: 40px auto;
	padding: 40px;
	background-color: #f9f9f9;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
}
.continue-test-button {
	background-color: #2196f3 !important;
	padding: 0 !important;
}

.timeout-test-button {
	background-color: #d81212 !important;
	padding: 0 !important;
}

.continue-test-button:hover {
	background-color: #1c85db !important;
}

.md-button:active,
.md-button:focus {
	background-color: #2196f3 !important; /* or any color you prefer */
}

.md-success {
	padding: 0 !important;
}
.background {
	width: 100vw !important;
	height: 100vh !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.tests-table {
	width: 70% !important;
	margin: 2rem auto 0 auto !important;
}
.tests-table-cell {
	text-align: right !important;
}
.status-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.status-box {
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.status-text {
	width: fit-content;
	text-align: right;
}
</style>
