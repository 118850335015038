exports.UG_YEAR_OF_STUDY = {
	FIRST_YEAR: '1st Year',
	SECOND_YEAR: '2nd Year',
	THIRD_YEAR: '3rd Year',
	FOURTH_YEAR: '4th Year',
	FIFTH_YEAR: '5th Year',
	GRADUATED: 'Graduated',
};

exports.FUNDING = {
	NSFAS: 'NSFAS',
	BURSARY: 'Bursary',
	STUDENT_LOAN: 'Student Loan',
	SELF_FUNDED: 'Self Funded',
};
