<template>
	<div>
		<questions-taker-vue @bypassRoutingCheck="bypassRoutingCheck" />
		<modal v-if="showModal">
			<template #header>
				<h4 class="modal-title black">Whoa there! 🤚</h4>
			</template>
			<template #body>
				<h4>{{ errorMessage }}</h4>
			</template>
			<template #footer>
				<div class="button-container" style="text-align: center">
					<md-button class="md-button md-danger" @click="leavePage"> Leave</md-button>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<md-button class="md-button md-success" @click="stayOnPage"> Stay</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
import QuestionsTakerVue from '@/components/QuestionsTaker.vue';
import Modal from '@/components/Modal.vue';
export default {
	components: { QuestionsTakerVue, Modal },
	data() {
		return {
			skills: null,
			showModal: false,
			errorMessage: '',
			nextRoute: null,
			routingCheck: false,
		};
	},
	methods: {
		leavePage() {
			this.showModal = false;
			this.nextRoute();
		},
		stayOnPage() {
			this.showModal = false;
		},
		bypassRoutingCheck(value) {
			this.routingCheck = value;
		},
	},
	beforeRouteLeave(to, from, next) {
		if (!this.routingCheck) {
			this.errorMessage = 'Please note! Leaving the page will result in lost work and the test timer will not pause.';
			this.showModal = true;
			this.nextRoute = next;
		} else {
			next();
		}
	},
};
</script>

<style scoped>
.container {
	width: 100%;
	height: 100vh;
	background-color: white;
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.container > h1 {
	text-align: center;
}
.button-container {
	display: flex;
	justify-content: space-between !important;
	align-items: center;
	gap: 10px;
}
</style>
