<template>
	<div>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<div class="md-layout" v-if="activeJobs">
			<div class="card-layout md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33" v-for="job in jobs" :key="job.id">
				<product-card header-animation="false">
					<img class="img" slot="imageHeader" :src="product1" />
					<md-icon slot="fixed-button">build</md-icon>
					<template slot="first-button">
						<md-icon>art_track</md-icon>
						<md-tooltip md-direction="bottom">View</md-tooltip>
					</template>
					<h4 slot="title" class="title">
						{{ job.name }}
					</h4>
					<div slot="description" class="card-description">
						{{ job.category }}
					</div>
					<template slot="footer">
						<div class="price">
							<i class="fas fa-money-bill-wave"></i> budget
							<h4 style="text-align: center">max: {{ job.budget.max }} min: {{ job.budget.min }}</h4>
						</div>
						<div class="price">
							<router-link :to="{ name: 'student-micro-status', params: { id: job.jobId } }">
								<md-button class="md-success">View</md-button>
							</router-link>
						</div>
						<div class="stats">
							<div class="price">
								<md-icon>place</md-icon> Location
								<h4 v-if="job.location !== 'Remote'" style="text-align: center">on-site</h4>
								<h4 v-else style="text-align: center">{{ job.location }}</h4>
							</div>
						</div>
					</template>
				</product-card>
			</div>
		</div>
		<div v-else>
			<h1 class="black" style="text-align: center">You currently have no active jobs</h1>
		</div>
	</div>
</template>

<script>
import { ProductCard } from '@/components';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default {
	components: {
		ProductCard,
	},
	data() {
		return {
			product1: '/img/dashboard/client/card-1.jpg',
			jobs: [],
			activeJobs: false,
			loading: true,
		};
	},
	methods: {
		async getApplicationswithStatusActive(applications, user) {
			let applicationslist = await applications.where('studentId', '==', user.uid).where('status', 'in', ['active', 'complete', 'rate']).get();
			applicationslist.docs.forEach(async doc => {
				this.activeJobs = true;
				let jobs = await db.collection('jobs').doc(doc.data().jobId).get();
				let job = jobs.data();
				let micros = await db.collection('micros').doc(job.jobId).get();
				job.budget = micros.data().budget;
				let skills = await db.collection('skills').doc(job.jobId).get();
				job.category = skills.data().category;
				this.jobs.push(job);
			});
		},
	},
	created() {
		let user = firebase.auth().currentUser;
		let applications = db.collection('applications');
		this.getApplicationswithStatusActive(applications, user);
		this.loading = false;
	},
};
</script>
