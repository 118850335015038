import db from '@/firebase/init';
import moment from 'moment';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default {
	state: {
		modal: false,
		loading: false,
		user: {},
	},
	mutations: {
		updateJobDetails: (state, payload) => {
			db.collection('jobs')
				.doc(payload.id)
				.get()
				.then(doc => {
					if (doc.exists) {
						// step 1
						if (payload.name) {
							db.collection('jobs')
								.doc(payload.id)
								.update({
									name: payload.name,
									lastModified: moment(Date.now()).format('L'),
								});
						}
						if (payload.description) {
							db.collection('micros')
								.doc(payload.id)
								.update({
									description: payload.description,
									lastModified: moment(Date.now()).format('L'),
								});
						}
						if (payload.jobType) {
							db.collection('jobs')
								.doc(payload.id)
								.update({
									jobType: payload.jobType,
									lastModified: moment(Date.now()).format('L'),
								});
						}

						if (payload.programme) {
							db.collection('jobs')
								.doc(payload.id)
								.update({
									programme: payload.programme,
									lastModified: moment(Date.now()).format('L'),
								});
						}

						if (payload.citizenship) {
							db.collection('micros')
								.doc(payload.id)
								.update({
									citizenship: payload.citizenship,
									lastModified: moment(Date.now()).format('L'),
								});
						}
						if (payload.education) {
							db.collection('jobs')
								.doc(payload.id)
								.update({
									education: payload.education,
									lastModified: moment(Date.now()).format('L'),
								});
						}
						if (payload.experience) {
							db.collection('jobs')
								.doc(payload.id)
								.update({
									experience: payload.experience,
									lastModified: moment(Date.now()).format('L'),
								});
						}
						if (payload.industryCategory) {
							db.collection('skills')
								.doc(payload.id)
								.update({
									industry: payload.industryCategory,
									lastModified: moment(Date.now()).format('L'),
								});
						}
						if (payload.jobCategory) {
							db.collection('skills')
								.doc(payload.id)
								.update({
									category: payload.jobCategory,
									lastModified: moment(Date.now()).format('L'),
								});
						}
						if (payload.skills.length !== 0) {
							db.collection('skills')
								.doc(payload.id)
								.update({
									skills: payload.skills,
									lastModified: moment(Date.now()).format('L'),
								});
						}

						// Step 2
						if (payload.applicationDeadline) {
							db.collection('jobs')
								.doc(payload.id)
								.update({
									applicationDeadline: moment(payload.applicationDeadline).format('L'),
									lastModified: moment(Date.now()).format('L'),
								});
						}
						if (payload.location) {
							db.collection('micros')
								.doc(payload.id)
								.update({
									location: payload.location,
									lastModified: moment(Date.now()).format('L'),
								});
						}
						if (payload.address) {
							db.collection('micros')
								.doc(payload.id)
								.update({
									address: payload.address,
									lastModified: moment(Date.now()).format('L'),
								});
						}
						if (payload.deadline) {
							db.collection('micros')
								.doc(payload.id)
								.update({
									duration: payload.deadline,
									lastModified: moment(Date.now()).format('L'),
								});
						}
						if (payload.daysOfTheWeek) {
							db.collection('micros')
								.doc(payload.id)
								.update({
									daysOfTheWeek: payload.daysOfTheWeek,
									lastModified: moment(Date.now()).format('L'),
								});
							if (payload.jobType !== 'Once-off Project/Task') {
								db.collection('micros')
									.doc(payload.id)
									.update({
										duration: null,
										lastModified: moment(Date.now()).format('L'),
									});
							}
						}
						if (payload.hours) {
							db.collection('micros')
								.doc(payload.id)
								.update({
									workingHours: payload.hours,
									lastModified: moment(Date.now()).format('L'),
								});
							if (payload.jobType !== 'Once-off Project/Task') {
								db.collection('micros')
									.doc(payload.id)
									.update({
										duration: null,
										lastModified: moment(Date.now()).format('L'),
									});
							}
						}

						// Step 3
						if (payload.budget) {
							db.collection('micros')
								.doc(payload.id)
								.update({
									budget: payload.budget,
									total: payload.total,
									lastModified: moment(Date.now()).format('L'),
								});
						}
						// Step 4
						if (payload.additionalSubmissions) {
							const additionalSubmissions = {};

							if (payload.additionalSubmissions.writtenSubmission) {
								additionalSubmissions.writtenSubmission = payload.additionalSubmissions.writtenSubmission;
							}

							if (payload.additionalSubmissions.writtenSubmissionDescription) {
								additionalSubmissions.writtenSubmissionDescription = payload.additionalSubmissions.writtenSubmissionDescription;
							}

							if (payload.additionalSubmissions.videoSubmissionDescription) {
								additionalSubmissions.videoSubmissionDescription = payload.additionalSubmissions.videoSubmissionDescription;
							}

							if (payload.additionalSubmissions.videoSubmission) {
								additionalSubmissions.videoSubmission = payload.additionalSubmissions.videoSubmission;
							}

							db.collection('micros')
								.doc(payload.id)
								.update({
									additionalSubmissions: additionalSubmissions,
									lastModified: moment(Date.now()).format('L'),
								});
						}
					}
					if (doc.exists === false) {
						// console.log("doc does not exist: " + payload.id);
					}
				});
		},
	},
	actions: {
		updateJobDetails: (context, payload) => {
			context.commit('updateJobDetails', payload);
		},
	},
};
