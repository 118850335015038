import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// namespaced compatibility
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyAby5rzBcz7OqryFl2CI6FBDIi5HEr2sS4',
	authDomain: 'jobox-test-fdcd5.firebaseapp.com',
	projectId: 'jobox-test-fdcd5',
	storageBucket: 'jobox-test-fdcd5.appspot.com',
	messagingSenderId: '606949019994',
	appId: '1:606949019994:web:7ac87ebf99e3db1447e363',
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig); //namespaced compatibility
const firebaseApp = initializeApp(firebaseConfig);

export default app.firestore();
export const db = getFirestore(firebaseApp);
