<template>
	<div>
		<SelectField
			:label="'Would you like a video submission?'"
			:options="['Yes', 'No']"
			v-model="videoSubmission"
			:error="getErrorMessage('videoSubmission')"
			@input="addVideoSubmission"
		/>
		<div v-if="videoSubmission === 'Yes'">
			<label :class="getErrorMessage('videoSubmissionDetails') && 'error_message'">Video Submission Details</label>
			<vue-editor style="margin: 1rem 0 3rem 0 !important" v-model="videoSubmissionDetails" @text-change="addVideoSubmissionDetails" />
		</div>
		<SelectField
			:label="'Would you like a written submission?'"
			:options="['Yes', 'No']"
			v-model="writtenSubmission"
			:error="getErrorMessage('writtenSubmission')"
			@input="addWrittenSubmission"
		/>
		<div v-if="writtenSubmission === 'Yes'">
			<label :class="getErrorMessage('writtenSubmissionDetails') && 'error_message'">Written Submission Details</label>
			<vue-editor style="margin: 1rem 0 3rem 0 !important" v-model="writtenSubmissionDetails" @text-change="addWrittenSubmissionDetails" />
		</div>

		<div class="justify-between">
			<md-button class="md-raised" @click="previousStep"><md-icon>arrow_back</md-icon>Previous</md-button>
			<md-button class="md-raised md-primary" @click="nextStep">Next<md-icon>arrow_forward</md-icon></md-button>
		</div>
	</div>
</template>

<script>
import SelectField from '@/components/Inputs/SelectField.vue';
import { VueEditor } from 'vue2-editor';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

export default {
	components: { SelectField, VueEditor },
	data() {
		return {
			videoSubmission: '',
			videoSubmissionDetails: '',
			writtenSubmission: '',
			writtenSubmissionDetails: '',
		};
	},
	setup() {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			videoSubmission: { required },
			videoSubmissionDetails: {
				required: this.videoSubmission === 'Yes' ? required : false,
				minLength: this.videoSubmission === 'Yes' ? minLength(10) : false,
			},
			writtenSubmission: { required },
			writtenSubmissionDetails: {
				required: this.writtenSubmission === 'Yes' ? required : false,
				minLength: this.writtenSubmission === 'Yes' ? minLength(10) : false,
			},
		};
	},
	methods: {
		nextStep() {
			this.$emit('next');
		},
		previousStep() {
			this.$emit('previous', 'additional', 'funding');
		},
		addVideoSubmission() {
			this.$emit('videoSubmission', this.videoSubmission);
		},
		addVideoSubmissionDetails() {
			this.$emit('videoSubmissionDetails', this.videoSubmissionDetails);
		},
		addWrittenSubmission() {
			this.$emit('writtenSubmission', this.writtenSubmission);
		},
		addWrittenSubmissionDetails() {
			this.$emit('writtenSubmissionDetails', this.writtenSubmissionDetails);
		},
		getErrorMessage(field) {
			const fieldObj = this.v$[field];
			return fieldObj.$dirty && fieldObj.$error && fieldObj.required.$message;
		},
	},
};
</script>

<style scoped>
.error {
	color: red;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 10px;
}
</style>
