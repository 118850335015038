<template>
	<form @submit.prevent="sendOffer">
		<modal v-if="visible" @close="hideModal">
			<template slot="header">
				<h4 class="modal-title black">Offer Details</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideModal">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<div class="start_date_container">
					<date-picker name="startDate" :modelValidation="{}" v-model="startDate" :error="getErrorMessage('startDate')"></date-picker>
					<p v-if="getErrorMessage('startDate')">Start Date {{ getErrorMessage('startDate') }}</p>
				</div>
				<text-field name="salary" type="number" label="Salary" iconName="payments" v-model="salary" :error="getErrorMessage('salary')"></text-field>
				<text-field
					name="duration"
					type="number"
					label="Contract Duration (months)"
					iconName="schedule"
					v-model="duration"
					:error="getErrorMessage('duration')"
				></text-field>
			</template>

			<template slot="footer">
				<md-button type="submit" class="md-primary">Send</md-button>
			</template>
		</modal>
		<confirm-modal
			title="Confirmation"
			:message="`Are you sure you want to send an offer to ${profile ? profile.applicant : ''}?`"
			:visible="showConfirmModal"
			@onYesClick="onOfferConfirm"
			@onNoClick="showConfirmModal = false"
		></confirm-modal>
	</form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

import { Modal, DatePicker, TextField, ConfirmModal } from '@/components';
import db from '@/firebase/init';
import { getCurrentDateTime, convertDateToEpoch } from '@utils';
import moment from 'moment';
import MixpanelFunctions from '@/mixpanel/mixpanel';

const startDateValidator = value => {
	const currentTimeStamp = Date.now();
	const chosenTimeStamp = convertDateToEpoch(value);

	return chosenTimeStamp > currentTimeStamp;
};

export default {
	name: 'SendOfferModal',
	components: { Modal, DatePicker, TextField, ConfirmModal },
	props: {
		visible: Boolean,
		profile: Object,
		employerCompany: String,
		industry: String,
		companySize: String,
		jobName: String,
		jobId: String,
		jobType: String,
	},
	data() {
		return {
			startDate: null,
			salary: null,
			duration: null,
			showConfirmModal: false,
		};
	},
	validations() {
		return {
			startDate: { required, startDateValidator: helpers.withMessage('Start date must be in the future', startDateValidator) },
			salary: { required },
			duration: { required },
		};
	},
	setup: () => ({ v$: useVuelidate() }),
	validationConfig: {
		$lazy: true,
		$autoDirty: true,
	},
	methods: {
		hideModal() {
			this.$emit('close-send-offer');
		},
		async sendOffer() {
			const isFormValid = await this.v$.$validate();
			if (isFormValid) {
				this.showConfirmModal = true;
			}
		},
		async onOfferConfirm() {
			this.showConfirmModal = false;
			const { jobId, id: applicantId, alias: userId, applicantEmail: email, clientName: employerName, applicant: studentName } = this.profile;
			const { startDate, salary: monthlySalary, duration } = this;

			const data = {
				startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
				monthlySalary: Number(monthlySalary),
				duration: Number(duration),
				userId,
				email,
				employerName,
				studentName,
				created: getCurrentDateTime(),
			};
			try {
				await db.collection(`jobs/${jobId}/offers`).doc(applicantId).set(data);
				this.updateApplicationStatus(this.profile);
				this.$emit('onOfferSent');
				var offerDetails = {
					university: this.profile.firstDegree.campus,
					faculty: this.profile.firstDegree.faculty,
					yearOfStudy: this.profile.firstDegree.year,
					gender: this.profile.profile.gender,
					ethnicity: this.profile.profile.race,
					emailAddress: this.profile.profile.email,
					citizen: this.profile.profile.citizenship,
					jobId: this.jobId,
					jobType: this.jobType,
					jobName: this.jobName,
					companyIndustry: this.industry,
					companyName: this.employerCompany,
					companySize: this.companySize,
				};
				MixpanelFunctions.sendOffer(offerDetails);
			} catch (err) {
				console.log(err);
			}
		},
		getErrorMessage(field) {
			const fieldObj = this.v$[field];
			return fieldObj.$dirty && fieldObj.$error && fieldObj.required.$message;
		},
		async updateApplicationStatus(item) {
			const applicant = await db.collection('applications').doc(item.id);
			applicant.update({
				applicationStatus: 'Offer Sent',
				approved: true,
			});
		},
	},
};
</script>

<style scoped>
.start_date_container > p {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 10px;
	color: red;
}
</style>
