<template>
	<div>
		<div v-if="loading" class="background" style="display: flex; align-items: center; justify-content: center"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<hr />
		<div class="md-layout" v-if="available">
			<md-card class="padding" id="container">
				<md-table v-model="queriedData" md-card @md-selected="onSelect" id="content">
					<md-table-toolbar>
						<div class="button-section">
							<div>
								<md-field>
									<md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Search" v-model="searchData"> </md-input>
								</md-field>
							</div>
							<div class="btn-submissions btn-center">
								<md-button class="md-info button" @click="filterDetails()">Filter</md-button>
							</div>
							<div class="btn-submissions btn-center">
								<md-button class="md-success" @click="onInvite">Schedule an interview</md-button>
							</div>
							<div v-if="appointments.length" class="btn-submissions btn-center">
								<md-button class="btn-next md-info button" @click="showAppointmentsModal" style="max-width: 155px"> Edit Interview Slots </md-button>
							</div>
						</div>
					</md-table-toolbar>

					<md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple">
						<md-table-cell md-label="Name" md-sort-by="profile.name">{{ item.profile.name }}</md-table-cell>
						<md-table-cell md-label="Surname" md-sort-by="profile.surname">{{ item.profile.surname }}</md-table-cell>
						<md-table-cell md-label="Year of Study">{{ item.firstDegree.year }}</md-table-cell>
						<md-table-cell md-label="Degree"> {{ item.firstDegree.degree }}</md-table-cell>
						<md-table-cell md-label="Status">
							<div :class="getChipClass(item.applicationStatus)">
								{{ item.applicationStatus }}
							</div>
						</md-table-cell>

						<md-table-cell>
							<md-button @click="onViewProfile(item)" class="md-success"> View Profile </md-button>
						</md-table-cell>
						<md-table-cell v-if="isVideoSubmissionQuesition">
							<div class="btn-submissions">
								<md-button class="btn-next md-info" @click="viewVideoSubmission(item)">View Video Submission</md-button>
							</div>
						</md-table-cell>
						<md-table-cell v-if="isWrittenSubmissionQuesition">
							<div class="btn-submissions">
								<md-button class="btn-next md-info button" style="margin-left: 1rem" @click="viewWrittenSubmission(item)">View Written Submission</md-button>
							</div>
						</md-table-cell>

						<md-table-cell>
							<div @click="setFavourite(item)">
								<div v-if="item.favourite">
									<md-icon style="color: #e2c800">star</md-icon>
								</div>
								<div v-else>
									<md-icon>star_border</md-icon>
								</div>
							</div>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</md-card>
		</div>

		<modal v-if="profileModalVisible" @close="profileModalHide">
			<template slot="header">
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="profileModalHide">
					<md-icon style="font-size: 2rem !important; color: black !important">clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<CandidateProfile
					:userProfile="this.getUserProfile"
					@setProfileFavourite="setProfileFavourite"
					:showFavoriteButton="true"
					@profileViewWrittenSubmission="profileViewWrittenSubmission"
					@profileViewVideoSubmission="profileViewVideoSubmission"
					:jobId="this.$route.params.id"
				/>
			</template>

			<template slot="footer">
				<!-- action button here -->
			</template>
		</modal>

		<modal v-if="noSubmission">
			<template slot="header"> {{ this.submissionErrorMessage }}</template>

			<template slot="footer">
				<md-button class="md-danger" @click="closeWrittenSubmissionModal()"> OK </md-button>
			</template>
		</modal>

		<view-submission
			v-if="this.isWrittenSubmission || this.isVideoSubmission"
			:writtenSubmission="this.isWrittenSubmission"
			:isVideoSubmission="this.isVideoSubmission"
			:currentProfile="this.currentProfile"
			@closeViewSubmissionModal="closeViewSubmissionModal"
			@viewProfile="viewProfile"
			:name="currentProfile.profile.name"
			:surname="currentProfile.profile.surname"
		></view-submission>

		<modal v-if="interviewModal">
			<template slot="header">
				<h4 class="modal-title black">Woah there ✋</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="interviewModalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<h4>Please select an applicant using the checkboxes.</h4>
			</template>
		</modal>

		<invite-modal
			v-if="this.visible"
			:selected="selected"
			:employerEmail="employerEmail"
			:employerName="employerName"
			:employerCompany="employerCompany"
			:jobName="jobName"
			:jobId="jobId"
			:jobType="jobType"
			:industry="industry"
			:companySize="companySize"
			:visible="visible"
			@refresh="this.refresh"
		></invite-modal>
		<div v-if="!available && !approved">
			<br /><br />
			<h2 class="black centre">{{ feedback }}</h2>
		</div>
		<div v-if="showFilter">
			<application-filter-selection
				@closeModal="x => (showFilter = x)"
				@setFilterFavourite="setFilterFavourite"
				@setQualification="setQualification"
				@setGender="setGender"
				@setUniversities="setUniversities"
				@setExtracirricularActivities="setExtracirricularActivities"
				@setApplicationStatus="setApplicationStatus"
				@setEthnicity="setEthnicity"
				@setAge="setAge"
				@setLocation="setLocation"
				@setStudentRelocation="setStudentRelocation"
				@resetFilters="resetFilters"
				:userGender="this.gender"
				:userFavourite="this.favourite === true ? 'yes' : this.favourite === false ? 'no' : null"
				:userQualification="this.qualification"
				:userAgeRange="this.ageRange"
				:userEthnicity="this.ethnicity"
				:universities="this.institutions"
				:applicant="applicants"
				:selectedTown="selectedLocation"
				:selectedStudentRelocation="selectedStudentRelocation"
			></application-filter-selection>
		</div>

		<modal v-if="viewAppointments">
			<template slot="header">
				<h4 class="modal-title black">🗓 Your Appointments</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideAppointmentsModal">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<div style="width: 100%; text-align: left">
					<div v-for="(appointment, index) in appointments" :key="index" style="width: 100%; display: flex; align-items: center; justify-content: space-between">
						<p>{{ appointment.name }}</p>
						<div style="display: flex; align-items: center; justify-content: center">
							<div @click="editAppointment(appointment)">
								<md-icon style="color: orange; cursor: pointer">edit</md-icon>
							</div>
							<div @click="deleteAppointment(appointment)">
								<md-icon style="color: red; margin-left: 1rem; cursor: pointer">delete</md-icon>
							</div>
						</div>
					</div>
				</div>
				<md-button @click="hideAppointmentsModal" class="md-success">Okay</md-button>
			</template>
		</modal>
		<edit-appointment :appointment="selectedAppointment" v-if="isEditAppointmentClicked" @hideAppointmentsModal="hideEditAppointmentsModal" />
	</div>
</template>

<script>
import { ApplicationStatus } from '../../../../../../constants/application-status.js';
import db from '@/firebase/init';

import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';

import 'firebase/compat/firestore';

import 'firebase/compat/storage';
import { mapActions } from 'vuex';
import { Modal } from '@/components';
import InviteModal from '@/views/dashboard/client/jobs/status/micro/flow/select/InviteModal.vue';
import CandidateProfile from '@/components/Profile/CandidateProfile.vue';
import ApplicationFilterSelection from '@/components/Modals/ApplicationFilterSelection.vue';
import ViewSubmission from '@/views/dashboard/client/jobs/applications/applicants/ViewSubmission.vue';
import EditAppointment from '@/views/dashboard/client/jobs/status/micro/appointment/EditAppointment.vue';
export default {
	components: { Modal, CandidateProfile, InviteModal, ApplicationFilterSelection, ViewSubmission, EditAppointment },
	data() {
		return {
			selectedStudentRelocation: '',
			selectedLocation: '',
			location: [],
			isEditAppointmentClicked: false,
			selectedAppointment: null,
			appointments: [],
			viewAppointments: false,
			isVideoSubmissionQuesition: false,
			isWrittenSubmissionQuesition: false,
			additionalSubmission: {},
			visible: false,
			videoSubmissionErrorMessage: 'Student has not submitted a video submission yet',
			writtenSubmissionErrorMessage: 'Student has not submitted a written submission yet',
			submissionErrorMessage: null,
			isWrittenSubmission: false,
			isVideoSubmission: false,
			noSubmission: false,
			searchData: '',
			resetData: false,
			qualification: null,
			favourite: null,
			ageRange: null,
			gender: null,
			showFilter: false,
			applicants: [],
			currentProfile: null,
			available: false,
			approved: false,
			selected: [],
			profileModalVisible: false,
			index: null,
			loading: true,
			feedback: 'Please be patient, students will start applying soon',
			cardUserImage: '/img/dashboard/client/card-1.jpg',
			showInviteModal: () => {},
			jobName: null,
			jobId: null,
			jobType: null,
			employerCompany: null,
			employerName: null,
			employerEmail: null,
			showSendOfferModal: false,
			industry: null,
			companySize: null,
			currentYear: new Date().getFullYear(),
			institutions: [],
			university: [],
			activities: [],
			ethnicity: [],
			applicationStatus: [],
			interviewModal: false,
			inviteCallback: null,
			favouriteMap: new Map(),
			clientId: null,
		};
	},
	computed: {
		queriedData() {
			let result = this.applicants;
			if (this.searchData.length > 0) {
				result = result.filter(applicant => {
					const name = applicant.profile.name.toLowerCase();
					const surname = applicant.profile.surname.toLowerCase();
					const keyword = this.searchData.toLowerCase();
					const degreeSearch = this.searchDegrees(applicant, keyword);
					const yearOfStudy = this.searchYearOfStudy(applicant, keyword);
					return yearOfStudy.length || name.includes(keyword) || surname.includes(keyword) || degreeSearch.length;
				});
			}

			if (
				this.getFavouriteData != null ||
				this.getGenderData != null ||
				this.getQualificationData != null ||
				this.getAgeData != null ||
				this.getUniversity.length != 0 ||
				this.getExtracirricularActivities.length != 0 ||
				this.getEthnicity.length != 0 ||
				this.getApplicationStatus.length != 0 ||
				(this.selectedLocation && this.selectedLocation.trim() !== '') ||
				(this.selectedStudentRelocation && this.selectedStudentRelocation.trim() !== '')
			) {
				result = result.filter(applicant => {
					const qualificationCategory = !this.getQualificationData || this.getQualificationCategory(applicant).includes(this.getQualificationData);
					const genderCategory = !this.getGenderData || this.getGenderData === applicant.profile.gender;
					const favouriteCategory = this.getFavouriteData === null || this.getFavouriteData === applicant.favourite;
					const ethnicityCategory = !this.getEthnicity.length || this.getEthnicity.includes(applicant.profile.race.trim());
					const ageCategory = !this.getAgeData || this.ageRangeCalculation(this.getAgeData, applicant);
					const universityCategory = !this.getUniversity.length || this.getUniversity.some(item => this.getUniversityList(applicant).includes(item));
					const applicationStatus = !this.getApplicationStatus.length || this.getApplicationStatus.includes(applicant.applicationStatus);
					const activitiesCategory =
						this.getExtracirricularActivities.length === 0 ||
						this.getExtracirricularActivities.some(item => {
							return Object.hasOwn(applicant.profile, 'extraCirricularActivities') && applicant.profile.extraCirricularActivities.includes(item);
						});
					const locationCategory =
						!this.selectedLocation || (this.selectedLocation.trim() !== '' && applicant.location && applicant.location.town === this.selectedLocation);
					const studentRelocationCategory =
						!this.selectedStudentRelocation || (this.selectedStudentRelocation.trim() !== '' && applicant.relocation === this.selectedStudentRelocation);

					return (
						activitiesCategory &&
						ethnicityCategory &&
						universityCategory &&
						favouriteCategory &&
						genderCategory &&
						qualificationCategory &&
						ageCategory &&
						applicationStatus &&
						locationCategory &&
						studentRelocationCategory
					);
				});
			}
			return result;
		},
		getGenderData() {
			return this.gender;
		},
		getFavouriteData() {
			return this.favourite;
		},
		getQualificationData() {
			return this.qualification;
		},
		getAgeData() {
			return this.ageRange;
		},
		getUniversity() {
			return this.university;
		},
		getExtracirricularActivities() {
			return this.activities;
		},
		getEthnicity() {
			return this.ethnicity;
		},
		getApplicationStatus() {
			return this.applicationStatus;
		},
		getUserProfile() {
			return this.currentProfile;
		},
	},
	methods: {
		hideEditAppointmentsModal(isEditAppointmentClicked) {
			this.isEditAppointmentClicked = isEditAppointmentClicked;
			this.getAppointments();
		},
		async getAppointments() {
			try {
				this.loading = true;
				const jobAppointments = [];
				const appointments = firebase.functions().httpsCallable('getInterviewAppointments');
				const response = await appointments({ externalUserId: firebase.auth().currentUser.uid });
				const currentJobAppointments = response.data.appointments.appointments;

				currentJobAppointments.forEach(appointment => {
					const finalString = appointment.bookingUrl.split('/').pop().split('-').slice(0, -1).join('-');
					if (this.jobId === finalString) {
						jobAppointments.push(appointment);
					}
				});
				this.appointments = jobAppointments;
				this.loading = false;
			} catch (error) {
				this.loading = false;
			}
		},

		async deleteAppointment(appointment) {
			this.loading = true;
			const deleteAppointmentFunction = firebase.functions().httpsCallable('deleteAppointment');
			try {
				const response = await deleteAppointmentFunction({ appointmentId: appointment.id, externalUserId: firebase.auth().currentUser.uid });
				this.loading = false;
				await this.getAppointments();
			} catch (e) {
				console.error('Error deleting appointment:', e);
				this.loading = false;
			}
		},
		closeEditModal(data) {
			this.isEditAppointmentClicked = data;
		},
		editAppointment(appointment) {
			this.selectedAppointment = appointment;
			this.isEditAppointmentClicked = true;
		},
		showAppointmentsModal() {
			this.viewAppointments = true;
			this.getAppointments();
		},
		hideAppointmentsModal() {
			this.viewAppointments = false;
		},
		closeViewSubmissionModal() {
			this.isWrittenSubmission = false;
			this.isVideoSubmission = false;
		},
		viewProfile() {
			this.profileModalVisible = true;
		},
		onInvite() {
			if (!this.selected || this.selected.length < 1) {
				this.interviewModal = true;
			} else {
				this.getShowModalAction(true);
			}
		},
		interviewModalHide() {
			this.interviewModal = false;
		},
		closeWrittenSubmissionModal() {
			this.noSubmission = false;
		},
		closeViewWrittenSubmissionModal() {
			this.isWrittenSubmission = false;
		},
		reset() {
			this.resetData = !this.resetData;
		},
		searchDegrees(applicant, keyword) {
			const degrees = this.getDegrees(applicant);
			let degreeList = degrees.filter(degree => {
				if (degree.includes(keyword)) return degree;
			});
			return degreeList;
		},
		searchYearOfStudy(applicant, keyword) {
			const yearOfStudy = this.getYearOfStudy(applicant);
			let yearOfStudyList = yearOfStudy.filter(year => {
				if (year.includes(keyword)) return year;
			});
			return yearOfStudyList;
		},
		resetFilters(data) {
			this.resetData = data;
			this.gender = null;
			this.favourite = null;
			this.ageRange = null;
			this.qualification = null;
			this.applicationStatus = [];
			this.ethnicity = [];
			this.activities = [];
			this.university = [];
			this.selectedLocation = null;
			this.selectedStudentRelocation = null;
		},
		ageRangeCalculation(ageRange, applicant) {
			if (ageRange != null) {
				let ageRangeList = ageRange.trim().split('-');
				if (ageRange) {
					let profileYear = new Date(applicant.profile.dateOfBirth).getFullYear();
					let currentAge = this.currentYear - profileYear;
					if (currentAge >= ageRangeList[0] && currentAge <= ageRangeList[1]) {
						return applicant;
					}
				}
			}
		},
		setAge(ageRange) {
			this.ageRange = ageRange;
		},
		setStudentRelocation(selectedStudentRelocation) {
			this.selectedStudentRelocation = selectedStudentRelocation;
		},
		setLocation(selectedCity) {
			this.selectedLocation = selectedCity;
		},
		setGender(gender) {
			this.gender = gender;
		},
		setFilterFavourite(favourite) {
			this.favourite = favourite;
		},
		setQualification(qualification) {
			this.qualification = qualification;
		},
		setUniversities(university) {
			this.university = university;
		},
		setExtracirricularActivities(activities) {
			this.activities = activities;
		},
		setEthnicity(ethnicity) {
			this.ethnicity = ethnicity;
		},
		setApplicationStatus(applicationStatus) {
			this.applicationStatus = applicationStatus;
		},
		filterDetails() {
			this.showFilter = true;
		},
		getDegrees(applicant) {
			let degreelist = [];
			for (const degree of applicant.degree) {
				if (degree.degree && degree.degree != undefined) {
					degreelist.push(degree.degree.toLowerCase());
				}
			}
			return degreelist;
		},
		getYearOfStudy(applicant) {
			let yearOfStudyList = [];
			for (const degree of applicant.degree) {
				if (degree.year && degree.year != undefined) {
					yearOfStudyList.push(degree.year.toLowerCase());
				}
			}
			return yearOfStudyList;
		},
		getUniversityList(applicant) {
			let universityList = [];
			for (const qualification of applicant.degree) {
				if (qualification.institution != null && qualification.institution != undefined) {
					universityList.push(qualification.institution);
				}
			}
			return universityList;
		},
		getQualificationCategory(applicant) {
			let qualificationCategoryList = [];
			for (const qualification of applicant.degree) {
				if (qualification.graduateStatus != null && qualification.graduateStatus != undefined) {
					qualificationCategoryList.push(qualification.graduateStatus);
				}
			}
			return qualificationCategoryList;
		},
		async setFavourite(item) {
			await db.collection('applications').doc(item.id).update({
				favourite: !item.favourite,
			});
			item.favourite = !item.favourite;
			this.applicants.sort((a, b) => b.favourite - a.favourite);
		},
		setProfileFavourite(item) {
			this.setFavourite(item);
		},
		getChipClass(item) {
			if (item === 'Applied') {
				return { applied: true };
			}

			if (item === 'Offer Accepted') {
				return { offerAccepted: true };
			}

			if (item === 'Offer Rejected') {
				return { offerRejected: true };
			}

			if (item === 'Withdrawn') {
				return { withdrawn: true };
			}

			if (item === 'Offer Sent') {
				return { offerSent: true };
			}

			if (item === 'Interview Request Sent') {
				return { interviewRequest: true };
			}
		},
		async reload() {
			location.reload();
		},
		async profile(alias) {
			let student = {};
			const studentDoc = await db.collection('students').doc(alias).get();
			if (!studentDoc.exists) {
				return null;
			} else {
				const usersDoc = await db.collection('users').doc(alias).get();
				if (usersDoc.exists && usersDoc.data() !== undefined) {
					student.name = usersDoc.data().name;
					student.surname = usersDoc.data().surname;
					student.email = usersDoc.data().email;
					student.phone = usersDoc.data().phone;
					return student;
				}
			}
		},
		onSelect(items) {
			this.selected = items;
		},
		onViewProfile(profile) {
			this.currentProfile = profile;
			this.profileModalVisible = true;
		},
		profileViewWrittenSubmission(item) {
			this.viewWrittenSubmission(item);
		},
		profileViewVideoSubmission(item) {
			this.viewVideoSubmission(item);
		},
		viewWrittenSubmission(item) {
			if (item.writtenSubmissionUrl) {
				this.isWrittenSubmission = true;
				this.isVideoSubmission = false;
				this.currentProfile = item;
			} else {
				this.noSubmission = true;
				this.submissionErrorMessage = this.writtenSubmissionErrorMessage;
			}
		},
		viewVideoSubmission(item) {
			if (item.videoSubmissionUrl) {
				this.isVideoSubmission = true;
				this.isWrittenSubmission = false;
				this.currentProfile = item;
			} else {
				this.noSubmission = true;
				this.submissionErrorMessage = this.videoSubmissionErrorMessage;
			}
		},
		hideSendOfferModal() {
			this.showSendOfferModal = false;
		},
		profileModalHide() {
			this.profileModalVisible = false;
			this.currentProfile = null;
		},
		getShowModalAction(cb) {
			this.visible = true;
		},
		afterOfferSent() {
			this.showSendOfferModal = false;
			this.refresh();
		},
		refresh() {
			this.applicants = [];
			this.fetchData();
			this.visible = false;
		},
		async getApplicants() {
			try {
				const snapshot = await db.collection('applications').where('jobId', '==', this.$route.params.id).where('status', '==', ApplicationStatus.SHORTLIST).get();
				const applicants = snapshot.docs.map(async doc => {
					let applicant = doc.data();
					applicant.id = doc.id;
					applicant.firstDegree = applicant.degree[0];
					const studentDoc = await db.collection('students').where('userId', '==', applicant.studentId).get();
					if (!studentDoc.empty) {
						const studentData = studentDoc.docs[0].data();
						applicant.location = studentData.location;
						applicant.relocation = studentData.relocation;
					}
					let app = await this.profile(applicant.alias);
					if (app !== null) {
						applicant.profile = app;
						if (!applicant?.favourite) applicant.favourite = false;
						return applicant;
					}
				});
				this.applicants = await Promise.all(applicants);
				this.applicants = this.applicants.filter(applicant => applicant !== undefined);
				this.applicants.sort((a, b) => b.favourite - a.favourite);
				this.available = true;
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		},
		async getUniversityData() {
			const university = await db.collection('Settings/Institutions/Names').get();
			university.forEach(doc => {
				this.institutions.push(doc.data().name);
			});
		},
		async getJobdata() {
			try {
				await this.getUniversityData();

				const taskproject = db.collection('micros');
				const jobsSnapshot = await taskproject.where('jobId', '==', this.$route.params.id).get();

				jobsSnapshot.docs.map(async doc => {
					const job = doc.data();
					this.additionalSubmission = job.additionalSubmissions;
					if (job.additionalSubmissions) {
						this.isVideoSubmissionQuesition = job.additionalSubmissions.videoSubmission === 'Yes';
						this.isWrittenSubmissionQuesition = job.additionalSubmissions.writtenSubmission === 'Yes';
					}
					this.jobId = doc.id;
					this.jobName = job.name;
					this.jobType = job.jobType;
					this.getUserData(job);
				});

				await this.getAppointments();
			} catch (error) {
				console.error('Error fetching job data:', error);
			}
		},
		async getClientData(clientId, job) {
			const clientRef = await db.collection('clients').doc(clientId).get();

			this.clientId = clientRef.id;
			this.companySize = clientRef.data().companySize;
			this.industry = clientRef.data().industry;
			if (job.clientAlias != clientRef.id) {
				// To do: create a 404 page or component
				this.$router.go(-1);
			} else {
				this.loading = false;
				this.employerCompany = job.companyName;
				this.employerEmail = job.clientEmail;
				this.employerName = clientRef.data().name + ' ' + clientRef.data().surname;
			}
		},
		async getUserData(job) {
			const users = await db.collection('users').where('userId', '==', firebase.auth().currentUser.uid).get();
			users.docs.forEach(async doc => {
				const userData = doc.data();

				if (userData.accessType) {
					this.getClientData(userData.clientAlias, job);
				} else {
					this.clientId = doc.id;
					this.getClientData(doc.id, job);
				}
			});
		},
		async fetchData() {
			this.getApplicants();
			this.getJobdata();
			this.loading = false;
		},
	},
	async created() {
		await this.fetchData();
		this.$emit('applicants', this.applicants);
	},
};
</script>

<style scoped>
.button-section.md-title {
	margin: 0px !important;
	width: 10px;
}
.button-section {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	position: inherit;
	align-items: center;
}
.btn-submissions .md-button {
	width: 200px !important;
}
#container {
	width: 100%;
	height: 150%;
	border: 1px solid #ccc;
	overflow-x: auto;
	display: flex;
	position: relative;
	z-index: 1;
	margin: 30px;
}

#content {
	width: 107%;
}
.btn-center {
	display: flex;
	justify-content: space-evenly;
	margin-left: 50px;
}
.centre {
	text-align: center;
	margin: auto;
}

/* Pop up modal */

.modal-container {
	margin: -100px auto;
}

.small-font {
	font-size: small;
}

.large-font {
	font-size: large;
}

.left {
	text-align: left;
}

.avatar {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}

.radius {
	border-radius: 100px;
}

.red {
	color: red;
	font-weight: bold;
}

.md-button {
	margin: 0;
	padding: 5px;
	height: 30px !important;
	min-width: 27px;
	width: 140px !important;
	line-height: 27px;
}

.md-table >>> .md-table-head {
	/* background-color: #64a151 !important; */
	background-color: #ff5722 !important;
}

.md-table >>> .md-table-head-label {
	color: rgb(255, 255, 255) !important;
}

.md-table >>> .md-checkbox-container {
	background-color: white !important;
}
.view_profile {
	background-color: green !important;
}
.statusColor {
	background-color: green;
}
.offerAccepted {
	background-color: #ccffcc;
	border: 2px #99ff99 solid;
	border-radius: 25px;
	display: inline;
	line-height: 50px;
	padding: 10px 15px;
}
.offerRejected {
	background-color: #ffcccc;
	border: 2px #ffb3b3 solid;
	border-radius: 25px;
	display: inline;
	line-height: 50px;
	padding: 10px 15px;
}
.withdrawn {
	background-color: #ffffcc;
	border: 2px #ffff99 solid;
	border-radius: 25px;
	display: inline;
	line-height: 50px;
	padding: 10px 15px;
}
.offerSent {
	background-color: #ccffff;
	border: 2px #99ffff solid;
	border-radius: 25px;
	display: inline;
	line-height: 50px;
	padding: 10px 15px;
}
.interviewRequest {
	background-color: #ccccff;
	border: 2px #b3b3ff solid;
	border-radius: 25px;
	display: inline;
	line-height: 50px;
	padding: 10px 15px;
}
.applied {
	display: inline;
	line-height: 50px;
	border-radius: 25px;
	padding: 10px 15px;
	background-color: #ffe6ff;
	border: 2px #ffccff solid;
}
</style>
