<template>
	<div>
		<br />
		<br />
		<div class="row">
			<md-icon style="margin-right: 1rem" :class="v$.requirements.$error && 'error'">description</md-icon>
			<label :style="v$.requirements.$error && 'color:red'"> Bursary Requirements </label>
		</div>
		<p class="error_message" v-if="v$.requirements.$error">Requirements are required and should be at least 10 characters long.</p>
		<vue-editor style="margin: 1rem 0 3rem 0 !important" v-model="requirements" @text-change="addRequirements" />
		<div style="display: flex; flex-direction: column">
			<div class="row">
				<md-icon :class="v$.documentRequirements.$error && 'error'" style="margin-right: 1rem">folder</md-icon>
				<label :style="v$.documentRequirements.$error && 'color:red'"> Documentation Requirements </label>
			</div>
			<p class="error_message" v-if="v$.documentRequirements.$error">At least one document is required</p>
			<md-checkbox @change="addCv" v-model="cv">CV</md-checkbox>
			<md-checkbox @change="addId" v-model="id">ID</md-checkbox>
			<md-checkbox @change="addProofOfIncome" v-model="proofOfIncome">Proof of Income</md-checkbox>
			<md-checkbox @change="addMatricResults" v-model="matricResults">Matric Results</md-checkbox>
			<md-checkbox @change="addAcademicTranscript" v-model="academicTranscript">Academic Transcript</md-checkbox>
			<md-checkbox @change="addTuitionQuotation" v-model="tuitionQuotation">Tuition Quotation</md-checkbox>
			<md-checkbox @change="addOther" v-model="other">Other</md-checkbox>

			<p v-if="other && additionalDocuments.length === 0" class="error_message">Please add at least one additional document</p>
		</div>
		<p v-if="additionalDocuments.length > 0">Additional Documents</p>
		<div v-for="(doc, index) in additionalDocuments" :key="index" style="display: flex; align-items: center; flex-direction: column; margin-top: 10px; width: 30%">
			<md-field>
				<md-input v-model="doc.name" @change="updateDocument(index, doc.name)" />
				<md-button class="md-icon-button md-raised md-primary" @click="deleteDocument(index)">
					<md-icon>delete</md-icon>
				</md-button>
			</md-field>
		</div>
		<div v-if="other">
			<TextField style="width: 30%" :label="'Additional Document'" v-model="newDocument" />
			<md-button class="md-raised md-primary" @click="addDocument"><md-icon>add</md-icon> Add</md-button>
		</div>
		<br />
		<br />
		<div class="row">
			<md-icon style="margin-right: 1rem" :class="v$.documentInstructions.$error && 'error'">description</md-icon>
			<label :style="v$.documentInstructions.$error && 'color:red'"> Document Submission Instructions </label>
		</div>
		<p class="error_message" v-if="v$.documentInstructions.$error">Document submission instructions are required.</p>
		<vue-editor style="margin: 1rem 0 3rem 0 !important" v-model="documentInstructions" @text-change="addDocumentInstructions" />
		<div class="justify-between">
			<md-button class="md-raised" @click="previousStep"><md-icon>arrow_back</md-icon> Previous</md-button>
			<md-button class="md-raised md-primary" @click="nextStep"> Next <md-icon>arrow_forward</md-icon></md-button>
		</div>
	</div>
</template>

<script>
import TextField from '@/components/Inputs/TextField';
import { VueEditor } from 'vue2-editor';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
	components: {
		TextField,
		VueEditor,
	},
	data() {
		return {
			requirements: '',
			cv: false,
			id: false,
			proofOfIncome: false,
			matricResults: false,
			academicTranscript: false,
			tuitionQuotation: false,
			other: false,
			newDocument: '',
			additionalDocuments: [],
			documentInstructions: '',
		};
	},
	setup() {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			requirements: { required },
			documentInstructions: { required },
			documentRequirements: {
				validator: () => this.cv || this.id || this.proofOfIncome || this.matricResults || this.academicTranscript || this.tuitionQuotation || this.other,
			},
		};
	},
	methods: {
		nextStep() {
			this.v$.$touch();
			if ((!this.v$.$invalid && !this.other) || this.additionalDocuments.length > 0) {
				this.$emit('next');
			}
		},
		previousStep() {
			this.$emit('previous', 'requirements', 'description');
		},
		addDocument() {
			if (this.newDocument.trim()) {
				this.additionalDocuments.push({ name: this.newDocument.trim() });
				this.newDocument = '';
				this.$emit('addAdditionalDocuments', this.additionalDocuments);
			}
		},
		updateDocument(index, name) {
			this.$set(this.additionalDocuments, index, { name: name.trim() });
			this.$emit('addAdditionalDocuments', this.additionalDocuments);
		},
		deleteDocument(index) {
			this.additionalDocuments.splice(index, 1);
			this.$emit('addAdditionalDocuments', this.additionalDocuments);
		},
		addCv() {
			this.$emit('cv', this.cv);
		},
		addId() {
			this.$emit('id', this.id);
		},
		addRequirements() {
			this.$emit('requirements', this.requirements);
		},
		addProofOfIncome() {
			this.$emit('proofOfIncome', this.proofOfIncome);
		},
		addMatricResults() {
			this.$emit('matricResults', this.matricResults);
		},
		addAcademicTranscript() {
			this.$emit('academicTranscript', this.academicTranscript);
		},
		addTuitionQuotation() {
			this.$emit('tuitionQuotation', this.tuitionQuotation);
		},
		addOther() {
			this.$emit('other', this.other);
		},
		addNewDocument() {
			this.$emit('newDocument', this.newDocument);
		},
		addDocumentInstructions() {
			this.$emit('documentInstructions', this.documentInstructions);
		},
	},
};
</script>
