<template>
	<modal v-if="this.writtenSubmission || this.isVideoSubmission" class="modal-frame">
		<template slot="header">
			<div class="top-left-text">
				<p>{{ currentProfile.profile.name }} {{ currentProfile.profile.surname }}</p>
			</div>
			<md-button class="md-simple md-just-icon md-round modal-default-button" @click="closeViewWrittenSubmissionModal">
				<md-icon class="md-size-3x">clear</md-icon>
			</md-button>
		</template>
		<template slot="body">
			<div>
				<iframe :src="this.submissionUrl"> </iframe>
				<modal v-if="profileModalVisible" @close="profileModalHide">
					<template slot="header">
						<md-button class="md-simple md-just-icon md-round modal-default-button" @click="profileModalHide">
							<md-icon style="font-size: 2rem !important; color: black !important">clear</md-icon>
						</md-button>
					</template>
				</modal>
				<div class="bottom-right-button">
					<md-button @click="viewProfile" class="md-success"> View Profile </md-button>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
import { Modal } from '@/components';
export default {
	components: { Modal },
	data() {
		return {
			isWrittenSubmission: null,
			writtenUrl: null,
			submissionUrl: '',
			profileModalVisible: false,
		};
	},
	methods: {
		closeViewWrittenSubmissionModal() {
			this.$emit('closeViewSubmissionModal');
		},
		checkSubmissions() {
			if (this.writtenSubmission && !this.isVideoSubmission) {
				this.checkWrittenSubmission();
			} else if (!this.writtenSubmission && this.isVideoSubmission) {
				this.checkVideoSubmission();
			}
		},
		checkVideoSubmission() {
			if (this.currentProfile.videoSubmissionUrl) {
				if (this.currentProfile.videoSubmissionUrl.includes('www.loom.com')) {
					this.submissionUrl = this.currentProfile.videoSubmissionUrl.replace('share', 'embed');
				}

				if (this.currentProfile.videoSubmissionUrl.includes('drive.google.com')) {
					this.submissionUrl = this.currentProfile.videoSubmissionUrl.replace('view', 'preview');
				}
			} else {
				this.noSubmission = true;
				this.submissionErrorMessage = this.videoSubmissionErrorMessage;
			}
		},
		checkWrittenSubmission() {
			if (this.currentProfile.writtenSubmissionUrl) {
				this.submissionUrl = this.currentProfile.writtenSubmissionUrl;
			} else {
				this.noSubmission = true;
				this.submissionErrorMessage = this.writtenSubmissionErrorMessage;
			}
		},
		viewProfile() {
			this.$emit('viewProfile', true);
			this.$emit('closeViewSubmissionModal');
		},
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
		writtenSubmission: {
			type: Boolean,
			default: false,
		},
		currentProfile: {
			type: Object,
			required: true,
		},
		isVideoSubmission: {
			type: Boolean,
		},
		name: {
			type: String,
			required: true,
		},
		surname: {
			type: String,
			required: true,
		},
	},
	created() {
		this.checkSubmissions();
	},
};
</script>

<style>
.modal-frame .modal-container {
	max-width: 1200px !important;
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
}
iframe {
	width: 1100px !important;
	height: 600px !important;
}
.top-left-text {
	position: absolute;
	left: 10px;
	font-weight: bold;
	margin-left: 35px;
	font-size: 1.5rem;
	padding: 10px;
}
.bottom-right-button {
	position: absolute;
	right: 10px;
	margin-right: 20px;
	padding: 15px;
}
</style>
