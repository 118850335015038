<template>
	<div>
		<div>
			<form @submit.prevent="confirmChoices">
				<!-- <h1 class="md-headline" style="font-size: 14px; text-align: center">Select the three companies you would love to work for in the future</h1> -->

				<!-- <div class="company-input">
					<md-field style="margin-right: 30px">
						<label>What is your first choice company?</label>
						<md-input type="search" v-model="firstChoice" @click="openFirstCompanySelection"></md-input>
						<span class="md-error">There is an error</span>
					</md-field>
					<md-button @click="openFirstCompanySelection" class="md-success"> {{ this.firstChoice != null ? 'Edit' : 'Add' }}</md-button>
				</div>

				<div class="company-input">
					<md-field style="margin-right: 30px">
						<label>What is your second choice company?</label>
						<md-input type="search" v-model="secondChoice" @click="openSecondCompanySelection"></md-input>
					</md-field>
					<md-button @click="openSecondCompanySelection" class="md-success"> {{ this.secondChoice != null ? 'Edit' : 'Add' }}</md-button>
				</div>

				<div class="company-input">
					<md-field style="margin-right: 30px">
						<label>What is your third choice company?</label>
						<md-input type="search" v-model="thirdChoice" @click="openThirdCompanySelection"></md-input>
					</md-field>
					<md-button @click="openThirdCompanySelection" class="md-success"> {{ this.thirdChoice != null ? 'Edit' : 'Add' }}</md-button>
				</div> -->
				<div class="department-selection-container">
					<h5 class="info-text">What departments would you like to work in?</h5>
					<h5 class="info-text" style="font-weight: bold; font-size: 12px; margin-top: -25px">Select 1-3 departments that you would like to work in</h5>

					<div v-if="v$.department.$error" class="error-message">Select your preferred departments</div>

					<div class="selected-departments">
						<span v-for="(dept, index) in department" :key="index" class="department-tag">
							{{ dept.department }}
						</span>
					</div>

					<div style="width: 100%; display: flex; justify-content: center; align-items: center">
						<md-button @click="openDepartmentSelection" class="md-success">
							{{ department.length > 0 ? 'Edit' : '+ Add Departments' }}
						</md-button>
					</div>
				</div>
			</form>
		</div>

		<!-- <preffered-companies-seletion
			v-if="openFirstCompanyModal"
			:modalHeading="'Select your first company'"
			:openCompanyModal="openFirstCompanyModal"
			@cancelSelection="cancelFirstSelection"
			@companySelection="firstCompanySelection"
		></preffered-companies-seletion>

		<preffered-companies-seletion
			v-if="openSecondCompanyModal"
			:modalHeading="'Select your second company'"
			:openCompanyModal="openSecondCompanyModal"
			@cancelSelection="cancelSecondSelection"
			@companySelection="secondCompanySelection"
		></preffered-companies-seletion>

		<preffered-companies-seletion
			v-if="openThirdCompanyModal"
			:modalHeading="'Select your third company'"
			:openCompanyModal="openThirdCompanyModal"
			@cancelSelection="cancelThirdSelection"
			@companySelection="thirdCompanySelection"
		></preffered-companies-seletion> -->
		<department-selection
			v-if="openDepartmentModal"
			:companyDepartments="companyDepartments"
			:openDepartmentModal="openDepartmentModal"
			@cancelDepartmentSelection="cancelDepartmentSelection"
			@departmentSelection="departmentSelection"
		></department-selection>
	</div>
</template>

<script>
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import { useVuelidate } from '@vuelidate/core';
import { helpers, minLength } from '@vuelidate/validators';
import AutofillSelect from '@/components/Inputs/AutofillSelect.vue';
import { requiredValidator } from '@/helpers/validators.helper';
import SelectField from '@/components/Inputs/SelectField.vue';

export default {
	data() {
		return {
			openSecondCompanyModal: false,
			openFirstCompanyModal: false,
			openThirdCompanyModal: false,
			openCompanyModal: false,
			firstChoice: null,
			secondChoice: null,
			thirdChoice: null,
			department: [],
			companies: [],
			companyDepartments: [],
			choices: [],
			openDepartmentModal: false,
		};
	},
	components: {
		// PrefferedCompaniesSeletion: () => import('@/views/dashboard/student/preferred-companies/preffered-companies-seletion.vue'),
		DepartmentSelection: () => import('@/views/dashboard/student/department/department-selection.vue'),
	},
	setup: () => ({
		v$: useVuelidate({
			$lazy: true,
			$autoDirty: false,
		}),
	}),
	validations: {
		// firstChoice: { required: requiredValidator },
		// secondChoice: { required: requiredValidator },
		// thirdChoice: { required: requiredValidator },
		department: { required: requiredValidator, minLength: minLength(3) },
	},

	methods: {
		cancelDepartmentSelection() {
			this.openDepartmentModal = false;
		},
		// cancelSelection(value) {
		// 	this.openCompanyModal = false;
		// },
		// cancelFirstSelection(value) {
		// 	this.openFirstCompanyModal = value;
		// },
		// cancelSecondSelection(value) {
		// 	this.openSecondCompanyModal = value;
		// },
		// cancelThirdSelection(value) {
		// 	this.openThirdCompanyModal = value;
		// },
		// firstCompanySelection(item) {
		// 	this.firstChoice = item.companyName;
		// 	this.openFirstCompanyModal = false;
		// },
		// async secondCompanySelection(item) {
		// 	this.secondChoice = item.companyName;
		// 	this.openSecondCompanyModal = false;
		// },
		// async thirdCompanySelection(item) {
		// 	this.thirdChoice = item.companyName;
		// 	this.openThirdCompanyModal = false;
		// },
		// async openFirstCompanySelection() {
		// 	this.openFirstCompanyModal = true;
		// },
		// async openSecondCompanySelection() {
		// 	this.openSecondCompanyModal = true;
		// },
		// async openThirdCompanySelection() {
		// 	this.openThirdCompanyModal = true;
		// },
		async openDepartmentSelection() {
			this.openDepartmentModal = true;
		},
		departmentSelection(value) {
			this.department = value;
		},
		async validate() {
			const res = await this.v$.$validate();
			this.$emit('on-validated', res);
			if (res) {
				// this.addChoices();
				this.addDepartment();
			}
			return res;
		},
		async confirmChoices() {
			const isFormValid = await this.v$.$validate();
			if (isFormValid) {
				// this.addChoices();
				this.addDepartment();
			}
		},
		// addChoices() {
		// 	if (this.firstChoice) {
		// 		this.choices.splice(0, 1, this.firstChoice);
		// 	}
		// 	if (this.secondChoice) {
		// 		this.choices.splice(1, 1, this.secondChoice);
		// 	}
		// 	if (this.thirdChoice) {
		// 		this.choices.splice(2, 1, this.thirdChoice);
		// 	}
		// 	this.$emit('choices', this.choices);
		// },
		addDepartment() {
			this.$emit('department', this.department);
		},
		// async getCompanies() {
		// 	//Companies Drop Down List
		// 	let companyRef = await db.collection('Settings').doc('Preferred Employers').get();
		// 	let temp = companyRef.data().companies;
		// 	for (let i = 0; i < temp.length; i++) {
		// 		this.companies.push(temp[i].companyName);
		// 	}
		// },
		async getDepartments() {
			//Departments Drop Down List
			let departmentRef = await db.collection('Settings').doc('Departments').get();
			this.companyDepartments = departmentRef.data().departments;
		},
		async isNullOrUndefinedOrEmpty(obj) {
			return obj === null || obj === undefined || (typeof obj === 'object' && Object.keys(obj).length === 0);
		},
	},
	async created() {
		// await this.getCompanies();
		await this.getDepartments();
		// Pulling student saved companies
		this.user = firebase.auth().currentUser;
		let ref = db.collection('users');
		ref.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(async doc => {
					if (doc.exists) {
						this.alias = doc.id;
						let studentRef = await db.collection('students').doc(doc.id).get();
						this.student = studentRef.data();
						// let studentCompanies = this.student?.preferredCompanies || {};
						let studentDepartments = this.student?.preferredDepartments || [];
						// if (!this.isNullOrUndefinedOrEmpty(studentCompanies)) {
						// 	this.firstChoice = studentCompanies?.choices?.[0] || '';
						// 	this.secondChoice = studentCompanies?.choices?.[1] || '';
						// 	this.thirdChoice = studentCompanies?.choices?.[2] || '';
						// }
						this.department = studentDepartments;
					}
				});
			});
	},
};
</script>
<style scoped>
.department-selection-container {
	margin: 20px;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.department-label {
	font-size: 16px;
	color: #333;
	margin-bottom: 10px;
	display: block;
}

.selected-departments {
	margin-top: 15px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	width: fit-content;
}

.department-tag {
	background-color: #5694f0;
	color: #fff;
	border-radius: 20px;
	padding: 5px 15px;
	margin: 5px;
	display: inline-block;
	font-size: 14px;
	width: fit-content;
	font-weight: bold;
	line-height: 18px;
}

.department-input-btn {
	/* display: flex;
	justify-content: flex-end; */
	margin-top: 0;
}

.add-btn {
	background-color: #4caf50;
	color: #fff;
	font-weight: bold;
}

.error-message {
	color: red;
	margin-top: 10px;
}

@media (max-width: 600px) {
	.department-selection-container {
		margin: 0;
	}
}
</style>
