<template>
  <div class="md-layout-item md-size-100 transparant">
    <md-card>
      <md-card-content>
        <collapse
          :collapse="[
            'GLOSSARY OF TERMS',
            'OVERVIEW',
            'SECTION 1 - ONLINE TERMS',
            'SECTION 2 - GENERAL CONDITIONS',
            'SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION',
            'SECTION 4 - SERVICE, PRICES AND PAYMENT',
            'SECTION 5 - JOB ACCEPTANCE',
            'SECTION 6 - JOB CANCELLATION',
            'SECTION 7 - PRODUCTS AND SERVICES',
            'SECTION 8 - SUSPENSION OF ACCOUNT',
            'SECTION 9 - ACCOUNT INFORMATION',
            'SECTION 10 - OPTIONAL TOOLS',
            'SECTION 11 - THIRD-PARTY LINKS',
            'SECTION 12 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS',
            'SECTION 13 - DATA PROTECTION LAWS',
            'SECTION 14 - ERRORS, INACCURACIES AND OMISSIONS',
            'SECTION 15 - PROHIBITED USES',
            'SECTION 16 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY AND INDEMNIFICATION',
            'SECTION 17 - SEVERABILITY',
            'SECTION 18 - TERMINATION',
            'SECTION 19 - ENTIRE AGREEMENT',
            'SECTION 20 - GOVERNING LAW',
            'SECTION 21 - CONTACT INFORMATION'
            ]"
          icon="keyboard_arrow_down"
          color-collapse="success">
            <template slot="md-collapse-pane-1">
              <p><b>“You”, “User”, “Client” and “Student”</b> refer to any person (including a juristic person) with a registered Account on the Website or a visitor of this Website.</p>
              <p><b>“Account”</b> means the profile You create when You register on the Website and which is associated with your email address;</p>
              <p><b>“Client/s”</b> means the User which offers jobs for Students to bid for or to apply to and purchases a Service from a Student;</p>
              <p><b>“Data Protection Laws”</b> means the South African Protection of Personal Information Act 4 of 2013 (“POPIA”) and the European Union General Data Protection Regulation (“GDPR”);</p>
              <p><b>“Escrow”</b> means a financial instrument held by a third party on behalf of the other two parties in a transaction. The funds are held by the escrow service until it receives the appropriate written or oral instructions or until obligations have been fulfilled;</p>
              <p><b>“Fee”</b> means a percentage applied to the cost the Client intends to pay for the Job or in accordance with the membership pricing structure subscribed to, as more detailed in Schedule 1, and as may be amended from time to time, at Jobox’s sole discretion;</p>
              <p><b>"Fixed Term Contract”</b> means an agreement using the template in Schedule 2 that is entered into between a Client and a Student upon agreement to begin a Job. The agreement is for a Student to provide the agreed upon work and for the Client to make a payment in monetary terms for the work agreed upon;</p>
              <p><b>“Job”</b> means any On-site or Remote project, task, part-time work or internship offered, advertised or awarded by a Client to a Student through the Website;</p>
              <p><b>“Jobox”, “We”, “Us” and “Our”</b> refers to Jobox (Pty) Ltd, a company duly incorporated in terms of the company laws of South Africa with Registration Number 2015/143706/07 and its principal place of business at 26 Merano Crescent, Kyalami Estates , Johannesburg, Gauteng, 1684.</p>
              <p><b>“Intellectual Property”</b> means all intellectual property, whether registered or not, including but not limited to designs; processes; know-how; trade secrets; research results; business names; domain names; patents and patent applications; trademarks and trade mark applications; copyright (including rights in any reports, presentation slide shows, software source code, photographs, literary works, computer software and typography rights); confidential and proprietary knowledge and information and any rights protecting same; business goodwill and reputation and rights protecting same; database rights; and all intellectual property rights and forms of protection of a similar nature to any of the foregoing or having an equivalent effect anywhere in the world belonging to Jobox or its affiliates;</p>
              <p><b>“On-site Job”</b> means a Job that is started and/or completed at a location that is requested by a Client.</p>
              <p><b>“Remote Job"</b> means the websites operated by Jobox and any related Jobox service, tool or application.</p>
              <p><b>Service</b> means providing a marketplace for Students and Clients to interact and to enter into an agreement between themselves for the completion of a Job and facilitating the payment process.</p>
              <p><b>Student/s</b> means the User which performs a Job and sells a service to the Client</p>
              <p><b>Website</b> means the site <a href="https://app.jobox.co.za/">https://app.jobox.co.za/</a> operated by Jobox and any related Jobox service, tool or application and is inclusive of any social media platforms.</p>
            </template>
            <template slot="md-collapse-pane-2">
              <p>This Website is operated by Jobox. Jobox offers this Website, including all information, tools and Services available from this Website to You, the User, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
              <p>Jobox is an online platform where Users buy and sell Jobs. Clients and Students must register for an account in order to buy or sell a Job and/or items. Jobox enables Users to find each other online, to complete and pay for Jobs, buy and sell items and to use the Services that We provide.</p>
              <p>By visiting Our Website and/or registering with Us, You engage in Our “Service” and agree to be bound by the following terms and conditions (“Terms and Conditions”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms and Conditions apply to all Users of the Website, including without limitation Users who are browsers, vendors, suppliers, customers, merchants, and/ or contributors of content.</p>
              <p>Please read these Terms and Conditions carefully before accessing or using Our Website. By accessing or using any part of the Website, You agree to be bound by these Terms and Conditions. If You do not agree to all the terms and conditions of this agreement, then You may not access the Website or use any services. If these Terms and Conditions are considered an offer, acceptance is expressly limited to these Terms and Conditions.</p>
              <p>Any new features or tools which are added to the Website shall also be subject to these Terms and Conditions. You can review the most current version of the Terms and Conditions at any time on this page. We reserve the right to update, change or replace any part of the Terms and Conditions by posting updates and/or changes to Our Website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the Website following the posting of any changes constitutes acceptance of those changes.</p>
            </template>
            <template slot="md-collapse-pane-3">
              <p>By agreeing to these Terms and Conditions, You represent that You are at least the age of majority in the Republic of South Africa or that You are the age of majority and You have given Us your consent to allow any of your minor dependents to use this Website.</p>
              <p>You may not use Our services for any illegal or unauthorised purpose nor may You, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
              <p>You must not transmit any worms or viruses or any code of a destructive nature.</p>
              <p>A breach or violation of any of the Terms will result in an immediate termination of your Account and further legal proceedings should We deem necessary.</p>
              <p>We reserve the right to restrict or refuse access to the Service should You not adhere to these Terms and Conditions.</p>
            </template>
            <template slot="md-collapse-pane-4">
              <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the Website through which the Service is provided, without express written permission by Us.</p>
              <p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>
              <p>When using Jobox, You agree to not:</p>
              <ol type="a">
                  <li>post content or items in inappropriate categories or areas on Our Websites and Services;</li>
                  <li>infringe any laws, third party rights or Our policies</li>
                  <li>fail to deliver payment for the Job delivered to You, unless the Student/Client has materially changed the Job provided from the bid or a clear typographical error is made;</li>
                  <li>fail to deliver on the Job purchased from You, unless the Client fails to meet the terms, materially alters the terms of the Student services from the listing;</li>
                  <li>circumvent or manipulate Our fee structure, the billing process, or fees owed to Jobox;</li>
                  <li>process and/or post false, inaccurate, misleading, defamatory or offensive content (including personal information);</li>
                  <li>take any action that may undermine the feedback or reputation systems (such as displaying, importing or exporting feedback information or using it for purposes unrelated to the Jobox);</li>
                  <li>distribute or post spam, unsolicited, or bulk electronic communications, chain letters, or pyramid schemes;</li>
                  <li>distribute viruses or any other technologies that may harm Jobox, the Website, or the interests or property of Jobox Users (including their Intellectual Property rights, privacy and publicity rights) or is unlawful, threatening, abusive, defamatory, invasive of privacy, vulgar, obscene, profane or which may harass or cause distress or inconvenience to, or incite hatred of, any person;</li>
                  <li>download and aggregate listings from Our Website for display with listings from other Websites without Our express written permission, "frame", "mirror" or otherwise incorporate any part of the Website into any other Website without Our prior written authorisation;</li>
                  <li>attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by Us in connection with the Website;</li>
                  <li>copy, modify or distribute rights or content from the Website or Jobox’s copyrights and trademarks; or</li>
                  <li>harvest or otherwise collect information about Users, including email addresses, without their consent.</li>
              </ol>
              <p>By registering for an Account, You consent to electronically receive and access, via email or the Website, all records and notices for the services provided to You under this Agreement that We would otherwise be required to provide to You in paper form.</p>
            </template>
            <template slot="md-collapse-pane-5">
              <p>We are not responsible if information made available on this Website is not accurate, complete or current. The User details on this Website is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the information on this Website is at your own risk.</p>
              <p>We cannot be held liable for failed, incomplete or unsatisfactory performances from any of the Users on the Website. We cannot be held liable if a Client fails to pay for a completed Job. We are also not responsible for enforcement mechanisms if a Client refuses to pay for a completed Job and the User hereby agrees to rely on the terms and conditions set out in the Fixed Term Contract should the User intend to enforce any rights, title or interest it may have. We also cannot be held liable if a Student does not complete a promised performance such as showing up at the requested location, completing the Job as agreed upon or failure to perform as promised in any way or form that can be construed as an incomplete performance of the agreed-upon Job. Any reliance on the information on this Website is at the User’s own risk.</p>
              <p>This Website may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this Website at any time, but We have no obligation to update any information on Our Website unless specifically requested by a User in terms of the Data Protection Laws. The User remains solely responsible for updating and managing the User’s Account on the Website and You agree that it is your responsibility to monitor any changes to Our Website.</p>
              <p>Users may provide a business name or a company name, which is to be associated with the User's Account. Should the User be a business or company and unless otherwise agreed in writing by Jobox, the Users acknowledge and agree that where a business name or company name is associated with their Account, the Terms and Conditions shall be construed as a contract with the User, jointly and severally as an individual (not the business or company) and as a representative of the business or company and Jobox. The Users remain solely responsible for all activities undertaken in respect of their Account.</p>
              <p>We may, at Our absolute sole discretion, refuse to register a person or corporate entity as a User and shall provide the User with a copy of the reasons for refusing to register the User within 72 hours from the User’s application.</p>
            </template>
            <template slot="md-collapse-pane-6">
              <p>Jobox is an online platform to facilitate interactions between the Student and the Client. We assist the Clients and Students to make informed decisions by providing a facility to rate and review Students but We cannot be held liable if the review and rating of a Student does not match the quality of what is done or a service that is performed as We do not control the ratings and reviews of the Students or the Clients.</p>
              <p>The Client determines the amount the Client intends to pay for the Job and We charge the Fee for certain Services, such as but not limited to introduction fees for Jobs, listing upgrades dependent on your elected memberships. When the Client uses a Service that has a Fee, the client has an opportunity to review and accept the Fee that will be charged by Us for the Service that We provide which We may change from time to time and We will update You in this regard by updating Our Website Terms and Conditions. We may choose to temporarily change the fees for Our Services for promotional events (for example, discounts on memberships) or new services, and such changes are effective when We post the temporary promotional event or new Service on the Website.</p>
              <p>Unless otherwise stated, all costs and Fees are quoted in South African Rands (ZAR).</p>
              <p>In return for providing a facility to source Services, Jobox charges a Fee for each Job. Upon registering for an Account, You agree to this Fee being levied and consent to the amount prior to confirming the Job. </p>
              <p>The Client is entitled to make payment of the amount prior to the commencement of the Job and hereby agrees to allow Jobox to hold the payment of a Job in Escrow. In the event that the Client does not make upfront payment, the Client shall make payment immediately on receipt of Jobox’s invoice.</p>
              <p>Jobox cannot be held liable if the Client does not make the payment for the services rendered by the Student in terms of the Job or if there is a delay in transferring the payment that is held in Escrow to the Student. Subject to the Client making payment for the Job, Jobox will release the payment to the Student in terms of the Fixed Term Contract within 7 (seven) days from completion of the Job.</p>
              <p>The Client hereby indemnifies Jobox against any and all claims that the Student may have against Jobox, however so arising as a direct, indirect or consequential result of the Client failing to timeously make payment for the Job.</p>
              <p>Fees for Our Services and product offerings are subject to change without notice.</p>
              <p>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</p>
              <p>We shall not be liable to You or any third-party for any modification, price change, suspension or discontinuance of the Service.</p>
            </template>
            <template slot="md-collapse-pane-7">
              <p>Upon the Client awarding a Job to a Student, and the Student’s acceptance of the Job on the Website, the Client and Student will be deemed to have entered into a Fixed Term Contract under which the Client agrees to purchase, and the Student agrees to render/deliver the Client Job. The User agrees not to enter into any contractual provisions in conflict with the Fixed Term Contract.</p>
              <p>You are solely responsible for ensuring that You comply with your obligations to other Users. If You do not, You may become liable to that User. You must ensure that You are aware of any domestic laws (including common law), international laws, statutes, ordinances and regulations relevant to You as a Client or Student, or in any other use You may make through the Website.</p>
              <p>If another User breaches any obligation to You, You are solely responsible for enforcing any rights that You may have. For the avoidance of doubt, We have no responsibility for enforcing any rights under a Fixed Term Contract. We merely provide a space where Students and Clients can find each other, and We facilitate the payment process. We are not liable for anything that may happen between the Users before the Job is awarded, whilst the Job is being performed and after the completion of the Job.</p>
              <p>Jobox cannot be held liable for the non-payment from the Client to the Student upon the completion of a Job or the pro-rata payment when a Job is cancelled.</p>
              <p>Jobox cannot be held liable for inadequate quality of work that is done by a Student.</p>
              <p>Jobox cannot be held liable in the case of an On-site Job, a Student or Client does not show up on time or at all to the premises or location that the Student promised the Client to be at.</p>
              <p>Whilst Jobox attempts to screen all Clients and Students prior to accepting the application, Jobox cannot be held liable for any fake Job postings.</p>
              <p>Notwithstanding the above, Jobox endeavours to create a list of Jobs that are offered on the Website together with recommended Users for the completion of the Job.</p>
              <p>Nothing in these Terms and Conditions is intended to override a right that by applicable law may not be excluded.</p>
              <p>Each User acknowledges and agrees that the relationship between Clients and Students is that of an independent contractor. Nothing in this Terms and Conditions creates a partnership, joint venture, agency or employment relationship between Users.</p>
              <p>Nothing in this Terms and Conditions document shall in any way be construed as forming a joint venture, partnership, franchise or an employer-employee relationship between Jobox and the User.</p>
            </template>
            <template slot="md-collapse-pane-8">
              <b>Cancellation before the Student applies for the Job</b>
              <p>In the event that a Client removes a Job offering prior to any Student accepting and entering into a Fixed Term Contract, Jobox shall not charge the Client any Fee, except the banking transaction fee in the event that the Client made upfront payment of the Fee, held in Escrow and which Fee is to be reimbursed to the Client.</p>
              <b>Cancellation of Job whilst the Job is on-going </b>
              <p>Should the Client terminate the Job after acceptance and entering into a Fixed Term Contract, for any reason whatsoever, the following cancellation policy will be applicable:
                <ul>
                  <li>Each Job will be referred to Jobox and dealt with on an ad-hoc basis;</li>
                  <li>The Client and the Student will enter into a mediation process, as facilitated by Jobox in Our sole discretion, the outcome of which is absolute and cannot be appealed or reviewed;</li>
                  <li>Jobox will take into consideration various factors including but not limited to the Job specifications set out in the Fixed Term Contract, the duration of the Job, how far the Student is to completing the Job, the quality and satisfactory performance of the Job by the Student.</li>
                </ul>
        	  <!-- eslint-disable-next-line prettier/prettier -->
        	  </p>
              <b>The Student cancels the contract whilst the Job is in process</b>
              <p>In the event that the Student cancels the Job, Jobox shall reimburse the Client (if an upfront payment was made and held in Escrow) less the reasonable reimbursement fee applicable.</p>
            </template>
            <template slot="md-collapse-pane-9">
              <p>Certain Services may be available exclusively online through the Website. These Services may have limited quantities.</p>
              <p>We reserve the right but are not obligated to limit the sales of Our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or Services that We offer. All descriptions of products or Services or pricing thereof are subject to change at any time without notice, at Our sole discretion. We reserve the right to discontinue any product or Service at any time. Any offer for any product or Service made on this Website is void where legally prohibited.</p>
              <p>We do not warrant that the quality of any products, services, information, or other material purchased or obtained by You will meet your expectations, or that any errors in the Service will be corrected.</p>
            </template>
            <template slot="md-collapse-pane-10">
              <p>We may terminate, suspend or limit your access to your Account provided we have provided You with a  reason. Without limiting the foregoing, We may terminate, suspend or limit your access to your Account:</p>
              <ol>
                  <li>if We determine that You have breached, or are acting in breach of these Terms and Conditions;</li>
                  <li>if You under-bid on any Job in an attempt to renegotiate the actual price privately, in order to circumvent Jobox;</li>
                  <li>if We determine that You have breached legal liabilities (actual or potential), including, infringing Jobox’s or a third party’s Intellectual Property rights;</li>
                  <li>if We determine that You have engaged, or are engaging in fraudulent, or illegal activities;</li>
                  <li>You do not respond to account verification requests;</li>
                  <li>You do not complete account verification when requested within 3 months of the date of request;</li>
                  <li>to manage any risk of loss to us, a User, or any other person; or</li>
                  <li>for other similar reasons.</li>
              </ol>
              <p>If We terminate your Account for a reason other than as a result of your breach of the Terms and Conditions unless as otherwise specified in the Terms and Conditions, You will be entitled to receive any payment due from Us to You.</p>
              <p>In the event that We terminate, suspend or limit your Account, You hereby release and indemnify Jobox against any and all claims, however so arising in respect of any such termination, suspension or limitation of your Account.</p>
            </template>
            <template slot="md-collapse-pane-11">
              <p>Jobox cannot and does not confirm each User's purported identity. We may provide information about a User, such as a strength or risk score, geographical location, or third party background or verification check of identity or credentials. However, such information is based solely on data that the User submits and We provide such information solely for convenience and the provision of such information is not an introduction, endorsement or recommendation by Us.</p>
              <p>You warrant that the information provided to Jobox is accurate, correct and true at all material times and undertake to update the information within 48 hours of any information changing.</p>
            </template>
            <template slot="md-collapse-pane-12">
              <p>We may provide You with access to third-party optional tools over which We neither monitor nor have any control nor input in.</p>
              <p>You acknowledge and agree that We provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.</p>
              <p>Any use by You of optional tools offered through the Website is entirely at your own risk and discretion and You should ensure that You are familiar with and agree to the terms and conditions on which the relevant third-party service provider(s) provides the optional tools.</p>
              <p>We may also, in the future, offer new services and/or features through the Website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms and Conditions.</p>
            </template>
            <template slot="md-collapse-pane-13">
              <p>Certain content, products and services available via Our Website may include materials from third-parties.</p>
              <p>Third-party links on this Website may direct You to third-party Websites that are not affiliated with Us. We are not responsible for examining or evaluating the content or accuracy and We do not warrant and will not have any liability or responsibility for any third-party materials or Websites, or for any other materials, products, or services of third-parties.</p>
              <p>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party website. Please review the third-party's independent terms and conditions, policies and practices and make sure You understand them before You agree and engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products or services should be directed to the third-party.</p>
              <p>Unless otherwise agreed with Us, You may not advertise an external website, product or service on Our Website. Any website, product or service offering posted on the Website, including in a listing, bid, listing description, clarification board or the message board, must relate to a Job, contest, an item listed, User or service being performed on the Website. An example of a permissible website link would be reference to a portfolio of work.</p>
              <p>We may display sponsor advertisements and promotions on the Website. You acknowledge and agree that We shall not be responsible for any loss or damage of any kind incurred by You as a result of the presence of such advertisements/ promotions in the Website or your subsequent dealings with the Advertisers. Furthermore, You acknowledge and agree that the content of sponsor advertisements or promotions is protected by copyright, trademarks, service marks, patents or other intellectual property or proprietary rights and laws. Unless expressly authorised by Jobox or a third-party right holder, You agree not to modify, sell, distribute, appropriate any works including the creation of any derivative works based on such advertisements/promotions.</p>
            </template>
            <template slot="md-collapse-pane-14">
              <p>You hereby grant a worldwide, royalty free perpetual license, alternatively acknowledge, assign, cede and consent to the processing and transfer of any copyright in the feedback, reputation and reviews You leave consisting of comments and a multidimensional rating (e.g. quality, communication etc.) together with a composite rating by Us. You acknowledge that such feedback, reputation and reviews belong solely to Us, notwithstanding that We permit You to use it on Our Website while You have an active Account. You may not use, or deal with, such feedback, reputation and reviews in any way inconsistent with Our policies as posted on the Website from time to time without Our prior written permission.</p>
              <p>You may not do (or omit to do) anything that may undermine the integrity of Our feedback system. We are entitled to suspend, limit or terminate your Account at any time if We, in Our sole discretion, are concerned by any feedback about You, or your feedback rating, where We believe Our feedback system may be subverted.</p>
              <p>Our feedback ratings belong to Us and may not be used for any purpose other than facilitating the provision of a Job via the Website. As a User, You may not use your feedback (including, but not limited to, marketing or exporting any or all of your composite rating or feedback comments) in any real or virtual venue other than a Website operated by Jobox or its affiliates without Our written permission.</p>
              <p>If at Our request, You send certain specific submissions or without a request from Us, You send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), You agree that We may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that You may have forwarded to Us. We are and shall be under no obligation:
                <ol>
                  <li>to maintain any comments in confidence;</li>
                  <li>to pay compensation for any comments; or</li>
                  <li>to respond to any comments.</li>
                </ol>
              </p>
              <p>We may, but have no obligation, to monitor, edit or remove content that We determine in Our sole discretion are unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms and Conditions.</p>
              <p>You agree that your comments will not violate any right of Jobox or any third-party, including copyright, trademark, privacy, personality or another personal or proprietary right. You further agree that your comments will not contain libellous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related Website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead Us or third-parties as to the origin of any comments. You are solely responsible for any comments You make and their accuracy. We take no responsibility and assume no liability for any comments posted by You or any third-party.</p>
              
            </template>
            <template slot="md-collapse-pane-15">
              <p>You understand that your Personal Information (excluding financial information), may be transferred unencrypted and involve (a) transmissions over various networks outside the Republic of South Africa; and (b) change to conform and adapt to technical requirements of connecting networks or devices. Financial information may also be transferred, however the same is always encrypted during the transfer over networks. You hereby irrevocably consent to such transfer as We deem necessary in order to render the Service.</p>
              <p>We process the following personal information including but not limited to name, surname, email addresses, cellphone number, GPS location, race, gender, age, date of birth, education, income tax and VAT number, company statistics and industry scope (“Personal Information”) in line with the Data Protection Laws in order to provide the Service; assess Our ability to meet Our obligation under this Agreement; share with Our service providers as may be require, to protect Our interests and You hereby authorise Us to process the aforesaid Personal Information.</p>
              <p>You shall bear the responsibility of capturing the Personal Information and using such Personal Information to generate the Service thereof.</p>
              <p><b>We will only use the Personal Information as provided for in these terms and conditions, and shall only disclose the Personal Information to the entities that are related to Us and Our service providers, certain types of Personal Information captured by You, that are necessary for the Service to be rendered.</b></p>
              <p>Your submission of personal information through the Website is governed by Our <router-link to="/privacy-policy">**Privacy Policy**</router-link></p>
              <p>You authorise Us, directly or through third parties, to make any inquiries We consider necessary to validate your identity. We may request You to:
                <ol>
                  <li>provide further information to us, which may include your date of birth and other information that will allow Us to reasonably identify You;</li>
                  <li>take steps to confirm ownership of your email address or financial instruments; or</li>
                  <li>verify your information against third party databases or through other sources.</li>
                </ol>
              </p>
              <p>We reserve the right to close, suspend, or limit access to your Account and or the Website in the event We are unable to obtain or verify to Our satisfaction the information which We request under this section.</p>
              <p>We may read all correspondence posted to the Website and download or access, and test (if necessary), all uploaded files, programs and Websites related to your use of the Website for the purpose of investigating fraud and for risk management and related purposes.</p>
              <p>You hereby expressly consent, by your use of the Website herein, to the collection, use, processing, storage, transfer and sharing of your Personal Information to Our Affiliates and Us, provided that such processing is in line with the Data Protection Laws.</p>
              <p>You have the right to access, change, correct and delete Personal Information concerning You by contacting Us via email contact@jobox.co.za.</p>
            </template>
            <template slot="md-collapse-pane-16">
              <p>Occasionally there may be information on Our Website or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice.</p>
              <p>We undertake no obligation to update, amend or clarify information in the Service or on any related Website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related Website should be taken to indicate that all information in the Service or on any related Website has been modified or updated.</p>
              <p>Our Services, the Website and all content on it are provided on an 'as is', 'with all faults' and 'as available' basis and without warranties of any kind either expressed or implied. Without limiting the foregoing, We make no representation or warranty about:</p>
              <ol>
                  <li>the Website or any Jobox Services or Student Services;</li>
                  <li>the accuracy, reliability, availability, veracity, timeliness or content of the Website or any Jobox Services or Student Services;</li>
                  <li>whether the Website or Jobox Services or Student Services will be up-to-date, uninterrupted, secure, error-free or non-misleading;</li>
                  <li>whether defects in the Website will be corrected;</li>
                  <li>whether the Website, Jobox Services or the Student Services or any data, content or material will be backed up or whether business continuity arrangements are in place in respect of the Website, Jobox or Student Services;</li>
                  <li>any third-party agreements or any guarantee of business gained by You through the Website, Jobox Services or Student Services; or</li>
                  <li>the Website, Jobox Services or Student Services or infrastructure on which they are based, being error or malicious code free, secure, confidential or performing at any particular standard or having any particular function.</li>
              </ol>
            </template>
            <template slot="md-collapse-pane-17">
              <p>In addition to other prohibitions as set forth in the Terms and Conditions, You are prohibited from using the Website or its content:</p>
              <ol>
                  <li>for any unlawful purpose;</li>
                  <li>to solicit others to perform or participate in any unlawful acts;</li>
                  <li>to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;</li>
                  <li>to infringe upon or violate Our intellectual property rights or the intellectual property rights of others; </li>
                  <li>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on race, gender, sexual orientation, belief, religion, language, ethnicity, colour, pregnancy, conscience, birth, marital status, age, national origin, ethnic or social origin, or disability;</li>
                  <li>to submit false or misleading information;</li>
                  <li>to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related Website, other Websites, or the Internet;</li>
                  <li>to collect or track the personal information of others;</li>
                  <li>to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
                  <li>for any obscene or immoral purpose; or</li>
                  <li>to interfere with or circumvent the security features of the Service or any related Website, other Websites, or the Internet.</li>
              </ol>
              <p>We reserve the right to suspend, limit or terminate your use of the Service or any related Website for violating any of the prohibited uses.</p>
            </template>
            <template slot="md-collapse-pane-18">
              <p>We do not guarantee, represent or warrant that your use of Our service will be uninterrupted, timely, secure or error-free.</p>
              <p>We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.</p>
              <p>We cannot be held liable if a User’s Account is hacked or if a hack can be linked back to the User using Jobox.</p>
              <p>In the case of on-site Jobs, We are not liable for any theft, damage to property or any other actions that can be construed as such.</p>
              <p>You agree that from time to time We may remove the Service for indefinite periods of time or cancel the Service at any time, without notice to You.</p>
              <p>You expressly agree that your use of, or inability to use, the service is at your sole risk. The services delivered to a Client by a Student are (except as expressly stated by us) provided 'as is' and 'as available' for your use.</p>
              <p>In no case shall Jobox, Our directors, shareholders, investors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, delict, strict liability or otherwise, arising from your use of any of the service or any products procured using the Website and Our Service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some legislation does not allow the exclusion or the limitation of liability for consequential or incidental damages, in such legislation, Our liability shall be limited to the maximum extent permitted by law.</p>
              <p>Furthermore, in no event shall We, Our related entities, Our affiliates or employees be liable, whether in contract, warranty, delict, or any other form of liability, for:
                <ol>
                  <li>any indirect, special, incidental or consequential damages that may be incurred by You;</li>
                  <li>any loss of income, business or profits (whether direct or indirect) that may be incurred by You;</li>
                  <li>any claim, damage, or loss which may be incurred by You as a result of any of your transactions involving the Website.</li>
                </ol>
              </p>
              <p>The limitations on Our liability to You above shall apply whether or not We, Our related entities, Our affiliates or employees have been advised of the possibility of such losses or damages arising.</p>
              <p>Notwithstanding the above provisions, nothing in this Terms and Conditions is intended to limit or exclude any liability on the part of Jobox and Our affiliates and related entities where and to the extent that applicable law prohibits such exclusion or limitation including those within the Consumer Protection Act 68 of 2008 and other relevant legislation.</p>
              <p>To the extent that We are able to limit the remedies available under this Terms and Conditions, We expressly limit Our liability for breach of a non-excludable condition or warranty implied by virtue of any legislation to the following remedies (the choice of which is to be at Our sole discretion) to the supply of the Jobox services again or the payment of the cost of having the Jobox services supplied again.</p>
              <p>You agree to indemnify, defend and hold harmless Jobox and Our parent, subsidiaries, affiliates, partners, officers, directors, shareholders, investors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees on attorney and client scale, made by any third-party due to or arising out of your breach of these Terms and Conditions or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>
            </template>
            <template slot="md-collapse-pane-19">
              <p>In the event that any provision of these Terms and Conditions is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms and Conditions, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
            </template>
            <template slot="md-collapse-pane-20">
              <p>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</p>
              <p>These Terms and Conditions are effective unless and until terminated by either You or us. You may terminate these Terms and Conditions at any time by notifying Us that You no longer wish to use Our Services, or when You cease using Our Website.</p>
              <p>If in Our sole discretion You fail, or We suspect that You have failed, to comply with any term or provision of these Terms and Conditions, We also may terminate these Terms and Conditions at any time without notice and You will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny You access to Our Services (or any part thereof).</p>
              <p>On termination of the Account, You will return any and all Intellectual Property in your possession, if any and hereby undertake to immediately cease and desist from using and processing the Intellectual Property of Jobox or its Affiliates.</p>
            </template>
            <template slot="md-collapse-pane-21">
              <p>The failure of Us to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.</p>
              <p>These Terms and Conditions and any policies or operating rules posted by Us on this Website or in respect to The Service constitutes the entire agreement and understanding between You and Us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between You and Us (including, but not limited to, any prior versions of the Terms and Conditions).</p>
              <p>Any ambiguities in the interpretation of these Terms and Conditions shall not be construed against the drafting party.</p>
            </template>
            <template slot="md-collapse-pane-22">
              <p>These Terms and Conditions and any separate agreements whereby We provide You Services shall be governed by and construed in accordance with the laws of the Republic of South Africa. The Users hereby submit to the jurisdiction of the high court Gauteng Division, Pretoria.</p>
            </template>
            <template slot="md-collapse-pane-23">
              <p>Questions about the Terms and Conditions should be sent to Us at <u>contact@jobox.co.za</u></p>
            </template>
      </collapse>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import {Collapse} from '@/components'
export default {
    name: 'terms-and-conditions',
    components: {
      Collapse
    }
}
</script>
<style lang="scss">
.transparant {
      opacity: 0.8;
}
</style>
