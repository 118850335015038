<template>
	<div>
		<div class="modal-overlay">
			<div class="modal-content">
				<div class="image-wrapper">
					<img :src="imageSrc" :alt="imageAlt" />
				</div>
			</div>
			<div>
				<md-button class="close-btn md-danger" @click="closeModal">Close</md-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		imageSrc: {
			type: String,
			required: true,
		},
		imageAlt: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			scale: 1,
		};
	},
	methods: {
		closeModal() {
			this.$emit('close');
		},
	},
};
</script>

<style scoped>
.close-btn {
	margin-top: 2rem;
}
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content {
	background-color: #fff;
	padding: 20px;
	border-radius: 10px;
	max-width: 90%;
	max-height: 90%;
	overflow: hidden;
}

.image-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	max-height: 100%;
	overflow: hidden;
}

img {
	max-width: 100%;
	max-height: 100%;
	transition: transform 0.2s ease;
}
</style>
