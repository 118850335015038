<template>
	<div v-if="postedJobs" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column">
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<job-data-table :data="jobs" :columns="['Name', 'Salary', 'Location', 'Job Type', 'Job Industry', 'Action']" class="desktop-table" />
		<job-mobile-data-table :data="jobs" class="mobile-table" />
	</div>
	<div v-else>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<h1 class="black centre">There are currently no jobs posted.</h1>
	</div>
</template>

<script>
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import JobDataTable from '@/components/Tables/JobDataTable.vue';
import JobMobileDataTable from '@/components/Tables/JobMobileDataTable.vue';
import firebase from 'firebase/compat/app';
export default {
	components: {
		JobDataTable,
		JobMobileDataTable,
	},
	data() {
		return {
			product1: '/img/dashboard/client/card-1.jpg',
			jobs: [],
			postedJobs: false,
			loading: true,
			userId: firebase.auth().currentUser.uid,
			specialProgramme: '',
		};
	},
	async created() {
		let student = await db.collection('students').where('userId', '==', this.userId).get();
		student.docs.forEach(doc => {
			this.specialProgramme = doc.data().programme || 'None';
		});
		let micros = await db.collection('micros').where('status', '==', 'select').get();
		micros.docs.forEach(async doc => {
			let job = doc.data();
			job.id = doc.id;

			let jobs = await db.collection('jobs').doc(doc.id).get();
			if (jobs.exists) {
				job.type = jobs.data().jobType;
				job.programme = jobs.data().programme || 'None';
				if (jobs.data().verified === true && !jobs.data().jobClosed && this.displayJob(job.programme)) {
					let skills = await db.collection('skills').doc(doc.id).get();
					job.category = skills.data().category;
					this.jobs.push(job);
					this.postedJobs = true;
				}
			}
		});
		this.loading = false;
	},
	methods: {
		displayJob(jobProgramme) {
			if (this.specialProgramme === 'None') return jobProgramme === 'None';
			return jobProgramme === this.specialProgramme || jobProgramme === 'None';
		},
	},
};
</script>

<style scoped>
.container {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.container .content_container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.container .view_container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mobile-table {
	display: none;
}

@media (max-width: 680px) {
	.desktop-table {
		display: none;
	}
	.mobile-table {
		display: block;
	}
}
</style>
