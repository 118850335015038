<template>
	<div>
		<h5 class="info-text">Tell us about your most recent work experience?</h5>
		<div class="md-layout">
			<notifications></notifications>
			<div class="md-layout" v-if="experiences">
				<md-table md-card>
					<md-table-toolbar>
						<h1 class="md-title">Experience</h1>
					</md-table-toolbar>

					<md-table-row v-for="experience in experiences" :key="experience.experienceNum">
						<md-table-cell md-label="JobTitle" md-sort-by="jobTitle">{{ experience.jobTitle }}</md-table-cell>
						<md-table-cell md-label="Employer" md-sort-by="employer">{{ experience.employer }}</md-table-cell>
						<md-table-cell md-label="StartDate" md-sort-by="startDate">{{ experience.startDate }}</md-table-cell>
						<md-table-cell md-label="endDate" md-sort-by="endDate" v-if="experience.work === false">{{ experience.endDate }}</md-table-cell>
						<md-table-cell md-label="EndDate" md-sort-by="status" v-else>Currently working</md-table-cell>
						<md-table-cell>
							<md-button class="md-icon-button" @click="viewExperience(experience.experienceNum)">
								<md-icon>edit</md-icon>
								<md-tooltip md-direction="bottom">Edit</md-tooltip>
							</md-button>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</div>
			<div v-else>
				<h6 class="info-text">You currently have no experiences added</h6>
			</div>
		</div>
		<md-button class="md-plain jb-standard md-fab-bottom-right" @click="addExperience"> <md-icon>add</md-icon>Add Experiences </md-button>
		<experience-modal :student="student" :selectedExp="selectedExp" :status="status" />
	</div>
</template>
<script>
import { watchEffect } from 'vue';
import { SlideYDownTransition } from 'vue2-transitions';
import ExperienceModal from './Components/ExperienceModal';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import { mapActions } from 'vuex';

export default {
	components: {
		ExperienceModal,
	},
	data() {
		return {
			user: null,
			student: null,
			experiences: [],
			experience: null,
			selectedExp: null,
			status: null,
		};
	},
	methods: {
		addExperience: function () {
			this.status = 'add';
			this.modalShow();
		},
		viewExperience: function (selected) {
			this.status = 'update';
			this.selectedExp = selected;
			this.modalShow();
		},
		...mapActions(['modalShow', 'incrementExpNum']),
	},
	created() {
		this.user = firebase.auth().currentUser;
		let ref = db.collection('users');
		const unsub = ref
			.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.student = db.collection('students').doc(doc.id);
					this.student.collection('experiences').onSnapshot(snapshot => {
						const changes = snapshot.docChanges();

						changes.forEach(change => {
							if (change.type === 'added') {
								this.experiences.push({
									...change.doc.data(),
								});

								this.incrementExpNum();
							}
							if (change.type === 'modified') {
								let pos = this.experiences
									.map(function (e) {
										return e.experienceNum;
									})
									.indexOf(this.selectedExp);
								this.experiences[pos] = change.doc.data();
								// Doesn't reflect experiences changes for some reason!
							}
							if (change.type === 'removed') {
								let pos = this.experiences
									.map(function (e) {
										return e.experienceNum;
									})
									.indexOf(this.selectedExp);
								this.experiences.splice(pos, 1);
							}
						});
					});
				});
			});

		watchEffect(onInvalidate => {
			// unsub from prev collection when watcher is stopped (component unmounted)
			onInvalidate(() => {
				unsub();
			});
		});
	},
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
	.md-field label {
		font-size: 11px;
	}
}
@media only screen and (max-width: 475px) {
	.md-card {
		overflow: scroll;
	}
}
</style>
