<template>
	<div class="text-center">
		<div class="copy-content">
			<h1 class="copy-content-heading">You've Already Completed This Test! ✅</h1>

			<h3 class="copy-content-text">It looks like you've already taken the {{ skillName }} vetting test for the {{ jobName }} role.</h3>
			<br />
			<md-button @click="navigateToVettingTests" class="md-success copy-content-text"> <md-icon>west</md-icon> Go Back To Remaining Tests</md-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			skillName: this.$route.params.skillName,
			jobName: this.$route.params.jobName,
			jobId: this.$route.params.jobId,
		};
	},
	methods: {
		navigateToVettingTests() {
			this.$router.push({ name: 'vetting-tests', params: { jobId: this.jobId, skillName: this.skillName } });
		},
	},
};
</script>

<style scoped>
.copy-content {
	width: fit-content;
	margin: auto;
	padding: 3rem;
	border-radius: 10px;
}

@media (max-width: 990px) {
	.copy-content-heading {
		font-size: 30px;
	}
	.copy-content-text {
		font-size: 19px !important;
	}
}

@media (max-width: 624px) {
	.copy-content-heading {
		font-size: 25px;
	}
	.copy-content-text {
		font-size: 15px !important;
	}
}
</style>
