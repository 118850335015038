<template>
	<div>
		<h5 class="info-text" v-if="jobType === 'Once-off Project/Task'">
			Let us know what your budget is. Minimum amount is <b><u>R400.00</u></b>
		</h5>
		<h5 class="info-text" v-else-if="jobType === 'Full-time'">
			Let us know what the employee salary is. Minimum amount is <b><u>R3500.00</u></b> per month.
		</h5>
		<h5 class="info-text" v-else>
			Let us know what the employee salary is. Minimum amount is <b><u>R2000.00</u></b> per month.
		</h5>
		<div class="md-layout">
			<div class="md-layout-item mt-4 md-size-100">
				<md-field
					v-if="jobType === 'Once-off Project/Task'"
					:class="[
						{ 'md-valid': !errors.has('budget') && touched.budget && typeof budget !== 'object' },
						{ 'md-form-group': true },
						{ 'md-error': errors.has('budget') && touched.budget },
					]"
				>
					<md-icon><i class="fas fa-wallet"></i></md-icon>
					<label v-if="jobType === 'Once-off Project/Task'">Budget</label>
					<label v-else>Salary</label>
					<md-input
						v-if="jobType === 'Once-off Project/Task'"
						id="once-off-budget"
						@change="addBudget"
						@blur="setTouched('budget')"
						v-model="budget"
						data-vv-name="budget"
						type="number"
						min="10"
						step=".01"
						name="budget"
						required
						v-validate="modelValidations.budget"
					></md-input>

					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('budget') && touched.budget">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('budget') && touched.budget && budget.length > 0">done</md-icon>
					</slide-y-down-transition>
				</md-field>
				<p v-if="jobType === 'Once-off Project/Task' && (budget < 400 || !budget || budget === 0)" style="margin-top: 1rem; text-align: left; font-size: 10px; color: red">
					{{ budgetError }}
				</p>
				<div v-if="jobType !== 'Once-off Project/Task'" class="ranges">
					<md-field :class="{ 'md-valid': isSalaryMinValid, 'md-error': !isSalaryMinValid && touched.salaryMin }">
						<label>Minimum (ZAR) </label>
						<md-input
							@change="addBudget"
							@blur="setTouched('salaryMin')"
							v-model="salaryMin"
							data-vv-name="salaryMin"
							type="number"
							step="10"
							required
							v-validate="modelValidations.minSalary"
							style="outline: none; border: none; width: auto; text-align: left"
						></md-input>
						<span class="md-helper-text">Please enter a minimum salary</span>
					</md-field>

					<md-field style="margin-top: 3rem" :class="{ 'md-valid': isSalaryMaxValid, 'md-error': !isSalaryMaxValid && touched.salaryMax }">
						<label>Maximum (ZAR) </label>
						<md-input
							@change="addBudget"
							@blur="setTouched('salaryMax')"
							v-model="salaryMax"
							data-vv-name="salaryMax"
							type="number"
							:min="modelValidations.salary.min_value"
							:max="modelValidations.salary.min_value + 50000"
							step="10"
							required
							v-validate="modelValidations.maxSalary"
							style="outline: none; border: none; width: auto; text-align: left"
						></md-input>
						<span class="md-helper-text">Please enter a maximum salary</span>
					</md-field>
					<p v-if="minAngleError" style="margin-top: 1rem; text-align: left; font-size: 10px; color: red">
						{{ minAngleError }}
					</p>
				</div>
			</div>

			<div class="salary_range">
				<p v-if="jobType === 'Once-off Project/Task'">
					Jobox service fee ({{ percentage() }}%): <b>R{{ fee() }}</b
					><br />
					Admin fee: <b>R{{ price.facilitationFee }}.00</b> <br /><br />
					<span style="font-size: 20px"
						>Total:
						<b
							><u>R{{ total() }}</u></b
						></span
					>
				</p>
				<p v-else>
					<span style="font-size: 20px"
						>Salary Range:
						<b
							><u>R {{ salaryMin }} - {{ salaryMax }}</u></b
						></span
					>
				</p>
			</div>
		</div>
	</div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions';
import firebase from 'firebase/compat/app';
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
export default {
	components: {
		SlideYDownTransition,
	},
	props: {
		jobType: {},
	},
	data() {
		return {
			budgetError: null,
			minAngleError: false,
			microsDoc: null,
			budget: null,
			benefit: null,
			price: {},
			list: {},
			salary: {
				min: 2000,
				max: 4000,
			},
			minAngle: 2000,
			maxAngle: 4000,
			touched: {
				budget: false,
			},
			modelValidations: {
				budget: {
					required: true,
					min_value: 400,
				},
				salary: {
					required: true,
					min_value: 2000,
				},
				minSalary: {
					required: true,
					min_value: 3500,
				},
				benefit: {
					required: true,
				},
			},
		};
	},
	computed: {
		salaryMin: {
			get: function () {
				var val = parseInt(this.minAngle);
				return val;
			},
			set: function (val) {
				val = parseInt(val);
				this.minAngle = val;
			},
		},
		salaryMax: {
			get: function () {
				var val = parseInt(this.maxAngle);
				return val;
			},
			set: function (val) {
				val = parseInt(val);
				this.maxAngle = val;
			},
		},
		isSalaryMinValid() {
			return this.salaryMin >= 2000;
		},
		isSalaryMaxValid() {
			return this.salaryMax >= this.salaryMin;
		},
	},
	methods: {
		setTouched(field) {
			this.touched[field] = true;
		},
		validate() {
			this.budgetError = false;
			this.minAngleError = false;

			const isOnceOffProject = this.jobType === 'Once-off Project/Task';

			const validateBudget = () => {
				if (!this.budget || typeof this.budget === 'object' || this.budget === 0) {
					this.budgetError = 'Please enter a budget';
					return false;
				}
				if (this.budget < 400) {
					this.budgetError = 'Budget is less than 400, you cannot proceed';
					return false;
				}
				return true;
			};

			const validateAngle = () => {
				if (!this.minAngle || !this.maxAngle) {
					this.minAngleError = 'Please enter a minimum and a maximum value';
					return false;
				}
				if (this.minAngle < 2000) {
					this.minAngleError = 'Minimum amount is R2000.00 per month.';
					return false;
				}
				if (this.maxAngle < this.minAngle) {
					this.minAngleError = 'Your maximum budget cannot be less than your minimum';
					return false;
				}
				return true;
			};

			return isOnceOffProject ? validateBudget() : validateAngle();
		},

		getError(fieldName) {
			return this.errors.first(fieldName);
		},
		addBudget: function () {
			if (this.jobType !== 'Once-off Project/Task') {
				this.budget = {
					min: this.salaryMin,
					max: this.salaryMax,
				};
			}

			this.$emit('budget', this.budget);
		},
		addBenefit: function () {
			this.$emit('benefit', this.benefit);
		},
		total() {
			let total = (this.budget * (1 + this.price.serviceFee) + this.price.facilitationFee).toFixed(2);
			return total;
		},
		percentage() {
			return this.price.serviceFee * 100;
		},
		fee() {
			let fee = (this.budget * this.price.serviceFee).toFixed(2);
			return fee;
		},
	},
	watch: {
		salaryMin(newVal) {
			if (this.touched.salaryMin) {
				this.validate();
			}
		},
		salaryMax(newVal) {
			if (this.touched.salaryMax) {
				this.validate();
			}
		},
	},
	created() {
		let settings = db.collection('Settings');
		settings
			.doc('Business Model')
			.get()
			.then(doc => {
				this.price = doc.data();
			});
		settings
			.doc('Drop-down Lists')
			.get()
			.then(doc => {
				this.list = doc.data();
			});
		let job = db.collection('micros').doc(this.$route.params.id);
		job.get()
			.then(doc => {
				if (doc.exists) {
					let selectedJob = doc.data();
					if (Object.keys(selectedJob.budget)?.length > 0) {
						this.salaryMin = selectedJob.budget.min;
						this.salaryMax = selectedJob.budget.max;
					}
				} else {
					console.log('No such document!');
				}
			})
			.catch(error => {
				console.log('Error getting document:', error);
			});

		this.user = firebase.auth().currentUser;
		let ref = db.collection('jobs');
		ref.where('clientId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					if (doc.exists) {
						this.microsDoc = db.collection('micros').doc(this.$route.params.id);
						this.skillsDoc = db.collection('skills').doc(this.$route.params.id);
						this.jobsDoc = db.collection('jobs').doc(this.$route.params.id);
						this.microsDoc.get().then(doc => {
							if (doc.exists) {
								this.budget = doc.data().budget;
								this.$emit('budget', this.budget);
								let payment = db.collection('payments').doc(this.$route.params.id);
								payment.get().then(doc => {
									this.paid = doc.data().inboundPayment;
								});
							}
						});
					}
				});
			});
	},
};
</script>
<style scoped>
.range-slider {
	width: 300px;
	margin: auto;
	text-align: center;
	position: relative;
	height: 6em;
}

.range-slider input[type='range'] {
	position: absolute;
	left: -3px;
	bottom: -30px;
}

#once-off-budget {
	text-align: left;
}

input[type='number'] {
	border: 1px solid #ddd;
	text-align: center;
	font-size: 1.6em;
	-moz-appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='number']:invalid,
input[type='number']:out-of-range {
	border: 2px solid #ff6347;
}

input[type='range'] {
	-webkit-appearance: none;
	width: 100%;
}

input[type='range']:focus {
	outline: none;
}

input[type='range']:focus::-webkit-slider-runnable-track {
	background: #2497e3;
}

input[type='range']:focus::-ms-fill-lower {
	background: #2497e3;
}

input[type='range']:focus::-ms-fill-upper {
	background: #2497e3;
}

input[type='range']::-webkit-slider-runnable-track {
	width: 100%;
	height: 5px;
	cursor: pointer;
	animate: 0.2s;
	background: #2497e3;
	border-radius: 1px;
	box-shadow: none;
	border: 0;
	border-radius: 5px;
}

input[type='range']::-webkit-slider-thumb {
	z-index: 2;
	position: relative;
	box-shadow: 0px 0px 0px #000;
	border: 1px solid #2497e3;
	height: 18px;
	width: 18px;
	border-radius: 25px;
	background: #a1d0ff;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -7px;
}

.salary_range {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.ranges {
	width: 400px;
	margin: 2rem auto;
}

input[type='number'][data-v-70b3c6b9]:invalid,
input[type='number'][data-v-70b3c6b9]:out-of-range,
input[type='number'][data-v-70b3c6b9] {
	border: none !important;
	outline: none !important;
}
</style>
