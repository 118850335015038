<template>
	<form @submit.prevent="registerUser({ email, password })" class="md-layout text-center">
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div>
				<img src="@/assets/img/logo.png" />
				<div class="loading"></div>
			</div>
		</div>
		<div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
			<login-card header-color="green">
				<h3 slot="title" class="title">Jobox Workspace</h3>
				<md-field
					class="md-form-group"
					slot="inputs"
					:class="[{ 'md-valid': !errors.has('firstName') && touched.firstName }, { 'md-form-group': true }, { 'md-error': errors.has('firstName') }]"
				>
					<md-icon>face</md-icon>
					<label>First Name </label>
					<md-input v-model="firstName" type="firstName" data-vv-name="firstName" name="firstName" required v-validate="modelValidations.firstName"></md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('firstName')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('firstName') && touched.firstName">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<md-field
					class="md-form-group"
					slot="inputs"
					:class="[{ 'md-valid': !errors.has('surname') && touched.surname }, { 'md-form-group': true }, { 'md-error': errors.has('surname') }]"
				>
					<md-icon>person</md-icon>
					<label>Surname</label>
					<md-input v-model="surname" data-vv-name="surname" name="surname" type="surname" required v-validate="modelValidations.surname"></md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('surname')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('surname') && touched.surname">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<md-field
					class="md-form-group"
					slot="inputs"
					:class="[{ 'md-valid': !errors.has('email') && touched.email }, { 'md-form-group': true }, { 'md-error': errors.has('email') }]"
				>
					<md-icon>email</md-icon>
					<label>Email </label>
					<md-input v-model="email" type="email" data-vv-name="email" name="email" required v-validate="modelValidations.email"></md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('email')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('email') && touched.email">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<md-field
					class="md-form-group"
					slot="inputs"
					:class="[{ 'md-valid': !errors.has('password') && touched.password }, { 'md-form-group': true }, { 'md-error': errors.has('password') }]"
				>
					<md-icon>lock_outline</md-icon>
					<label>Password</label>
					<md-input v-model="password" data-vv-name="password" name="password" type="password" required v-validate="modelValidations.password"></md-input>
					<slide-y-down-transition>
						<md-icon class="error" v-show="errors.has('password')">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!errors.has('password') && touched.password">done</md-icon>
					</slide-y-down-transition>
				</md-field>

				<button class="md-button jb-standard" slot="footer" :disabled="isProcessing">
					<div class="md-ripple">
						<div class="md-button-content">Let's go</div>
					</div>
				</button>
				<router-link to="/forgot-password" slot="hyperlink" style="color: blue"> Forgot Password? </router-link>
			</login-card>
		</div>
		<!-- Modal: Error handling -->
		<modal v-if="modal" @close="modalHide">
			<template slot="header">
				<h4 class="modal-title black">{{ header }}</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="modalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<p class="black">{{ body }}</p>
			</template>
			<template slot="footer">
				<div class="centre">
					<md-button class="md-button md-success" @click="modalHide">{{ footer }}</md-button>
				</div>
			</template>
		</modal>
	</form>
</template>
<script>
import { LoginCard, Modal } from '@/components';
import { SlideYDownTransition } from 'vue2-transitions';
import { ValidationProvider, extend } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';

export default {
	components: {
		LoginCard,
		Modal,
		SlideYDownTransition,
	},
	data() {
		return {
			isProcessing: false,
			clientAlias: null,
			email: null,
			password: null,
			firstName: null,
			surname: null,
			touched: {
				email: false,
				password: false,
				firstName: false,
				surname: false,
			},
			modelValidations: {
				email: {
					required: true,
					email: true,
				},
				password: {
					required: true,
					min: 6,
					regex: '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])',
				},
				firstName: {
					required: true,
				},
				surname: {
					required: true,
				},
			},
		};
	},
	computed: {
		...mapGetters({
			header: 'headerU',
			body: 'bodyU',
			footer: 'footerU',
			modal: 'modalU',
			loading: 'loadingU',
		}),
	},
	methods: {
		...mapActions(['registerInvitedClient', 'modalHide']),
		async registerUser() {
			this.isProcessing = true;
			await this.registerInvitedClient({
				email: this.email,
				password: this.password,
				firstName: this.firstName,
				surname: this.surname,
				clientAlias: this.clientAlias,
			});
			this.isProcessing = false;
		},
	},
	watch: {
		email() {
			this.touched.email = true;
		},
		password() {
			this.touched.password = true;
		},
		firstName() {
			this.touched.firstName = true;
		},
		surname() {
			this.touched.surname = true;
		},
	},
	created() {
		this.clientAlias = this.$route.params.clientAlias;
	},
};
</script>
