import { render, staticRenderFns } from "./EditAppointment.vue?vue&type=template&id=e183dfae&scoped=true&"
import script from "./EditAppointment.vue?vue&type=script&lang=js&"
export * from "./EditAppointment.vue?vue&type=script&lang=js&"
import style0 from "./EditAppointment.vue?vue&type=style&index=0&id=e183dfae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e183dfae",
  null
  
)

export default component.exports