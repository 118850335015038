<template>
	<div>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<div v-if="jobs.length > 0">
			<AppliedJobsTable
				:data="jobs"
				:columns="[
					{ label: 'Company Name', key: 'companyName' },
					{ label: 'Job Name', key: 'name' },
					{ label: 'Status', key: 'status' },
				]"
			/>
		</div>
		<div v-else>
			<h1 class="black centre">You have no open applications</h1>
		</div>
	</div>
</template>

<script>
import AppliedJobsTable from '@/components/Tables/AppliedJobsDataTable.vue';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default {
	components: {
		AppliedJobsTable,
	},
	data() {
		return {
			jobs: [],
			loading: true,
		};
	},
	async created() {
		let user = firebase.auth().currentUser;
		let applications = db.collection('applications');
		let applicationList = await applications.where('studentId', '==', user.uid).where('status', '!=', 'cancel').get();
		applicationList.docs.forEach(async doc => {
			let jobDoc = await db.collection('jobs').doc(doc.data().jobId).get();
			let job = jobDoc.data();
			job.companyName = job.clientProfile.companyName;
			job.status = doc.data().applicationStatus;
			job.id = doc.data().jobId;
			this.jobs.push(job);
		});
		this.loading = false;
	},
};
</script>

<style scoped></style>
