<template>
	<div>
		<h6 class="centre">Client Information</h6>
		<br />
		<div class="md-layout">
			<div class="md-layout-item md-small-size-100 profile">
				<div class="md-card-avatar clientImg">
					<img v-if="picture" class="img" :src="picture" />
					<img v-else class="img" :src="cardUserImage" />
				</div>
				<br />
				<h4>
					<router-link class="card-title" :to="{ name: 'view-client-profile', params: { id: clientAlias } }"
						><a>{{ companyName }}</a></router-link
					>
				</h4>
				<p class="card-description">
					{{ name }}
				</p>
				<p style="text-align: center">
					Email us at: <b><a style="color: blue" href="mailto:contact@joinjobox.com">contact@joinjobox.com</a></b>
				</p>

				<div v-if="this.applicationStatus === 'active'">
					<div is="script" src="https://platform.linkedin.com/in.js" type="text/javascript"></div>
					<div is="script" type="IN/Share" data-url="https://www.joinjobox.com"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		companyName: { type: String },
		clientName: { type: String },
		clientAlias: { type: String },
		name: { type: String },
		picture: { type: Boolean },
		applicationStatus: { type: String },
		cardUserImage: {
			type: String,
			default: '/img/dashboard/client/card-1.jpg',
		},
	},
};
</script>

<style>
.md-card-profile {
	box-shadow: none;
}
.profile {
	display: flex;
	flex-direction: column;
	position: relative;
	text-align: center;
}
.clientImg img {
	width: 20% !important;
	height: 10%;
}
</style>
