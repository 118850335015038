<template>
	<div>
		<div class="md-layout">
			<md-card>
				<md-card-content>
					<collapse :collapse="['Description', 'Requirements', 'Funding Cover', 'Additional Submissions']" icon="keyboard_arrow_down" color-collapse="success">
						<template slot="md-collapse-pane-1">
							<md-card class="bg-success">
								<md-card-content>
									<p><strong>Title:</strong> {{ title }}</p>
									<p><strong>Description:</strong> <span v-html="description"></span></p>
									<p><strong>Work Placement:</strong> {{ workPlacement }}</p>
									<p v-if="workPlacement === 'Yes'"><strong>Work Placement Information:</strong> <span v-html="workPlacementInfo"></span></p>
									<p><strong>Application Deadline:</strong> {{ deadline }}</p>
								</md-card-content>
							</md-card>
						</template>
						<template slot="md-collapse-pane-2">
							<md-card class="bg-success">
								<md-card-content>
									<p v-if="requirements"><strong>Requirements:</strong> <span v-html="requirements"></span></p>
									<p v-if="cv"><strong>CV:</strong> Required</p>
									<p v-if="id"><strong>ID:</strong> Required</p>
									<p v-if="proofOfIncome"><strong>Proof of Income:</strong> Required</p>
									<p v-if="matricResults"><strong>Matric Results:</strong> Required</p>
									<p v-if="academicTranscript"><strong>Academic Transcript:</strong> Required</p>
									<p v-if="tuitionQuotation"><strong>Tuition Quotation:</strong> Required</p>
									<p v-if="other"><strong>Other:</strong> Required</p>
									<div v-if="additionalDocuments.length > 0">
										<h5>Additional Documents:</h5>
										<ul>
											<li v-for="(doc, index) in additionalDocuments" :key="index">{{ doc.name }}</li>
										</ul>
									</div>
									<p v-if="documentInstructions"><strong>Document Submission Instructions:</strong> <span v-html="documentInstructions"></span></p>
								</md-card-content>
							</md-card>
						</template>
						<template slot="md-collapse-pane-3">
							<md-card class="bg-success">
								<md-card-content>
									<p v-if="tuition"><strong>Tuition:</strong> Covered</p>
									<p v-if="accommodation"><strong>Accommodation:</strong> Covered</p>
									<p v-if="meals"><strong>Meals:</strong> Covered</p>
									<p v-if="books"><strong>Books:</strong> Covered</p>
									<p v-if="stipend"><strong>Stipend:</strong> Covered</p>
									<p v-if="additionalCoverDetails"><strong>Additional Cover Details:</strong> <span v-html="additionalCoverDetails"></span></p>
								</md-card-content>
							</md-card>
						</template>
						<template slot="md-collapse-pane-4">
							<md-card class="bg-success">
								<md-card-content>
									<p><strong>Video Submission:</strong> {{ videoSubmission }}</p>
									<p v-if="videoSubmission === 'Yes'"><strong>Video Submission Details:</strong> <span v-html="videoSubmissionDetails"></span></p>
									<p><strong>Written Submission:</strong> {{ writtenSubmission }}</p>
									<p v-if="writtenSubmission === 'Yes'"><strong>Written Submission Details:</strong> <span v-html="writtenSubmissionDetails"></span></p>
									<br />
									<br />
								</md-card-content>
							</md-card>
						</template>
					</collapse>
				</md-card-content>
			</md-card>
			<div class="justify-between">
				<md-button class="md-raised" @click="previousStep"><md-icon>arrow_back</md-icon>Previous</md-button>
				<md-button class="md-raised md-primary" @click="postBursary">Post Bursary</md-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Collapse } from '@/components';
import moment from 'moment';
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default {
	components: {
		Collapse,
	},
	props: {
		title: String,
		description: String,
		workPlacement: String,
		workPlacementInfo: String,
		deadline: String,
		requirements: String,
		cv: Boolean,
		id: Boolean,
		proofOfIncome: Boolean,
		matricResults: Boolean,
		academicTranscript: Boolean,
		tuitionQuotation: Boolean,
		other: Boolean,
		additionalDocuments: Array,
		documentInstructions: String,
		tuition: Boolean,
		accommodation: Boolean,
		meals: Boolean,
		books: Boolean,
		stipend: Boolean,
		additionalCoverDetails: String,
		videoSubmission: String,
		videoSubmissionDetails: String,
		writtenSubmission: String,
		writtenSubmissionDetails: String,
		resetSteps: Boolean,
	},
	methods: {
		postBursary() {
			this.$emit('postBursary');
			this.resetSteps = true;
		},
		previousStep() {
			this.$emit('previous', 'review', 'additional');
		},
	},
};
</script>

<style scoped>
.card-description {
	color: #353535 !important;
}

.card-title {
	font-weight: 500 !important;
	margin-bottom: 0px !important;
}

.md-card {
	margin-bottom: 20px;
}
</style>
