<template>
	<form @submit.prevent="confirm">
		<modal v-if="modal">
			<template slot="header">
				<h4 class="modal-title black">Education</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="closeModal">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<div v-if="loading" class="lds-circle loader">
					<div class="loader">
						<div class="lds-circle">
							<div style="position: relative">
								<img src="@/assets/img/logo.png" />
								<div class="loading"></div>
							</div>
						</div>
					</div>
				</div>
				<!-- Currently Studying -->
				<select-field
					v-model="studying"
					:value="studying"
					name="studying"
					iconName="school"
					label="Are You Currently Studying"
					:options="['Yes', 'No']"
					:required="true"
					:error="getErrorMessage('studying')"
				></select-field>
				<!-- Institution -->
				<select-field
					v-if="status === 'update' || institutions.length"
					v-model="institution"
					:value="institution"
					name="institution"
					iconName="school"
					:label="studying == 'Yes' ? 'Where are you studying?' : 'Where did you study?'"
					:options="institutions"
					:required="true"
					@change="updateValue"
					@opened="onInputOpen"
					:error="getErrorMessage('institution')"
				></select-field>
				<text-field v-show="institution === 'Other'" name="institutionName" v-model="institutionName" label="Institution Name" iconName="school"></text-field>
				<select-field
					v-if="status === 'update' || institutions.length"
					v-model="fundedBy"
					:value="fundedBy"
					name="fundedBy"
					iconName="money"
					:label="studying == 'Yes' ? 'How are you funding your studies?' : 'How did you fund your studies?'"
					:options="fundingOptions"
					:required="true"
					:multiple="true"
					:error="getErrorMessage('fundedBy')"
				></select-field>
				<select-field
					v-if="status === 'update' || (institution && faculties.length)"
					v-model="faculty"
					:value="faculty"
					name="faculty"
					iconName="school"
					label="Faculty"
					:options="faculties"
					:required="true"
					:error="getErrorMessage('faculty')"
					@change="updateValue"
					@opened="onInputOpen"
				></select-field>
				<select-field
					v-if="status === 'update' || (institution && faculty && graduateStatuses.length)"
					v-model="graduateStatus"
					:value="graduateStatus"
					name="graduateStatus"
					iconName="school"
					label="Graduate Status"
					:options="graduateStatuses"
					:required="true"
					:error="getErrorMessage('graduateStatus')"
					@change="updateValue"
					@opened="onInputOpen"
				></select-field>
				<select-field
					v-if="status === 'update' || (institution && faculty && graduateStatus && qualificationTypes.length)"
					v-model="qualificationType"
					:value="qualificationType"
					name="qualificationType"
					iconName="school"
					label="Qualification Type"
					:options="qualificationTypes"
					:required="true"
					:error="getErrorMessage('qualificationType')"
					@change="updateValue"
					@opened="onInputOpen"
				></select-field>
				<select-field
					v-if="status === 'update' || (institution && faculty && graduateStatus && qualificationType)"
					v-model="year"
					:value="year"
					name="year"
					iconName="school"
					label="Year"
					:options="ugYears"
					:required="true"
					:error="getErrorMessage('year')"
				></select-field>
				<select-field
					v-if="status === 'update' || (institution && faculty && graduateStatus && qualificationType && degrees.length)"
					v-model="degree"
					:value="degree"
					name="degree"
					iconName="school"
					label="Degree"
					:options="degrees"
					:required="true"
					:error="getErrorMessage('degree')"
				></select-field>
				<!-- Start Date -->
				<date-picker name="startDate" v-model="startDate" label="Start Date" :error="getErrorMessage('startDate')"></date-picker>
				<!-- End Date -->
				<date-picker v-show="studying === 'No'" name="endDate" label="End Date" v-model="endDate" :error="getErrorMessage('endDate')"></date-picker>
				<!-- Student Number -->
				<text-field name="studentNo" v-model="studentNo" label="Student Number" iconName="school" :error="getErrorMessage('studentNo')"></text-field>
				<!-- Average Mark -->
				<div style="width: 100%; margin: 1rem 0">
					<label style="width: 100%; margin: 2rem 0">You can leave out your academic average & transcript if you don't know it</label>
				</div>
				<div style="width: 100%; margin-top: 2rem">
					<text-field
						name="average"
						v-model.number="average"
						label="What is your latest academic average?"
						iconName="functions"
						:error="getErrorMessage('average')"
					></text-field>
				</div>
				<!-- Academic Transcript -->
				<md-field :class="{ 'md-error': getErrorMessage(academicTranscript) }" style="margin-top: 2rem">
					<label>Please Upload your latest academic transcript</label>
					<md-file @change="previewPdf" v-model="academicTranscript" data-vv-name="academicTranscript" name="academicTranscript" accept="application/pdf" />
				</md-field>
				<span v-if="getErrorMessage(academicTranscript)" class="error_message">{{ getErrorMessage(academicTranscript) }}</span>
			</template>
			<template slot="footer">
				<div style="text-align: right">
					<md-button class="md-button md-danger" @click="closeModal()">Cancel</md-button>
					&nbsp;
					<md-button class="md-button md-success" style="background-color: green !important" @click="confirm">Confirm</md-button>
				</div>
			</template>
		</modal>
	</form>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, between, requiredIf } from '@vuelidate/validators';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { Modal } from '@/components';
import SelectField from '@/components/Inputs/SelectField.vue';
import DatePicker from '@/components/Inputs/DatePicker.vue';
import { UG_YEAR_OF_STUDY, FUNDING } from '@/constants/qualification.const';
import { getCurrentDateTime, convertDateToEpoch } from '@utils';
import TextField from '@/components/Inputs/TextField.vue';
import { requiredValidator, pdfFileValidator, numericValidator } from '@/helpers/validators.helper';
import { createStorageRef, uploadFile, updateQualificationDocument } from '@/utils/upload.util.js';

const studyingValidator = function () {
	return this.studying === 'No';
};

const endDateValidator = (value, vm) => {
	if (vm.studying && vm.studying === 'No') {
		return convertDateToEpoch(value) > convertDateToEpoch(vm.startDate);
	}
	return false;
};

export default {
	components: {
		Modal,
		SelectField,
		DatePicker,
		TextField,
	},
	props: {
		selectedQualification: [Number, String],
		student: Object,
		status: String,
	},
	data() {
		return {
			average: null,
			alias: null,
			firebaseUrl: null,
			name: null,
			academicTranscript: null,
			qualification: null,
			user: null,
			institutions: [],
			institution: '',
			institutionName: '',
			studying: null,
			fundedBy: null,
			startDate: null,
			endDate: null,
			campus: null,
			faculties: [],
			faculty: '',
			studentNo: null,
			degrees: [],
			degree: '',
			ugYears: Object.values(UG_YEAR_OF_STUDY),
			fundingOptions: Object.values(FUNDING),
			year: null,
			graduateStatuses: [],
			graduateStatus: '',
			qualificationTypes: [],
			qualificationType: '',
			readOnlyModal: false,
			loading: false,
			isChangeTriggered: false,
			hasValueChanged: false,
		};
	},
	validations() {
		return {
			average: {
				between: helpers.withMessage('Academic average must be between 0 and 100.', between(0, 100)),
				numericValidator,
			},
			academicTranscript: {},
			institution: { required: requiredValidator },
			institutionName: { requiredIf: this.institution === 'Other' },
			studying: { required: requiredValidator },
			fundedBy: { required: requiredValidator },
			faculty: { required: requiredValidator },
			studentNo: { required: requiredValidator },
			degree: { required: requiredValidator },
			year: { required: requiredValidator },
			graduateStatus: { required: requiredValidator },
			qualificationType: { required: requiredValidator },
			startDate: { required: requiredValidator },
			endDate: { required: requiredIf(endDateValidator) },
		};
	},
	setup: () => ({ v$: useVuelidate() }),
	validationConfig: {
		$lazy: true,
		$autoDirty: true,
	},
	methods: {
		onInputOpen() {
			this.isChangeTriggered = true;
		},
		updateValue() {
			if (this.isChangeTriggered) {
				this.hasValueChanged = true;
			}
		},
		closeModal() {
			this.isChangeTriggered = false;
			this.hasValueChanged = false;
			this.modalHide();
		},
		previewPdf(event) {
			const file = event.target.files[0];
			const name = event.target.name;
			this.fileUpload(file, name.toString());
		},
		async fileUpload(data, location) {
			try {
				const storageRef = createStorageRef(this.alias, location, data.name);

				uploadFile(
					storageRef,
					data,
					snapshot => {
						this.loading = true;
					},
					error => {
						this.loading = false;
						throw new Error('Cannot upload file, an error has occurred');
					},
					async firebaseUrl => {
						this.firebaseUrl = firebaseUrl;
						this.loading = false;
						if (location === 'academic_transcript') {
							this.academicTranscript = data.name;
							this.qualification = this.student.collection('qualifications').doc('qualification' + this.selectedQualification);

							try {
								await updateQualificationDocument(this.qualification, this.academicTranscript, this.firebaseUrl);
							} catch (error) {
								throw new Error('Cannot update qualification document, an error has occurred');
							}
						}
					}
				);
			} catch (error) {
				this.loading = false;
				throw new Error('Cannot upload file, an error has occurred');
			}
		},
		async confirm() {
			const isFormValid = await this.v$.$validate();
			if (isFormValid) {
				await this.confirmQualification();
			}
		},
		async confirmQualification() {
			if (this.status === 'add') {
				let qualificationID = Date.now();
				this.qualification = this.student.collection('qualifications').doc('qualification' + qualificationID);
				await this.qualification.set({
					average: this.average,
					academicTranscript: { fileName: this.academicTranscript, url: this.firebaseUrl },
					qualificationNum: qualificationID,
					institution: this.institution,
					institutionName: this.institutionName,
					studying: this.studying,
					campus: this.campus,
					faculty: this.faculty,
					studentNo: this.studentNo,
					qualificationType: this.qualificationType,
					graduateStatus: this.graduateStatus,
					degree: this.degree,
					year: this.year,
					fundedBy: this.fundedBy,
					startDate: moment(this.startDate).format('L') || null,
					endDate: moment(this.endDate).format('L') || null,
					lastModified: getCurrentDateTime(),
				});
			} else if (this.status === 'update') {
				this.qualification = this.student.collection('qualifications').doc('qualification' + this.selectedQualification);
				const doc = await this.qualification.get();
				if (doc.exists) {
					if (this.institution) {
						this.qualification.update({
							institution: this.institution,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.institutionName) {
						this.qualification.update({
							institutionName: this.institutionName,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.fundedBy) {
						this.qualification.update({
							fundedBy: this.fundedBy,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.average) {
						this.qualification.update({
							average: this.average,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.academicTranscript) {
						this.qualification.update({
							academicTranscript: { fileName: this.academicTranscript, url: this.firebaseUrl },
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.studying) {
						this.qualification.update({
							studying: this.studying,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.startDate || this.startDate === null) {
						this.qualification.update({
							startDate: moment(this.startDate).format('L') || null,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.endDate || this.endDate === null) {
						this.qualification.update({
							endDate: moment(this.endDate).format('L') || null,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.campus) {
						this.qualification.update({
							campus: this.campus,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.faculty) {
						this.qualification.update({
							faculty: this.faculty,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.studentNo) {
						this.qualification.update({
							studentNo: this.studentNo,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.degree) {
						this.qualification.update({
							degree: this.degree,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.major) {
						this.qualification.update({
							major: this.major,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.year) {
						this.qualification.update({
							year: this.year,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.graduateStatus) {
						this.qualification.update({
							graduateStatus: this.graduateStatus,
							lastModified: getCurrentDateTime(),
						});
					}
					if (this.qualificationType) {
						this.qualification.update({
							qualificationType: this.qualificationType,
							lastModified: getCurrentDateTime(),
						});
					}
				}
			}

			this.$notify({
				message: 'Your data has been saved!',
				icon: 'add_alert',
				horizontalAlign: 'center',
				verticalAlign: 'top',
				type: 'success',
			});

			this.modalHide();
		},
		editStudies() {
			this.readOnlyModal = true;
			this.modal = false;
		},
		async deleteQualification() {
			this.qualification = await this.student.collection('qualifications').doc('qualification' + this.selectedQualification);
			await this.qualification.delete();
			this.decrementQualificationNum();
			this.modalHide();
		},
		clearModal() {
			this.institution = null;
			this.institutionName = null;
			this.studying = null;
			this.fundedBy = null;
			this.startDate = null;
			this.endDate = null;
			this.campus = null;
			this.faculty = null;
			this.studentNo = null;
			this.degree = null;
			this.year = null;
			this.qualificationType = null;
		},
		getErrorMessage(field) {
			if (!field) return;
			const fieldObj = this.v$[field];
			return fieldObj?.$dirty && fieldObj?.$error && fieldObj?.$errors[0]?.$message;
		},
		...mapActions(['modalHide', 'incrementQualificationNum', 'decrementQualificationNum']),
	},
	computed: {
		...mapGetters({
			modal: 'getModal',
		}),
	},
	watch: {
		selectedQualification: {
			immediate: true,
			async handler(newVal) {
				if (this.status === 'update') {
					this.loading = true;
					this.qualification = this.student.collection('qualifications').doc(`qualification${newVal}`);
					const qualificationData = (await this.qualification.get()).data();
					let snapshot = await db.collection('Settings/Institutions/Faculties').where('institution', '==', newVal).get();
					const faculties = [];
					snapshot.forEach(doc => {
						faculties.push(doc.data());
					});
					this.faculties = faculties.map(f => f.name);

					snapshot = await db.collection('Settings/Institutions/Qualifications').where('institution', '==', this.institution).where('faculty', '==', newVal).get();
					const graduateStatuses = [];
					snapshot.forEach(doc => {
						graduateStatuses.push(doc.data());
					});
					this.graduateStatuses = graduateStatuses.map(f => f.name);

					snapshot = await db
						.collection('Settings/Institutions/Qualification-Types')
						.where('institution', '==', this.institution)
						.where('faculty', '==', this.faculty)
						.where('qualification', '==', newVal)
						.get();
					const qualificationTypes = [];
					snapshot.forEach(doc => {
						qualificationTypes.push(doc.data());
					});
					this.qualificationTypes = qualificationTypes.map(f => f.name);

					snapshot = await db
						.collection('Settings/Institutions/Degrees')
						.where('institution', '==', this.institution)
						.where('faculty', '==', this.faculty)
						.where('qualification', '==', this.graduateStatus)
						.where('qualificationType', '==', newVal)
						.get();
					const degrees = [];
					snapshot.forEach(doc => {
						degrees.push(doc.data());
					});
					this.degrees = degrees.map(d => d.name);
					this.average = qualificationData.average;
					this.academicTranscript = qualificationData.academicTranscript ? qualificationData.academicTranscript.fileName : null;
					this.institution = qualificationData.institution;
					this.studying = qualificationData.studying;
					this.startDate = moment(qualificationData.startDate, 'DD/MM/YYYY', true).isValid() ? new Date(qualificationData.startDate) : new Date('01/01/2023');
					this.endDate = new Date(qualificationData.endDate) || null;
					this.campus = qualificationData.campus;
					this.faculty = qualificationData.faculty;
					this.studentNo = qualificationData.studentNo;
					this.degree = qualificationData.degree;
					this.year = qualificationData.year;
					this.graduateStatus = qualificationData.graduateStatus;
					this.qualificationType = qualificationData.qualificationType;
					this.fundedBy = qualificationData.fundedBy;

					this.loading = false;
				} else {
					this.clearModal();
				}
			},
		},
		institution: {
			immediate: true,
			async handler(newVal) {
				if (!newVal) return;
				this.loading = true;
				try {
					const snapshot = await db.collection('Settings/Institutions/Faculties').where('institution', '==', newVal).get();
					const faculties = [];
					snapshot.forEach(doc => {
						faculties.push(doc.data());
					});
					this.faculties = faculties.map(f => f.name).sort();
					this.faculty = this.hasValueChanged ? null : this.faculty;
					this.loading = false;
				} catch (e) {
					this.$notify({
						message: e.message,
						icon: 'add_alert',
						horizontalAlign: 'center',
						verticalAlign: 'top',
						type: 'danger',
						duration: 0,
					});
					this.loading = false;
				}
			},
		},
		faculty: {
			immediate: true,
			async handler(newVal) {
				if (!newVal) return;
				this.loading = true;
				try {
					const snapshot = await db.collection('Settings/Institutions/Qualifications').where('institution', '==', this.institution).where('faculty', '==', newVal).get();
					const graduateStatuses = [];
					snapshot.forEach(doc => {
						graduateStatuses.push(doc.data());
					});
					this.graduateStatuses = [...new Map(graduateStatuses.map(f => [f.name, f.name]).reverse()).values()];
					this.graduateStatus = this.hasValueChanged ? null : this.graduateStatus;
					this.loading = false;
				} catch (e) {
					this.$notify({
						message: e.message,
						icon: 'add_alert',
						horizontalAlign: 'center',
						verticalAlign: 'top',
						type: 'danger',
						duration: 0,
					});
					this.loading = false;
				}
			},
		},
		graduateStatus: {
			immediate: true,
			async handler(newVal) {
				if (!newVal) return;
				this.loading = true;
				try {
					const snapshot = await db
						.collection('Settings/Institutions/Qualification-Types')
						.where('institution', '==', this.institution)
						.where('faculty', '==', this.faculty)
						.where('qualification', '==', newVal)
						.get();
					const qualificationTypes = [];
					snapshot.forEach(doc => {
						qualificationTypes.push(doc.data());
					});
					this.qualificationTypes = [...new Map(qualificationTypes.map(f => [f.name, f.name]).sort()).values()];
					this.qualificationType = this.hasValueChanged ? null : this.qualificationType;
					this.loading = false;
				} catch (e) {
					this.$notify({
						message: e.message,
						icon: 'add_alert',
						horizontalAlign: 'center',
						verticalAlign: 'top',
						type: 'danger',
						duration: 0,
					});
					this.loading = false;
				}
			},
		},
		qualificationType: {
			immediate: true,
			async handler(newVal) {
				if (!newVal) return;
				this.loading = true;
				try {
					const snapshot = await db
						.collection('Settings/Institutions/Degrees')
						.where('institution', '==', this.institution)
						.where('faculty', '==', this.faculty)
						.where('qualification', '==', this.graduateStatus)
						.where('qualificationType', '==', newVal)
						.get();
					const degrees = [];
					snapshot.forEach(doc => {
						degrees.push(doc.data());
					});
					this.degrees = degrees.map(d => d.name).sort();
					this.degree = this.hasValueChanged ? null : this.degree;
					this.loading = false;
				} catch (e) {
					this.$notify({
						message: e.message,
						icon: 'add_alert',
						horizontalAlign: 'center',
						verticalAlign: 'top',
						type: 'danger',
						duration: 0,
					});
					this.loading = false;
				}
			},
		},
	},
	async created() {
		this.loading = true;

		try {
			const snapshot = await db.collection('Settings/Institutions/Names').get();
			let institutions = [];
			snapshot.forEach(doc => {
				institutions.push(doc.data());
			});
			this.institutions = institutions.map(i => i.name).sort();
			const indexItem = this.institutions.indexOf('Other');
			this.institutions.splice(indexItem, 1);
			this.institutions.push('Other');
			this.loading = false;
		} catch (e) {
			console.log(e);
			this.loading = false;
		}
		let user = firebase.auth().currentUser;
		let ref = db.collection('users');
		ref.where('userId', '==', user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.alias = doc.id;
				});
			});
	},
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
	.md-field label {
		font-size: 11px;
	}
}
.loader {
	width: 100%;
	height: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 150px;
	margin: auto;
}
.error_message {
	color: red;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 10px;
	padding-top: 0%;
}
</style>
