<template>
	<div>
		<h5 class="info-text">Have one last final look at the job you are about to post</h5>
		<div class="md-layout">
			<md-card>
				<md-card-content>
					<collapse
						:collapse="
							jobType === 'Once-off Project/Task'
								? ['Job Description', 'Job Requirements', 'Job Information', 'Cost Breakdown']
								: ['Job Description', 'Job Requirements', 'Job Information']
						"
						icon="keyboard_arrow_down"
						color-collapse="success"
					>
						<template slot="md-collapse-pane-1">
							<md-card class="bg-success">
								<md-card-content>
									<h3 class="card-category card-category-social" style="text-align: center"><i class="fas fa-list-ul"></i> Job Description</h3>
									<h4 class="card-title">Job Name</h4>
									<p class="card-description">{{ name }}</p>

									<h4 class="card-title">Job Description</h4>
									<p class="card-description" v-html="description"></p>

									<h4 class="card-title">Job Title</h4>
									<p class="card-description">{{ jobCategory }}</p>

									<h4 class="card-title">Skills Required</h4>
									<ul v-if="skills">
										<li v-for="(skill, index) in skills" :key="index" class="card-description">{{ skill.skillName }}</li>
									</ul>
									<h4 class="card-title">Salary</h4>
									<p class="card-description">{{ formatBudget }}</p>
								</md-card-content>
							</md-card>
						</template>
						<template slot="md-collapse-pane-2">
							<md-card class="bg-success">
								<md-card-content>
									<h3 class="card-category card-category-social" style="text-align: center"><i class="fas fa-dungeon"></i> Job Requirements</h3>
									<h4 class="card-title">Minimum Level of Education</h4>
									<p class="card-description">{{ education }}</p>

									<h4 class="card-title">Minimum Work Experience</h4>
									<p class="card-description">{{ experience }}</p>
								</md-card-content>
							</md-card>
						</template>
						<template slot="md-collapse-pane-3">
							<md-card class="bg-success">
								<md-card-content>
									<h3 class="card-category card-category-social" style="text-align: center"><i class="fas fa-clipboard-list"></i> Job Information</h3>
									<h4 class="card-title">Location</h4>
									<p class="card-description">{{ location }}</p>

									<h4 class="card-title">Application Deadline</h4>
									<p class="card-description">{{ date }}</p>

									<h4 v-if="jobType === 'Once-off Project/Task'" class="card-title">Estimated Duration</h4>
									<p v-if="jobType === 'Once-off Project/Task'" class="card-description">{{ deadline }}</p>

									<h4 v-if="jobType !== 'Once-off Project/Task'" class="card-title">Working Days</h4>
									<ul v-if="jobType !== 'Once-off Project/Task'">
										<li v-for="days in daysOfTheWeek" :key="days" class="card-description">{{ days }}</li>
									</ul>

									<h4 v-if="jobType === 'Internship' || jobType === 'Part-time'" class="card-title">Working Hours Per Week</h4>
									<p v-if="jobType === 'Internship' || jobType === 'Part-time'" class="card-description">{{ hours }}</p>
								</md-card-content>
							</md-card>
						</template>
						<template slot="md-collapse-pane-4">
							<md-card v-show="jobType === 'Once-off Project/Task'" class="bg-success">
								<md-card-content>
									<h3 class="card-category card-category-social" style="text-align: center"><i class="fas fa-wallet"></i> Breakdown</h3>
									<h4 class="card-title">Total Cost</h4>
									<p class="card-description">
										<b>R{{ total() }}</b> = <b>R{{ rate() }}</b> + <b>R{{ fee() }}</b> + <b>R{{ price.facilitationFee }}.00</b>
									</p>
									<hr />
									<h4 class="card-title">Cost Breakdown</h4>
									<p class="card-description" v-if="jobType === 'Once-off Project/Task'">
										Fixed-Term Rate: <b>R{{ rate() }}</b>
									</p>
									<p class="card-description" v-if="jobType !== 'Once-off Project/Task'">
										Salary: <b>R{{ rate() }}</b>
									</p>
									<p class="card-description">
										Jobox Service Fee ({{ percentage() }}%): <b>R{{ fee() }}</b>
									</p>
									<p class="card-description">
										Jobox Facilitation Cost: <b>R{{ price.facilitationFee }}.00</b>
									</p>
								</md-card-content>
							</md-card>
						</template>
					</collapse>
				</md-card-content>
			</md-card>
		</div>
	</div>
</template>
<script>
import { IconCheckbox, Collapse } from '@/components';
import { SlideYDownTransition } from 'vue2-transitions';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import formatBudget from '../../../../../../../../mixins/formatBudget';

export default {
	components: {
		Collapse,
	},
	props: {
		name: {},
		description: {},
		jobType: {},
		education: {},
		experience: {},
		industryCategory: {},
		jobCategory: {},
		skills: [],
		location: {},
		deadline: {},
		daysOfTheWeek: {},
		hours: {},
		budget: {},
		payment: {},
		applicationDeadline: {},
	},
	mixins: [formatBudget],
	data() {
		return {
			deadlineReview: null,
			price: {},
		};
	},
	methods: {
		total() {
			let total = (this.budget * (1 + this.price.serviceFee) + this.price.facilitationFee).toFixed(2);
			return total;
		},
		rate() {
			let rate = (this.budget * 1).toFixed(2);
			return rate;
		},
		fee() {
			let fee = (this.budget * this.price.serviceFee).toFixed(2);
			return fee;
		},
		percentage() {
			return this.price.serviceFee * 100;
		},
	},
	computed: {
		date() {
			return moment(this.applicationDeadline).format('LL');
		},
	},
	created() {
		let businessModel = db.collection('Settings').doc('Business Model');
		businessModel.get().then(doc => {
			this.price = doc.data();
		});
	},
};
</script>
<style scoped>
.card-description {
	color: #353535 !important;
}

.card-title {
	font-weight: 500 !important;
	margin-bottom: 0px !important;
}
</style>
