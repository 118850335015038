<template>
	<div>
		<br />
		<div style="display: flex; flex-direction: column">
			<div class="row">
				<md-icon :class="v$.coverOptions.$error && 'error'" style="margin-right: 1rem">school</md-icon>
				<label :style="v$.coverOptions.$error && 'color:red'">What does the bursary cover?</label>
			</div>
			<p v-if="v$.coverOptions.$error" class="error_message">Please select at least one option</p>
			<md-checkbox @change="addTuition" v-model="tuition">Tuition</md-checkbox>
			<md-checkbox @change="addAccommodation" v-model="accommodation">Accommodation</md-checkbox>
			<md-checkbox @change="addMeals" v-model="meals">Meals</md-checkbox>
			<md-checkbox @change="addBooks" v-model="books">Books</md-checkbox>
			<md-checkbox @change="addStipend" v-model="stipend">Stipend</md-checkbox>
		</div>
		<br />
		<div class="row">
			<md-icon :class="v$.additionalCoverDetails.$error && 'error'" style="margin-right: 1rem">description</md-icon>
			<label :style="v$.additionalCoverDetails.$error && 'color:red'">Additional Bursary Cover Details</label>
		</div>
		<p v-if="v$.additionalCoverDetails.$error" class="error_message">Additional details are required</p>
		<vue-editor style="margin: 1rem 0 3rem 0 !important" v-model="additionalCoverDetails" @text-change="addAdditionalCoverDetails" />

		<div class="justify-between">
			<md-button class="md-raised" @click="previousStep"><md-icon>arrow_back</md-icon>Previous</md-button>
			<md-button class="md-raised md-primary" @click="nextStep">Next<md-icon>arrow_forward</md-icon></md-button>
		</div>
	</div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
	components: {
		VueEditor,
	},
	data() {
		return {
			tuition: false,
			accommodation: false,
			meals: false,
			books: false,
			stipend: false,
			additionalCoverDetails: '',
		};
	},
	setup() {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			coverOptions: {
				validator: () => {
					return this.tuition || this.accommodation || this.meals || this.books || this.stipend;
				},
			},
			additionalCoverDetails: { required },
		};
	},
	methods: {
		nextStep() {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				this.$emit('next');
			}
		},
		previousStep() {
			this.$emit('previous', 'funding', 'requirements');
		},
		addTuition() {
			this.$emit('tuition', this.tuition);
		},
		addAccommodation() {
			this.$emit('accommodation', this.accommodation);
		},
		addMeals() {
			this.$emit('meals', this.meals);
		},
		addBooks() {
			this.$emit('books', this.books);
		},
		addStipend() {
			this.$emit('stipend', this.stipend);
		},
		addAdditionalCoverDetails() {
			this.$emit('additionalCoverDetails', this.additionalCoverDetails);
		},
	},
};
</script>
