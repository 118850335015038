export const ApplicationStatus = {
	APPLIED: 'Applied',
	WITHDRAWN: 'Withdrawn',
	INTERVIEWREQUEST: 'Interview Request Sent',
	OFFERSENT: 'Offer Sent',
	OFFERREJECTED: 'Offer Rejected',
	IN_PROGRESS: 'In Progress',
	DISQUALIFIED: 'Disqualified',
	COMPLETED: 'Completed',
	TIMED_OUT: 'Timed Out',
	ACTIVE: 'Active',
	SHORTLIST: 'Shortlist',
	CANCEL: 'Cancel',
	DECLINE: 'Decline',
	CONTINUE: 'Continue Test',
};
