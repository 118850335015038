<template>
	<modal v-if="visible" @close="hideModal">
		<template slot="header">
			<h4 class="modal-title black">{{ this.title }}</h4>
			<md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideModal">
				<md-icon>clear</md-icon>
			</md-button>
		</template>

		<template slot="body">
			<p class="black">{{ this.message }}</p>
		</template>

		<template slot="footer">
			<div style="text-align: center">
				<md-button class="md-button md-danger" @click="onNoClick">{{ this.noLabel || 'No' }}</md-button>
				&nbsp;&nbsp;&nbsp;
				<md-button class="md-button md-success" @click="onYesClick">{{ this.yesLabel || 'Yes' }}</md-button>
			</div>
		</template>
	</modal>
</template>

<script>
import { Modal } from '@/components';

export default {
	name: 'ConfirmModal',
	components: { Modal },
	props: {
		title: String,
		message: String,
		noLabel: String,
		yesLabel: String,
		visible: Boolean,
	},
	methods: {
		onYesClick(data) {
			this.$emit('onYesClick', data);
		},
		onNoClick(data) {
			this.$emit('onNoClick', data);
		},
		hideModal() {
			this.$emit('on-hide');
		},
	},
};
</script>
